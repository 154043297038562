import { useMemo } from 'react';
import { Doctor, DoctorCardLight } from '../types';

export const isDoctorCardLight = (doctor: Doctor): doctor is DoctorCardLight => {
  return (doctor as DoctorCardLight).light !== undefined;
};

const useIsDoctorCardLight = (doctor: Doctor): doctor is DoctorCardLight => {
  return useMemo(() => isDoctorCardLight(doctor), [doctor]);
};

export default useIsDoctorCardLight;
