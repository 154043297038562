/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { MapCameraChangedEvent } from '@vis.gl/react-google-maps/dist/components/map/use-map-events';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Nullable } from '../../../../../types/types';
import { calculateViewport } from '../../../../shared/helperMethods/HelperMethod';
import Images from '../../../../shared/utils/Images';
import { hideLoader } from '../../../../shared/utils/ReusableComponents';
import Clusters from './Clusters/Clusters';
import MapSideBar from './MapSideBar';
import './MapView.scss';
import Polygons from './Polygons';
import CaliforniaPlacesIDs from './Polygons/CaliforniaPlacesIDs';
import {
  API_KEY,
  CountyZoomRange,
  LocationZoomRange,
  MAP_ID,
  ZipCodeZoomRange,
  initialConfigMapView,
} from './constants';
import useMapContext from './hooks/useMapContext';
import { AreaType, Cluster, Doctor, LatLngLiteral, Point } from './types';

const ENV = process.env.REACT_APP_ENVIRONMENT;
type FeatureType = google.maps.FeatureType;

const getAreaTypeByZoom = (zoom: any): AreaType => {
  if (zoom >= ZipCodeZoomRange.min && zoom <= ZipCodeZoomRange.max) {
    return AreaType.Zipcode;
  }
  if (zoom >= LocationZoomRange.min) {
    return AreaType.Individual;
  }
  return AreaType.County;
};

const isLocationValid = (location: Nullable<LatLngLiteral> | null): location is LatLngLiteral => {
  return !!location && !!location.lat && !!location.lng;
};

interface MapViewProps {
  doctors: Doctor[];
  onRecordsChange?: (records: number) => void;
  onFilterChange: (filter: string, type: AreaType) => void;
  onSelectCounty: (county: string) => void;
  onSearchFomMap: () => void;
  fetchProviderProfile: (payload: any, callBack: any) => any;
  allData: any;
}

const MapView = ({
  doctors,
  onRecordsChange,
  onFilterChange,
  onSelectCounty,
  onSearchFomMap,
  fetchProviderProfile,
  allData,
}: MapViewProps): JSX.Element => {
  const { map } = useMapContext();
  const [clickedClustors, setClickedClustors] = useState<Point[]>([]);
  const [isMapSideBarShown, setIsMapSideBarShown] = useState(false);
  const [shownDoctors, setShownDoctors] = useState<Doctor[]>([]);
  const [isSideBarAbleToShow, setIsSideBarAbleToShow] = useState(false);
  const [isOpenedByUser, setIsOpenedByUser] = useState(false);
  const searchOnLoadCount = useRef(0);
  const zoomRef = useRef(initialConfigMapView.zoom);
  const { isZipSearch } = useMapContext();
  useEffect(() => {
    if (searchOnLoadCount.current === 0) {
      // onSearchFomMap();
    }
    searchOnLoadCount.current += 1;
    if (!doctors.length || !map) {
      setShownDoctors([]);
      return;
    }
    if (isZipSearch) {
      const {
        address: { zip },
      } = doctors[0];
      const featureStyleOptions = {
        strokeColor: '#E74235',
        strokeOpacity: 1.0,
        strokeWeight: 1.0,
        fillColor: '#FFFFFF',
        fillOpacity: 0.5,
      };
      const featureLayerZip = map.getFeatureLayer('POSTAL_CODE' as FeatureType);

      const zipBoundary = CaliforniaPlacesIDs[zip];
      map.fitBounds(zipBoundary.viewport!!);
      featureLayerZip.style = (options: any) => {
        if (options.feature.placeId === zipBoundary.placeId) {
          return featureStyleOptions;
        }
      };
    } else if (doctors.length < 10000 && zoomRef.current !== initialConfigMapView.zoom) {
      const locations = doctors
        .filter(({ address: { location } }) => isLocationValid(location))
        .map(({ address: { location } }) => location);
      const viewport = calculateViewport(locations);
      map.fitBounds(viewport);
    } else if (zoomRef.current === initialConfigMapView.zoom) {
      map.setZoom(CountyZoomRange.min);
      zoomRef.current = CountyZoomRange.min;
    }

    setShownDoctors(doctors);
    if (doctors.length === 0) {
      setIsMapSideBarShown(false);
      setIsSideBarAbleToShow(false);

      return;
    }

    setIsSideBarAbleToShow(true);
  }, [doctors, map]);

  const handleOpenSideBar = (): void => {
    setIsOpenedByUser(true);
    setIsMapSideBarShown((prev) => !prev);
  };

  useEffect(() => {
    if (shownDoctors.length === 0) {
      setIsMapSideBarShown(false);
      setIsSideBarAbleToShow(false);

      return;
    }

    setIsSideBarAbleToShow(true);
  }, [zoomRef.current]);

  const { clusters, areaType: type } = useMemo(() => {
    const areaType = getAreaTypeByZoom(zoomRef.current);

    const reducedClusters = shownDoctors.reduce((acc: Cluster[], currentDoctor: Doctor) => {
      const {
        _id,
        address: { county, location, zip },
      } = currentDoctor;

      if (!isLocationValid(location)) {
        return acc;
      }

      let clusterBy = county;
      if (areaType !== 'county') {
        clusterBy = areaType === AreaType.Zipcode ? zip : `${location.lat}${location.lng}`;
      }
      const index = acc.findIndex((item) => item.id === clusterBy);
      if (index === -1) {
        acc.push({
          id: clusterBy,
          points: [{ location, id: _id }],
          location: areaType !== AreaType.Individual ? CaliforniaPlacesIDs[clusterBy].center : location,
        });
        return acc;
      }
      acc[index].points.push({ location, id: _id });
      return acc;
    }, []);

    if (ENV !== 'production') {
      const doctorsWithoutLocation = shownDoctors.filter(({ address: { location } }) => !isLocationValid(location));
      if (doctorsWithoutLocation.length) {
        // eslint-disable-next-line no-console
        console.warn('Doctors without location (lat, lng):', doctorsWithoutLocation);
      }
    }
    if (!isOpenedByUser) {
      if (areaType === AreaType.Zipcode || areaType === AreaType.Individual) {
        setIsMapSideBarShown(true);
      } else {
        setIsMapSideBarShown(false);
      }
    }
    hideLoader();

    return {
      clusters: reducedClusters,
      areaType,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(shownDoctors), zoomRef.current]);

  const areas = useMemo(() => {
    return clusters.map(({ id }) => id);
  }, [clusters]);

  const handleZoomChange = (zoomEvent: MapCameraChangedEvent): void => {
    const { zoom: newZoom } = zoomEvent.detail;
    zoomRef.current = newZoom;
    setIsOpenedByUser(false);
  };

  const handleBoundsChange = ({ map: mapObject }: MapCameraChangedEvent): void => {
    const bounds = mapObject.getBounds();
    if (!bounds) {
      return;
    }
    let doctorsToShow = [];
    const areaType = getAreaTypeByZoom(zoomRef.current);
    if (areaType === AreaType.Individual) {
      doctorsToShow = doctors.filter(({ address: { location } }) =>
        clickedClustors.some((clickedClustor) => clickedClustor.location === location)
      );
    } else {
      doctorsToShow = doctors.filter(({ address: { location } }) => {
        if (!isLocationValid(location)) {
          return false;
        }

        return bounds.contains(location);
      });
    }

    setShownDoctors(doctorsToShow);

    if (onRecordsChange) {
      onRecordsChange(doctorsToShow.length);
    }
  };
  const handleFeatureClick = (placeId: string): void => {
    if (!map) {
      return;
    }

    const place = Object.entries(CaliforniaPlacesIDs).find(([, value]) => value.placeId === placeId);
    if (!place) {
      return;
    }

    const [filter, { viewport, type: filterType = AreaType.Zipcode }] = place;
    if (!viewport || !filterType) {
      return;
    }

    onFilterChange(filter, filterType);
    map.fitBounds(viewport);
    zoomRef.current = 10;
  };

  const handleClusterClick = (clusterPoints: Point[]): void => {
    const mapZoom = map?.getZoom();
    if (!map || !mapZoom) {
      return;
    }
    setClickedClustors(clusterPoints);
    const bounds = new google.maps.LatLngBounds();
    clusterPoints.forEach(({ location }) => bounds.extend(location));

    map.fitBounds(bounds);
  };

  return (
    <div id='map-view'>
      <div className='map-view-container'>
        {isSideBarAbleToShow && (
          <div className='sidebar-container'>
            {isMapSideBarShown && (
              <MapSideBar doctors={shownDoctors} fetchProviderProfile={fetchProviderProfile} allData={allData} />
            )}
            <img
              className={clsx({ open: isMapSideBarShown && isSideBarAbleToShow }, 'sidebar-tab')}
              src={isMapSideBarShown ? Images.tabSidebarOpen : Images.tabSidebarClose}
              alt=''
              onClick={() => handleOpenSideBar()}
            />
          </div>
        )}
        <div className={clsx({ small: isMapSideBarShown }, 'map-container')}>
          <APIProvider apiKey={API_KEY}>
            <Map
              mapId={MAP_ID}
              center={initialConfigMapView.center}
              zoom={zoomRef.current}
              // restriction={{
              //   latLngBounds: initialConfigMapView.latLngBounds,
              // }}
              isFractionalZoomEnabled={false}
              fullscreenControl={false}
              mapTypeControl={false}
              streetViewControl={false}
              minZoom={CountyZoomRange.min}
              maxZoom={LocationZoomRange.max}
              onZoomChanged={handleZoomChange}
              onBoundsChanged={handleBoundsChange}>
              <Polygons areaType={type} areas={areas} onFeatureClick={handleFeatureClick} />
              <Clusters clusters={clusters} onClusterClick={handleClusterClick} />
            </Map>
          </APIProvider>
        </div>
      </div>
    </div>
  );
};

export default MapView;
