import { Nullable } from '../../../../../types/types';

/* eslint-disable no-unused-vars */
export type Address = {
  county: string;
  location: Nullable<LatLngLiteral> | null;
  zip: string;
  city: string;
  state: string;
  street1: string;
  street2: string;
};

export type DoctorLocations = {
  additional: Doctor[];
  result: Doctor[];
};

export type Doctor = {
  _id: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  address: Address;
  clinicName: string;
  typeOfLicensure: string;
  phone: string;
  gender: string;
  medicalGroup: string;
  hospital: string[];
  hospitalist: string;
  hospitalPrivilegeStatus: string[];
  boardCertifiedOrEligible: string;
  npi: string;
  caLicense: string;
  nonCALicense: string;
  telehealth: boolean;
  nameOfNetwork: string[];
  hospitalNPI: string;
  providerLanguage: string[];
  acceptingNewPatients: boolean;
  hoursOfOperation: string;
  distance?: number;
  bookingType?: string;
  outsideSearch: Doctor[];
};

export type DoctorDetails = {
  bio?: string;
  video?: string;
  picture?: string;
  lgbtq?: boolean;
  pronoun?: string;
};

export type DoctorWithDetails = Doctor & DoctorDetails;
export type DoctorWithLocations = Doctor & { otherLocations: Doctor[] };

export type DoctorCardLight = Doctor & {
  light: boolean;
  details?: DoctorDetails;
};

export type LatLngLiteral = google.maps.LatLngLiteral;
export type LatLngBounds = google.maps.LatLngBounds;
export type LatLngBoundsLiteral = google.maps.LatLngBoundsLiteral;

export type Point = {
  location: LatLngLiteral;
  id: string;
};

export type Cluster = {
  id: string;
  points: Point[];
  location: LatLngLiteral;
};

export enum AreaType {
  County = 'county',
  Zipcode = 'zipcode',
  Individual = 'individual',
}
