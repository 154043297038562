/* eslint-disable no-underscore-dangle */
import * as actions from '@canopy/shared';
import { marked } from 'marked';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { POPUP_MESSAGE } from '../../../shared/constant/AppMessages';
import ComfirmationModal from '../../../shared/sharedComponents/confirmationModal/ComfirmationModal';
import Auth from '../../../shared/utils/Auth';
import images from '../../../shared/utils/Images';
import { hideLoader, showLoader } from '../../../shared/utils/ReusableComponents';

interface ownState {
  showError: boolean;
  errorMessage: string;
  errorTitle: string;
}

interface ownProps {
  closedPsaVersion: any;
  createGetUserData: any;
}
interface DispatchProps {
  createSetClosedPSARequest: typeof actions.createSetClosedPSARequest;
}
interface stateProps {
  data: any;
}

type componentProps = ownProps & DispatchProps & stateProps;
class PSA extends PureComponent<componentProps, ownState> {
  constructor(props: componentProps) {
    super(props);
    this.state = {
      showError: false,
      errorTitle: '',
      errorMessage: '',
    };
  }

  dismissPSA = (): any => {
    const { closedPsaVersion, createGetUserData, data } = this.props;
    if (!Auth.isAuthenticated()) {
      sessionStorage.setItem('closedPsaVersion', 'true');
      // eslint-disable-next-line react/destructuring-assignment
      closedPsaVersion();
    } else {
      const { createSetClosedPSARequest } = this.props;
      showLoader();
      createSetClosedPSARequest(
        {
          closedPsaVersion: '1',
        },
        (response: any) => {
          if (response.isSuccess) {
            closedPsaVersion();
            createGetUserData({ id: data?.userInfoData?.responsedata?.data?._id }, () =>
              console.log('Get user data:: Success ')
            );
            hideLoader();
          } else {
            hideLoader();
            this.setState({
              errorTitle: POPUP_MESSAGE(response.statusCode, response.message).title,
              errorMessage: POPUP_MESSAGE(response.statusCode, response.message).message,
              showError: true,
            });
          }
        }
      );
    }
  };

  getLocationLink = (location: any): any => {
    const { data } = this.props;
    const filteredData = data?.ConfigData?.getModuleConfigdata?.data?.addressableSpaces?.filter(
      (i: any) => i.location === location
    );
    return filteredData?.length > 0 && filteredData[0];
  };

  render(): React.ReactElement {
    const borderColor = this.getLocationLink('psa')?.borderColor;
    const { showError, errorMessage, errorTitle } = this.state;
    const items = this.getLocationLink('psa')?.items;
    const html1 = items[0] && marked.parse(items[0]?.text);
    const html2 = items[1] && marked.parse(items[1]?.text);
    return (
      <>
        <div
          className='welcome-new-member-container doctor-language-html psa-border col-12 pb-0'
          style={{ border: `2px solid ${borderColor}` }}>
          {this.getLocationLink('psa')?.items?.length === 1 &&
            this.getLocationLink('psa')?.items?.map((i: any) => {
              const html = marked.parse(i.text);
              return (
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: html,
                  }}
                />
              );
            })}
          {this.getLocationLink('psa')?.items?.length > 1 && (
            <div className='row'>
              <div
                className='col-6 separator position-relative'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: html1,
                }}
              />
              <div
                className='col-6'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: html2,
                }}
              />
            </div>
          )}

          <span
            role='none'
            className='delete-icon clickable psa'
            onClick={() => this.dismissPSA()}
            onKeyPress={() => this.dismissPSA()}>
            <img
              className='provider-image'
              src={images.icClose}
              srcSet={`${images.icClose2x} 2x, ${images.icClose3x} 3x`}
              alt=''
            />
          </span>
        </div>
        <ComfirmationModal
          title={errorTitle}
          message={errorMessage}
          closeModal={() => this.setState({ showError: false })}
          showModal={showError}
        />
      </>
    );
  }
}
const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createSetClosedPSARequest: actions.createSetClosedPSARequest,
};

export default connect<stateProps, DispatchProps, ownProps>(mapStateToProps, mapDispatchToProps)(PSA);
