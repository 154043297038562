import clsx from 'clsx';
import { marked } from 'marked';
import { useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import {
  ACCEPTING_NEW_PATIENTS,
  OFFICE_HOURS,
  REPORT_INACCURACIES_LABLE,
  SHARE_ERROR_TEXT,
} from '../../../../../../shared/constant/AppConstants';
import { POPUP_MESSAGE } from '../../../../../../shared/constant/AppMessages';
import { DOCTOR_REPORT_INACCURACIES } from '../../../../../../shared/constant/Urls';
import {
  convertToLowerCase,
  createPDFDataForMapView,
  formatPhone,
  formattedSchedule,
  formattedScheduleTime,
  getProviderName,
  preparePDFBlob,
} from '../../../../../../shared/helperMethods/HelperMethod';
// import Button from '../../../../../../shared/library/Button';
import ConfirmationModal from '../../../../../../shared/sharedComponents/confirmationModal/ComfirmationModal';
import Auth from '../../../../../../shared/utils/Auth';
import Images from '../../../../../../shared/utils/Images';
import { hideLoader, showLoader } from '../../../../../../shared/utils/ReusableComponents';
import AddressMap from '../../../AddressMap';
import PrintComponent from '../../../Print/PrintComponent';
// import useMapContext from '../../hooks/useMapContext';
import { DoctorWithDetails } from '../../types';
import './DoctorDetails.scss';

type Item = {
  icon: string;
  header: string;
  value: string | string[];
};

interface AdditionalInfoProps {
  item: Item;
}

const AdditionalInfo = ({ item: { icon, header, value } }: AdditionalInfoProps): JSX.Element => {
  return (
    <div className='additional-info-container'>
      <div className='additional-info-image'>
        <img src={icon} alt='' />
      </div>
      <div className='additional-info-right-side'>
        <span className='additional-info-header'>{header}</span>
        {value instanceof Array ? (
          <div className='additional-info-value-container'>
            {value.map((item) => (
              <span className='additional-info-value' key={item}>
                {item}
              </span>
            ))}
          </div>
        ) : (
          <span
            className={clsx(
              { capitalized: header === 'Languages' || header === 'County' || header === 'Clinic Name' },
              'additional-info-value'
            )}>
            {value}
          </span>
        )}
      </div>
    </div>
  );
};

interface DoctorDetailsProps extends DoctorWithDetails {
  onClose: () => void;
  fetchProviderProfile: any;
  doctorWithAllLocations: any;
  allData: any;
  pictureUrl: string;
}

const DoctorDetails = ({
  fetchProviderProfile,
  onClose,
  doctorWithAllLocations,
  allData,
  pictureUrl,
  ...doctor
}: DoctorDetailsProps): JSX.Element => {
  // const { isUserPreEffective, onBookAppointment } = useMapContext();
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [pdfData, setPdfData] = useState();
  const [pdfDetails, setPdfDetails] = useState();
  const [showVimTokenErrorModal, setShowVimTokenErrorModal] = useState(false);
  const [showEnableShareModal, setShowEnableShareModal] = useState(false);
  const printRef = useRef(null);

  const {
    _id,
    firstName,
    lastName,
    phone,
    clinicName,
    address,
    medicalGroup,
    hospital,
    hospitalPrivilegeStatus,
    boardCertifiedOrEligible,
    npi,
    caLicense,
    nonCALicense,
    telehealth,
    nameOfNetwork,
    hospitalNPI,
    providerLanguage,
    hoursOfOperation,
    // bookingType,
    bio,
    video,
    lgbtq,
    hospitalist,
  } = doctor;
  const { street1, city, state, zip, county } = address;
  const withRefHospitals = hospital.map(
    (h) => h + (hospitalPrivilegeStatus.length > 0 ? ' (Has Privileges, Referral Required)' : '')
  );
  const addInfo = useMemo(() => {
    return [
      { header: 'Medical Group IPA', value: medicalGroup, icon: Images.medicalGroup },
      { header: 'Clinic Name', value: convertToLowerCase(clinicName), icon: Images.clinic },
      { header: 'County', value: county.toLowerCase(), icon: Images.locationDot },
      { header: 'Languages', value: providerLanguage?.join(', ').toLowerCase(), icon: Images.languages },
      { header: 'Hospitalist', value: hospitalist ? 'Yes' : 'No', icon: Images.hospitalist },
      { header: 'Health Plan', value: nameOfNetwork, icon: Images.healthPlan },
      { header: 'Board Certified', value: boardCertifiedOrEligible === 'Y' ? 'Yes' : 'No', icon: Images.board },
      { header: 'NPI', value: npi, icon: Images.npi },
      { header: 'CA License', value: caLicense, icon: Images.awardSimple },
      { header: 'NON CA License', value: nonCALicense, icon: Images.awardSimple },
      { header: 'UHC Provider ID', value: '', icon: Images.hospitalsMap },
      { header: 'Telehealth', value: telehealth ? 'Yes' : 'No', icon: Images.telehealth },
      { header: 'Hospital NPI', value: hospitalNPI, icon: Images.hospitalNPI },
      { header: 'Hospitals', value: withRefHospitals, icon: Images.hospitalsMap },
    ];
  }, [
    boardCertifiedOrEligible,
    caLicense,
    clinicName,
    county,
    withRefHospitals,
    hospitalNPI,
    hospitalist,
    medicalGroup,
    nameOfNetwork,
    nonCALicense,
    npi,
    providerLanguage,
    telehealth,
  ]);

  // const handleBookApointment = (): void => {
  //   onBookAppointment(doctor);
  // };

  const fetchData = (id: any): any => {
    return new Promise((resolve, reject) => {
      showLoader();
      const pdfObj = createPDFDataForMapView(doctorWithAllLocations, id);
      const providerData = doctor;
      if (pdfObj.primaryLocation[0].clinicName) {
        try {
          fetchProviderProfile(
            { query: { npi: providerData.npi }, isAuthenticated: Auth.isAuthenticated() },
            async (response: any) => {
              if (response.isSuccess) {
                setPdfData(pdfObj);
                setPdfDetails(response?.data[0]);
                const element = printRef.current!!;
                const blob = await preparePDFBlob(element, pdfObj?.name);
                // const fileName = `${getProviderName(pdfObj?.name)}.pdf`;
                // const fileType = 'application/pdf';
                // const file = new File([blob], fileName, { type: fileType });
                resolve(blob);
                hideLoader();
              } else {
                reject(new Error('something bad happened'));
                hideLoader();
                setErrorMessage(POPUP_MESSAGE(response.statusCode, response.message).message);
                setErrorTitle(POPUP_MESSAGE(response.statusCode, response.message).title);
                setShowVimTokenErrorModal(true);
              }
            }
          );
        } catch (error) {
          hideLoader();
        }
      }
    });
  };

  const printProviderInfo = async (id: any): Promise<any> => {
    showLoader();
    const iframe = document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    document.body.appendChild(iframe);
    if (iframe) {
      const blob = await fetchData(id);
      const blobUrl = URL.createObjectURL(blob);
      iframe.src = blobUrl;
      iframe.onload = () => {
        hideLoader();
        setTimeout(() => {
          iframe.focus();
          if (iframe.contentWindow) {
            iframe.contentWindow.print();
          }
        }, 1);
      };
    }
  };

  const shareOtherLocation = async (id: any): Promise<any> => {
    if (id) {
      showLoader();
      const blob = await fetchData(id);
      // const fileName = `${getProviderName(pdfObj?.name)}.pdf`;
      const fileType = 'application/pdf';
      try {
        if (navigator.share) {
          const file = new File([blob], `${getProviderName(`${firstName} ${lastName}`)}.pdf`, { type: fileType });
          const shareData = {
            files: [file],
            title: 'Share Provider PDF: ',
          };
          await navigator
            .share(shareData)
            .then(() => {
              console.log('File shared successfully!');
            })
            .catch(() => {
              hideLoader();
              setShowEnableShareModal(true);
            });
          // await navigator.share({ files: [file] });
          hideLoader();
        } else {
          hideLoader();
          setShowEnableShareModal(true);
        }
      } catch (error) {
        hideLoader();
        // console.error('Error sharing:', error);
        // Handle the error, if needed
      }
    }
  };

  const getBioFormatted = (toFormatBio: string): JSX.Element => {
    return (
      <p
        className='bio'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: toFormatBio,
        }}
      />
    );
  };
  const scheduleObject = formattedSchedule(hoursOfOperation);
  return (
    <div className='doctor-details-inner-container'>
      {pdfData && (
        <PrintComponent
          reference={printRef}
          providerInfo={pdfData}
          providerDetails={pdfDetails}
          imageSrc={pictureUrl}
        />
      )}
      <div className='container-item'>
        {lgbtq && (
          <div className='img-text col-auto px-0 mt-2'>
            <img className='alert-icon img-fluid' src={Images.LGBTQ} alt='LGBTQ+' />
          </div>
        )}
        <div className='row'>
          <div className='col-6 ps-0' style={{ whiteSpace: 'nowrap' }}>
            <a href={`tel:${phone}`} className='phone-container-link'>
              <div className='phone-container'>
                <img src={Images.telephone} alt='' />
                <span>{formatPhone(phone)}</span>
              </div>
            </a>
          </div>
          <div className='col-6 d-lg-block d-xl-none text-end pe-0'>
            <div className='details-buttons'>
              <button type='button' aria-label='.' className='share-button' onClick={() => shareOtherLocation(_id)} />
              <button type='button' aria-label='.' className='print-button' onClick={() => printProviderInfo(_id)} />
              <button type='button' aria-label='.' className='close-button-red' onClick={onClose} />
            </div>
          </div>
        </div>

        <a
          href={`https://www.google.com/maps/place/${street1}+${city}+${state}+${zip}`}
          target='_blank'
          rel='noreferrer'
          className='provider-address-link'>
          <div className='provider-address'>
            <div className='clinic-container'>
              <img className='marker-icon' src={Images.location} alt='' />
              <div className='clinic-address-container capitalized'>
                <span>{convertToLowerCase(clinicName)}</span>
                <div className='address-container'>
                  <span>{convertToLowerCase(street1)}</span>
                  <span>{`${convertToLowerCase(city)}, ${state} ${zip}`}</span>
                </div>
              </div>
            </div>
          </div>
        </a>
        <div className='new-patients-container'>
          <img src={Images.greenSuccess} alt='' />
          <span className='new-patients'>{ACCEPTING_NEW_PATIENTS}</span>
        </div>
        {/* {bookingType !== '' && !isUserPreEffective && (
          <Button onClick={handleBookApointment} className='book-appointment'>
            Book Appointment
          </Button>
        )} */}
        {video && <ReactPlayer controls url={video} width='100%' height='160px' />}
        {bio && (
          <div className='bio-container'>
            {getBioFormatted(marked.parse(bio))}
            <span className='bio-author'>- Dr. {`${firstName.toLowerCase()} ${lastName.toLowerCase()}`}</span>
          </div>
        )}
        {hoursOfOperation && (
          <div className='office-hours-container'>
            <img className='hours-icon' src={Images.clock} alt='' />
            <div className='data-container'>
              <span className='hours-header'>{OFFICE_HOURS}</span>
              <div className='hours-container'>
                {Object.entries(scheduleObject).map(([day, time]) => {
                  return (
                    <div className='row mt-1'>
                      <div className='col-4'>{day}</div>
                      <div className='col-8 text-right'>{formattedScheduleTime(time)}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className='report-inaccuracy'>
          <a href={`${DOCTOR_REPORT_INACCURACIES}/${_id}`} target='_blank' rel='noreferrer'>
            <img
              className='img-fluid'
              src={Images.icDetailReport}
              srcSet={`${Images.icDetailReport2x} 2x, ${Images.icDetailReport3x} 3x`}
              alt='tick'
            />
            {REPORT_INACCURACIES_LABLE}
          </a>
        </div>
      </div>
      <div className='container-item'>
        <div className='details-buttons d-none d-sm-none d-lg-none d-xl-block'>
          <button type='button' aria-label='.' className='share-button' onClick={() => shareOtherLocation(_id)} />
          <button type='button' aria-label='.' className='print-button' onClick={() => printProviderInfo(_id)} />
          <button type='button' aria-label='.' className='close-button-red' onClick={onClose} />
        </div>
        <div className='additional-container'>
          <span className='header'>Additional Information</span>
          {addInfo.map((item) =>
            item.value instanceof Array
              ? item.value.length > 0 && <AdditionalInfo item={item} key={item.header} />
              : item.value && <AdditionalInfo item={item} key={item.header} />
          )}
        </div>
        <AddressMap address={address} />
      </div>
      {showVimTokenErrorModal && (
        <ConfirmationModal
          showModal={showVimTokenErrorModal}
          title={errorTitle}
          message={errorMessage}
          closeModal={() => setShowVimTokenErrorModal(false)}
        />
      )}
      {showEnableShareModal && (
        <ConfirmationModal
          title='Share not enabled'
          message={SHARE_ERROR_TEXT}
          showModal={showEnableShareModal}
          closeModal={() => setShowEnableShareModal(false)}
        />
      )}
    </div>
  );
};

export default DoctorDetails;
