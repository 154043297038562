import * as actions from '@canopy/shared';
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { LOGIN_PAGE_URL } from '../../shared/constant/Urls';
import Auth from '../../shared/utils/Auth';
import Images from '../../shared/utils/Images';
// import { height } from '@mui/system';
import { BrandingLocationEnum, CANCEL, CONFIRM_LABEL, LOGOUT_LABEL } from '../../shared/constant/AppConstants';
import { history } from '../../shared/helperMethods/HelperMethod';
import { hideLoggingOutLoader, showLoggingOutLoader } from '../../shared/utils/ReusableComponents';

interface ownState {
  isLogoutRequest: any;
}
interface ownProps {}
interface stateProps {
  data: any;
}
interface DispatchProps {
  createGetMainUnAuthConfigDataRequest: typeof actions.createGetMainUnAuthConfigDataRequest;
  createLogoutRequest: typeof actions.createLogoutRequest;
}

// const classList = {
//   spaceAround: {
//     paddingLeft: '5px',
//     paddingRight: '5px',
//   },
// };

type ComponentProps = ownProps & stateProps & DispatchProps;
class NavHeaderMobile extends PureComponent<ComponentProps, ownState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      isLogoutRequest: false,
    };
  }

  logout = (): any => {
    this.setState({ isLogoutRequest: true });
  };

  logoutUser = (): any => {
    showLoggingOutLoader();
    const { createGetMainUnAuthConfigDataRequest, createLogoutRequest } = this.props;
    createLogoutRequest({ token: Auth.getRefreshToken(), oktaUserId: Auth.getOktaUserId() }, () => {
      Auth.logout(() => {
        createGetMainUnAuthConfigDataRequest(() => {
          localStorage.clear();
          sessionStorage.clear();
          // this.deleteAllCookies();
          console.log('user logged out successfully');
          history.push(LOGIN_PAGE_URL);
          hideLoggingOutLoader();
        });
      });
    });
  };

  // deleteAllCookies = () => {
  //   var allCookies = document.cookie.split(';');

  //   // The "expire" attribute of every cookie is
  //   // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  //   for (var i = 0; i < allCookies.length; i++)
  //     document.cookie = allCookies[i] + '=;expires=' + new Date(0).toUTCString();
  // };

  getLocationLogo = (location: any): any => {
    const { data } = this.props;
    const brandsData = data?.ConfigData?.getMainConfigdata?.data?.branding?.filter((i: any) => i.location === location);
    return brandsData?.length > 0 && brandsData[0];
  };

  render(): React.ReactNode {
    const { isLogoutRequest } = this.state;
    const { data } = this.props;

    return (
      <div id='header' className={Auth.isAuthenticated() ? 'is-logged-in' : 'd-block d-lg-none '}>
        <div className='header-content'>
          <div
            className={`logos ps-2 ps-md-2 ps-xl-4 ${
              this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR) ? 'isforthLogo' : ''
            }`}>
            <div className='canopy-logo d-xl-none'>
              {this.getLocationLogo(BrandingLocationEnum.LOCATION_ONE)?.url?.mobile && (
                <picture className='d-sm-none'>
                  <img
                    alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_ONE)?.name}
                    src={this.getLocationLogo(BrandingLocationEnum.LOCATION_ONE)?.url?.mobile}
                    className='header-img-tri header-picture-mobile'
                  />
                </picture>
              )}
              {this.getLocationLogo(BrandingLocationEnum.LOCATION_ONE)?.url?.tablet && (
                <picture className='d-none d-sm-block d-lg-none'>
                  <img
                    alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_ONE)?.name}
                    src={this.getLocationLogo(BrandingLocationEnum.LOCATION_ONE)?.url?.tablet}
                    className='canopy-logo-for-tab'
                  />
                </picture>
              )}
              <picture className='d-none '>
                <img
                  alt='log'
                  src={Images.imgLogoCanopyBlue}
                  srcSet={`${Images.imgLogoCanopyBlue}, ${Images.imgLogoCanopyBlue2x} 2x, ${Images.imgLogoCanopyBlue3x} 3x`}
                  className='header-picture '
                />
              </picture>
            </div>
            {(this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.url?.mobile ||
              this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.url?.tablet ||
              this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.url?.web) && (
              <div
                className={`tri-logos ${this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)
                  ?.name.split(' ')
                  .join('')}`}>
                {this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.url?.mobile && (
                  <picture className='d-sm-none'>
                    <img
                      alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.name}
                      src={this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.url?.mobile}
                      className='header-img-tri header-picture'
                    />
                  </picture>
                )}
                {this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.url?.tablet && (
                  <picture className='d-sm-block d-lg-none d-none'>
                    <img
                      alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.name}
                      src={this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.url?.tablet}
                      className='header-picture'
                    />
                  </picture>
                )}
                {this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.url?.web && (
                  <picture className='d-lg-block d-none'>
                    <img
                      alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.name}
                      src={this.getLocationLogo(BrandingLocationEnum.LOCATION_TWO)?.url?.web}
                      className='header-picture'
                      // style={{ height: 39 }}
                    />
                  </picture>
                )}
              </div>
            )}
            {(this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.url?.mobile ||
              this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.url?.tablet ||
              this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.url?.web) && (
              <div
                className={`tri-logos ${this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)
                  ?.name.split(' ')
                  .join('')}`}>
                {this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.url?.mobile && (
                  <picture className='d-sm-none'>
                    <img
                      alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.name}
                      src={this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.url?.mobile}
                      className='header-img-tri header-picture-mobile'
                    />
                  </picture>
                )}
                {this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.url?.tablet && (
                  <picture className='d-none d-sm-block d-lg-none'>
                    <img
                      alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.name}
                      src={this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.url?.tablet}
                      className='header-picture'
                    />
                  </picture>
                )}
                {this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.url?.web && (
                  <picture className='d-none  d-lg-block'>
                    <img
                      alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.name}
                      src={this.getLocationLogo(BrandingLocationEnum.LOCATION_THREE)?.url?.web}
                      className='header-picture'
                    />
                  </picture>
                )}
              </div>
            )}
            {(this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.url?.mobile ||
              this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.url?.tablet ||
              this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.url?.web) && (
              <div
                className={`tri-logos ${this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)
                  ?.name.split(' ')
                  .join('')}`}>
                {this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.url?.mobile && (
                  <picture className='d-sm-none'>
                    <img
                      alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.name}
                      src={this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.url?.mobile}
                      className='header-img-tri header-picture-mobile'
                    />
                  </picture>
                )}
                {this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.url?.tablet && (
                  <picture className='d-none d-sm-block d-lg-none'>
                    <img
                      alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.name}
                      src={this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.url?.tablet}
                      className='header-picture'
                    />
                  </picture>
                )}
                {this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.url?.web && (
                  <picture className='d-none  d-lg-block'>
                    <img
                      alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.name}
                      src={this.getLocationLogo(BrandingLocationEnum.LOCATION_FOUR)?.url?.web}
                      className='header-picture'
                    />
                  </picture>
                )}
              </div>
            )}
          </div>
          <div>
            <div className='hamburger'>
              <button
                className='navbar-toggler p-0'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarToggleExternalContent'
                aria-controls='navbarToggleExternalContent'
                aria-expanded='false'
                aria-label='Toggle navigation'>
                <img
                  alt='log'
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarToggleExternalContent'
                  aria-controls='navbarToggleExternalContent'
                  // aria-expanded='false'
                  aria-label='Toggle navigation'
                  src={Images.icMenu}
                  srcSet={`${Images.icMenu2x} 2x, ${Images.icMenu3x} 3x`}
                  className='hamburger-icon'
                />
              </button>
            </div>
            <div className='name-pic-logout'>
              {isLogoutRequest ? (
                <div className='double-check'>
                  <div className='warning-logout pe-3'>e</div>
                  <div
                    className='btn btn-main btn-cancel-confirm'
                    role='button'
                    tabIndex={0}
                    onClick={() => this.setState({ isLogoutRequest: false })}
                    onKeyPress={() => this.setState({ isLogoutRequest: false })}>
                    {CANCEL}
                  </div>
                  <div
                    className='btn btn-main btn-logout'
                    role='button'
                    tabIndex={0}
                    onClick={() => this.logoutUser()}
                    onKeyPress={() => this.logoutUser()}>
                    {CONFIRM_LABEL}
                  </div>
                </div>
              ) : (
                <>
                  <span
                    className='navbar-text navbar-right user-name clickable'
                    onClick={() => history.push('/')}
                    onKeyPress={() => history.push('/')}
                    tabIndex={0}
                    role='button'>
                    {data?.userInfoData?.responsedata?.data?.empi?.firstName.charAt(0).toUpperCase() +
                      data?.userInfoData?.responsedata?.data?.empi?.firstName.slice(1).toLowerCase()}{' '}
                    {data?.userInfoData?.responsedata?.data?.empi?.lastName.charAt(0).toUpperCase() +
                      data?.userInfoData?.responsedata?.data?.empi?.lastName.slice(1).toLowerCase()}
                  </span>
                  <div className='user-img-header'>
                    <div className=' '>
                      <img
                        alt='log'
                        src={
                          data?.AccountInfo?.getPhotoResponse?.data?.data?.bodyInfo
                            ? `data:image/png;base64,${data?.AccountInfo?.getPhotoResponse?.data?.data?.bodyInfo}`
                            : ''
                        }
                        className='rounded-circle image-cropper'
                      />
                    </div>
                  </div>
                  <div className='ui-split' />
                  <div
                    className='btn btn-main btn-logout'
                    onClick={() => this.logout()}
                    onKeyPress={() => this.logout()}
                    role='button'
                    tabIndex={0}>
                    {LOGOUT_LABEL}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetMainUnAuthConfigDataRequest: actions.createGetMainUnAuthConfigDataRequest,
  createLogoutRequest: actions.createLogoutRequest,
};

export default connect<stateProps, DispatchProps, ownProps>(mapStateToProps, mapDispatchToProps)(NavHeaderMobile);
