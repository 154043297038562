import React from 'react';
import ConfirmationModal from '../../../shared/sharedComponents/confirmationModal/ComfirmationModal';
import { trackException } from '../../utils/ApplicationInsightsServices';
import { hideLoader } from '../../utils/ReusableComponents';

interface ErrorBoundaryState {
  showErrorModal: boolean;
  isDisconnected: boolean;
}

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { showErrorModal: false, isDisconnected: false };
  }

  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidCatch(error: any, info: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      showErrorModal: true,
    });
    // You can also log error messages to an error reporting service here
    trackException('Error cought by Error Boundry: ' + JSON.stringify(error) + JSON.stringify(info));
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            let connectionStatus = this.state.isDisconnected;
            this.setState({ isDisconnected: false }, () => {
              if(connectionStatus === true){
                window.location.reload();
              }
              return clearInterval(webPing);;
            });
          })
          .catch(() => this.setState({ isDisconnected: true }));
      }, 2000);
      return;
    }

    this.setState({ isDisconnected: true });
    hideLoader();
  };

  render() {
    const { showErrorModal, isDisconnected } = this.state;
    if (showErrorModal || isDisconnected) {
      return (
        <>
          {(showErrorModal) && (
            <ConfirmationModal
              title='Something went wrong'
              message='Sorry for the inconvenience, an unknown error has occurred. Please try again later or contact the Canopy Health Contact Center 1-866-810-1498.'
              showModal={showErrorModal}
              closeModal={() => this.setState({ showErrorModal: false })}
            />
          )}
          {(isDisconnected) && (
            <ConfirmationModal
              title='Network Error'
              message='There was a problem connecting internet. Please check your internet connection and try again.'
              showModal={isDisconnected}
              closeModal={() => this.setState({ isDisconnected: false })}
            />
          )}
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
