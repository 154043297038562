/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouteProps } from 'react-router';

export interface IProps extends RouteProps {
  component?: any;
  layout: React.ComponentClass<any>;
  pageTitle?: string;
}

const GuestRoute = ({ component: Component, layout: Layout, path: Path, pageTitle, ...rest }: IProps): JSX.Element => {
  return (
    <Route
      {...rest}
      key={window.location.pathname.split('/')[1]}
      render={(props) => (
        <Layout>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default GuestRoute;
