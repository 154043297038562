import React from 'react';
import { ACCESS_EXTERNAL_SITE_TITLE_TXT, NO_LABEL, YES_LABEL } from '../../constant/AppConstants';
import { NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG } from '../../constant/AppMessages';

export default function AccessExternalSiteDialog({ link, onOK }: any): JSX.Element {
  return (
    <div className='modal-dialog modal-dialog-centered'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title' id='AccessExternalSiteLabel'>
            {ACCESS_EXTERNAL_SITE_TITLE_TXT}
          </h5>
        </div>
        <div className='modal-body'>
          <p className='modal-p'>{NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG}</p>
        </div>
        <div className='modal-footer text-center justify-content-end'>
          <a type='button' href={link} target='_blank' rel='noreferrer'>
            <button
              type='button'
              className='btn btn-primary me-2'
              data-bs-dismiss='modal'
              onClick={onOK}>
              {' '}
              {YES_LABEL}
            </button>
          </a>
          <button type='button' className='btn btn-primary' data-bs-dismiss='modal'>
            {NO_LABEL}
          </button>
        </div>
      </div>
    </div>
  );
}
