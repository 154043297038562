import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Auth from '../../shared/utils/Auth';
import Footer from '../footer/Footer';
import NavHeaderMobile from '../header/NavHeaderMobile';
import NavMenu from '../header/NavMenu';
import NavMenuMobile from '../header/NavMenu-Mobile';

type Props = RouteComponentProps<any>;

export default class GuestLayout extends React.PureComponent<Props, {}> {
  public render(): React.ReactNode {
    const { children, location } = this.props;
    return (
      <div key={location?.key}>
        <div id='highlight-line' />
        {/* Mobile-menu */}
        <div
          className='mobile-menu-container navbar-collapse collapse d-lg-none d-lg-none-new '
          id='navbarToggleExternalContent'>
          <NavMenuMobile />
        </div>
        {/* Mobile-menu */}
        <div id='main-container'>
          <div id='sidebar'>
            <div className='row'>
              <div className='col-12 navigation'>
                <NavMenu />
              </div>
            </div>
          </div>
          <div id='content'>
            {/* Mobile-Header */}
            <NavHeaderMobile />
            {/* Mobile-Header */}
            <div className='scrolling-content'>
              <div className='row'>
                <div id='view' className={Auth.isAuthenticated() ? 'isLoggedIn' : ''}>
                  <div>
                    <div>{children}</div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
