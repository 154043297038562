// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.other-location .address-link {
  color: #4a4a4a;
  text-decoration: none;
}

@media (min-width: 1280px) {
  .col-8.col-md-9.col-lg-9.px-0.col-add {
    width: 82% !important;
  }
  .col-4.col-md-3.col-lg-3.col-print-share {
    width: 18% !important;
  }
  .col-9.col-md-9.col-lg-9.px-0.col-add {
    width: 83%;
  }
  .col-print-share.col-3.col-md-3.col-lg-3.pe-0.ps-0.d-flex.align-items-end.justify-content-end.justify-content-md-between.flex-column {
    width: 17%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/screens/doctorsAndServices/doctors/OtherLocation/OtherLocation.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,qBAAA;AAAJ;;AAGA;EACE;IACE,qBAAA;EAAF;EAGA;IACE,qBAAA;EADF;EAGA;IACE,UAAA;EADF;EAGA;IACE,UAAA;EADF;AACF","sourcesContent":[".other-location {\n  .address-link {\n    color: #4a4a4a;\n    text-decoration: none;\n  }\n}\n@media (min-width: 1280px) {\n  .col-8.col-md-9.col-lg-9.px-0.col-add {\n    width: 82% !important;\n  }\n\n  .col-4.col-md-3.col-lg-3.col-print-share {\n    width: 18% !important;\n  }\n  .col-9.col-md-9.col-lg-9.px-0.col-add {\n    width: 83%;\n  }\n  .col-print-share.col-3.col-md-3.col-lg-3.pe-0.ps-0.d-flex.align-items-end.justify-content-end.justify-content-md-between.flex-column {\n    width: 17%;\n  }\n}\n// @media (min-width: 1280px) {\n//   .col-9.col-md-9.col-lg-9.px-0.col-add {\n//     width: 452px;\n//   }\n\n//   .col-4.col-md-3.col-lg-3.pe-0.ps-0.d-flex.align-items-end.justify-content-end.flex-column.col-print-share,\n//   .col-print-share.col-3.col-md-3.col-lg-3.pe-0.ps-0.d-flex.align-items-end.justify-content-end.justify-content-md-between.flex-column {\n//     width: 96px;\n//   }\n// }\n// @media (min-width: 1600px) {\n//   .col-9.col-md-9.col-lg-9.px-0.col-add {\n//     width: 448px !important;\n//   }\n// }\n// @media (min-width: 1920px) {\n//   .col-9.col-md-9.col-lg-9.px-0.col-add {\n//     width: 446px !important;\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
