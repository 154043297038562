import './index.css';

import { AllReducer, configureStore } from '@canopy/shared/lib';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { combineReducers } from 'redux';

import App from './App';
import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });

const middleware = [routerMiddleware(history)];

const rootReducer = combineReducers({
  ...AllReducer,
  router: connectRouter(history),
});
export const stores = configureStore(rootReducer, middleware);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={stores}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
