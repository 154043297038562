/* eslint-disable no-underscore-dangle */
import * as actions from '@canopy/shared';
// import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  ACCEPT_BTN_LABEL,
  // BACK,
  DONT_ACCEPT_BTN_LABEL,
  // LOGIN_ERROR_CODES,
  // TERMS_CONDITION_TITLE,
  TERMS_VAR,
} from '../../shared/constant/AppConstants';
// import images from '../../shared/utils/Images';
// import { TermsAndConditionWarning } from '../../shared/constant/AppMessages';
// import {
//   APP_HOME_URL,
//   LOGIN_PAGE_URL,
//   VERIFY_ACCOUNT_SETUP,
//   VERIFY_LOGIN_OTP
// } from '../../shared/constant/Urls';
import { history } from '../../shared/helperMethods/HelperMethod';
import ConfirmationModal from '../../shared/sharedComponents/confirmationModal/ComfirmationModal';
// import ComfirmationModalWithTwoButtons from '../../shared/sharedComponents/ModalWithTwoButtons/ModalWithTwoButtons';
import Analytics, { FA } from '../../shared/utils/Analytics';
// import Auth from '../../shared/utils/Auth';
import { DOCTOR_SERVICES_DOCTOR } from '../../shared/constant/Urls';
import { hideLoader, showLoader } from '../../shared/utils/ReusableComponents';

interface ownState {
  // userId: any;
  acceptTerms: boolean;
  acceptError: boolean;
  // lastPage: any;
  // doNotAccept: boolean;
  // user: any;
}

interface ownProps {
  // match?: any;
}

interface DispatchProps {
  createGetTermsRequest: typeof actions.createGetTermsRequest;
  // createAcceptTermsRequest: typeof actions.createAcceptTermsRequest;
  // createLoginRequest: typeof actions.createLoginRequest;
  // openCloseConfirmationModalRequest: typeof actions.openCloseConfirmationModalRequest;
}

interface StateProps {
  data: any;
}

type Props = StateProps & ownProps & DispatchProps & RouteComponentProps;

class TermsAndCondition extends PureComponent<Props, ownState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // userId: '',
      acceptTerms: false,
      acceptError: false,
      // lastPage: '',
      // doNotAccept: false,
      // user: {},
    };
  }

  componentDidMount(): any {
    const { createGetTermsRequest } = this.props;
    // const { id } = match.params;
    // if (id) {
    //   const state = history.location.state as { from: string; userData: any; callBack: any };
    //   this.setState({ userId: id, user: state, acceptTerms: true }, () => {
    //     if (!state?.from) {
    //       window.location.href = '/';
    //     }
    //   });
    // }
    showLoader();
    createGetTermsRequest(TERMS_VAR, () => hideLoader());
    Analytics.logEvent(FA.ACCOUNT_SETTINGS_TERMS_AND_CONDITION_VIEW);
  }

  // AcceptUser = (): any => {
  //   const { userId, user } = this.state;
  //   const { createAcceptTermsRequest } = this.props;
  //   showLoader();
  //   createAcceptTermsRequest(
  //     {
  //       userId,
  //       acceptanceDate: moment().unix(),
  //     },
  //     (res: any) => {
  //       if (res.isSuccess) {
  //         Analytics.logEvent(FA.CREATE_ACCOUNT_ACCEPT_TERMS_CONDITION);
  //         Analytics.logEvent(FA.CREATE_ACCOUNT_SUCCESS);
  //         if (user.userData.tokenDetails) {
  //           hideLoader();
  //           Auth.setUserId(user.userData.id);
  //           Auth.setUserContext(user.userData.tokenDetails);
  //           Auth.setAccessToken(user.userData.tokenDetails.access_token);
  //           Auth.setRefreshToken(user.userData.tokenDetails.refresh_token);
  //           window.location.href = DOCTOR_SERVICES_DOCTOR;
  //         } else {
  //           this.getLoginToken();
  //         }
  //       } else {
  //         hideLoader();
  //         Analytics.logEvent(FA.CREATE_ACCOUNT_ACCEPT_TERMS_CONDITION_FAILED);
  //         this.setState({ acceptError: true });
  //       }
  //     }
  //   );
  // };

  // getLoginToken = (): any => {
  //   const {
  //     user: { userData },
  //   } = this.state;
  //   const { createLoginRequest } = this.props;
  //   createLoginRequest(
  //     { username: userData.username, password: userData.password },
  //     (res: any, header: any) => {
  //       this.callBack(res, header);
  //       hideLoader();
  //     }
  //   );
  // };

  // callBack = (data: any, header: any): void => {
  //   const {
  //     user: { userData },
  //   } = this.state;
  //   if (data.isSuccess) {
  //     const {
  //       data: { mfa, profile, userId, status },
  //     } = data;
  //     if (mfa?.enabled) {
  //       history.push(`${VERIFY_LOGIN_OTP}/${mfa.factorType}`, {
  //         from: 'login',
  //         userData: {
  //           mfa,
  //           profile,
  //           userId,
  //           username: userData.username,
  //           password: userData.password,
  //         },
  //       } as {
  //         from: string;
  //         userData: any;
  //       });
  //     } else {
  //       Auth.setAccessToken(header?.access_token);
  //       Auth.setRefreshToken(header?.refresh_token);
  //       Auth.setSessionToken(header?.session_token);
  //       Auth.setUserId(data?.data?._id);
  //       window.location.href = APP_HOME_URL;
  //     }
  //   } else if (
  //     data?.data?.statusCode === LOGIN_ERROR_CODES.statusCode1511 &&
  //     data?.data?.data?.status === 'MFA_ENROLL'
  //   ) {
  //     history.push(VERIFY_ACCOUNT_SETUP, {
  //       from: 'login ',
  //       userData: {
  //         username: userData.username,
  //         password: userData.password,
  //         email: data?.data?.data?.email,
  //         id: data?.data?.data?.userId,
  //         termsAndCondition: false,
  //       },
  //     } as {
  //       from: string;
  //       userData: any;
  //     });
  //   } else if (
  //     data?.data?.statusCode === LOGIN_ERROR_CODES.statusCode1502 ||
  //     data?.data?.statusCode === LOGIN_ERROR_CODES.statusCode1503
  //   ) {
  //     if (data?.data?.data?.status === 'MFA_REQUIRED') {
  //       const factor = data?.data?.data?._embedded?.factors[0];
  //       const user = data?.data?.data?._embedded?.user;
  //       history.push(`${VERIFY_LOGIN_OTP}/${factor.factorType}`, {
  //         from: 'login',
  //         userData: { user, factor },
  //       } as {
  //         from: string;
  //         userData: any;
  //       });
  //     }
  //   }
  // };

  render(): React.ReactElement {
    const { data } = this.props;
    const { acceptTerms, acceptError } = this.state;
    const dataHtml = data?.TermsData?.data?.data?.content;
    return (
      <div className='enrollment'>
        <div className='recovery-container col-12 px-2'>
          <div className='recover-divider large-divider col-12' />
          <div className='row'>
            {/* <a className={`btn-small-action ${acceptTerms ? '' : 'd-none'}`} href='/'>
              <img
                alt='buttonBack'
                src={images.icButtonaddBack}
                srcSet={`${images.icButtonaddBack2x} 2x ${images.icButtonaddBack3x} 3x`}
              />
              {BACK}
            </a> */}
            <div className='remote-content  mt-4 mt-sm-2'>
              <div dangerouslySetInnerHTML={{ __html: dataHtml }} />
            </div>
            <div className='col-12 error-container'>
              <div className='error pull-right' />
            </div>
          </div>
          <div className='row'>
            <div className='col col-sm-auto ms-auto text-center'>
              <button
                type='button'
                tabIndex={0}
                className={` btn-anchor px-4 ${acceptTerms ? '' : 'd-none'}`}
                onClick={() => history.push(DOCTOR_SERVICES_DOCTOR)}>
                {DONT_ACCEPT_BTN_LABEL}
              </button>
              <button
                type='button'
                className={`btn btn-primary dark-blue-btn px-4 ${acceptTerms ? '' : 'd-none'}`}
                onClick={() => history.push(DOCTOR_SERVICES_DOCTOR)}>
                {ACCEPT_BTN_LABEL}
              </button>
            </div>
          </div>
        </div>
        <ConfirmationModal
          title='Error'
          message={data?.TermsData?.acceptErrorMessage}
          showModal={acceptError}
          closeModal={() => this.setState({ acceptError: false })}
        />
        {/* {doNotAccept && (
          <ComfirmationModalWithTwoButtons
            title={TERMS_CONDITION_TITLE}
            message={TermsAndConditionWarning()}
            showModal={doNotAccept}
            closeModal={() => this.setState({ doNotAccept: false })}
            action={() => {
              history.push(LOGIN_PAGE_URL);
            }}
            btn1Title={`Don't Accept`}
            btn2Title='Review Terms'
          />
        )} */}
      </div>
    );
  }
}
const mapStateToProps = (state: actions.termsState): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  // createLoginRequest: actions.createLoginRequest,
  createGetTermsRequest: actions.createGetTermsRequest,
  // createAcceptTermsRequest: actions.createAcceptTermsRequest,
  // openCloseConfirmationModalRequest: actions.openCloseConfirmationModalRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndCondition);
