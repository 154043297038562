import icEditActive from '../../../assets/images/edit-white.png';

import icDetailCertificate from '../../../assets/images/ic_detail_certificate.png';
import icDetailCertificate2x from '../../../assets/images/ic_detail_certificate@2x.png';
import icDetailCertificate3x from '../../../assets/images/ic_detail_certificate@3x.png';

import icDetailReport from '../../../assets/images/ic_detail_report.png';
import icDetailReport2x from '../../../assets/images/ic_detail_report@2x.png';
import icDetailReport3x from '../../../assets/images/ic_detail_report@3x.png';

import icDetailPlan from '../../../assets/images/ic_detail_plan.png';
import icDetailPlan2x from '../../../assets/images/ic_detail_plan@2x.png';
import icDetailPlan3x from '../../../assets/images/ic_detail_plan@3x.png';

import icCheck2x from '../../../assets/images/ic_check@2x.png';

import icListNocheck from '../../../assets/images/ic_list_nocheck.png';
import icListNocheck2x from '../../../assets/images/ic_list_nocheck@2x.png';

import icPinList from '../../../assets/images/ic_pin_list.png';
import icPinList2x from '../../../assets/images/ic_pin_list@2x.png';
import icPinList3x from '../../../assets/images/ic_pin_list@3x.png';

import IcClinic from '../../../assets/images/ic_clinic.png';
import IcClinic2x from '../../../assets/images/ic_clinic@2x.png';
import IcClinic3x from '../../../assets/images/ic_clinic@3x.png';
import icDeeplink from '../../../assets/images/ic_deeplink.png';
import icDeeplink2x from '../../../assets/images/ic_deeplink@2x.png';
import icDeeplink3x from '../../../assets/images/ic_deeplink@3x.png';

import icDetailFax from '../../../assets/images/ic_detail_fax.png';
import icDetailFax2x from '../../../assets/images/ic_detail_fax@2x.png';
import icDetailFax3x from '../../../assets/images/ic_detail_fax@3x.png';

import icDeductible3x from '../../../assets/images/ic-deductible@3x.png';

import icEdit from '../../../assets/images/edit-gray.png';
import icEmptyProvider2x from '../../../assets/images/ic-empty-provider@2x.png';
import icEmptyProvider3x from '../../../assets/images/ic-empty-provider@3x.png';
import icMenuGrievancesAppealsActive from '../../../assets/images/ic-menu-grievancesappeals-active.png';
import icClose from '../../../assets/images/ic_close.png';
import icClose2x from '../../../assets/images/ic_close@2x.png';
import icClose3x from '../../../assets/images/ic_close@3x.png';
import icDeeplinkGray from '../../../assets/images/ic_deeplink_gray.png';
import icDeeplinkGray2x from '../../../assets/images/ic_deeplink_gray@2x.png';
import icDeeplinkGray3x from '../../../assets/images/ic_deeplink_gray@3x.png';
import icDeeplinkWhite from '../../../assets/images/ic_deeplink_white.png';
import icDeeplinkWhite2x from '../../../assets/images/ic_deeplink_white@2x.png';
import icDeeplinkWhite3x from '../../../assets/images/ic_deeplink_white@3x.png';
import icDetailHours from '../../../assets/images/ic_detail_hours.png';
import icDetailHours2x from '../../../assets/images/ic_detail_hours@2x.png';
import icDetailHours3x from '../../../assets/images/ic_detail_hours@3x.png';
import icEmptyProvider from '../../../assets/images/ic_empty_provider.png';
import icMenuClose from '../../../assets/images/ic_menu_close.png';
import icMenuClose2x from '../../../assets/images/ic_menu_close@2x.png';
import icMenuClose3x from '../../../assets/images/ic_menu_close@3x.png';
import icMenuHomeActive from '../../../assets/images/ic_menu_home_active.png';
import icMenuNetworkActive from '../../../assets/images/ic_menu_network_active.png';
import icMenuSupportActive from '../../../assets/images/ic_menu_support_active.png';
import icPhoneHeader from '../../../assets/images/ic_phone_header.png';
import icPhoneHeader2x from '../../../assets/images/ic_phone_header@2x.png';
import icPhoneHeader3x from '../../../assets/images/ic_phone_header@3x.png';
import icSearch from '../../../assets/images/ic_search.png';
import imgLogoCanopyBlue from '../../../assets/images/img_logo_canopy_blue.png';
import imgLogoCanopyBlue2x from '../../../assets/images/img_logo_canopy_blue@2x.png';
import imgLogoCanopyBlue3x from '../../../assets/images/img_logo_canopy_blue@3x.png';

import avatarF from '../../../assets/images/Profile-FemaleDoctor1.jpg';
import avatarM from '../../../assets/images/Profile-MaleDoctor1.jpg';
import btnDeepLink from '../../../assets/images/btn_deepLink.png';
import btnDeepLink2x from '../../../assets/images/btn_deepLink@2x.png';
import btnDeepLink3x from '../../../assets/images/btn_deepLink@3x.png';
import clinickName from '../../../assets/images/clinick-name.svg';
import griverence from '../../../assets/images/grievances_&_appeals_icon.png';
import icMenu from '../../../assets/images/ic_menu.png';
import icMenu2x from '../../../assets/images/ic_menu@2x.png';
import icMenu3x from '../../../assets/images/ic_menu@3x.png';
import SCCIPA from '../../../assets/images/svg/accipa.svg';
import awardSimple from '../../../assets/images/svg/award-simple-duotone.svg';
import board from '../../../assets/images/svg/board.svg';
import caLicense from '../../../assets/images/svg/ca-license.svg';
import cerificate from '../../../assets/images/svg/certificate.svg';
import clinic from '../../../assets/images/svg/clinic.svg';
import clockHours from '../../../assets/images/svg/clock-hours.svg';
import clock from '../../../assets/images/svg/clock-regular.svg';
import closeWhite from '../../../assets/images/svg/close-white.svg';
import close from '../../../assets/images/svg/close.svg';
import county from '../../../assets/images/svg/county.svg';
import DIGNITY from '../../../assets/images/svg/dignity.svg';
import flag from '../../../assets/images/svg/flag.svg';
import greaterThan from '../../../assets/images/svg/greater-than.svg';
import greenSuccess from '../../../assets/images/svg/green-sucess.svg';
import group from '../../../assets/images/svg/group.svg';
import healthPlan from '../../../assets/images/svg/health-plan.svg';
import heartPulse from '../../../assets/images/svg/heart-pulse-solid.svg';
import HILL from '../../../assets/images/svg/hill.svg';
import hospitalNPI from '../../../assets/images/svg/hospital-npi.svg';
import hospitals from '../../../assets/images/svg/hospital.svg';
import hospitalist from '../../../assets/images/svg/hospitalist.svg';
import hospitalsMap from '../../../assets/images/svg/hospitals-map.svg';
import houseChimneyMedical from '../../../assets/images/svg/house-chimney-medical.svg';
import idCard from '../../../assets/images/svg/id-card.svg';
import JOHNMUIR from '../../../assets/images/svg/john-muir.svg';
import languages from '../../../assets/images/svg/languages.svg';
import LGBTQ from '../../../assets/images/svg/lgbtq.svg';
import locationDot from '../../../assets/images/svg/location-dot-solid.svg';
import location from '../../../assets/images/svg/location.svg';
import mapMarker from '../../../assets/images/svg/map-marker.svg';
import medicalGroup from '../../../assets/images/svg/medical-group.svg';
import MERITAGE from '../../../assets/images/svg/meritage.svg';
import npi from '../../../assets/images/svg/npi.svg';
import phone from '../../../assets/images/svg/phone.svg';
import printLogo from '../../../assets/images/svg/print.svg';
import PROVIDENCE from '../../../assets/images/svg/providence.svg';
import shareLogo from '../../../assets/images/svg/share.svg';
import stethescope from '../../../assets/images/svg/stethescope.svg';
import tabSidebarClose from '../../../assets/images/svg/tab-sidebar-close.svg';
import tabSidebarOpen from '../../../assets/images/svg/tab-sidebar-open.svg';
import telehealth from '../../../assets/images/svg/telehealth.svg';
import telephone from '../../../assets/images/svg/telephone.svg';
import watchVideo from '../../../assets/images/svg/watch-video.svg';
import xMarkSolid from '../../../assets/images/xmark-solid.png';

// PDF images

import pdfCALic from '../../../assets/images/pdf-icons/CA-license-gray.png';
import pdfHospitalNPI from '../../../assets/images/pdf-icons/Hospital-NPI.png';
import pdfBoardCert from '../../../assets/images/pdf-icons/board-certified-icon-gray.png';
import pdfCall from '../../../assets/images/pdf-icons/call-gray.png';
import pdfCallBlue from '../../../assets/images/pdf-icons/call-icon-blue.png';
import pdfClinic from '../../../assets/images/pdf-icons/clinic-gray.png';
import pdfDignity from '../../../assets/images/pdf-icons/dignity.png';
import pdfFlag from '../../../assets/images/pdf-icons/flag-gray.png';
import pdfCheck from '../../../assets/images/pdf-icons/green-check.png';
import pdfHealthPlan from '../../../assets/images/pdf-icons/health-plan-gray.png';
import pdfHillPhysician from '../../../assets/images/pdf-icons/hillPhysiciansMedicalGroup.png';
import pdfHospitalist from '../../../assets/images/pdf-icons/hospital-list-gray.png';
import pdfHospital from '../../../assets/images/pdf-icons/hospital-regular.png';
import pdfJMH from '../../../assets/images/pdf-icons/jmh.png';
import pdfLGBTQ from '../../../assets/images/pdf-icons/lgbtq-large.png';
import pdfMap from '../../../assets/images/pdf-icons/map-gray.png';
import pdfMapBlue from '../../../assets/images/pdf-icons/map-icon-blue.png';
import pdfMapPin from '../../../assets/images/pdf-icons/map-pin.png';
import pdfMedicalGrp from '../../../assets/images/pdf-icons/medical-group-icon-gray.png';
import pdfMeritage from '../../../assets/images/pdf-icons/meritage.png';
import pdfCross from '../../../assets/images/pdf-icons/not-icon.png';
import pdfLOGO from '../../../assets/images/pdf-icons/pdf-logo.png';
import pdfProvidence from '../../../assets/images/pdf-icons/providence.png';
import pdfSCIPPA from '../../../assets/images/pdf-icons/scippa.png';
import pdfTelehealth from '../../../assets/images/pdf-icons/telehealth-gray.png';
import pdfTopStrip from '../../../assets/images/pdf-icons/top-strip.png';

export default {
  icClose,
  icClose2x,
  icClose3x,
  icDeeplinkGray,
  icDeeplinkGray2x,
  icDeeplinkGray3x,
  icEdit,
  icSearch,
  icEmptyProvider,
  icEmptyProvider2x,
  icEmptyProvider3x,
  icMenuGrievancesAppealsActive,
  icMenuHomeActive,
  icMenuSupportActive,
  icMenuNetworkActive,
  icMenuClose,
  icMenuClose2x,
  icMenuClose3x,
  icDetailHours,
  icDetailHours2x,
  icDetailHours3x,
  IcClinic,
  IcClinic2x,
  IcClinic3x,
  btnDeepLink,
  btnDeepLink2x,
  btnDeepLink3x,
  icMenu,
  icMenu2x,
  icMenu3x,
  imgLogoCanopyBlue,
  imgLogoCanopyBlue2x,
  imgLogoCanopyBlue3x,
  icPhoneHeader,
  icPhoneHeader2x,
  icPhoneHeader3x,
  icDetailReport,
  icDetailReport2x,
  icDetailReport3x,
  icListNocheck,
  icListNocheck2x,
  icDetailFax,
  icDetailFax2x,
  icDetailFax3x,
  icPinList,
  icPinList2x,
  icPinList3x,
  icDetailCertificate,
  icDetailCertificate2x,
  icDetailCertificate3x,
  icDetailPlan,
  icDetailPlan2x,
  icDetailPlan3x,
  icEditActive,
  icDeeplinkWhite,
  icDeeplinkWhite2x,
  icDeeplinkWhite3x,
  icDeeplink,
  icDeeplink2x,
  icDeeplink3x,
  icDeductible3x,
  icCheck2x,
  griverence,
  xMarkSolid,
  avatarF,
  avatarM,
  watchVideo,
  SCCIPA,
  DIGNITY,
  HILL,
  JOHNMUIR,
  MERITAGE,
  PROVIDENCE,
  LGBTQ,
  stethescope,
  printLogo,
  shareLogo,
  awardSimple,
  flag,
  group,
  hospitals,
  hospitalist,
  heartPulse,
  cerificate,
  houseChimneyMedical,
  idCard,
  telehealth,
  locationDot,
  clock,
  phone,
  greaterThan,
  location,
  greenSuccess,
  mapMarker,
  telephone,
  tabSidebarOpen,
  tabSidebarClose,
  clockHours,
  medicalGroup,
  clinic,
  hospitalsMap,
  county,
  languages,
  healthPlan,
  board,
  npi,
  caLicense,
  hospitalNPI,
  close,
  closeWhite,
  clinickName,
  pdfBoardCert,
  pdfCALic,
  pdfCall,
  pdfCallBlue,
  pdfCheck,
  pdfClinic,
  pdfCross,
  pdfDignity,
  pdfFlag,
  pdfHealthPlan,
  pdfHillPhysician,
  pdfHospital,
  pdfHospitalNPI,
  pdfHospitalist,
  pdfJMH,
  pdfLGBTQ,
  pdfLOGO,
  pdfMap,
  pdfMapBlue,
  pdfMapPin,
  pdfMedicalGrp,
  pdfMeritage,
  pdfProvidence,
  pdfSCIPPA,
  pdfTopStrip,
  pdfTelehealth,
};
