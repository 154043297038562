/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import * as actions from '@canopy/shared';
import { i18n } from '@canopy/shared';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Router } from 'react-router';
import { Slide, ToastContainer } from 'react-toastify';
import './assets/css/main.scss';
import Routes from './components/routing/Routes';
import { Limits, Timeouts } from './components/shared/constant/AppConstants';
import { DOCTOR_SERVICES_DOCTOR, LOGIN_PAGE_URL } from './components/shared/constant/Urls';
import { history } from './components/shared/helperMethods/HelperMethod';
import ErrorBoundary from './components/shared/sharedComponents/ErrorBoundary/ErrorBoundary';
import ComfirmationModal from './components/shared/sharedComponents/confirmationModal/ComfirmationModal';
import { reactPlugin, trackException } from './components/shared/utils/ApplicationInsightsServices';
import Auth from './components/shared/utils/Auth';
import { hideLoader, showLoader } from './components/shared/utils/ReusableComponents';
import useWindowFocus from './hooks/useWindowFocus';

export default function App(): JSX.Element {
  const focused = useWindowFocus();
  const [ifGetConfigSuccess, setgetConfigSuccess] = useState(false);
  const [logoutWarning, setWarningModal] = useState(false);
  const dispatch = useDispatch();

  const getNotifications = useCallback((): any => {
    if (Auth.getUserId().length > 0) {
      dispatch(actions.createGetNotificationsRequest({ page: Limits.Page, limit: '-1' }, () => hideLoader()));
    }
  }, [dispatch]);

  const logoutUser = (): any => {
    Auth.logout(() => {
      dispatch(
        actions.createGetMainUnAuthConfigDataRequest(() => {
          hideLoader();
          window.location.href = LOGIN_PAGE_URL;
        })
      );
    });
  };

  useEffect(() => {
    i18n.setI18nConfig();
  }, []);

  const getConfiguration = useCallback(
    (res: any): void => {
      dispatch(
        actions.createGetMainConfigDataRequest(
          {
            payor: res?.data?.empi?.healthPlan?.code,
            product: res?.data?.product,
            plan: res?.data?.categoryName,
            ipa: res?.data?.ipa || '',
            employer: res?.data?.empi?.healthPlan?.group?.policyId,
            effective: !res?.data?.isPreEffective,
            clinicId: res?.data?.empi?.pcp?.organizationId,
            userId: res?.data?._id,
          },
          () => {
            hideLoader();
            setgetConfigSuccess(true);
          }
        )
      );
    },
    [dispatch]
  );

  const onInit = useCallback((): void => {
    if (Auth.isAuthenticated()) {
      showLoader();
      localStorage.openpages = Date.now();

      window.addEventListener('storage', sessionStorageTransfer, false);
      dispatch(
        actions.createGetUserInfoRequest({ id: Auth.getUserId() }, (res: any) => {
          if (JSON.parse(JSON.stringify(res)).status === 401) {
            Auth.logout(() => {
              dispatch(
                actions.createGetMainUnAuthConfigDataRequest(() => {
                  hideLoader();
                  window.location.href = DOCTOR_SERVICES_DOCTOR;
                })
              );
            });
          } else {
            getConfiguration(res);
            dispatch(
              actions.createGetPlanContactsRequest({
                type: ['Member', 'Provider'],
                userId: res?.data?._id,
                // payor: null,
              })
            );
            getNotifications();
            dispatch(
              actions.getPhotoRequestAction({}, (photoResponse: any) => {
                if (!res.isSuccess) {
                  trackException('getPhotoRequestAction', photoResponse);
                }
              })
            );
            setTimeout(() => getNotifications(), Timeouts.GetNotificationsTime);
          }
        })
      );
    } else {
      Auth.clearHealthPlan();

      dispatch(
        actions.createGetPlanContactsRequest({
          type: ['Provider', 'Member'],
        })
      );
      dispatch(
        actions.createGetMainUnAuthConfigDataRequest(() => {
          hideLoader();
          setgetConfigSuccess(true);
        })
      );
    }
  }, [dispatch, getConfiguration, getNotifications]);

  const sessionStorageTransfer = (e: any): any => {
    if (!e.newValue) {
      return;
    }
    if (e.key === 'REQUESTING_SHARED_CREDENTIALS') {
      window.localStorage.setItem('SHARING_AUTH', JSON.stringify(sessionStorage));
      window.localStorage.removeItem('SHARING_AUTH');
    }
    if (e.key === 'SHARING_AUTH') {
      // console.log(e.key);
      if (e.newValue) {
        // setSessionData(JSON.parse(e.newValue));
        sessionStorage.setItem('test1', JSON.parse(e.newValue));
      }
    }
    if (e.key === 'REQUESTING_LOGOUT') {
      Auth.logout();
    }
  };

  useEffect(() => {
    onInit();
  }, [onInit]);

  useEffect(() => {
    if (focused) {
      if (Auth.isAuthenticated()) {
        dispatch(
          actions.createGetUserInfoRequest({ id: Auth.getUserId() }, (res: any) => {
            if (JSON.parse(JSON.stringify(res)).status === 401) {
              Auth.logout(() => {
                dispatch(
                  actions.createGetMainUnAuthConfigDataRequest(() => {
                    window.location.href = DOCTOR_SERVICES_DOCTOR;
                  })
                );
              });
            }
          })
        );
      }
    }
  }, [focused, dispatch]);

  return (
    <ErrorBoundary>
      <AppInsightsContext.Provider value={reactPlugin}>
        <ToastContainer transition={Slide} hideProgressBar position='top-right' />
        <Router history={history}>{ifGetConfigSuccess && <Routes />}</Router>
        <ComfirmationModal
          message='Session Timeout'
          title='Timeout'
          closeModal={() => {
            setWarningModal(false);
            logoutUser();
          }}
          showModal={logoutWarning}
        />
      </AppInsightsContext.Provider>
    </ErrorBoundary>
  );
}
