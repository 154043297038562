/* eslint no-underscore-dangle: 0 */
import React, { PureComponent } from 'react';
import {
  AFFILIATED_WITH_LABEL,
  FAX_LABLE,
  HIDE_DETAILS,
  HOLIDAY_INFO,
  NPI_LABLE,
  OFFICE_HOURS,
  REPORT_INACCURACIES_LABLE,
  SERVICES_LABLE,
  VENDOR_DETAILS,
  VIEW_DETAILS,
} from '../../../shared/constant/AppConstants';
import { URGENT_CARE_REPORT_INACCURACIES } from '../../../shared/constant/Urls';
import { formatPhone, getMiles, getOfficeHours } from '../../../shared/helperMethods/HelperMethod';
import Analytics, { FA } from '../../../shared/utils/Analytics';
import images from '../../../shared/utils/Images';

interface ownProps {
  data: any;
}

interface ownState {}

export default class SearchResultUrgentCare extends PureComponent<ownProps, ownState> {
  getFilteredIPA = (data: any): any => {
    if (data && data.ipa) {
      const filteredArray = data.ipa.split('|').filter((word: string) => !word.includes('Canopy Health'));
      return filteredArray.length > 0 ? filteredArray : null;
    }
    return null;
  };

  render(): React.ReactElement {
    const { data } = this.props;
    const ipaList = this.getFilteredIPA(data);
    return (
      <>
        <div className='grid-row container-fluid' key={data._id}>
          <div className='container-fluid container-dt-doctor padding-left-0'>
            <div className='col-lg-12 col-md-12 col-sm-12 no-padding grid-content margin-left-15'>
              <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-4 ps-0 pe-md-5'>
                  <div className='col-lg-12 col-md-12 col-sm-12 no-padding-mob clearfix margin-bottom-15-mob'>
                    <h1>{data && data.displayName}</h1>
                    <div className='d-none'>
                      <span className='tags'>{data && data.displayName}</span>
                    </div>
                    {ipaList ? (
                      <span className='text-tick-1'>
                        {AFFILIATED_WITH_LABEL}
                        {ipaList.map((i: any) => (
                          <p key={i}>{i}</p>
                        ))}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='col-sm-8 no-padding mt-0 mt-sm-2 mt-lg-0'>
                  <div className='row'>
                    <div className='col-sm-6  no-padding'>
                      <div className='medical-group mt-2'>
                        <img src={images.phone} alt='' />
                        <span>
                          <a href={`tel:${data?.phone}`}>
                            {data && data.phone.indexOf('-') === -1 ? formatPhone(data.phone) : data?.phone}
                          </a>
                        </span>
                      </div>
                      <div className='img-text-2 mt-2'>
                        <span className='img-icon'>
                          <img
                            className='img-fluid'
                            src={images.icPinList}
                            srcSet={`${images.icPinList2x} 2x, ${images.icPinList3x} 3x`}
                            alt='tick'
                          />
                        </span>
                        <span className='text-tick address-text shift-up'>{data.address.street1}</span>
                        <span className='text-tick address-text'>{data.address.street2}</span>
                        <span className='text-tick address-text'>
                          {data.address.city.replace(/(\w)(\w*)/g, function (g0: any, g1: any, g2: any) {
                            return g1.toUpperCase() + g2.toLowerCase();
                          })}
                          , {data.address.state} {data.address.zip}
                        </span>
                      </div>
                      <div className='img-text-2'>
                        <span className='img-icon' />
                        <span className='miles'>
                          {data?.distance === 0 ? '0 miles away' : data?.distance && getMiles(data?.distance)}
                        </span>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 no-padding url-section-col col-sm-6 link-align link-align-services collpase-section link-crop-fix-service text-center text-sm-end clearfix ps-0 ms-0 pr-0 mt-2'>
                      <h2 className='d-block position-relative text-center text-sm-end h2ClosedOpen'>
                        {data?.openNow}
                      </h2>

                      <a target='_blank' href={data.url} rel='noreferrer'>
                        {data && data.url}
                      </a>
                    </div>
                    <div className='col-12 text-center text-sm-end px-0'>
                      <a
                        className='hook collapsed view-details-btn view-details-btn-service clickable'
                        data-bs-toggle='collapse'
                        href={`#collapseDetails${data._id}`}
                        role='button'
                        aria-expanded='false'
                        onClick={() => {
                          const myCollapse = document.getElementById(`${data._id}_span`);
                          if (myCollapse?.offsetParent == null) {
                            Analytics.logEvent(FA.DOCTOR_AND_SERVICE_URGENT_CARE_VIEW_DETAILS, {
                              provider_name: data.displayName,
                            });
                          }
                        }}
                        aria-controls={`collapseDetails${data._id}`}>
                        <span className='btn-toggle-details hide-detail'>{HIDE_DETAILS}</span>
                        <span className='btn-toggle-details view-detail' id={`${data._id}_span`}>
                          {VIEW_DETAILS}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='collapse  px-0' id={`collapseDetails${data._id}`}>
          <div className='container-fluid detail-section'>
            <div className='container-fluid detail-section-padding'>
              <fieldset>
                <legend>{VENDOR_DETAILS}</legend>
                <div className='row'>
                  <div className='col-lg-4 col-md-4 col-sm-4 col-12 no-padding-left'>
                    <div className='img-text-2 d-flex'>
                      <span className='img-icon'>
                        <img
                          className='img-fluid image20'
                          src={images.icDetailHours}
                          srcSet={`${images.icDetailHours2x} 2x, ${images.icDetailHours3x} 3x`}
                          alt='tick'
                        />
                      </span>
                      <span className='text-tick'>
                        {OFFICE_HOURS}
                        <div>
                          {data &&
                            data?.officeHours?.length > 0 &&
                            getOfficeHours(data?.officeHours)?.map((i: any) => (
                              <p>
                                <span> {i?.day}</span>:<span className='ps-1'> {i?.time}</span>
                              </p>
                            ))}
                        </div>
                        <div className='holiday-label'>{HOLIDAY_INFO()}</div>
                      </span>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-4 col-12 no-padding-left'>
                    <div className='row'>
                      <div className='img-text-2 col-12 no-padding-left d-flex'>
                        <span className='img-icon'>
                          <img
                            className='img-fluid image20'
                            src={images.IcClinic}
                            srcSet={`${images.IcClinic2x} 2x, ${images.IcClinic3x} 3x`}
                            alt='tick'
                          />
                        </span>
                        <span className='text-tick'>
                          {SERVICES_LABLE}
                          <div>
                            {data.servicesProvided &&
                              data.servicesProvided.split('^').length > 0 &&
                              data.servicesProvided.split('^').map((i: any) => (
                                <div className='services-row-wrap'>
                                  <span className='service-type'>{i.split('|')[0]}</span>
                                  <span className='service-text'>{i.split('|')[1]}</span>
                                </div>
                              ))}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-4 col-12 no-padding-left'>
                    <div className='row'>
                      <div className='img-text-2 clearfix col-12 no-padding-left d-flex'>
                        <span className='img-icon'>
                          <img
                            className='img-fluid image20'
                            src={images.icDetailFax}
                            srcSet={`${images.icDetailFax2x} 2x, ${images.icDetailFax3x} 3x`}
                            alt='tick'
                          />
                        </span>
                        <span className='text-tick'>
                          {FAX_LABLE}
                          <p>{data && data.fax}</p>
                        </span>
                      </div>
                    </div>
                    <div className='row mt-2'>
                      <div className='img-text-2 col-12 no-padding-left d-flex'>
                        <span className='img-icon'>
                          <img
                            className='img-fluid image20'
                            src={images.icDetailCertificate}
                            srcSet={`${images.icDetailCertificate2x} 2x, ${images.icDetailCertificate3x} 3x`}
                            alt='tick'
                          />
                        </span>
                        <span className='text-tick'>
                          {NPI_LABLE}
                          <p className='mb-2'>{data && data.npi}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className='col-12 no-padding text-end report-inaccuracy'>
                <span
                  role='none'
                  onClick={() => window.open(`${URGENT_CARE_REPORT_INACCURACIES}/${data._id}`, '_blank')}
                  onKeyDown={() => window.open(`${URGENT_CARE_REPORT_INACCURACIES}/${data._id}`, '_blank')}>
                  <img
                    className='img-fluid'
                    src={images.icDetailReport}
                    srcSet={`${images.icDetailReport2x} 2x, ${images.icDetailReport3x} 3x`}
                    alt='tick'
                  />
                  {REPORT_INACCURACIES_LABLE}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
