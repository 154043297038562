// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-side-bar-container {
  display: flex;
  width: 100%;
  background-color: #f1f1f0;
}
.map-side-bar-container .doctor-cards-container {
  height: 500px;
  width: var(--map-side-bar-width);
  overflow-y: auto;
  border: 1px solid #dbdbdb;
  border-top: none;
}
@media (max-width: 567px) {
  .map-side-bar-container .doctor-cards-container {
    width: 100%;
    z-index: 9;
  }
}
.map-side-bar-container .doctor-cards-container .advanceToggle {
  background-color: #d9d9d9;
}
.map-side-bar-container .doctor-cards-container .advanceToggle .span {
  width: 100%;
  margin: 0;
}
.map-side-bar-container .doctor-details-container {
  width: var(--map-container-width-small);
  z-index: 10;
}
@media (max-width: 567px) {
  .map-side-bar-container .doctor-details-container {
    width: 100%;
    position: absolute;
    z-index: 10;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/screens/doctorsAndServices/doctors/MapView/MapSideBar/MapSideBar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,yBAAA;AACF;AACE;EACE,aAAA;EACA,gCAAA;EAMA,gBAAA;EAEA,yBAAA;EACA,gBAAA;AALJ;AAHI;EAHF;IAII,WAAA;IACA,UAAA;EAMJ;AACF;AACI;EACE,yBAAA;AACN;AACM;EACE,WAAA;EACA,SAAA;AACR;AAIE;EACE,uCAAA;EACA,WAAA;AAFJ;AAGI;EAHF;IAII,WAAA;IACA,kBAAA;IACA,WAAA;EAAJ;AACF","sourcesContent":[".map-side-bar-container {\n  display: flex;\n  width: 100%;\n  background-color: #f1f1f0;\n\n  .doctor-cards-container {\n    height: 500px;\n    width: var(--map-side-bar-width);\n    @media (max-width: 567px) {\n      width: 100%;\n      z-index: 9;\n    }\n\n    overflow-y: auto;\n\n    border: 1px solid #dbdbdb;\n    border-top: none;\n\n    .advanceToggle {\n      background-color: #d9d9d9;\n\n      .span {\n        width: 100%;\n        margin: 0;\n      }\n    }\n  }\n\n  .doctor-details-container {\n    width: var(--map-container-width-small);\n    z-index: 10;\n    @media (max-width: 567px) {\n      width: 100%;\n      position: absolute;\n      z-index: 10;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
