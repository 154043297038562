import axios from 'axios';

import { SessionStorageKeys } from '../constant/AppConstants';
import { oktaClientId, oktaUrl, sessionRedirectUrl, ssoRedirectUrl } from '../../../config.js';

const ENV = process.env.REACT_APP_ENVIRONMENT;
const VER = process.env.REACT_APP_API_VERSION;
const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const API_KEY_AUTH = process.env.REACT_APP_API_KEY_AUTH ?? '';

class Auth {
  public authenticated: boolean;

  constructor() {
    this.authenticated = false;
    this.getAccessToken = this.getAccessToken.bind(this);
  }

  login(cb?: any): void {
    this.authenticated = true;
    cb();
  }

  logout = (callBack?: any): void => {
    this.authenticated = false;
    callBack();
    sessionStorage.clear();
  };

  isAuthenticated = (): boolean => {
    if (sessionStorage.getItem(SessionStorageKeys.UserId) != null) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  };

  getAccessToken = (): any => {
    console.log('READING ACCESS');
    const accessToken = sessionStorage.getItem(SessionStorageKeys.AccessToken);
    if (accessToken) {
      return accessToken;
    }
    return null;
  };

  getRefreshToken = (): any => {
    const refreshToken = sessionStorage.getItem(SessionStorageKeys.RefreshToken);
    if (refreshToken) {
      return refreshToken;
    }
    return null;
  };

  getSessionToken = (): string | null => {
    const sessionToken = sessionStorage.getItem(SessionStorageKeys.SessionToken);
    if (sessionToken) {
      return sessionToken;
    }
    return null;
  };

  getUser = (): any => {
    const user = sessionStorage.getItem(SessionStorageKeys.User);
    if (user !== null) {
      return JSON.parse(user);
    }
    return null;
  };

  setHealthPlan = (healthPlan: any): any => {
    if (healthPlan) {
      sessionStorage.setItem(SessionStorageKeys.HealthPlan, healthPlan);
    }
  };

  getHealthPlan = (): any => {
    const healthPlan = sessionStorage.getItem(SessionStorageKeys.HealthPlan);
    if (healthPlan) {
      return healthPlan;
    }
    return '';
  };

  clearHealthPlan = (): any => {
    sessionStorage.removeItem(SessionStorageKeys.HealthPlan);
  };

  getUserId = (): any => {
    const userId = sessionStorage.getItem(SessionStorageKeys.UserId);
    if (userId) {
      return userId;
    }
    return '';
  };

  setUsernamePassword = (data: any): any => {
    sessionStorage.setItem(SessionStorageKeys.UserInfo, data);
  };

  getUsernamePassword = (): any => {
    const user = sessionStorage.getItem(SessionStorageKeys.UserInfo);
    if (user) {
      const userInfo = JSON.parse(user);
      return userInfo;
    }
    return '';
  };

  setUserId = (userId: any): void => {
    sessionStorage.setItem(SessionStorageKeys.UserId, userId);
    localStorage.setItem(SessionStorageKeys.LoggedIn, 'yes');
  };

  setAccessToken = (token: any): void => {
    console.log('WRITING ACCESS', token);
    sessionStorage.setItem(SessionStorageKeys.AccessToken, token);
    localStorage.setItem(SessionStorageKeys.AccessToken, token);
  };

  setRefreshToken = (token: any): void => {
    console.log('READING SESSION');
    sessionStorage.setItem(SessionStorageKeys.RefreshToken, token);
    localStorage.setItem(SessionStorageKeys.RefreshToken, token);
  };

  setSessionToken = (token: string): void => {
    console.log('WRITING SESSION', token);
    sessionStorage.setItem(SessionStorageKeys.SessionToken, token);
  };

  setAmwellToken = (token: any): void => {
    sessionStorage.setItem(SessionStorageKeys.AmwellToken, token);
  };

  getAmwellToken = (): any => {
    return sessionStorage.getItem(SessionStorageKeys.AmwellToken);
  };

  setUserContext = (userInfo: any): void => {
    sessionStorage.setItem(SessionStorageKeys.User, JSON.stringify(userInfo));
  };

  setVVMessageCloseStatus = (): void => {
    sessionStorage.setItem('vv_welcome_message', 'closed');
  };

  getVVMessageCloseStatus = (): any => {
    return sessionStorage.getItem('vv_welcome_message');
  };

  setOktaUserId = (oktaId: string): void => {
    localStorage.setItem(SessionStorageKeys.OktaUserId, oktaId);
  };

  getOktaUserId = (): string | null => {
    return localStorage.getItem(SessionStorageKeys.OktaUserId);
  };

  // deleteSession = (): void => {
  //   const token = sessionStorage.getItem(SessionStorageKeys.SessionToken);
  //   // https://{baseUrl}/logout?id_token_hint=${id_token}&post_logout_redirect_uri=${post_logout_redirect_uri}&state=${state}
  //   const url = `${oktaUrl}/oauth2/v1/logout?id_token_hint=${token}&post_logout_redirect_uri=https%3A%2F%2Fportal.canopyhealth.com&state=Af0ifjslDkj&nonce=n-0S6_WzA2Mj`;
  //   const iframe = document.createElement('iframe');
  //   iframe.setAttribute('src', url);
  //   iframe.style.display = 'none';
  //   document.body.appendChild(iframe);
  // };

  createSession = (): void => {
    console.log('CREATE_SESSION');
    // const redirectUrl = 'https%3A%2F%2Fportal-stage.canopyhealth.com';
    const token = sessionStorage.getItem(SessionStorageKeys.SessionToken);
    const nonce = 'n-0S6_WzA2Mj'; // crypto.randomBytes(16).toString('base64');
    const state = 'Af0ifjslDkj'; // short(short.constants.cookieBase90).new();
    const url = `${oktaUrl}/oauth2/default/v1/authorize?client_id=${oktaClientId}&response_type=id_token&scope=openid&prompt=none&redirect_uri=${sessionRedirectUrl}&state=${state}&nonce=${nonce}&sessionToken=${token}`;

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', url);
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  };

  getCurrentSession = async (): Promise<string | null> => {
    console.log('getCurrentSession');
    const url = `${oktaUrl}/api/v1/sessions/me`;
    const response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      // mode: 'cors',
      // referrer: 'https://okta-staging.canopyhealth.com',
    });
    if (response.status === 200) {
      const res = await response.json();
      console.log('session', res);
      return res.userId;
    }

    return null;
  };

  ssoLogin = (isVirtualVisit: boolean): void => {
    // const redirectUri = 'https%3A%2F%2Fportal-stage.canopyhealth.com/sso_routing';
    const url = `${oktaUrl}/oauth2/default/v1/authorize?client_id=${oktaClientId}&response_type=token&scope=openid&redirect_uri=${ssoRedirectUrl}${
      isVirtualVisit ? '%3Froute%3Dvirtual_visits' : ''
    }&state=state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb601&nonce=foo`;
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', url);
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    console.log('sso login');
  };

  getUserIdFromOktaId = async (token: string): Promise<void> => {
    const oktaUserId = localStorage.getItem(SessionStorageKeys.OktaUserId);
    if (!oktaUserId) {
      console.log('OKTAID is null');
      return;
    }
    const url = `${BASE_URL}/mch-dashboard-${ENV}/${VER}/user-info?oktaUserId=${oktaUserId}`;
    const response = await axios.get<Record<any, any>>(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`,
        // 'refresh_token': `Bearer ${sessionStorage.getItem('refresh_token')}`,
        'Ocp-Apim-Subscription-Key': API_KEY_AUTH,
        'Accept': 'application/json',
        // 'x-correlation-id': short.uuid(),
        // 'x-user-id': `${sessionStorage.getItem('userId')}`,
      },
    });
    console.log('RESP', response.data);
    if (response.status === 200) {
      const id = response?.data.data._id;
      console.log('DATA', response.data.data);
      console.log('USERID', id);
      this.setUserId(id);
    }
  };
}

export default new Auth();
