import React, { PureComponent } from 'react';
import { formatPhone, getMiles } from '../../../shared/helperMethods/HelperMethod';
import images from '../../../shared/utils/Images';

interface ownState {}
interface ownProps {
  data: any;
}

export default class SearchResultHospital extends PureComponent<ownProps, ownState> {
  render(): React.ReactElement {
    const { data } = this.props;
    return (
      <>
        <div className='grid-row container-fluid'>
          <div className='container-fluid container-dt-doctor padding-left-0'>
            <div className='col-lg-12 col-md-12 col-sm-12 no-padding grid-content margin-left-15'>
              <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-4 ps-0 pe-md-5'>
                  <div className='col-lg-12 col-md-12 col-sm-12 no-padding-mob clearfix margin-bottom-15-mob'>
                    <h1>{data && data.hospitalName}</h1>
                    <p>{data && data.hospitalSystem}</p>
                  </div>
                </div>
                <div className='col-sm-8 no-padding mt-0 mt-sm-2 mt-lg-0'>
                  <div className='row'>
                    <div className='col-md-7  no-padding'>
                      <div className='medical-group mt-2'>
                        <img src={images.phone} alt='' />
                        <span>
                          <a href={`tel:${data?.phone}`}>
                            {data && data.phone.indexOf('-') === -1 ? formatPhone(data.phone) : data?.phone}
                          </a>
                        </span>
                      </div>

                      <div className='img-text-2 mt-2'>
                        <span className='img-icon'>
                          <img
                            className='img-fluid'
                            src={images.icPinList}
                            srcSet={`${images.icPinList2x} 2x, ${images.icPinList3x} 3x`}
                            alt='tick'
                          />
                        </span>
                        <span className='text-tick address-text shift-up'>{data?.address?.street1}</span>
                        <span className='text-tick address-text'>{data?.address?.street2}</span>
                        <span className='text-tick address-text'>
                          {data?.address?.city.replace(/(\w)(\w*)/g, function (g0: any, g1: any, g2: any) {
                            return g1.toUpperCase() + g2.toLowerCase();
                          })}
                          , {data?.address?.state} {data?.address?.zip}
                        </span>
                      </div>
                      <div className='img-text-2'>
                        <span className='img-icon' />
                        <span className='miles'>
                          {data?.distance === 0 ? '0 miles away' : data?.distance && getMiles(data?.distance)}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-5 collpase-section crop-text-align link-hospital mt-2 mt-md-0'>
                      <a target='_blank' href={data.url} rel='noreferrer'>
                        {data && data.url}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
