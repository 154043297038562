/* eslint no-underscore-dangle: 0 */
import React, { PureComponent } from 'react';
import {
  COUNTIES_SERVED_LABEL,
  FAX_LABLE,
  HEALTH_PLAN,
  HIDE_DETAILS,
  NPI_LABLE,
  REPORT_INACCURACIES_LABLE,
  VENDOR_DETAILS,
  VIEW_DETAILS,
} from '../../../shared/constant/AppConstants';
import { SERVICES_REPORT_INACCURACIES } from '../../../shared/constant/Urls';
import { formatFax, formatPhone, getMiles } from '../../../shared/helperMethods/HelperMethod';
import Analytics, { FA } from '../../../shared/utils/Analytics';
import images from '../../../shared/utils/Images';

interface ownState {}
interface ownProps {
  data: any;
}

export default class SearchResultServices extends PureComponent<ownProps, ownState> {
  render(): React.ReactElement {
    const { data } = this.props;
    return (
      <>
        <div className='grid-row container-fluid' key={data._id}>
          <div className='container-fluid container-dt-doctor padding-left-0'>
            <div className='col-lg-12 col-md-12 col-sm-12 no-padding grid-content margin-left-15'>
              <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-4 ps-0 pe-md-5'>
                  <div className='col-lg-12 col-md-12 col-sm-12 no-padding-mob clearfix margin-bottom-15-mob'>
                    <h1>{data && data.displayName}</h1>
                    <div>
                      <span className='tags'>{data && data.typeOfService}</span>
                    </div>
                  </div>
                </div>
                <div className='col-sm-8 no-padding mt-0 mt-sm-2 mt-lg-0'>
                  <div className='row'>
                    <div className='col-sm-6  no-padding'>
                      <div className='medical-group mt-2'>
                        <img src={images.phone} alt='' />
                        <span>
                          <a href={`tel:${data?.phone}`}>
                            {data && data.phone.indexOf('-') === -1 ? formatPhone(data.phone) : data?.phone}
                          </a>
                        </span>
                      </div>
                      <div className='img-text-2 mt-2'>
                        <span className='img-icon'>
                          <img
                            className='img-fluid'
                            src={images.icPinList}
                            srcSet={`${images.icPinList2x} 2x, ${images.icPinList3x} 3x`}
                            alt='tick'
                          />
                        </span>
                        <span className='text-tick address-text shift-up'>{data.address.street1}</span>
                        <span className='text-tick address-text'>{data.address.street2}</span>
                        <span className='text-tick address-text'>
                          {data.address.city.replace(/(\w)(\w*)/g, function (g0: any, g1: any, g2: any) {
                            return g1.toUpperCase() + g2.toLowerCase();
                          })}
                          , {data.address.state} {data.address.zip}
                        </span>
                      </div>
                      <div className='img-text-2'>
                        <span className='img-icon' />
                        <span className='miles'>
                          {data?.distance === 0 ? '0 miles away' : data?.distance && getMiles(data?.distance)}
                        </span>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 no-padding url-section-col col-sm-6 link-align link-align-services collpase-section link-crop-fix-service text-center text-sm-end clearfix ps-0 ms-0 pr-0 mt-2'>
                      <a target='_blank' href={data.url} rel='noreferrer'>
                        {data && data.url}
                      </a>
                    </div>
                    <div className='col-12 text-center text-sm-end px-0'>
                      <a
                        className='hook collapsed view-details-btn view-details-btn-service clickable'
                        data-bs-toggle='collapse'
                        href={`#collapseDetails${data._id}`}
                        role='button'
                        aria-expanded='false'
                        aria-controls={`collapseDetails${data._id}`}
                        onClick={() => {
                          const myCollapse = document.getElementById(`${data._id}_span`);
                          if (myCollapse?.offsetParent == null) {
                            Analytics.logEvent(FA.DOCTOR_AND_SERVICE_SERVICE_VIEW_DETAILS, {
                              provider_name: data.displayName,
                            });
                          }
                        }}>
                        <span className='btn-toggle-details hide-detail'>{HIDE_DETAILS}</span>
                        <span className='btn-toggle-details view-detail' id={`${data._id}_span`}>
                          {VIEW_DETAILS}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='collapse  px-0' id={`collapseDetails${data._id}`}>
          <div className='container-fluid detail-section'>
            <div className='container-fluid detail-section-padding'>
              <fieldset>
                <legend>{VENDOR_DETAILS}</legend>
                <div className='row'>
                  <div className='col-lg-4 col-md-4 col-sm-4 col-12 no-padding-left'>
                    <div className='row'>
                      <div className='img-text-2 col-12 no-padding-left d-flex'>
                        <span className='img-icon'>
                          <img
                            className='img-fluid image20'
                            src={images.icDetailCertificate}
                            srcSet={`${images.icDetailCertificate2x} 2x, ${images.icDetailCertificate3x} 3x`}
                            alt='tick'
                          />
                        </span>
                        <span className='text-tick'>
                          {NPI_LABLE}
                          <p className='mb-2'>{data && data.npi}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-4 col-12 no-padding-left'>
                    <div className='row'>
                      <div className='img-text-2 clearfix col-12 no-padding-left d-flex'>
                        <span className='img-icon'>
                          <img
                            className='img-fluid image20'
                            src={images.icDetailPlan}
                            srcSet={`${images.icDetailPlan2x} 2x, ${images.icDetailPlan3x} 3x`}
                            alt='tick'
                          />
                        </span>
                        <span className='text-tick'>
                          {HEALTH_PLAN}
                          {data?.nameOfNetwork &&
                            data?.nameOfNetwork?.map((i: any) => (
                              <p className='mb-2' key={i}>
                                {i}
                              </p>
                            ))}
                        </span>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='img-text-2 clearfix col-12 no-padding-left d-flex'>
                        <span className='img-icon' />
                        <span className='text-tick'>
                          {COUNTIES_SERVED_LABEL}
                          <p>
                            {data &&
                              data?.serviceCounties?.map((i: any, index: any) => (
                                <span>
                                  {i}
                                  {index === data?.serviceCounties.length - 1 ? '' : ','}{' '}
                                </span>
                              ))}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-4 col-md-4 col-sm-4 col-12 no-padding-left'>
                    <div className='row'>
                      <div className='img-text-2 clearfix col-12 no-padding-left d-flex'>
                        <span className='img-icon'>
                          <img
                            className='img-fluid image20'
                            src={images.icDetailFax}
                            srcSet={`${images.icDetailFax2x} 2x, ${images.icDetailFax3x} 3x`}
                            alt='tick'
                          />
                        </span>
                        <span className='text-tick'>
                          {FAX_LABLE}
                          <p>{data && formatFax(data.fax)}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className='col-12 no-padding text-end report-inaccuracy'>
                <span
                  role='none'
                  onClick={() => window.open(`${SERVICES_REPORT_INACCURACIES}/${data._id}`, '_blank')}
                  onKeyDown={() => window.open(`${SERVICES_REPORT_INACCURACIES}/${data._id}`, '_blank')}>
                  <img
                    className='img-fluid'
                    src={images.icDetailReport}
                    srcSet={`${images.icDetailReport2x} 2x, ${images.icDetailReport3x} 3x`}
                    alt='tick'
                  />
                  {REPORT_INACCURACIES_LABLE}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
