import * as actions from '@canopy/shared';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { SELECT_HEALTH_PLAN, SELECT_HEALTH_PLAN_MESSAGE } from '../../../shared/constant/AppConstants';
import { history } from '../../../shared/helperMethods/HelperMethod';
import { reactPlugin } from '../../../shared/utils/ApplicationInsightsServices';
import Auth from '../../../shared/utils/Auth';
import Images from '../../../shared/utils/Images';
import DoctorAndServiceFooterInfo from '../sharedDoctorsComponents/DoctorAndServiceFooterInfo';

interface DispatchProps {
  createGetModuleConfigDataRequest: typeof actions.createGetModuleConfigDataRequest;
}

interface DoctorsAndServicesHomeState {
  selectedData: string;
}

interface DoctorsAndServicesHomeProps {
  onSearch: any;
  healthPlanData: any;
}

interface stateProps {
  data: any;
}

type props = DoctorsAndServicesHomeProps & DispatchProps & stateProps;

class DoctorsAndServicesHome extends PureComponent<props, DoctorsAndServicesHomeState> {
  constructor(props: props) {
    super(props);
    this.state = {
      selectedData: '',
    };
  }

  componentDidMount(): void {
    const { createGetModuleConfigDataRequest, data } = this.props;

    let params = history.location?.search;
    if (params) {
      params = params.substring(params.indexOf('=') + 1, params.length);
      const { onSearch } = this.props;
      if (params === 'CanopyCare') {
        this.setState({ selectedData: 'Canopy Health - Health Net CanopyCare' });
        onSearch('Canopy Health - Health Net CanopyCare');
      } else if (params === 'SignatureValue Harmony') {
        this.setState({ selectedData: 'Canopy Health - UnitedHealthcare SignatureValue Harmony' });
        onSearch('Canopy Health - UnitedHealthcare SignatureValue Harmony');
      } else if (params === 'Blue & Gold') {
        this.setState({ selectedData: 'Canopy Health - Health Net Blue & Gold' });
        onSearch('Canopy Health - Health Net Blue & Gold');
      } else if (params === 'SmartCare') {
        this.setState({ selectedData: 'Canopy Health - Health Net SmartCare' });
        onSearch('Canopy Health - Health Net SmartCare');
      } else if (params === 'Medicare Advantage') {
        this.setState({ selectedData: 'Canopy Health - UnitedHealthcare Medicare Advantage' });
        onSearch('Canopy Health - UnitedHealthcare Medicare Advantage');
      } else if (params === 'SignatureValue Advantage') {
        this.setState({ selectedData: 'Canopy Health - UnitedHealthcare SignatureValue Advantage' });
        onSearch('Canopy Health - UnitedHealthcare SignatureValue Advantage');
      } else if (params === 'Doctors Plan') {
        this.setState({ selectedData: 'Canopy Health - UnitedHealthcare Doctors Plan' });
        onSearch('Canopy Health - UnitedHealthcare Doctors Plan');
      }
    }

    createGetModuleConfigDataRequest(
      {
        query: `configId=${data.ConfigData?.getMainConfigdata?.data?._id}&module=${'doctor'}`,
        isAuthenticated: Auth.isAuthenticated(),
      },
      () => {}
    );
  }

  handleChange = (e: any): any => {
    this.setState({ selectedData: e.target.value });
  };

  render(): React.ReactElement {
    const { selectedData } = this.state;
    const { onSearch, healthPlanData, data } = this.props;
    return (
      <>
        <div className='network doctor'>
          <div className='doctors-initial-screen'>
            <div className='provider-image-container'>
              <img
                className='provider-image'
                src={Images.icEmptyProvider}
                srcSet={`${Images.icEmptyProvider2x} 2x, ${Images.icEmptyProvider3x} 3x`}
                alt='provider'
              />
            </div>
            <div className='select-plan-section'>
              <div className='select-plan-label'>{SELECT_HEALTH_PLAN_MESSAGE}</div>
              <div>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  displayEmpty
                  value={selectedData}
                  id='select_healthplan'
                  name='nameOfNetwork'
                  className='custom-dark health-plan health-plan-select blue distance-dropdown'
                  onChange={this.handleChange}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value=''>{SELECT_HEALTH_PLAN}</MenuItem>
                  {healthPlanData &&
                    healthPlanData.sort().map((i: any) => (
                      <MenuItem value={i} key={i}>
                        {i}
                      </MenuItem>
                    ))}
                </Select>
                {/* <select
                  className='health-plan health-plan-select form-select m-auto'
                  id='select_healthplan'
                  value={selectedData}
                  onChange={this.handleChange}>
                  <option value=''>{SELECT_HEALTH_PLAN} </option>
                  {healthPlanData?.sort().map((i: any) => (
                    <option key={i}>{i}</option>
                  ))}
                </select> */}
              </div>
              <div>
                <button
                  disabled={selectedData === ''}
                  type='button'
                  className='btn search-button active-search-button p-0'
                  onClick={() => onSearch(selectedData)}>
                  <img className='search-image' alt='search' src={Images.icSearch} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <DoctorAndServiceFooterInfo data={data} location='providers' page='d_s_home' />
        {/* <p className='text-descriptive px-0 mt-0'>{DIRECTORY_INFO_LABEL}.</p> */}
      </>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetModuleConfigDataRequest: actions.createGetModuleConfigDataRequest,
};

export default connect<stateProps, DispatchProps, DoctorsAndServicesHomeProps>(
  mapStateToProps,
  mapDispatchToProps
)(withAITracking(reactPlugin, DoctorsAndServicesHome));
