export const showLoader = (): any => {
  document.body.classList.add('loading-indicator');
};

export const hideLoader = (): any => {
  document.body.classList.remove('loading-indicator');
};

export const showLoggingInLoader = (): any => {
  document.body.classList.add('logging-in');
};

export const hideLoggingInLoader = (): any => {
  document.body.classList.remove('logging-in');
};
export const showLoggingOutLoader = (): any => {
  document.body.classList.add('logging-out');
};

export const hideLoggingOutLoader = (): any => {
  document.body.classList.remove('logging-out');
};

export const getFormattedBio = (bio: any): any => {
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: bio,
      }}
    />
  );
};
