/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { RESULTS_SHOWN_MESSAGE, SORT_BY_LABLE } from '../../../shared/constant/AppConstants';
import { PAGINATION_DISCLAIMER_MSG } from '../../../shared/constant/AppMessages';
import Auth from '../../../shared/utils/Auth';

interface ownProps {
  data: any;
  skip?: any;
  skipArray?: any[];
  onPageChange: any;
  currentPage: any;
  sortOrder: any;
  onSortChange: any;
  pageName: any;
  onViewChange?: (viewType: string) => void;
  isListView: boolean;
}

class Paginations extends PureComponent<ownProps> {
  componentDidMount(): void {
    const { skipArray, skip } = this.props;
    if (skipArray && !skipArray.includes(skip)) {
      skipArray.push(skip);
      skipArray.sort((a, b) => a - b);
    }
  }

  getPreviousSkip = (skip: any): any => {
    const { skipArray } = this.props;
    const index =
      _.findIndex(skipArray, (e) => {
        return e === skip;
      }) - 2;
    return skipArray && (skipArray[index] ? skipArray[index] : 0);
  };

  render(): React.ReactElement {
    const {
      onPageChange,
      currentPage,
      data,
      sortOrder,
      onSortChange,
      pageName,
      onViewChange,
      isListView,
      skip,
      skipArray,
    } = this.props;
    const totalPages = Math.ceil(data?.data?.totalRecords / 15);
    return (
      <>
        <div className='pagination-bar container-fluid col-12' id='header-pagination'>
          <div className='disclaimer-red small d-none'>{PAGINATION_DISCLAIMER_MSG}</div>
          <div className='row align-items-center'>
            <div className='total-records float-start col-12 col-lg-12 col-xl-4 px-0 mt-0  full-width margin-bottom'>
              {data?.data?.totalRecords}{' '}
              {`${pageName === 'changePCP' ? 'Doctor' : pageName}${
                pageName === 'Doctor' || pageName === 'changePCP' ? ' Locations' : ''
              } Found`}
              {!Auth.isAuthenticated() && <div className='result-disclaimer'>{RESULTS_SHOWN_MESSAGE}</div>}
            </div>
            <div className={`col-12 col-md-12 col-lg-12 action-section px-0  ${!isListView ? 'col-xl-4' : 'col-xl-8'}`}>
              <div className='row'>
                <div className='col-12 col-md-12 col-lg-12 text-md-center px-0 full-width margin-bottom'>
                  <div className='row'>
                    <div
                      className={`btn-group col-md-4 ps-0 ${
                        !isListView ? 'col-12 d-flex align-items-center justify-content-center mx-auto' : 'col-6'
                      } `}>
                      {pageName === 'Doctor' && (
                        <>
                          <button
                            onClick={() => {
                              if (onViewChange) {
                                onViewChange('list');
                              }
                            }}
                            type='button'
                            className={`btn-list-view toggleBtn ${isListView ? 'active' : ''}`}>
                            List View
                          </button>
                          <button
                            onClick={() => {
                              if (onViewChange) {
                                onViewChange('map');
                              }
                            }}
                            type='button'
                            className={`btn-map-view toggleBtn ${isListView ? '' : 'active'}`}>
                            Map View
                          </button>
                        </>
                      )}
                    </div>
                    {isListView && (
                      <div className='col-6 col-md-4 sort-section pe-0'>
                        <div className='row d-flex align-items-center'>
                          <div className='col-auto px-0'>
                            <label className='mb-0' htmlFor='sort'>
                              {' '}
                              {SORT_BY_LABLE}:{' '}
                            </label>
                          </div>
                          <div className='col-auto pe-0'>
                            <select onChange={onSortChange} value={sortOrder.lastName}>
                              <option value={1}>A-Z</option>
                              <option value={-1}>Z-A</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    {isListView && totalPages !== 0 && data?.data?.totalRecords > 15 && (
                      <div className='col-12 col-md-4 col-lg-4 float-end text-md-center pagination-section pe-0'>
                        <div className='justify-content-center'>
                          <div className='col-12 col-sm-auto no-padding mt-2 mt-sm-0'>
                            <nav aria-label='Page navigation example nav-section'>
                              <ul className='pagination mb-0 justify-content-end'>
                                <li className='page-item'>
                                  <span
                                    role='button'
                                    tabIndex={0}
                                    onClick={() =>
                                      currentPage !== 1 && pageName === 'Doctor' ? onPageChange(0) : onPageChange(1)
                                    }
                                    onKeyPress={() =>
                                      currentPage !== 1 && pageName === 'Doctor' ? onPageChange(0) : onPageChange(1)
                                    }
                                    className='page-link'
                                    aria-label='Previous'>
                                    <span aria-hidden='true'>{'<<'}</span>
                                  </span>
                                </li>
                                <li className='page-item'>
                                  <span
                                    role='button'
                                    tabIndex={0}
                                    onClick={() =>
                                      currentPage !== 1 && pageName === 'Doctor'
                                        ? onPageChange(this.getPreviousSkip(skip), 'prev')
                                        : onPageChange(currentPage - 1)
                                    }
                                    onKeyPress={() =>
                                      currentPage !== 1 && pageName === 'Doctor'
                                        ? onPageChange(this.getPreviousSkip(skip), 'prev')
                                        : onPageChange(currentPage - 1)
                                    }
                                    className='page-link'
                                    aria-label='Previous'>
                                    <span aria-hidden='true'>{'<'}</span>
                                  </span>
                                </li>
                                <li className='page-item'>
                                  <span className='page-link pagination-center text-white' style={{ minWidth: '61px' }}>
                                    {currentPage} of {Math.ceil(data && data?.data?.totalRecords / 15)}
                                  </span>
                                </li>
                                <li className='page-item'>
                                  <span
                                    role='button'
                                    tabIndex={0}
                                    onClick={() =>
                                      currentPage < data?.data?.totalRecords / 15 && pageName === 'Doctor'
                                        ? onPageChange(skip, 'next')
                                        : onPageChange(currentPage + 1)
                                    }
                                    onKeyPress={() =>
                                      currentPage < data?.data?.totalRecords / 15 && pageName === 'Doctor'
                                        ? onPageChange(skip, 'next')
                                        : onPageChange(currentPage + 1)
                                    }
                                    className='page-link'
                                    aria-label='Next'>
                                    <span aria-hidden='true'>{'>'}</span>
                                  </span>
                                </li>
                                <li className='page-item'>
                                  <span
                                    role='button'
                                    tabIndex={0}
                                    // onClick={() => currentPage < totalPages && onPageChange(totalPages)}
                                    // onKeyPress={() => currentPage < totalPages && onPageChange(totalPages)}
                                    className='page-link'
                                    aria-label='Next'>
                                    <span aria-hidden='true'>{'>>'}</span>
                                  </span>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Paginations;
