/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import { PureComponent } from 'react';

import { ACCESS_EXTERNAL_SITE_TITLE_TXT } from '../../constant/AppConstants';
import { NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG } from '../../constant/AppMessages';
import Analytics, { FA } from '../../utils/Analytics';
import Auth from '../../utils/Auth';
import images from '../../utils/Images';
import AccessExternalSiteDialog from '../accessExternalSiteDialog/AccessExternalSiteDialog';

interface ownProps {
  type: any;
  url: any;
  text: any;
  id?: any;
  bold?: boolean;
}

export default class Links extends PureComponent<ownProps, {}> {
  componentDidMount(): void {
    // Auth.createSession();
  }

  render(): JSX.Element {
    const { type, url, text, id = '1', bold } = this.props;

    const setUrl = (): any => {
      let uri = url;
      const sessionToken = Auth.getSessionToken();
      if (sessionToken) {
        if (uri.endsWith('/')) {
          uri = uri.replace(/\/\s*$/, '');
          uri = `${uri}?sessionToken=${Auth.getSessionToken()}`;
        } else {
          const path = uri.split('/').pop();
          if (path?.includes('?')) {
            uri = `${uri}&sessionToken=${Auth.getSessionToken()}`;
          } else {
            uri = `${uri}?sessionToken=${Auth.getSessionToken()}`;
          }
        }
      }
      return url;
    };

    const callAnalytics = (linkText: string): void => {
      Analytics.logEvent(FA.APPLICATION_ALERT, {
        title: ACCESS_EXTERNAL_SITE_TITLE_TXT,
        description: NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG,
      });

      if (linkText === 'CVS-Manage Prescriptions') {
        Analytics.logEvent(FA.CLICK_CAREMARK_CVS, {
          title: ACCESS_EXTERNAL_SITE_TITLE_TXT,
          description: NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG,
        });
      }

      if (linkText === 'Visit my UHC') {
        Analytics.logEvent(FA.CLICK_VISIT_MYUHC_COM);
      }
      if (linkText === 'Find Care and Costs') {
        Analytics.logEvent(FA.ACCEPT_FIND_CARE_AND_COSTS);
        Analytics.logEvent(FA.CLICK_FIND_CARE_AND_COSTS);
      }
    };

    return (
      <>
        {type === 'link' ? (
          <a
            className={type}
            href={url}
            target='_blank'
            rel='noreferrer'
            data-bs-toggle='modal'
            data-bs-target={`#AccessExternalSite${id}`}
            onClick={() => {
              callAnalytics(text);
            }}>
            <img
              alt={text}
              className='clickable arrow img-fluid'
              src={bold ? images.btnDeepLink : images.icDeeplink}
              srcSet={`${bold ? images.btnDeepLink : images.icDeeplink}, ${
                bold ? images.btnDeepLink2x : images.icDeeplink2x
              } 2x, ${bold ? images.btnDeepLink3x : images.icDeeplink3x} 3x`}
            />
            <span>{text}</span>
          </a>
        ) : type === 'button' ? (
          <a
            className={`virtual-visit-${type}`}
            href={url}
            target='_blank'
            rel='noreferrer'
            data-bs-toggle='modal'
            data-bs-target={`#AccessExternalSite${id}`}
            onClick={() => {
              callAnalytics(text);
            }}>
            <span>{text}</span>
          </a>
        ) : (
          <a className='link' href={url}>
            <img
              alt={text}
              className='clickable arrow img-fluid'
              src={bold ? images.btnDeepLink : images.icDeeplink}
              srcSet={`${bold ? images.btnDeepLink : images.icDeeplink}, ${
                bold ? images.btnDeepLink2x : images.icDeeplink2x
              } 2x, ${bold ? images.btnDeepLink3x : images.icDeeplink3x} 3x`}
            />
            <span>{text}</span>
          </a>
        )}
        {url && (
          <div
            className='modal fade'
            id={`AccessExternalSite${id}`}
            aria-labelledby={`AccessExternalSite${id}Label`}
            aria-hidden='true'>
            <AccessExternalSiteDialog link={setUrl()} />
          </div>
        )}
      </>
    );
  }
}
