/* eslint no-underscore-dangle: 0 */
import { PureComponent } from 'react';

import {
  BOARD_CERTIFIED_LABEL,
  CA_LICENSE_LABEL,
  CLINIC_NAME,
  COUNTY,
  HEALTH_PLAN,
  HOSPITALIST_LABEL,
  HOSPITALS_TEXT,
  HOSPITAL_NPI_LABEL,
  LANGUAGES,
  MEDIA_GROUP_IPA_LABLE,
  NO_LABEL,
  NPI_LABLE,
  OFFICE_HOURS,
  TELEHEALTH_LABEL,
  YES_LABEL,
} from '../../../shared/constant/AppConstants';

import {
  convertToLowerCase,
  formattedSchedule,
  formattedScheduleTime,
} from '../../../shared/helperMethods/HelperMethod';

import Images from '../../../shared/utils/Images';
import AddressMap from './AddressMap/index';

interface ownProps {
  provider: any;
  data: any;
}

interface ownState {}
interface stateProps {}

type componentProps = ownProps & stateProps;

// eslint-disable-next-line no-unused-vars
declare let Vim: (a: string, b: any) => Promise<any>;

class ConditionalUI extends PureComponent<componentProps, ownState> {
  constructor(props: componentProps) {
    super(props);
    this.state = {};
  }

  getImageByText = (text: string): any => {
    switch (text) {
      case 'Hill Physicians Medical Group':
        return Images.HILL;
      case 'SCCIPA':
        return Images.SCCIPA;
      case 'sccipa':
        return Images.SCCIPA;
      case 'Santa Clara County IPA':
        return Images.SCCIPA;
      case 'Meritage Medical Network':
        return Images.MERITAGE;
      case 'John Muir Health':
        return Images.JOHNMUIR;
      case 'John Muir Physician Network':
        return Images.JOHNMUIR;
      case 'Dignity Health Medical Network - Santa Cruz':
        return Images.DIGNITY;
      case 'Providence Medical Network':
        return Images.PROVIDENCE;
      default:
        return null;
    }
  };

  render(): React.ReactElement {
    const { provider: data, data: mainData } = this.props;

    const scheduleObject = formattedSchedule(data?.hoursOfOperation);

    return (
      <div className='container-fluid detail-section-padding mt-3'>
        <div className='col-header first-name px-3'>Additional Information</div>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-6'>
            {data?.hoursOfOperation ? (
              <>
                <div className='provider-office-hours mt-3 pe-2 ps-3 py-3'>
                  <div className='medical-group mt-0 d-flex align-items-center '>
                    <img src={Images.clock} alt='' />
                    <span className='col-header'>{OFFICE_HOURS}</span>
                  </div>

                  {typeof scheduleObject === 'string' ? (
                    <div className='hours-container'>{scheduleObject.toLowerCase()}</div>
                  ) : (
                    Object.entries(scheduleObject).map(([day, time]) => {
                      return (
                        <div className='row mt-1' key={`${data._id}-${day}-${time}-scheduleObject`}>
                          <div className='col-4 ps-4'>{day}</div>
                          <div className='col-8 text-right pe-4'>{formattedScheduleTime(time)}</div>
                        </div>
                      );
                    })
                  )}
                </div>

                <div className='col-12 col-md-12 col-lg-12 mt-3'>
                  <AddressMap address={data.address} />
                </div>
              </>
            ) : (
              <div className='additional-info mt-3 py-1 pt-2 ps-1'>
                <div className='row'>
                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img style={{ marginTop: '5px' }} className='' src={Images.group} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{MEDIA_GROUP_IPA_LABLE}</span>
                        <span className='data'>{data?.medicalGroup}</span>
                      </div>
                    </div>
                  </div>
                  {mainData?.nameOfNetwork && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0col-1 px-0 d-flex align-items-start'>
                          <img className='mt-1' src={Images.heartPulse} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{HEALTH_PLAN}</span>
                          {mainData?.nameOfNetwork?.map((i: any) => (
                            <span className='data' key={i}>
                              {i}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img style={{ marginTop: '1px' }} src={Images.clinickName} alt={data?.medicalGroup} />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{CLINIC_NAME}</span>
                        <span className='data capitalized'>{convertToLowerCase(data?.clinicName)}</span>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0'>
                        <img src={Images.cerificate} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{BOARD_CERTIFIED_LABEL}</span>
                        <span className='data'>{data?.boardCertifiedOrEligible === 'Y' ? 'Yes' : 'No'}</span>
                      </div>
                    </div>
                  </div>

                  {data?.npi && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 d-flex align-items-start'>
                          <img style={{ marginTop: '1px' }} src={Images.idCard} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{NPI_LABLE}</span>
                          <span className='data'>{data?.npi}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {data?.address?.county && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 d-flex align-items-start'>
                          <img style={{ marginTop: '3px', marginLeft: '2px' }} src={Images.locationDot} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{COUNTY}</span>
                          <span className='data capitalized'>{data?.address?.county.toLowerCase()}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img style={{ marginTop: '3px', marginLeft: '2px' }} src={Images.awardSimple} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{CA_LICENSE_LABEL}</span>
                        <span className='data'>{data?.caLicense}</span>
                      </div>
                    </div>
                  </div>
                  {data?.providerLanguage && data?.providerLanguage?.length > 0 && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 text-center text-center'>
                          <img src={Images.flag} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{LANGUAGES}</span>
                          <span className='data capitalized'>{data?.providerLanguage?.join(', ').toLowerCase()}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img style={{ marginTop: '1px' }} src={Images.telehealth} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{TELEHEALTH_LABEL}</span>
                        <span className='data'>{data?.telehealth ? YES_LABEL : NO_LABEL}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img style={{ marginTop: '-2px' }} src={Images.hospitalist} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{HOSPITALIST_LABEL}</span>
                        <span className='data'>{NO_LABEL}</span>
                      </div>
                    </div>
                  </div>
                  {data?.hospitalNPI && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 d-flex align-items-start'>
                          <img style={{ marginTop: '1px' }} src={Images.idCard} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'> {HOSPITAL_NPI_LABEL}</span>
                          <span className='data'>{data?.hospitalNPI}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {data?.hospital && data?.hospital?.length > 0 && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 d-flex align-items-start'>
                          <img style={{ marginTop: '3px' }} src={Images.hospitals} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{HOSPITALS_TEXT}</span>

                          {data?.hospital.map((hospital: any) => (
                            <span className='data' key={hospital}>
                              {hospital}
                              <br />{' '}
                              {data?.hospitalPrivilegeStatus?.length > 0 && (
                                <span>(Has Privileges, Referral Required)</span>
                              )}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='col-12 col-md-6 col-lg-6'>
            {data?.hoursOfOperation ? (
              <div className='additional-info mt-3 py-1 pt-2 ps-1'>
                <div className='row'>
                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img className='mt-1' src={Images.group} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{MEDIA_GROUP_IPA_LABLE}</span>
                        <span className='data'>{data?.medicalGroup}</span>
                      </div>
                    </div>
                  </div>
                  {mainData?.nameOfNetwork && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 d-flex align-items-start'>
                          <img className='mt-1' src={Images.heartPulse} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{HEALTH_PLAN}</span>
                          {mainData?.nameOfNetwork?.map((i: any) => (
                            <span className='data' key={i}>
                              {i}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img style={{ marginTop: '1px' }} src={Images.clinickName} alt={data?.medicalGroup} />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{CLINIC_NAME}</span>
                        <span className='data capitalized'>{convertToLowerCase(data?.clinicName)}</span>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0'>
                        <img src={Images.cerificate} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{BOARD_CERTIFIED_LABEL}</span>
                        <span className='data'>{data?.boardCertifiedOrEligible === 'Y' ? 'Yes' : 'No'}</span>
                      </div>
                    </div>
                  </div>

                  {data?.npi && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 d-flex align-items-start'>
                          <img style={{ marginTop: '1px' }} src={Images.idCard} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{NPI_LABLE}</span>
                          <span className='data'>{data?.npi}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {data?.address?.county && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 d-flex align-items-start'>
                          <img style={{ marginTop: '3px', marginLeft: '2px' }} src={Images.locationDot} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{COUNTY}</span>
                          <span className='data capitalized'>{data?.address?.county.toLowerCase()}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img style={{ marginTop: '3px', marginLeft: '2px' }} src={Images.awardSimple} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{CA_LICENSE_LABEL}</span>
                        <span className='data'>{data?.caLicense}</span>
                      </div>
                    </div>
                  </div>
                  {data?.providerLanguage && data?.providerLanguage?.length > 0 && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 text-center'>
                          <img src={Images.flag} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{LANGUAGES}</span>
                          <span className='data capitalized'>{data?.providerLanguage?.join(', ').toLowerCase()}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img style={{ marginTop: '1px' }} src={Images.telehealth} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{TELEHEALTH_LABEL}</span>
                        <span className='data'>{data?.telehealth ? YES_LABEL : NO_LABEL}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-6 py-2'>
                    <div className='row'>
                      <div className='col-1 px-0 d-flex align-items-start'>
                        <img style={{ marginTop: '-2px' }} src={Images.hospitalist} alt='' />
                      </div>
                      <div className='col-11 pe-0'>
                        <span className='label'>{HOSPITALIST_LABEL}</span>
                        <span className='data'>{NO_LABEL}</span>
                      </div>
                    </div>
                  </div>
                  {data?.hospitalNPI && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 d-flex align-items-start'>
                          <img style={{ marginTop: '1px' }} src={Images.idCard} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'> {HOSPITAL_NPI_LABEL}</span>
                          <span className='data'>{data?.hospitalNPI}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {data?.hospital && data?.hospital?.length > 0 && (
                    <div className='col-12 col-md-6 col-lg-6 py-2'>
                      <div className='row'>
                        <div className='col-1 px-0 d-flex align-items-start'>
                          <img style={{ marginTop: '3px' }} src={Images.hospitals} alt='' />
                        </div>
                        <div className='col-11 pe-0'>
                          <span className='label'>{HOSPITALS_TEXT}</span>

                          {data?.hospital.map((hospital: any, index: any) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <span className='data' key={hospital + index}>
                              {hospital}
                              <br />{' '}
                              {data?.hospitalPrivilegeStatus?.length > 0 && (
                                <span>(Has Privileges, Referral Required)</span>
                              )}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className='col-12 col-md-12 col-lg-12 mt-3'>
                <AddressMap address={data.address} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ConditionalUI;
