export const CountyZoomRange = {
  max: 9,
  min: 8,
};

export const LocationZoomRange = {
  min: 13,
  max: 16,
};

export const ZipCodeZoomRange = {
  min: CountyZoomRange.max + 1,
  max: LocationZoomRange.min - 1,
};

export const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY ?? '';
export const MAP_ID = process.env.REACT_APP_GOOGLE_MAPS_MAP_ID ?? '';

/**
 * Initial configuration for the map view.
 */
export const initialConfigMapView = {
  zoom: CountyZoomRange.min,
  center: {
    lat: 37.8157426762233,
    lng: -122.732228205148,
  },
  latLngBounds: {
    south: 36.72291648059266,
    west: -125.43211836175294,
    north: 38.892627942983836,
    east: -120.03233804854304,
  },
};

export const numberOfDoctorCardsInSideBar = 30;

export const staticMap = {
  url: 'https://maps.googleapis.com/maps/api/staticmap?',
  canopyMarker: 'https://mchpublicimages.blob.core.windows.net/mch-logo-images/static-map-marker.png',
  size: '446x180',
  scale: '1',
  zoom: '15',
};
