/* eslint-disable react/no-array-index-key */
import * as actions from '@canopy/shared';
/* eslint-disable no-underscore-dangle */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HIDE_LBL, SHOW_LBL } from '../../shared/constant/AppConstants';
import { history } from '../../shared/helperMethods/HelperMethod';
import GenericLinkCard from '../../shared/sharedComponents/genericLinks/GenericLinkCard';
import Auth from '../../shared/utils/Auth';
import { hideLoader, showLoader } from '../../shared/utils/ReusableComponents';

interface ownState {
  selectedMenu: string;
}

interface ownProps {
  isSessionTimeout: any;
}

interface dispatchProps {
  createGetModuleConfigDataRequest: typeof actions.createGetModuleConfigDataRequest;
}

interface stateProps {
  data: any;
}

type componentProps = stateProps & dispatchProps & ownProps;

class CustomGenricLinks extends PureComponent<componentProps, ownState> {
  componentDidMount(): void {
    this.onInit();
  }

  onInit = (): any => {
    const state = history.location.state as { from: string; contentId: any };
    showLoader();
    const { createGetModuleConfigDataRequest, data, isSessionTimeout } = this.props;
    if (data.ConfigData?.getMainConfigdata?.data?._id && state) {
      createGetModuleConfigDataRequest(
        {
          query: `configId=${data.ConfigData?.getMainConfigdata?.data?._id}&module=${'custom'}&contentId=${
            state?.contentId
          }`,
          isAuthenticated: Auth.isAuthenticated(),
        },
        (res: any) => {
          hideLoader();
          isSessionTimeout(res);
        }
      );
    }
  };

  getLocationLink = (location: string): any => {
    const { data } = this.props;
    const filteredData = data?.ConfigData?.getModuleConfigdata?.data?.addressableSpaces?.filter(
      (i: any) => i.location === location
    );
    return filteredData?.length > 0 && filteredData[0];
  };

  render(): JSX.Element {
    const { data } = this.props;
    const sections = data.ConfigData?.getModuleConfigdata?.data?.sections;
    return (
      <>
        {sections &&
          Object.keys(sections)?.map((i: any, index: any) => (
            <div className='collapse-directive white-panel pt-2' key={`generic${index}`}>
              <div className='row' key={`generic-row-${index}`}>
                <div className='accordion px-0' id='accordionExample'>
                  <div className='accordion-item border-0'>
                    <h2 className='accordion-header p-0' id='headingOne'>
                      <button
                        className='accordion-button pt-2 pb-2 px-3'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#collapseOne${index}`}
                        aria-expanded='true'
                        aria-controls={`collapseOne${index}`}>
                        <h2 className='mb-0 col' style={sections[i]?.color ? { color: sections[i]?.color } : {}}>
                          {sections[i]?.text}
                        </h2>

                        <div className='show-hide-control col-auto me-1 mb-0'>
                          <span className='show-hide-text clickable'>
                            <span className='hide-text'> {HIDE_LBL}</span>
                            <span className='show-text'> {SHOW_LBL}</span>
                          </span>
                        </div>
                      </button>
                    </h2>
                  </div>
                </div>
              </div>
              <div
                id={`collapseOne${index}`}
                className='accordion-collapse collapse show'
                aria-labelledby='headingOne'
                data-bs-parent='#accordionExample'>
                <div className='accordion-body'>
                  <div className='row'>
                    <div className='col px-1'>
                      <div className='linkcards-container'>
                        {this.getLocationLink(i)?.items?.map((j: any, indexlink: any) => (
                          <GenericLinkCard data={j} key={indexlink} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetModuleConfigDataRequest: actions.createGetModuleConfigDataRequest,
};
export default connect<stateProps, dispatchProps, ownProps>(mapStateToProps, mapDispatchToProps)(CustomGenricLinks);
