/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import * as actions from '@canopy/shared';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { vimApiKey, vimEnvironment } from '../../../../config.js';
import { DoctorsHealthPlans, PROVIDER_TEXT } from '../../../shared/constant/AppConstants';
import {
  DEFAULT_CALL_CENTER,
  FEATUER_AVAILABLE_FOR_LOGGEDIN_USER_MSG_TITLE,
  POPUP_MESSAGE,
} from '../../../shared/constant/AppMessages';
import { isJsonString } from '../../../shared/helperMethods/HelperMethod';
import ConfirmationModal from '../../../shared/sharedComponents/confirmationModal/ComfirmationModal';
import Links from '../../../shared/sharedComponents/genericLinks/Links';
import Analytics, { FA } from '../../../shared/utils/Analytics';
import { reactPlugin } from '../../../shared/utils/ApplicationInsightsServices';
import Auth from '../../../shared/utils/Auth';
import { hideLoader, showLoader } from '../../../shared/utils/ReusableComponents';
import DoctorsPlan from '../DoctorsPlan';
import DoctorAndServiceFooterInfo from '../sharedDoctorsComponents/DoctorAndServiceFooterInfo';
import FooterPaginations from '../sharedDoctorsComponents/FooterPaginations';
import DoctorsAndServicesMenu from '../sharedDoctorsComponents/Menu';
import PSA from '../sharedDoctorsComponents/PSA';
import Paginations from '../sharedDoctorsComponents/Paginations';
import DoctorsAndServicesHome from './DoctorsAndServicesHome';
import MapProvider, { MapView } from './MapView';
import { AreaType, Doctor, DoctorLocations } from './MapView/types';
import SearchFilters from './SearchFilters';
import SearchResult from './SearchResultNewUI';

export type FilterFromMap = {
  value: string;
  type: AreaType;
};

interface doctorsProps {
  pageName: any;
  selectAsPCP: any;
}
interface DoctorsState {
  currentPage: any;
  sort: any;
  showErrorModal: boolean;
  isZipSearch: boolean;
  errorTitle: string;
  errorMessage: string;
  isMatchZipCode?: any;
  // filterDataSuccess: any;
  stringifiedQuery: string;
  closedPsaVersion: boolean;
  isListView: boolean;
  loading: boolean;
  showVimTokenErrorModal: boolean;
  showBookingErrorModal: boolean;
  filterFromMap: FilterFromMap;
  county: string;
  doctorsWithOutsideSearch: Doctor[];
}

interface DispatchProps {
  createGetHealthPlanDataRequest: typeof actions.createGetHealthPlanDataRequest;
  createGetFilterDataRequest: typeof actions.createGetFilterDataRequest;
  createGetFilterDataAuthRequest: typeof actions.createGetFilterDataAuthRequest;
  createGetDoctorsServiceRequest: typeof actions.createGetDoctorsServiceRequest;
  createGetDoctorsServiceCountRequest: typeof actions.createGetDoctorsServiceCountRequest;
  createGetVimMemberTokenRequest: typeof actions.createGetVimTokenRequest;
  createGetUserData: typeof actions.createGetUserInfoRequest;
  // getProviderPDFRequest: typeof actions.getProviderPDFRequest;
  fetchProviderProfile: typeof actions.fetchProviderProfile;
}

interface stateProps {
  data: any;
}

type props = doctorsProps & DispatchProps & stateProps;

const vimConstants = {
  apiKey: vimApiKey,
  env: vimEnvironment,
};

// eslint-disable-next-line no-unused-vars
declare let Vim: (a: string, b: any) => Promise<any>;

class Doctors extends PureComponent<props, DoctorsState> {
  searchData: any;

  skipArray: any[] = [];

  filterRef: any;

  constructor(props: props) {
    super(props);
    this.state = {
      currentPage: 1,
      sort: { lastName: 1 },
      showErrorModal: false,
      showVimTokenErrorModal: false,
      showBookingErrorModal: false,
      errorTitle: 'Error Code: 500 - Internal Server Error',
      errorMessage: 'Some error occurred. Please try again later.',
      stringifiedQuery: '',
      closedPsaVersion: false,
      isZipSearch: false,
      isListView: true,
      loading: false,
      filterFromMap: { value: '', type: AreaType.County },
      county: '',
      doctorsWithOutsideSearch: [],
      // filterDataSuccess: '1',
    };
    this.filterRef = React.createRef();
    this.searchData = {};
  }

  componentDidMount(): any {
    const { createGetHealthPlanDataRequest, pageName, data } = this.props;
    const resultData = data && data.DoctorsAndServicesData;
    resultData.DoctorsServicesDataResponse.data = null;
    if (!Auth.isAuthenticated()) {
      createGetHealthPlanDataRequest({}, () => {
        Auth.clearHealthPlan();
      });
      if (
        sessionStorage.getItem('closedPsaVersion') !== null &&
        sessionStorage.getItem('closedPsaVersion') === 'true'
      ) {
        this.setState({ closedPsaVersion: true });
      }
    }
    if (Auth.isAuthenticated()) {
      const userData = data?.userInfoData?.responsedata?.data;
      if (userData?.closedPsaVersion === '1') {
        this.setState({ closedPsaVersion: true });
      }
    }
    if (pageName === 'changePCP') {
      Analytics.logEvent(FA.CHANGE_PCP_SEARCH_PHYSICIAN_VIEW);
    }
    // if (Auth.isAuthenticated()) {
    //   this.filterCall();
    // }
    this.filterCall();
  }

  componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<DoctorsState>): void {
    const { isListView } = this.state;
    if (isListView !== prevState.isListView) {
      this.setState({ loading: true });
      const { stringifiedQuery } = this.state;
      const query = JSON.parse(stringifiedQuery);
      this.searchProviders(query);
    }

    const { data } = this.props;
    if (
      prevProps.data.DoctorsAndServicesData.DoctorsServicesDataResponse.data !==
      data.DoctorsAndServicesData.DoctorsServicesDataResponse.data
    ) {
      const doctorsWithOutsideSearch = data.DoctorsAndServicesData.DoctorsServicesDataResponse.data?.data?.map(
        (doctorLocations: DoctorLocations) => {
          return doctorLocations.result.map((doctor) => {
            const outsideSearchByMedicalGroup = doctorLocations.additional.filter(
              ({ medicalGroup }) => medicalGroup === doctor.medicalGroup
            );
            return { ...doctor, outsideSearch: outsideSearchByMedicalGroup };
          });
        }
      );

      this.setState({ doctorsWithOutsideSearch: doctorsWithOutsideSearch?.flat() });
      hideLoader();
    }
  }

  setStringifiedQueryString = (query: any): any => {
    this.setState({ stringifiedQuery: JSON.stringify(query) });
  };

  getLocationLink = (location: string): any => {
    const { data } = this.props;
    const filteredData = data?.ConfigData?.getModuleConfigdata?.data?.addressableSpaces?.filter(
      (i: any) => i.location === location
    );
    return filteredData?.length > 0 && filteredData[0];
  };

  filterCall = (): void => {
    const { createGetFilterDataAuthRequest, createGetFilterDataRequest } = this.props;
    if (Auth.isAuthenticated()) {
      const query = {
        serviceName: PROVIDER_TEXT,
      };
      showLoader();
      createGetFilterDataAuthRequest({ query }, (res: any) => this.callBack(res));
    } else {
      const query = {
        nameOfNetwork: Auth.getHealthPlan(),
        serviceName: PROVIDER_TEXT,
      };
      if (query.nameOfNetwork !== '') {
        showLoader();

        createGetFilterDataRequest({ query }, (res: any) => this.callBack(res));
      }
    }
  };

  onSearch = (value: string): any => {
    Auth.setHealthPlan(value);
    const { data } = this.props;
    const userData = data?.userInfoData?.responsedata?.data;
    if (!(Auth.getHealthPlan() === DoctorsHealthPlans.DOCTORS_PLAN || userData?.product === 'EPO')) {
      this.filterCall();
      return;
    }
    this.forceUpdate();
  };

  searchProviders = (searchData: any): any => {
    this.setState({ isMatchZipCode: false });
    if (Object.prototype.hasOwnProperty.call(searchData, 'zip')) {
      this.setState({ isZipSearch: true });
      if (searchData.distance === undefined || searchData.distance === 'Match Zipcode') {
        this.setState({ isMatchZipCode: true });
      }
    } else {
      this.setState({ isZipSearch: false });
    }
    const { createGetDoctorsServiceRequest, createGetDoctorsServiceCountRequest } = this.props;
    if (searchData.nameOfNetwork) {
      createGetDoctorsServiceCountRequest(
        { query: searchData, isAuthenticated: Auth.isAuthenticated() },
        (res: any) => {
          this.searchCallBack(res);
        }
      );
    }

    const { isListView } = this.state;
    const limit = isListView ? 15 : 10000;
    if (Auth.isAuthenticated()) {
      showLoader();
      if (searchData.nameOfNetwork) {
        createGetDoctorsServiceRequest({ query: { ...searchData, limit }, isAuthenticated: true }, (res: any) => {
          this.setState({ loading: false });
          this.searchCallBack(res);
        });
      }
    } else {
      showLoader();
      createGetDoctorsServiceRequest({ query: { ...searchData, limit }, isAuthenticated: false }, (res: any) => {
        this.setState({ loading: false });
        this.searchCallBack(res);
      });
    }
  };

  onSearchProviders = (searchData: any): any => {
    // showLoader();
    this.searchData = searchData.query;
    this.setState({ sort: { firstName: 1 }, currentPage: 1 });
    this.setState({ stringifiedQuery: JSON.stringify(searchData.query) });
    this.searchProviders(searchData.query);
  };

  onPageChange = (pageValue: any, step: string): any => {
    if (!this.skipArray.includes(pageValue)) {
      this.skipArray.push(pageValue);
      this.skipArray.sort((a, b) => a - b);
    }
    const { stringifiedQuery, currentPage } = this.state;
    if (isJsonString(stringifiedQuery)) {
      showLoader();
      this.setState({ currentPage: step === 'prev' ? currentPage - 1 : currentPage + 1 });
      const final = Object.assign(JSON.parse(stringifiedQuery), { skip: pageValue });
      this.searchProviders(final);
    }
  };

  onSortChange = (e: any): any => {
    showLoader();
    const sort = {
      lastName: e.target.value,
    };
    this.setState({ sort });
    Object.assign(this.searchData, { sort });
    this.searchProviders(this.searchData);
  };

  searchCallBack = (res: any): any => {
    const section = document.querySelector('#header-pagination');
    if (section) section.scrollIntoView({ behavior: 'smooth' });
    if (Auth.getHealthPlan() !== 'Canopy Health - UnitedHealthcare Doctors Plan' && !res.isSuccess) {
      this.setState({
        showErrorModal: true,
        errorTitle: `Error Code: ${res?.statusCode} - Unknown Error`,
        errorMessage: res?.message,
      });
    }
  };

  callBack = (res: any): any => {
    const { pageName, data } = this.props;
    if (pageName === 'changePCP') {
      hideLoader();
    }
    const hp = Auth.getHealthPlan();
    if (res.isSuccess) {
      const filterData = data && data.Flterdata;
      if (filterData?.getFilterDataResponse && filterData?.getFilterDataResponse.data && hp !== '') {
        Auth.setHealthPlan(filterData?.getFilterDataResponse?.data?.nameOfNetwork);
      } else if (hp !== 'Canopy Health - UnitedHealthcare Doctors Plan' && hp !== '') {
        const planCallCenter = data?.Register.planContactsData?.data[0]?.phoneNumber || DEFAULT_CALL_CENTER;
        this.setState({
          showErrorModal: true,
          errorTitle: 'Unknown error occurred',
          errorMessage: `Sorry for the inconvenience, an unknown error has occurred.
          Please try again later or contact the Canopy Health Contact Center: ${planCallCenter}.`,
        });
      }
      // if (res?.data?.nameOfNetwork) {
      //   this.searchProviders({
      //     limit: 15,
      //     nameOfNetwork: res?.data?.nameOfNetwork,
      //     page: 1,
      //     serviceName: 'Providers',
      //     sort: { lastName: 1 },
      //   });
      // }
    }
    Auth.setHealthPlan(res?.data?.nameOfNetwork);
    hideLoader();
  };

  clearResult = (): any => {
    const { data } = this.props;
    const resultData = data && data.DoctorsAndServicesData;
    resultData.DoctorsServicesDataResponse.data = null;
    this.forceUpdate();
  };

  getVimMemberToken = (data: any): void => {
    if (Auth.isAuthenticated()) {
      showLoader();
      Analytics.logEvent(FA.VIM_BOOK_FROM_PROVIDER_SEARCH);
      const { createGetVimMemberTokenRequest } = this.props;
      createGetVimMemberTokenRequest({}, (res: any) => {
        if (res.isSuccess && res?.data?.memberTokenDetails?.token) {
          hideLoader();
          this.bookAppointment(data, res?.data?.memberTokenDetails?.token);
          this.setState({ showVimTokenErrorModal: false });
        } else if (res.isSuccess === false) {
          hideLoader();
          this.setState({
            showVimTokenErrorModal: true,
            errorTitle: POPUP_MESSAGE(res.statusCode, res.message).title,
            errorMessage: POPUP_MESSAGE(res.statusCode, res.message).message,
          });
        } else {
          hideLoader();
          this.setState({ showErrorModal: true });
        }
      });
    } else {
      hideLoader();
      this.setState({ showBookingErrorModal: true });
    }
  };

  bookAppointment = (data: any, _token: any): any => {
    const npiData = data?.npi;
    let address: string | any[];
    address = `${data?.address?.street1} ${data?.address?.street2}` || '';
    address = address.concat(`, ${data?.address?.city}, ${data?.address?.state} ${data?.address?.zip}`);
    const options = {
      memberToken: _token || '',
    };
    if (address) {
      Vim(vimConstants.apiKey || '', { env: vimConstants.env })
        .then((vim: any) => {
          vim.showBookingDialog(npiData, address, options).then(() => hideLoader());
        })
        .catch(() => {
          hideLoader();
        });
    } else {
      hideLoader();
      this.setState({ showVimTokenErrorModal: true });
    }
  };

  handleViewChange = (viewType: string): void => {
    const { data } = this.props;
    const resultData = data && data.DoctorsAndServicesData;
    if (viewType === 'list') {
      resultData.DoctorsServicesDataResponse.data.data = [];
    }
    this.setState({ isListView: viewType === 'list' });
  };

  handleOnFilterChange = (value: string, type: AreaType): void => {
    this.setState({ filterFromMap: { value, type } });
  };

  setCounty = (county: string): void => {
    this.setState({ county });
  };

  callFilterSearch = (): void => {
    this.filterRef.current.onSearch();
  };

  render(): React.ReactElement {
    const {
      currentPage,
      sort,
      showErrorModal,
      isZipSearch,
      errorMessage,
      errorTitle,
      isMatchZipCode,
      closedPsaVersion,
      isListView,
      // filterDataSuccess,
      loading,
      showVimTokenErrorModal,
      showBookingErrorModal,
      filterFromMap,
      county,
      doctorsWithOutsideSearch,
    } = this.state;
    const { data, createGetFilterDataRequest, pageName, selectAsPCP, createGetUserData, fetchProviderProfile } =
      this.props;
    const healthPlanData = data && data.HealthPlanData;
    const filterData = data && data.Flterdata;
    const resultData = data && data.DoctorsAndServicesData;
    const userData = data?.userInfoData?.responsedata?.data;

    return (
      <>
        {Auth.getHealthPlan() === '' && !Auth.isAuthenticated() ? (
          <DoctorsAndServicesHome
            onSearch={this.onSearch}
            healthPlanData={healthPlanData?.getHealthPlanDataResponse?.data}
          />
        ) : (
          <>
            {' '}
            {this.getLocationLink('psa') && !closedPsaVersion && (
              <PSA
                closedPsaVersion={() => this.setState({ closedPsaVersion: true })}
                createGetUserData={createGetUserData}
              />
            )}
            <div className='network doctor multiselect-overflow'>
              {Auth.getHealthPlan() === 'Canopy Health - UnitedHealthcare Doctors Plan' ||
              userData?.product === 'EPO' ? (
                <DoctorsPlan />
              ) : (
                <>
                  <div style={{ border: 'solid 1px #d0d0ce', borderRadius: '4px 4px 0 0' }}>
                    {pageName !== 'changePCP' && <DoctorsAndServicesMenu />}

                    <SearchFilters
                      ref={this.filterRef}
                      filterData={
                        filterData && filterData.getFilterDataResponse && filterData.getFilterDataResponse.data
                      }
                      healthPlanData={
                        healthPlanData &&
                        healthPlanData.getHealthPlanDataResponse &&
                        healthPlanData.getHealthPlanDataResponse.data
                      }
                      // selectedHealthPlan={healthPlanSelected}
                      updateFilterData={createGetFilterDataRequest}
                      createGetDoctorsRequest={this.onSearchProviders}
                      setStringifiedQueryString={this.setStringifiedQueryString}
                      clearResult={this.clearResult}
                      filterFromMap={filterFromMap}
                      countyProps={county}
                    />
                    {resultData.DoctorsServicesDataResponse.data?.data?.length > 0 && (
                      <div className='row'>
                        <>
                          <Paginations
                            data={resultData.DoctorsServicesCountResponse}
                            skip={resultData.DoctorsServicesDataResponse?.data?.skip}
                            skipArray={this.skipArray}
                            currentPage={currentPage}
                            onPageChange={this.onPageChange}
                            sortOrder={sort}
                            onSortChange={this.onSortChange}
                            pageName={pageName || 'Doctor'}
                            onViewChange={this.handleViewChange}
                            isListView={isListView}
                          />
                          {pageName !== 'changePCP' &&
                            Auth.isAuthenticated() &&
                            this.getLocationLink('doctorsAndServices-searchResults')?.items?.map((i: any) => (
                              <div className='horizontal-links pill d-inline' key={i.text}>
                                <div className='link-wrapper d-inline' key={i.type}>
                                  {' '}
                                  <Links type={i?.type} text={i?.text} url={i?.url} />
                                </div>
                              </div>
                            ))}
                        </>
                      </div>
                    )}
                  </div>
                  {resultData?.DoctorsServicesDataResponse?.data?.length === 0 ||
                  resultData?.DoctorsServicesDataResponse?.data?.data?.length === 0 ? (
                    <div className='no-result'>0 Doctor Locations Found</div>
                  ) : isListView ? (
                    <>
                      <div className='row'>
                        <div className='grid-section container-fluid col-12 px-0'>
                          {resultData && resultData.DoctorsServicesDataSuccess && (
                            <>
                              {!loading &&
                                resultData?.DoctorsServicesDataResponse?.data?.data?.map((i: any) => (
                                  <div className='grid-row container-fluid mt-3 provider-row' key={i._id}>
                                    <SearchResult
                                      onBookAppointment={this.getVimMemberToken}
                                      isZipSearch={isZipSearch}
                                      isMatchZipCode={isMatchZipCode}
                                      allData={data}
                                      provider={i}
                                      pageName={pageName}
                                      selectAsPCP={selectAsPCP}
                                      hasOtherLocations={i.additional.length > 0}
                                    />
                                  </div>
                                ))}
                            </>
                          )}
                        </div>
                      </div>
                      {resultData &&
                        resultData.DoctorsServicesDataResponse &&
                        resultData.DoctorsServicesDataResponse.data?.data?.length > 0 && (
                          <FooterPaginations
                            pageName={pageName || 'Doctor'}
                            skip={resultData.DoctorsServicesDataResponse?.data?.skip}
                            skipArray={this.skipArray}
                            currentPage={currentPage}
                            onPageChange={this.onPageChange}
                            data={resultData.DoctorsServicesCountResponse.data}
                          />
                        )}
                    </>
                  ) : (
                    !loading &&
                    resultData.DoctorsServicesDataResponse.data && (
                      <MapProvider
                        isZipSearch={isZipSearch}
                        isUserPreEffective={data.userInfoData?.responsedata?.data?.isPreEffective}
                        onBookAppointment={this.getVimMemberToken}>
                        <MapView
                          doctors={doctorsWithOutsideSearch}
                          onFilterChange={this.handleOnFilterChange}
                          onSelectCounty={this.setCounty}
                          onSearchFomMap={this.callFilterSearch}
                          fetchProviderProfile={fetchProviderProfile}
                          allData={data}
                        />
                      </MapProvider>
                    )
                  )}
                </>
              )}
            </div>
            {!resultData.DoctorsServicesDataResponse.data &&
              !(
                Auth.getHealthPlan() === 'Canopy Health - UnitedHealthcare Doctors Plan' || userData?.product === 'EPO'
              ) && (
                <div className='row mb-4'>
                  <div className='col-12 highlight-message text-start'>
                    Select one or more filter options then click the search button. <br />
                    There are more options in the advanced filters that can help you find the perfect provider.
                  </div>
                </div>
              )}
            {pageName !== 'changePCP' && <DoctorAndServiceFooterInfo location='providers' data={data} page='' />}
            {showErrorModal && (
              <ConfirmationModal
                title={errorTitle}
                message={errorMessage}
                showModal={showErrorModal}
                closeModal={() => this.setState({ showErrorModal: false })}
              />
            )}
            {showVimTokenErrorModal && (
              <ConfirmationModal
                showModal={showVimTokenErrorModal}
                title={errorTitle}
                message={errorMessage}
                closeModal={() => this.setState({ showVimTokenErrorModal: false })}
              />
            )}
            {showBookingErrorModal && (
              <ConfirmationModal
                showModal={showBookingErrorModal}
                title={FEATUER_AVAILABLE_FOR_LOGGEDIN_USER_MSG_TITLE}
                message='This feature is only available for logged in users which is not supported in the application now.'
                closeModal={() => this.setState({ showBookingErrorModal: false })}
              />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetHealthPlanDataRequest: actions.createGetHealthPlanDataRequest,
  createGetFilterDataRequest: actions.createGetFilterDataRequest,
  createGetFilterDataAuthRequest: actions.createGetFilterDataAuthRequest,
  createGetDoctorsServiceRequest: actions.createGetDoctorsServiceRequest,
  createGetDoctorsServiceCountRequest: actions.createGetDoctorsServiceCountRequest,
  createGetVimMemberTokenRequest: actions.createGetVimTokenRequest,
  createGetUserData: actions.createGetUserInfoRequest,
  getProviderPDFRequest: actions.getProviderPDFRequest,
  fetchProviderProfile: actions.fetchProviderProfile,
};

export default connect<stateProps, DispatchProps, doctorsProps>(
  mapStateToProps,
  mapDispatchToProps
)(withAITracking(reactPlugin, Doctors));
