// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-img {
  border: 1px solid #b3b3b3;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/doctorsAndServices/doctors/AddressMap/AddressMap.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;AACF","sourcesContent":[".map-img {\n  border: 1px solid #b3b3b3;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
