import * as actions from '@canopy/shared';
/* eslint-disable react/jsx-props-no-spreading */
import { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';

import Auth from '../../shared/utils/Auth';
// import { history } from '../../shared/helperMethods/HelperMethod';
import { CANCEL, LOGOUT_LABEL, MENU_LABEL } from '../../shared/constant/AppConstants';
import { LOGIN_PAGE_URL } from '../../shared/constant/Urls';
import { history } from '../../shared/helperMethods/HelperMethod';
import Images from '../../shared/utils/Images';
import { hideLoggingOutLoader, showLoggingOutLoader } from '../../shared/utils/ReusableComponents';
import { pascalCase } from '../../shared/utils/string';
import NavMenuLists from './NavMenuLists';

interface ownState {
  isLogoutRequest: boolean;
}
interface ownProps {}
interface stateProps {
  data: any;
}
interface DispatchProps {
  createGetMainUnAuthConfigDataRequest: typeof actions.createGetMainUnAuthConfigDataRequest;
  createLogoutRequest: typeof actions.createLogoutRequest;
}

type ComponentProps = ownProps & stateProps & DispatchProps;

class NavMenuMobile extends PureComponent<ComponentProps, ownState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      isLogoutRequest: false,
    };
  }

  logout = (): any => {
    this.setState({ isLogoutRequest: true });
  };

  logoutUser = (): any => {
    showLoggingOutLoader();
    const { createGetMainUnAuthConfigDataRequest, createLogoutRequest } = this.props;
    createLogoutRequest({ token: Auth.getRefreshToken(), oktaUserId: Auth.getOktaUserId() }, () => {
      history.push(LOGIN_PAGE_URL);
      hideLoggingOutLoader();
      Auth.logout(() => {
        createGetMainUnAuthConfigDataRequest(() => console.log('user logged out successfully'));
        // window.location.href = LOGIN_PAGE_URL;
      });
    });
  };

  render(): ReactNode {
    const { data } = this.props;
    const { isLogoutRequest } = this.state;
    return (
      <div id='mobile-menu-container'>
        <div id='mobile-menu' className={Auth.isAuthenticated() ? 'is-logged-in' : 'not-logged-in'}>
          <div>
            <div className='mobile-header'>
              <div className={Auth.isAuthenticated() ? '' : 'd-none'}>
                {!isLogoutRequest ? (
                  <div className='regular-mobile-header'>
                    <div className='row align-items-center'>
                      <div className='menu-header mb-0 col-6 col-sm'>{MENU_LABEL}</div>
                      <div className='col d-none d-sm-none'>
                        <div className='user-img img-logged-in'>
                          <div className='image-cropper'>
                            <img
                              src={
                                data?.AccountInfo?.getPhotoResponse?.data?.data?.bodyInfo
                                  ? `data:image/png;base64,${data?.AccountInfo?.getPhotoResponse?.data?.data?.bodyInfo}`
                                  : ''
                              }
                              className='rounded-circle'
                              alt='profilepicture'
                            />
                          </div>
                        </div>
                        <p className='mobile-name'>
                          {pascalCase(data?.userInfoData?.responsedata?.data?.empi?.firstName)}
                          <br />
                          {pascalCase(data?.userInfoData?.responsedata?.data?.empi?.lastName)}
                        </p>
                      </div>
                      <div className='menu-close-button col-6'>
                        <button
                          className='navbar-toggler p-0'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#navbarToggleExternalContent'
                          aria-controls='navbarToggleExternalContent'
                          aria-expanded='false'
                          aria-label='Toggle navigation'>
                          <img
                            alt='menueClose'
                            data-bs-toggle='collapse'
                            data-bs-target='#navbarToggleExternalContent'
                            aria-controls='navbarToggleExternalContent'
                            // aria-expanded='false'
                            aria-label='Toggle navigation'
                            src={Images.icMenuClose}
                            srcSet={`${Images.icMenuClose2x} 2x, ${Images.icMenuClose3x} 3x`}
                            className='ic-menu-close'
                          />
                        </button>
                      </div>
                    </div>
                    <div className='row justify-content-end justify-content-sm-between align-items-center'>
                      <div className='col d-sm-block'>
                        <div className='user-img img-logged-in'>
                          <div className='image-cropper '>
                            <img
                              src={
                                data?.AccountInfo?.getPhotoResponse?.data?.data?.bodyInfo
                                  ? `data:image/png;base64,${data?.AccountInfo?.getPhotoResponse?.data?.data?.bodyInfo}`
                                  : ''
                              }
                              className='rounded-circle'
                              alt='profilePicture'
                            />
                          </div>
                        </div>
                        <p className='mobile-name mb-0'>
                          {pascalCase(data?.userInfoData?.responsedata?.data?.empi?.firstName)}
                          <br />
                          {pascalCase(data?.userInfoData?.responsedata?.data?.empi?.lastName)}
                        </p>
                      </div>
                      <div className='close-and-logout-not col-auto'>
                        <button className='btn btn-mobile-logout' type='button' onClick={() => this.logout()}>
                          {LOGOUT_LABEL}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='row no-margin-lr user-section'>
                    <div className='mobile-double-check'>
                      <div className='flex-container'>
                        <div className='menu-header mb-0'>{MENU_LABEL}</div>
                        <button
                          className='navbar-toggler p-0'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#navbarToggleExternalContent'
                          aria-controls='navbarToggleExternalContent'
                          aria-expanded='false'
                          aria-label='Toggle navigation'>
                          <img
                            data-bs-toggle='collapse'
                            data-bs-target='#navbarToggleExternalContent'
                            aria-controls='navbarToggleExternalContent'
                            // aria-expanded='false'
                            aria-label='Toggle navigation'
                            src={Images.icMenuClose}
                            srcSet={`${Images.icMenuClose2x} 2x, ${Images.icMenuClose3x} 3x`}
                            className='ic-menu-close'
                          />
                        </button>
                      </div>
                      <p className='double-check-mobile-message'>gt</p>
                      <div className='mobile-button-double'>
                        <button
                          className='btn btn-mobile-logout'
                          type='button'
                          onClick={() => this.setState({ isLogoutRequest: false })}>
                          {CANCEL}
                        </button>
                        <button className='btn btn-mobile-logout' type='button' onClick={() => this.logoutUser()}>
                          {LOGOUT_LABEL}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={Auth.isAuthenticated() ? 'd-none' : 'special-mobile-header '}>
                <div className='gradient-head' />
                <div className='menu-header mb-0'>{MENU_LABEL}</div>

                <div className='mobile-header-logo-container'>
                  <img
                    alt='imgLogo'
                    className='header-icon mobile-menu-img'
                    src={Images.imgLogoCanopyBlue}
                    srcSet={`${Images.imgLogoCanopyBlue}, ${Images.imgLogoCanopyBlue2x} 2x, ${Images.imgLogoCanopyBlue3x} 3x`}
                  />
                </div>
                <div className='mobile-header-close-btn-container'>
                  <button
                    className='navbar-toggler p-0'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarToggleExternalContent'
                    aria-controls='navbarToggleExternalContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'>
                    <img
                      data-bs-toggle='collapse'
                      data-bs-target='#navbarToggleExternalContent'
                      aria-controls='navbarToggleExternalContent'
                      // aria-expanded='false'
                      aria-label='Toggle navigation'
                      src={Images.icMenuClose}
                      srcSet={`${Images.icMenuClose2x} 2x, ${Images.icMenuClose3x} 3x`}
                      className='ic-menu-close hamburger-icon'
                    />
                  </button>
                </div>
              </div>
            </div>
            <ul className='mobile-app-menu-items non-logged-in-menu-items'>
              <NavMenuLists type='responsive' />
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetMainUnAuthConfigDataRequest: actions.createGetMainUnAuthConfigDataRequest,
  createLogoutRequest: actions.createLogoutRequest,
};

export default connect<stateProps, DispatchProps, ownProps>(mapStateToProps, mapDispatchToProps)(NavMenuMobile);
