// Create Account messages
export const ERROR_MESSAGE_EMAIL_VALIDATION = 'Please enter email address in correct format.';
export const ERROR_MESSAGE_EMAIL_DUPLICATE_VALIDATION =
  'The email address duplicateEmail is already in use. Please try again with a different email address.';
export const USERNAME_VALIDATION_MSG = 'Please enter username to match requirements.';
export const USERNAME_DUPLICATE_VALIDATION =
  'The username duplicateUsername is not available. Please try again with a different username.';
export const NEWPASSWORD_VALIDATION = 'Please enter password to match requirements.';
export const SAME_PASSWORD_VALIDATION = 'New password can not be same as current password';
export const CONFIRM_PASSWORD_VALIDATION = 'Password does not match.';

export const lookupAccountError = (contact: string): string => {
  return `
  There was an error validating the account. please try again or contact the Canopy Health Contact Center :${contact}`;
};
// Create Account messages

export const CREATE_ACCOUNT_LOADING_MESSAGE = 'Checking Your Information...';
export const LOOKUP_ACCOUNT_LOADING_MSG = 'Please wait while we check your data...';
export const DuplicateEmailError = (email: string): string => {
  return `*The email address ${email} is not available. Please try again with a different email address.`;
};
export const OOPError = (number: string): string => {
  return `Error: We apologize for this inconvenience. Your current out of pocket information is temporally unavailable at this time. If you need your current information please call the Canopy Health Contact Center at ${number}.`;
};
export const DuplicateEmailUsernameError = (email: string, username: string): any => {
  return (
    <div>
      `The email address <strong>{email}</strong> and username <strong>{username}</strong> are already in use. Please
      try again with a different email address and username`;
    </div>
  );
};
export const TermsAndConditionWarning = (): any => {
  return (
    <div>
      Not accepting these Terms and Conditions will log you out of the application,
      <br />
      You can accept Terms and Conditions at a later date to use the portal.
    </div>
  );
};

export const DuplicateUsernameError = (userName: string): string => {
  return `*The Username "${userName}" is not available. Please try again with a different username.`;
};

export const EmailSentMessage = (email: string): string => {
  return `Email has been sent to ${email} with instructions on resetting your password.`;
};

// login

export const ERRORS_MSG = (message: string): string => {
  return message;
};
export const USERNAME_VALIDATION_EMTY_MSG = 'Please enter a username.';

export const PASSWORD_VALIDATION_EMTY_MSG = 'Please enter a password.';
export const MOBILE_NUMBER_VALIDATION_EMTY_MSG = 'Please enter a mobile number.';
export const MOBILE_NUMBER_VALIDATION_MSG = 'Please enter a valid mobile number.';
export const OTP_VALIDATION_EMPTY_MESSAGE = 'This field cannot be left blank';
export const OTP_VALIDATION_MESSAGE = 'Please enter a valid OTP.';
export const PSWD_LENGTH_MESSAGE = 'Password should not be less than 8 characters.';
export const ALREADY_MEBMER_CREATE_ACCOUNT_MSG = " Are you already a member but haven't created an account yet?";
export const TOKEN_VALIDATION_ERROR_MSG =
  'You have accessed an account recovery link that has expired or been previously used.';
export const MFA_VERIFICATION_FAILED_MESSAGE = 'MFA verify challenge failed!';
export const MFA_VERIFICATION_FAILED_SYSTEM_MESSAGE = "Your token doesn't match our records. Please try again.";

// mfa
export const TWO_STEP_VERIFICATION_MESSAGE_1 =
  'Set up SMS or email as a method of two-step verification to protect your account.';

export const TWO_STEP_VERIFICATION_MESSAGE_2 =
  'A single-use code will be sent to your chosen method each time you login to MyCanopyHealth.';

export const TWO_STEPS_VERIFICATION_MOBILE_NUMBER_ACCEPT_MESSAGE =
  'Enter your valid mobile number to enable two-step verification via SMS for current & future MyCanopyHealth login';

export const SMS_VERIFICATION_INFO_1 =
  'You have selected SMS as your verification method. A single-use code will be sent to your phone each time you login to MyCanapyHealth.';
export const SMS_VERIFICATION_INFO_2 =
  'You can change your verification method anytime within MyCanopyHealth by going to your account settings section and selecting the reset MFA or call the Canopy Health contact center';

export const EMAIL_VERIFICATION_INFO_1 =
  'You have selected Email as your verification method. A single-use code will be sent to your emailid address each time you login to MyCanopyHealth.';

export const EMAIL_VERIFICATION_INFO_2 = '';

export const ONE_TIME_VERIFICATION_MESSAGE = (mobile: string): string => {
  return `A one time verification code has been sent to ${mobile}`;
};
export const ONE_TIME_VERIFICATION_EMAIL_MESSAGE = (email: string): string => {
  return `A one time verification code has been sent to your email address ${email}`;
};

export const ENTER_VERIFICATION_CODE = 'Enter the verification code below to complete the setup';

export const RESET_MFA_MESSAGE =
  "Once you click 'Continue', you'll be logged out and prompted to log back in and reset your multi-factor verification settings. Click 'Cancel' to stay logged in and keep your current multi-factor verification settings.";
// Recover Username

export const EMAIL_ERROR = 'Please enter email address in correct format.';
export const LOOKINGUP_MESSAGE = 'Looking up your account, please wait...';
export const UNKNOW_ERROR = 'There was an error with your request. Please try again later.';
export const RECOVERY_SUCCESS_MESSAGE = `Check your email for Username. It may take up to 15 minutes to arrive, and you may also need to check your spam folder. If still you haven't received the email, please contact Canopy Health Support.`;

// Reset Password
export const CHECK_MAIL_BODY_MESSAGE =
  ' Check your email for a message to reset your password. It may take up to 15 minutes to arrive, and you may also need to check your spam folder or unblock chsupport@canopyhealth.com. If still you haven&apos;t received the email, please contact Canopy Health support.';
export const PASSWORD_CHANGED_SUCCESS_MESSAGE = 'You can login using your existing username with new password';
// Unlock Account

export const UNLOCK_ERROR = 'An unknown error has occurred. Please try again later.';
export const UNLOCK_ACCOUNT_SUCCESS_MESSAGE =
  ' Unlock account link expires after 1 hour and if clicked after an hour, user will get the Link expired screen. Contact Customer Support : ';
export const SUCCESSFULLY_UNLOCKED_MESSAGE = 'You can login using your existing username and password';
// Error mesaages Intercepter;

export const ERROR_MSG = 'Something went wrong! Please contact admin!';
export const PLEASE_WAIT_MSG = 'Please wait...';
export const SAVING_PASSWORD_PLEASE_WAIT = 'Saving password change, please wait…';
export const BLANK_FIELD_ERROR_MSG = 'This field cannot be left blank.';
export const PLEASE_WAIT_INFO_CHECK_MSG = ' Please wait while we check your information...';
export const LOGOUT_CONRIMATION_MSG = ' Are you sure you want to log out from your member portal?';
export const LOADING_MESSAGE_WITH_THREE_DOTS = 'Loading...';
export const ENTER_VALID_CREDIT_CARD_NUMBER_MSG = 'Enter a valid credit card number.';
export const NO_VIDEO_VISIT_ACCOUNT_ACCESS_THROUGH_MENU_MSG =
  'No video visits account. Access Virtual Visits via the menu to get started.';
export const ENTER_VALID_EXPIRATION_DATE_MSG = 'Enter a valid expiration date.';
export const ENTER_VALID_EXPIRATION_DATE_GREATER_TODAY_MSG = `Enter an expiration date greater than today's date.`;
export const ENTER_VALID_ADDRESS_MSG = `Enter a valid address.`;
export const ENTER_VALID_CITY_MSG = `Enter a valid city.`;
export const ENTER_VALID_ZIP_CODE_MSG = `Enter a valid 5 digit zip code.`;

// cost estimator

export const ChargesToPayMsg = (changes: string, value: any): string => {
  return `For ${changes}, you will have to pay your copay of ${value}`;
};
export const ProfessionalChargesToPayMsg = (value: any): string => {
  return `For Professional Changes, you will have to pay your copay of $${value}`;
};
export const YOUR_TOTAL_OOPC_ESTIMATE_MSG = 'Your total out of pocket cost estimate';
export const UNABLE_TO_RETRIEVE_SERVICE_COST_ESTIMATE_MSG =
  ' Unable to retrieve Service Cost Estimate. Please try again.';
export const FEATUER_AVAILABLE_FOR_LOGGEDIN_USER_MSG_TITLE = ' This feature is only available for logged in users';
export const FEATURE_AVAILABLE_FOR_LOGGEDIN_USER_PLEASE_LOGIN_MSG =
  ' To schedule an appointment, first log in or create an account.';

export const PAGINATION_DISCLAIMER_MSG =
  'Disclaimer: You are searching All Health Plans, not all of these providers are in your health plan.';

export const MEMBER_ID_INFO = 'The Member ID is located on the front left side of your ID card.';
export const MEMBER_ID_DO_NOT_HAVE_INFO =
  'If you do not have an ID card please contact the Canopy Health Contact Center.';

// Notification
export const CONFIRMATION_DELETE_MSG = 'Are you sure you want to delete this notification?';
export const CONFIRMATION_DELETE_ALL_NOTIFICATION_MSG = 'Are you sure you want to delete all notification?';
export const ACTION_CANNOT_BE_UNDONE_MSG = 'This action cannot be undone.';

export const COST_ESTIMATOR_DISCLAIMER_SECTION_INFO = ` Cost Estimator is intended to help you estimate the costs of healthcare
services; your actual costs may vary. Information from this tool should not
inform your medical decisions. Our goal is to provide our Members with an
accurate estimate of services along with reliable out of pocket expenses. The
Cost Estimator offers a high-level estimate of costs. We are continually
improving our data acquisition processes to allow a closer, more accurate
estimate.`;

export const BEFOR_ESTIMATE_MODAL_DATA = (): any => {
  return (
    <p>
      <div>
        These values represent your current calculated out-of-pocket and deductible (if applicable) spend for the
        effective plan year. We receive these remaining and total values directly from your health insurance provider
        and may not be completely current/accurate. Medical bills take time to process and costs related to your most
        recent medical services may not be taken into account.
        <br />
        <br />
        In some cases, your health insurance provider may not aggregate these values at all. You will see &quot;no
        info&quot; instead of actual values when this occurs.
        <br />
        <br />
        Definitions <br />
        Deductible: The &quot;deductible&quot; is the amount you pay for covered health care services before your
        insurance plan starts to pay for services. For example, if your plan has a $5,000 deductible, you pay the first
        $5,000 of covered services yourself. After you pay your deductible, you may still be responsible for copayment
        or coinsurance for covered services.
        <br />
        <br />
        Out-of-Pocket Max: The out-of-pocket maximum/limit is generally the most you have to pay for covered services
        within a plan year.
      </div>
    </p>
  );
};
export const CONTRACT_RATE_MODAL_DATA = (): any => {
  return (
    <p>
      &quot;Contracted&quot; rate is approximately how much the physician(s) and medical facility get paid for the
      service. We have negotiated this rate on your behalf with our network physician(s) who carry out these services
      and the facilities where they are performed. You pay your out-of-pocket portion based on your specific plan
      benefits and the rest is paid by your insurance.
    </p>
  );
};
export const PLACE_SERVICE_MODAL_DATA = (contact: any): any => {
  return (
    <p>
      <b>&quot;Place of Service&quot;</b> describes where a procedure is done. We display costs based on a common
      location for the procedure you choose. Costs vary by location; some procedures can be done in several locations,
      such as:
      <ul className='mt-2'>
        <li>
          <b>Ambulatory Surgery Center or &quot;ASC&quot;</b>: not part of a hospital, offering procedures and surgeries
          where you don’t stay overnight. These are often the lowest cost location.
        </li>
        <li>
          <b>Hospital Outpatient</b>: part of a hospital, offering procedures and surgeries where you don&quot;t stay
          overnight, often recommended when overnight hospital stay might become necessary
        </li>
        <li>
          <b>Inpatient or Hospital</b>: offering procedures and surgeries where you stay overnight. Used for complex
          cases. Usually the most expensive location.
        </li>
        <li>
          <b>Office</b>: for minor procedures such as x-rays, office visits, lab tests, simple biopsies.
        </li>
      </ul>
      Talk with your doctor about the best place of service for your procedure. The MyCanopy app and portal has a
      section &quot;Providers &amp; Services&quot; showing facilities contracted with your Health Plan. To learn more,
      call Canopy Health’s Contact Center at{' '}
      <a href={`tel:${contact?.length > 0 && contact?.phoneNumber}`}>{contact?.length > 0 && contact?.phoneNumber}</a>.
    </p>
  );
};

// My Health Plan

export const REVIEW_BASIC_PLAN_INFO_TEXT_MSG =
  'Review basic plan information below. For more detailed plan information,';

export const NOTE_INFORMATION_MSG = ' Note: Benefits Highlights contains summary information only.';
export const FOR_MORE_INFO_INFORMATION_MSG = 'for more information.';
export const VARIETY_INFORMATION_MSG = ' A variety of professional services with associated fees are available.';

// Dashboard
export const WELCOME_USER_NEW_MEMBER_MSG = `  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
  been the industry's standard dummy text ever since the 1500s, when an unknown printer
  took a galley of type and scrambled it to make a type specimen book. It has survived not
  only five centuries, but also the leap into electronic typesetting, remaining essentially
  unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
  Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
  PageMaker including versions of Lorem Ipsum.`;

export const WENCOME_TXT = 'Welcome';

export const CANNOT_COMPLETE_REQUEST_CONTACT_CANOPY_MSG = (
  contactNumber: any
): any => `Your request cannot be completed via the portal. Please call CanopyCare at
${contactNumber}. A representative will help you update your contact information and
complete your request for a new ID card.`;

export const CHANGE_PCP_REQUEST_SUBMITTED_SUCCESS_MSG = (
  contactNumber = ''
): any => `We typically process PCP change requests within 30 days. Please call ${contactNumber} in
case you need further assistance.`;

export const BACK_TO_HOME_LBL = ' Back to Home';

export const NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG =
  'You are navigating to a page outside of MyCanopy Health. Do you want to continue ?';

export const ORDER_ID_SUCCESS_MSG = (number: any): any => {
  return {
    title: ' Your ID Card request has been submitted',
    message: ` Your request has been submitted. Please wait 5 days for your new ID card to
arrive before you request another replacement. If you have any questions please
contact the CanopyCare contact center at ${number}.`,
  };
};

export const POPUP_MESSAGE = (statusCode: any, errorMessage: any): any => {
  let title = '';
  let message = '';
  if (errorMessage?.indexOf(';') > -1) {
    if (errorMessage?.split(';')[0] !== '') {
      title = `- ${errorMessage?.split(';')[0]}`;
    }
    message = errorMessage?.split(';')[1];
  } else {
    message = errorMessage;
  }
  return {
    title: `Error Code: ${statusCode} ${title}`,
    message,
  };
};

export const ORDER_ID_ERROR_TITLE = 'Request ID Card error';
export const ORDER_ID_ERROR_MSG = (
  phoneNumber: string
): string => `There was an error ordering your ID card. Please contact the CanopyCare Contact
    Center ${phoneNumber}.`;

export const PRE_EFFECTIVE_MEMBER_WARNING = ' Your plan is not yet effective - some feature may not be available yet.';

export const SESSION_EXPIRED_TITLE = 'Session Expired';
export const SESSON_EXPIRED_MESSAGE = 'Your virtual visit session has expired. Please try again.';

export const ERROR_TITLE = 'Error';
export const ERROR_MESSAGE = 'Something went wrong please try again or contact administrator.';

export const VV_NOT_AUTH_ERR_MSG =
  'You are not authorized to access Virtual Visits resources. Please contact the Canopy Health Contact Center 1-888-926-4921.';

export const VV_GEN_ERR_MSG =
  'There was an error logging into video visits. Please try again later or contact the Canopy Health Contact Center 1-888-926-4921.';

export const HIPPA_TEXT =
  '<p>Your visit summary includes notes, prescriptions, and any diagnoses your provider entered during your conversation. Once your provider signs the summary (typically within the hour), a copy will be shared with the recipient (s) specified.</p>';

export const DEFAULT_CALL_CENTER = '1-888-926-4921';
