/* eslint-disable no-unused-vars */
/* eslint no-underscore-dangle: 0 */
import * as actions from '@canopy/shared';
import { marked } from 'marked';
import React, { PureComponent } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import {
  ACCEPTING_NEW_PATIENT,
  BOARD_CERTIFIED_LABEL,
  CA_LICENSE_LABEL,
  CHANGE_PCP_ENUM,
  CLINIC_NAME,
  COUNTY,
  HEALTH_PLAN,
  HIDE_DETAILS,
  HOSPITALIST_LABEL,
  HOSPITALS_TEXT,
  HOSPITAL_NPI_LABEL,
  INTERNAL_SERVER_ERROR_TEXT,
  LANGUAGES,
  MEDIA_GROUP_IPA_LABLE,
  NOT_ACCEPTING_NEW_PATIENT,
  NO_LABEL,
  NPI_LABLE,
  OFFICE_HOURS,
  REPORT_INACCURACIES_LABLE,
  SELECT_AS_PCP_LABLE,
  SHARE_ERROR_TEXT,
  SOME_ERROR_TEXT,
  TELEHEALTH_LABEL,
  VIEW_DETAILS,
  YES_LABEL,
} from '../../../shared/constant/AppConstants';
import { POPUP_MESSAGE } from '../../../shared/constant/AppMessages';
import { DOCTOR_REPORT_INACCURACIES } from '../../../shared/constant/Urls';
import {
  convertToLowerCase,
  createPDFData,
  formatPhone,
  formattedSchedule,
  formattedScheduleTime,
  getMiles,
  getProviderName,
  preparePDFBlob,
} from '../../../shared/helperMethods/HelperMethod';
import ConfirmationModal from '../../../shared/sharedComponents/confirmationModal/ComfirmationModal';
import Analytics, { FA } from '../../../shared/utils/Analytics';
import Auth from '../../../shared/utils/Auth';
import Images from '../../../shared/utils/Images';
import { getFormattedBio, hideLoader, showLoader } from '../../../shared/utils/ReusableComponents';
import AddressMap from './AddressMap';
import ConditionalUI from './ConditionalUI';
import OtherLocation from './OtherLocation';
import PrintComponent from './Print/PrintComponent';
import ProfilePicture from './components/ProfilePicture';

interface ownProps {
  provider: any;
  pageName: any;
  selectAsPCP: any;
  allData: any;
  isZipSearch: boolean;
  isMatchZipCode: boolean;
  hasOtherLocations: boolean;
  onBookAppointment: (data: any) => void;
}

interface ownState {
  showVimTokenErrorModal: boolean;
  showErrorModal: boolean;
  errorTitle: string;
  errorMessage: string;
  providerDetails: any;
  showEnableShareModal: boolean;
  pdfData?: any;
  pdfDetails?: any;
  pictureSrc: string;
}
interface stateProps {}

interface DispatchProps {
  fetchProviderProfile: typeof actions.fetchProviderProfile;
}
type componentProps = ownProps & DispatchProps & stateProps;

class DoctorsSearchResults extends PureComponent<componentProps, ownState> {
  printRef: any;

  constructor(props: componentProps) {
    super(props);
    this.state = {
      showVimTokenErrorModal: false,
      showErrorModal: false,
      errorTitle: '',
      errorMessage: '',
      providerDetails: null,
      showEnableShareModal: false,
      pictureSrc: '',
    };
    this.printRef = React.createRef();
  }

  componentDidMount(): void {
    const shareButtons = document.querySelectorAll('.share-button') as NodeListOf<HTMLButtonElement>;
    shareButtons.forEach((shareButton) => {
      if (shareButton) {
        shareButton.addEventListener('click', async (e: any) => {
          showLoader();
          const file = await this.fetchData(e.target.name);
          try {
            if (navigator.share) {
              await navigator
                .share({ title: 'Share Provider PDF', files: [file] })
                .then(() => {
                  console.log('File shared successfully!');
                })
                .catch((error) => {
                  hideLoader();
                  this.setState({ showEnableShareModal: true });
                });
              hideLoader();
            } else {
              hideLoader();
              this.setState({ showEnableShareModal: true });
            }
          } catch (error) {
            hideLoader();
          }
        });
      }
    });

    this.getProviderDetails();
  }

  fetchData = (id: any): any => {
    return new Promise((resolve, reject) => {
      showLoader();
      const { fetchProviderProfile, provider } = this.props;
      const pdfObj = createPDFData(provider, id);
      if (pdfObj.primaryLocation[0].clinicName) {
        try {
          fetchProviderProfile(
            { query: { npi: provider?.result[0].npi }, isAuthenticated: Auth.isAuthenticated() },
            (response: any) => {
              if (response.isSuccess) {
                this.setState(
                  {
                    providerDetails: response?.data[0],
                  },
                  () => {
                    const img = document.getElementById(`${id}_profilepic`) as HTMLImageElement;
                    this.setState(
                      { pdfData: pdfObj, pdfDetails: response?.data[0], pictureSrc: img?.currentSrc },
                      async () => {
                        const element = this.printRef.current;
                        const blob = await preparePDFBlob(element, pdfObj?.name);
                        const fileName = `${getProviderName(pdfObj?.name)}.pdf`;
                        const fileType = 'application/pdf';
                        const file = new File([blob], fileName, { type: fileType });
                        resolve(file);
                        hideLoader();
                      }
                    );
                  }
                );
              } else {
                reject(new Error('something bad happened'));
                hideLoader();
                this.setState({
                  showVimTokenErrorModal: true,
                  errorTitle: POPUP_MESSAGE(response.statusCode, response.message).title,
                  errorMessage: POPUP_MESSAGE(response.statusCode, response.message).message,
                });
              }
            }
          );
        } catch (error) {
          hideLoader();
        }
      }
    });
  };

  handleDownloadPdf = async (name: string): Promise<any> => {
    const iframe = document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    document.body.appendChild(iframe);
    const element = this.printRef.current;

    if (iframe) {
      const blob = await preparePDFBlob(element, name);
      const blobUrl = URL.createObjectURL(blob);
      iframe.src = blobUrl;
      iframe.onload = () => {
        hideLoader();
        setTimeout(() => {
          iframe.focus();
          if (iframe.contentWindow) {
            iframe.contentWindow.print();
          }
        }, 1);
      };
    }
  };

  printProviderInfo = (id: any, details: any): any => {
    showLoader();
    const { provider } = this.props;
    const pdfObj = createPDFData(provider, id);
    const img = document.getElementById(`${id}_profilepic`) as HTMLImageElement;
    this.setState({ pdfData: pdfObj, pdfDetails: details, pictureSrc: img.currentSrc }, () => {
      showLoader();
      this.handleDownloadPdf(pdfObj?.name);
    });
  };

  shareOtherLocation = async (id: any): Promise<any> => {
    if (id) {
      showLoader();
      const file = await this.fetchData(id);
      try {
        if (navigator.share) {
          const shareData = {
            files: [file],
            title: 'Share Provider PDF',
          };
          await navigator
            .share(shareData)
            .then(() => {
              console.log('File shared successfully!');
            })
            .catch((error) => {
              hideLoader();
              this.setState({ showEnableShareModal: true });
            });
          // await navigator.share({ files: [file] });
          hideLoader();
        } else {
          hideLoader();
          this.setState({ showEnableShareModal: true });
        }
      } catch (error) {
        hideLoader();
        // console.error('Error sharing:', error);
        // Handle the error, if needed
      }
    }
  };

  getProviderDetails = (): any => {
    const { fetchProviderProfile, provider } = this.props;
    fetchProviderProfile(
      { query: { npi: provider?.result[0].npi }, isAuthenticated: Auth.isAuthenticated() },
      (response: any) => {
        if (response.isSuccess) {
          this.setState({
            providerDetails: response?.data[0],
          });
        } else {
          // this.setState({ providerDetails: response?.data });
        }
      }
    );
  };

  getImageByText = (text: string): any => {
    switch (text) {
      case 'Hill Physicians Medical Group':
        return Images.HILL;
      case 'SCCIPA':
        return Images.SCCIPA;
      case 'sccipa':
        return Images.SCCIPA;
      case 'Santa Clara County IPA':
        return Images.SCCIPA;
      case 'Meritage Medical Network':
        return Images.MERITAGE;
      case 'John Muir Health':
        return Images.JOHNMUIR;
      case 'John Muir Physician Network':
        return Images.JOHNMUIR;
      case 'Dignity Health Medical Network - Santa Cruz':
        return Images.DIGNITY;
      case 'Providence Medical Network':
        return Images.PROVIDENCE;
      default:
        return null;
    }
  };

  InputField = (data: any): any => {
    if (data?.medicalGroup === 'SCCIPA') {
      return (
        <div className='medical-group mt-2 d-flex align-items-center'>
          <img src={this.getImageByText(data?.medicalGroup)} alt='' />
          <span>{data?.medicalGroup}</span>
        </div>
      );
    }
    if (data?.medicalGroup === 'Hill Physicians Medical Group') {
      return (
        <div style={{ marginTop: '7px' }} className='medical-group d-flex align-items-xl-center align-items-md-start'>
          <img style={{ marginLeft: '0px' }} src={this.getImageByText(data?.medicalGroup)} alt='' />
          <span>{data?.medicalGroup}</span>
        </div>
      );
    }
    if (data?.medicalGroup === 'Dignity Health Medical Network - Santa Cruz') {
      return (
        <div style={{ marginTop: '7px' }} className='medical-group d-flex align-items-xl-start align-items-md-start'>
          <img
            style={{ marginLeft: '-2px', paddingRight: '7px' }}
            src={this.getImageByText(data?.medicalGroup)}
            alt=''
          />
          <span>{data?.medicalGroup}</span>
        </div>
      );
    }
    if (data?.medicalGroup === 'John Muir Physician Network') {
      return (
        <div style={{ marginTop: '7px' }} className='medical-group d-flex align-items-xl-center align-items-md-start'>
          <img
            style={{ marginLeft: '2px', paddingRight: '9px' }}
            src={this.getImageByText(data?.medicalGroup)}
            alt=''
          />
          <span>{data?.medicalGroup}</span>
        </div>
      );
    }
    if (data?.medicalGroup === 'John Muir Health' || data?.medicalGroup === 'John Muir Physician Network') {
      return (
        <div style={{ marginTop: '4px' }} className='medical-group align-items-center d-flex'>
          <img src={this.getImageByText(data?.medicalGroup)} alt='' />
          <span>{data?.medicalGroup}</span>
        </div>
      );
    }

    return (
      <div className='medical-group mt-2'>
        <img src={this.getImageByText(data?.medicalGroup)} alt='' />
        <span>{data?.medicalGroup}</span>
      </div>
    );
  };

  render(): React.ReactElement {
    const {
      provider,
      pageName,
      selectAsPCP,
      allData,
      isZipSearch,
      isMatchZipCode,
      hasOtherLocations,
      onBookAppointment,
    } = this.props;
    const data = provider?.result[0];
    const userData = allData?.userInfoData?.responsedata?.data;
    const {
      showVimTokenErrorModal,
      showErrorModal,
      errorMessage,
      errorTitle,
      providerDetails,
      showEnableShareModal,
      pdfData,
      pdfDetails,
      pictureSrc,
    } = this.state;
    const scheduleObject = formattedSchedule(data?.hoursOfOperation);
    const bio = providerDetails?.bio ? marked.parse(providerDetails?.bio) : null;
    const video = providerDetails?.video;

    return (
      <>
        {pdfData && (
          <PrintComponent
            reference={this.printRef}
            providerInfo={pdfData}
            providerDetails={pdfDetails}
            imageSrc={pictureSrc}
          />
        )}
        <div className='blue-halo'>
          <div className='container-fluid container-dt-doctor' key={data?.id}>
            <div className='col-lg-12 col-md-12 col-sm-12 no-padding grid-content'>
              <div className='row'>
                <div className='col-12'>
                  <div className='first-name'>
                    <h1 className=''>
                      {data?.firstName?.toLowerCase()} {data?.middleName?.toLowerCase()} {data?.lastName?.toLowerCase()}
                      , {data?.typeOfLicensure}
                    </h1>
                    {providerDetails?.pronoun && (
                      <span className='provider-pronouns d-block'>{providerDetails?.pronoun} </span>
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 col-xl-5 col-md-6 col-sm-12 ps-0'>
                  <div className='d-flex'>
                    <div style={{ width: '119px' }} className='provider-picture'>
                      <ProfilePicture provider={data} details={providerDetails} />
                    </div>
                    <div className='w-100'>
                      <div className='col-lg-12 col-md-12 col-sm-12 no-padding-mob margin-bottom-15-mob d-grid'>
                        {this.InputField(data)}
                        {data?.specialty?.length > 0 && (
                          <div className='speciality mt-2'>
                            <img src={Images.stethescope} alt='' />
                            <span>{data?.specialty && data?.specialty?.join(', ')}</span>
                          </div>
                        )}
                        {data?.acceptingNewPatients ? (
                          <div className='img-text col-auto px-0 mt-2 d-flex align-items-center'>
                            <img
                              className='alert-icon img-fluid image14'
                              src={Images.icCheck2x}
                              srcSet={`${Images.icCheck2x} 2x`}
                              alt='tick'
                            />
                            <span className='text-tick'>{ACCEPTING_NEW_PATIENT}</span>
                          </div>
                        ) : (
                          <div className='img-text col-auto px-0 mt-2 d-flex align-items-center'>
                            <img
                              className='alert-icon img-fluid image14'
                              src={Images.icListNocheck2x}
                              srcSet={`${Images.icListNocheck2x} 2x`}
                              alt='tick'
                            />
                            <span className='text-tick'>{NOT_ACCEPTING_NEW_PATIENT}</span>
                          </div>
                        )}
                        {providerDetails?.lgbtq && (
                          <div className='img-text col-auto px-0 mt-2 d-none d-sm-block'>
                            <img className='alert-icon img-fluid' src={Images.LGBTQ} alt='LGBTQ+' />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6 col-xl-7 col-md-6 col-sm-12 px-0'>
                  <div className='row' style={{ height: '100%' }}>
                    <div className='col-8 col-md-9 col-lg-9 px-0 col-add'>
                      <div className='row'>
                        <div className='col-md-12 col-lg-12 col-xl-7 px-sm-0-custom order-sm-2 order-md-2 order-2 order-lg-2 order-xl-1'>
                          <a
                            href={`https://www.google.com/maps/place/${data?.address?.street1}+${data?.address?.city}+${data?.address?.state}+${data?.address?.zip}`}
                            className='address-link'
                            target='_blank'
                            rel='noreferrer'>
                            <div className='address-block'>
                              <div className='medical-group mt-md-1 mt-lg-2 capitalized mb-1'>
                                <img style={{ paddingRight: '9px' }} src={Images.icPinList} alt='pin' />
                                <span className='d-block w-100'>{convertToLowerCase(data?.clinicName)}</span>
                              </div>
                              <span className='text-tick address-text capitalized'>
                                {convertToLowerCase(data?.address?.street1)}
                              </span>
                              <span className='text-tick address-text capitalized'>
                                {convertToLowerCase(data?.address?.street2)}
                              </span>
                              <span className='text-tick address-text'>
                                {data?.address?.city.replace(/(\w)(\w*)/g, (g0: any, g1: any, g2: any) => {
                                  return g1.toUpperCase() + g2.toLowerCase();
                                })}
                                , {data?.address?.state} {data?.address?.zip}
                              </span>
                              {!isMatchZipCode && (
                                <div className='text-tick address-text img-text-2'>
                                  <span className='miles'>
                                    {isZipSearch === true &&
                                      (data?.distance === undefined || data?.distance === null) &&
                                      'Distance Not Available'}
                                    {data?.distance === 0 ? '0 miles away' : data?.distance && getMiles(data?.distance)}
                                  </span>
                                </div>
                              )}
                            </div>
                          </a>
                        </div>

                        <div className='col-md-12 col-lg-12 col-xl-5 px-sm-0-custom order-sm-1 order-md-1 order-1 order-lg-1 order-xl-2'>
                          <div className='address-block'>
                            <div className='medical-group contact mt-2 d-flex align-itesm-center'>
                              <img style={{ marginTop: '2px', paddingRight: '9px' }} src={Images.phone} alt='' />
                              <span>
                                <a style={{ whiteSpace: 'nowrap' }} href={`tel:${data?.phone}`}>
                                  {formatPhone(data?.phone)}
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-print-share col-4 col-md-3 col-lg-3 pe-0 ps-0 d-flex align-items-end justify-content-end flex-column ${
                        providerDetails?.pronoun ? 'hasPronoun' : ''
                      }`}>
                      <div className='share-print'>
                        {pageName !== 'changePCP' && (
                          <button
                            type='button'
                            aria-label='.'
                            className='share-button'
                            title='Share'
                            id='share-button'
                            name={data?._id}
                          />
                        )}

                        <button
                          type='button'
                          aria-label='.'
                          className='print-button'
                          title='Print'
                          onClick={() => this.printProviderInfo(data?._id, providerDetails)}
                        />
                      </div>
                      <div className='button-section'>
                        <div
                          className={`row action-buttons
                    justify-content-end mt-2 mt-lg-0 align-items-center`}>
                          <a
                            className='hook collapsed  view-details-btn clickable px-0 '
                            data-bs-toggle='collapse'
                            href={`#collapseDetails${data?._id}`}
                            role='button'
                            aria-expanded='false'
                            onClick={() => {
                              const myCollapse = document.getElementById(`${data?._id}_span`);
                              if (myCollapse?.offsetParent == null) {
                                if (pageName === 'changePCP') {
                                  Analytics.logEvent(FA.CHANGE_PCP_SEARCH_PHYSICIAN_VIEW_DETAILS, {
                                    clinic_name: `${data?.clinicName}`,
                                  });
                                } else {
                                  Analytics.logEvent(FA.DOCTOR_AND_SERVICE_PROVIDER_VIEW_DETAILS, {
                                    provider_name: `${data?.firstName} ${data?.lastName}`,
                                  });
                                }
                              }
                            }}
                            aria-controls={`collapseDetails${data?._id}`}>
                            <span className='btn-toggle-details view-detail ' id={`${data?._id}_span`}>
                              {VIEW_DETAILS}
                            </span>
                            <span className='btn-toggle-details hide-detail'>{HIDE_DETAILS}</span>
                          </a>
                          {pageName === 'changePCP' && (
                            <button
                              onClick={() => {
                                selectAsPCP(CHANGE_PCP_ENUM.CONFIRMCHANGES, data, CHANGE_PCP_ENUM.SELECTPHYSICIAN);
                                Analytics.logEvent(FA.CHANGE_PCP_NEW_PCP_CHOSEN, {
                                  npi: data?.npi,
                                  address1: data?.address?.street1,
                                  address2: data?.address.street2,
                                  city: data?.address?.city,
                                  state: data?.address?.state,
                                  zip: data?.address?.zip,
                                });
                              }}
                              type='button'
                              className='btn-toggle-details schedule-vims clickable col-auto change-pcp px-2 mt-2'>
                              {SELECT_AS_PCP_LABLE}
                            </button>
                          )}
                        </div>

                        {/* {data && data?.bookingType !== '' && pageName !== 'changePCP' && !userData?.isPreEffective && (
                          <button
                            onClick={() => onBookAppointment(data)}
                            type='button'
                            className='btn btn-toggle-details schedule-vims clickable col-auto mt-2 px-2'>
                            {BOOK_APPOINTMENT_LABLE}
                          </button>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='collapse ' id={`collapseDetails${data?._id}`} style={{ borderTop: '1px solid #D0D0CE' }}>
            <div className='container-fluid'>
              {providerDetails?.video || bio ? (
                <div className='container-fluid detail-section-padding'>
                  <div className='row'>
                    <div className='col-12 col-md-6 col-lg-6 mt-3'>
                      <div className='col-header capitalized'>
                        {data?.firstName?.toLowerCase()} {data?.middleName?.toLowerCase()}{' '}
                        {data?.lastName?.toLowerCase()}
                        <span>, </span> {data?.typeOfLicensure}
                      </div>
                      {providerDetails?.video && (
                        <ReactPlayer controls url={video} width='100%' height='250px' style={{ marginTop: '1rem' }} />
                      )}
                      {bio && <div className='provider-bio mt-3'>{getFormattedBio(bio)}</div>}
                      {data?.hoursOfOperation && (
                        <div className='provider-office-hours mt-3 pe-2 ps-3 py-3'>
                          <div className='medical-group mt-0'>
                            <img src={Images.clock} alt='' />
                            <span className='col-header'>{OFFICE_HOURS}</span>
                          </div>
                          {Object.entries(scheduleObject).map(([day, time]) => {
                            return (
                              <div className='row mt-1'>
                                <div className='col-4 ps-4'>{day}</div>
                                <div className='col-8 text-right pe-4'>{formattedScheduleTime(time)}</div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className='col-12 col-md-6 col-lg-6 mt-3'>
                      <div className='col-header'>Additional Information</div>
                      <div className='additional-info mt-3 py-1 pt-2 ps-1'>
                        <div className='row'>
                          <div className='col-12 col-md-6 col-lg-6 py-2'>
                            <div className='row'>
                              <div className='col-1 px-0'>
                                <img src={Images.group} alt='' />
                              </div>
                              <div className='col-11 pe-0'>
                                <span className='label'>{MEDIA_GROUP_IPA_LABLE}</span>
                                <span className='data'>{data?.medicalGroup}</span>
                              </div>
                            </div>
                          </div>
                          {data?.nameOfNetwork && (
                            <div className='col-12 col-md-6 col-lg-6 py-2'>
                              <div className='row'>
                                <div className='col-1 px-0'>
                                  <img src={Images.heartPulse} alt='' />
                                </div>
                                <div className='col-11 pe-0'>
                                  <span className='label'>{HEALTH_PLAN}</span>
                                  {data?.nameOfNetwork?.map((i: any) => (
                                    <span className='data' key={i}>
                                      {i}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}

                          <div className='col-12 col-md-6 col-lg-6 py-2'>
                            <div className='row'>
                              <div className='col-1 px-0'>
                                <img style={{ marginTop: '1px' }} src={Images.clinickName} alt={data?.medicalGroup} />
                              </div>
                              <div className='col-11 pe-0'>
                                <span className='label'>{CLINIC_NAME}</span>
                                <span className='data capitalized'>{convertToLowerCase(data?.clinicName)}</span>
                              </div>
                            </div>
                          </div>

                          <div className='col-12 col-md-6 col-lg-6 py-2'>
                            <div className='row'>
                              <div className='col-1 px-0'>
                                <img src={Images.cerificate} alt='' />
                              </div>
                              <div className='col-11 pe-0'>
                                <span className='label'>{BOARD_CERTIFIED_LABEL}</span>
                                <span className='data'>{data?.boardCertifiedOrEligible === 'Y' ? 'Yes' : 'No'}</span>
                              </div>
                            </div>
                          </div>

                          {data?.npi && (
                            <div className='col-12 col-md-6 col-lg-6 py-2'>
                              <div className='row'>
                                <div className='col-1 px-0'>
                                  <img src={Images.idCard} alt='' />
                                </div>
                                <div className='col-11 pe-0'>
                                  <span className='label'>{NPI_LABLE}</span>
                                  <span className='data'>{data?.npi}</span>
                                </div>
                              </div>
                            </div>
                          )}
                          {data?.address?.county && (
                            <div className='col-12 col-md-6 col-lg-6 py-2'>
                              <div className='row'>
                                <div className='col-1 px-0'>
                                  <img src={Images.locationDot} alt='' />
                                </div>
                                <div className='col-11 pe-0'>
                                  <span className='label'>{COUNTY}</span>
                                  <span className='data capitalized'>{data?.address?.county.toLowerCase()}</span>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className='col-12 col-md-6 col-lg-6 py-2'>
                            <div className='row'>
                              <div className='col-1 px-0'>
                                <img src={Images.awardSimple} alt='' />
                              </div>
                              <div className='col-11 pe-0'>
                                <span className='label'>{CA_LICENSE_LABEL}</span>
                                <span className='data'>{data?.caLicense}</span>
                              </div>
                            </div>
                          </div>
                          {data?.providerLanguage && data?.providerLanguage?.length > 0 && (
                            <div className='col-12 col-md-6 col-lg-6 py-2'>
                              <div className='row'>
                                <div className='col-1 px-0'>
                                  <img src={Images.flag} alt='' />
                                </div>
                                <div className='col-11 pe-0'>
                                  <span className='label'>{LANGUAGES}</span>
                                  <span className='data capitalized'>
                                    {data?.providerLanguage?.join(', ').toLowerCase()}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className='col-12 col-md-6 col-lg-6 py-2'>
                            <div className='row'>
                              <div className='col-1 px-0'>
                                <img src={Images.telehealth} alt='' />
                              </div>
                              <div className='col-11 pe-0'>
                                <span className='label'>{TELEHEALTH_LABEL}</span>
                                <span className='data'>{data?.telehealth ? YES_LABEL : NO_LABEL}</span>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6 col-lg-6 py-2'>
                            <div className='row'>
                              <div className='col-1 px-0'>
                                <img src={Images.hospitalist} alt='' />
                              </div>
                              <div className='col-11 pe-0'>
                                <span className='label'>{HOSPITALIST_LABEL}</span>
                                <span className='data'>{NO_LABEL}</span>
                              </div>
                            </div>
                          </div>
                          {data?.hospitalNPI && (
                            <div className='col-12 col-md-6 col-lg-6 py-2'>
                              <div className='row'>
                                <div className='col-1 px-0'>
                                  <img src={Images.idCard} alt='' />
                                </div>
                                <div className='col-11 pe-0'>
                                  <span className='label'> {HOSPITAL_NPI_LABEL}</span>
                                  <span className='data'>{data?.hospitalNPI}</span>
                                </div>
                              </div>
                            </div>
                          )}

                          {data?.hospital && data?.hospital?.length > 0 && (
                            <div className='col-12 col-md-6 col-lg-6 py-2'>
                              <div className='row'>
                                <div className='col-1 px-0'>
                                  <img src={Images.hospitals} alt='' />
                                </div>
                                <div className='col-11 pe-0'>
                                  <span className='label'>{HOSPITALS_TEXT}</span>

                                  {data?.hospital.map((hospital: any) => (
                                    <span className='data' key={hospital}>
                                      {hospital}
                                      <br />{' '}
                                      {data?.hospitalPrivilegeStatus &&
                                        data?.hospitalPrivilegeStatus.map((hospitalPrivilegeStatus: any) => (
                                          <span key={hospitalPrivilegeStatus}>{hospitalPrivilegeStatus}</span>
                                        )) && <span>(Has Privileges, Referral Required)</span>}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {data?.address?.location && (
                        <div className='col-12 col-md-12 col-lg-12 mt-3'>
                          <AddressMap address={data.address} />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-12 px-4 mt-3 report-inaccuracy'>
                    <span
                      role='none'
                      onClick={() => window.open(`${DOCTOR_REPORT_INACCURACIES}/${data?._id}`, '_blank')}
                      onKeyDown={() => window.open(`${DOCTOR_REPORT_INACCURACIES}/${data?._id}`, '_blank')}>
                      <img
                        className='img-fluid'
                        src={Images.icDetailReport}
                        srcSet={`${Images.icDetailReport2x} 2x, ${Images.icDetailReport3x} 3x`}
                        alt='tick'
                      />
                      {REPORT_INACCURACIES_LABLE}
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  <ConditionalUI provider={data} data={data} />
                  <div className='col-12 px-4 mt-3 report-inaccuracy'>
                    <span
                      role='none'
                      onClick={() => window.open(`${DOCTOR_REPORT_INACCURACIES}/${data?._id}`, '_blank')}
                      onKeyDown={() => window.open(`${DOCTOR_REPORT_INACCURACIES}/${data?._id}`, '_blank')}>
                      <img
                        className='img-fluid'
                        src={Images.icDetailReport}
                        srcSet={`${Images.icDetailReport2x} 2x, ${Images.icDetailReport3x} 3x`}
                        alt='tick'
                      />
                      {REPORT_INACCURACIES_LABLE}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {provider &&
          provider?.result?.slice(1).map((location: any) => (
            <OtherLocation
              data={data}
              location={location}
              isMatchZipCode={isMatchZipCode}
              isZipSearch={isZipSearch}
              pageName={pageName}
              selectAsPCP={selectAsPCP}
              key={location._id}
              printProviderInfo={this.printProviderInfo}
              shareOtherLocation={this.shareOtherLocation}
              details={providerDetails}
              // userData={userData}
              // onBookAppointment={onBookAppointment}
            />
          ))}
        {provider && provider?.additional.length > 0 && (
          <>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 text-center' style={{ backgroundColor: '#D9D9D9' }}>
                <button type='button' className='advanceToggle additional'>
                  <a
                    data-bs-toggle='collapse'
                    href={`#collapseExample${provider._id}`}
                    role='button'
                    aria-expanded='false'
                    aria-controls={`collapseExample${provider._id}`}
                    style={{ textDecoration: 'none' }}>
                    <span className='plusAdvanceFilter text-start text-sm-center text-md-center'>
                      <span className='capitalized no-wrap'>{`${data?.typeOfLicensure}
                      ${data?.lastName?.toLowerCase()}`}</span>
                      <span>
                        {` also works at ${provider?.additional.length} other locations outside your search criteria`}
                      </span>
                    </span>
                    <span className='minusAdvanceFilter'>
                      <span className='capitalized'>{`${data?.typeOfLicensure}
                      ${data?.lastName?.toLowerCase()}`}</span>
                      {` also works at ${provider?.additional.length} other locations outside your search criteria`}
                    </span>
                  </a>
                </button>
              </div>
            </div>
            <div className='collapse' id={`collapseExample${provider._id}`}>
              {provider?.additional.map((location: any) => (
                <OtherLocation
                  data={data}
                  location={location}
                  isMatchZipCode={isMatchZipCode}
                  isZipSearch={isZipSearch}
                  pageName={pageName}
                  selectAsPCP={selectAsPCP}
                  key={location._id}
                  printProviderInfo={this.printProviderInfo}
                  shareOtherLocation={this.shareOtherLocation}
                  details={providerDetails}
                  // userData={userData}
                  // onBookAppointment={onBookAppointment}
                />
              ))}
            </div>
          </>
        )}
        {showVimTokenErrorModal && (
          <ConfirmationModal
            showModal={showVimTokenErrorModal}
            title={errorTitle}
            message={errorMessage}
            closeModal={() => this.setState({ showVimTokenErrorModal: false })}
          />
        )}
        {showErrorModal && (
          <ConfirmationModal
            title={`Error Code: 500 - ${INTERNAL_SERVER_ERROR_TEXT}`}
            message={SOME_ERROR_TEXT}
            showModal={showErrorModal}
            closeModal={() => this.setState({ showErrorModal: false })}
          />
        )}
        {showEnableShareModal && (
          <ConfirmationModal
            title='Share not enabled'
            message={SHARE_ERROR_TEXT}
            showModal={showEnableShareModal}
            closeModal={() => this.setState({ showEnableShareModal: false })}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  fetchProviderProfile: actions.fetchProviderProfile,
};

export default connect<stateProps, DispatchProps, ownProps>(mapStateToProps, mapDispatchToProps)(DoctorsSearchResults);
