import {
  ACCEPTING_NEW_PATIENT,
  // BOOK_APPOINTMENT_LABLE,
  CHANGE_PCP_ENUM,
  HIDE_DETAILS,
  NOT_ACCEPTING_NEW_PATIENT,
  REPORT_INACCURACIES_LABLE,
  SELECT_AS_PCP_LABLE,
  VIEW_DETAILS,
} from '../../../../shared/constant/AppConstants';
import { DOCTOR_REPORT_INACCURACIES } from '../../../../shared/constant/Urls';
import { convertToLowerCase, formatPhone, getMiles } from '../../../../shared/helperMethods/HelperMethod';
import Analytics, { FA } from '../../../../shared/utils/Analytics';
import Images from '../../../../shared/utils/Images';
import ConditionalUI from '../ConditionalUI';
import './OtherLocation.scss';

interface OtherLocationsProps {
  data: any;
  isMatchZipCode: boolean;
  location: any;
  isZipSearch: any;
  pageName: any;
  selectAsPCP: any;
  printProviderInfo: any;
  shareOtherLocation: any;
  // eslint-disable-next-line react/require-default-props
  details?: any;
  // userData: any;
  // onBookAppointment: any;
}

const OtherLocations = ({
  data,
  isMatchZipCode,
  isZipSearch,
  pageName,
  selectAsPCP,
  location,
  printProviderInfo,
  shareOtherLocation,
  details,
}: // userData,
// onBookAppointment,
OtherLocationsProps): JSX.Element => {
  return (
    <div className='other-location' style={{ borderTop: '1px solid #D0D0CE' }}>
      <div className='blue-halo'>
        <div className='container-fluid'>
          <div className='col-lg-12 col-md-12 col-sm-12 no-padding grid-content'>
            <div className='row'>
              <div className='col-lg-6 col-xl-5 col-md-6 col-sm-12 ps-0'>
                <div className='d-flex align-items-end justify-content-start h-100'>
                  <div style={{ width: '119px' }} className='provider-picture other' />
                  {location?.acceptingNewPatients ? (
                    <div className='img-text col-auto px-3 px-md-0 mt-sm-0 mt-ld-2 px-sm-0-custom d-flex'>
                      <img
                        className='alert-icon img-fluid image14 mt-1'
                        src={Images.icCheck2x}
                        srcSet={`${Images.icCheck2x} 2x`}
                        alt='tick'
                      />
                      <span className='text-tick'>{ACCEPTING_NEW_PATIENT}</span>
                    </div>
                  ) : (
                    <div className='img-text col-auto px-3 px-md-0 mt-sm-0 mt-ld-2 d-flex'>
                      <img
                        className='alert-icon img-fluid image14 mt-1'
                        src={Images.icListNocheck2x}
                        srcSet={`${Images.icListNocheck2x} 2x`}
                        alt='tick'
                      />
                      <span className='text-tick'>{NOT_ACCEPTING_NEW_PATIENT}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className='col-lg-6 col-xl-7 col-md-6 col-sm-12 px-0'>
                <div className='row' style={{ height: '100%' }}>
                  <div className='col-9 col-md-9 col-lg-9 px-0 col-add'>
                    <div className='row'>
                      <div className='col-md-12 col-lg-12 col-xl-7  px-sm-0-custom order-sm-2 order-md-2 order-2 order-lg-2 order-xl-1'>
                        <a
                          href={`https://www.google.com/maps/place/${location?.address?.street1}+${location?.address?.city}+${location?.address?.state}+${location?.address?.zip}`}
                          className='address-link'
                          target='_blank'
                          rel='noreferrer'>
                          <div className='address-block other-address'>
                            <div className='medical-group mt-md-0 mt-lg-0 capitalized mb-1'>
                              <img src={Images.icPinList} alt='pin' />
                              <span>{convertToLowerCase(location?.clinicName)}</span>
                            </div>
                            <span className='text-tick address-text capitalized'>
                              {convertToLowerCase(location?.address?.street1)}
                            </span>
                            <span className='text-tick address-text capitalized'>
                              {convertToLowerCase(location?.address?.street2)}
                            </span>
                            <span className='text-tick address-text'>
                              {location?.address?.city.replace(/(\w)(\w*)/g, (g0: any, g1: any, g2: any) => {
                                return g1.toUpperCase() + g2.toLowerCase();
                              })}
                              , {location?.address?.state} {location?.address?.zip}
                            </span>
                            {!isMatchZipCode && (
                              <div className='text-tick address-text img-text-2'>
                                <span className='miles'>
                                  {isZipSearch === true &&
                                    (location?.distance === undefined || location?.distance === null) &&
                                    'Distance Not Available'}
                                  {location?.distance === 0
                                    ? '0 miles away'
                                    : location?.distance && getMiles(location?.distance)}
                                </span>
                              </div>
                            )}
                          </div>
                        </a>
                      </div>

                      <div className='col-md-12 col-lg-12 col-xl-5 px-sm-0-custom order-sm-1 order-md-1 order-1 order-lg-1 order-xl-2'>
                        <div className='address-block other-address contact '>
                          <div className='medical-group other-phone d-flex align-items-center'>
                            <img style={{ marginTop: '2px' }} src={Images.phone} alt='' />
                            <span>
                              <a style={{ whiteSpace: 'nowrap' }} href={`tel:${location?.phone}`}>
                                {formatPhone(location?.phone)}
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-print-share col-3 col-md-3 col-lg-3 pe-0 ps-0 d-flex align-items-end justify-content-end justify-content-md-between flex-column '>
                    <div className='share-print'>
                      {pageName !== 'changePCP' && (
                        <button
                          type='button'
                          aria-label='.'
                          className='share-button'
                          title='Share'
                          id='share-button'
                          onClick={() => shareOtherLocation(location?._id)}
                        />
                      )}

                      <button
                        type='button'
                        aria-label='.'
                        className='print-button'
                        title='Print'
                        onClick={() => printProviderInfo(location?._id, details)}
                      />
                    </div>
                    <div className='button-section'>
                      <a
                        className='hook collapsed  view-details-btn clickable px-0 mt-2'
                        data-bs-toggle='collapse'
                        href={`#collapseDetails_${location._id}`}
                        aria-controls={`collapseDetails_${location._id}`}
                        role='button'
                        aria-expanded='false'
                        onClick={() => {
                          const myCollapse = document.getElementById(`${location._id}_span`);
                          if (myCollapse?.offsetParent == null) {
                            if (pageName === 'changePCP') {
                              Analytics.logEvent(FA.CHANGE_PCP_SEARCH_PHYSICIAN_VIEW_DETAILS, {
                                clinic_name: `${data?.clinicName}`,
                              });
                            } else {
                              Analytics.logEvent(FA.DOCTOR_AND_SERVICE_PROVIDER_VIEW_DETAILS, {
                                provider_name: `${data?.firstName} ${data?.lastName}`,
                              });
                            }
                          }
                        }}>
                        <span className='btn-toggle-details view-detail ' id={`${location._id}_span`}>
                          {VIEW_DETAILS}
                        </span>
                        <span className='btn-toggle-details hide-detail'>{HIDE_DETAILS}</span>
                      </a>
                      {pageName === 'changePCP' && (
                        <button
                          onClick={() => {
                            selectAsPCP(CHANGE_PCP_ENUM.CONFIRMCHANGES, data, CHANGE_PCP_ENUM.SELECTPHYSICIAN);
                            Analytics.logEvent(FA.CHANGE_PCP_NEW_PCP_CHOSEN, {
                              npi: data?.npi,
                              address1: data?.address?.street1,
                              address2: data?.address.street2,
                              city: data?.address?.city,
                              state: data?.address?.state,
                              zip: data?.address?.zip,
                            });
                          }}
                          type='button'
                          className='btn-toggle-details schedule-vims clickable col-auto change-pcp px-2 mt-2'>
                          {SELECT_AS_PCP_LABLE}
                        </button>
                      )}
                      {/* {data && data?.bookingType !== '' && pageName !== 'changePCP' && !userData?.isPreEffective && (
                        <button
                          onClick={() => onBookAppointment(data)}
                          type='button'
                          className='btn btn-toggle-details schedule-vims clickable col-auto mt-2 px-2'>
                          {BOOK_APPOINTMENT_LABLE}
                        </button>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='collapse ' id={`collapseDetails_${location._id}`} style={{ borderTop: '1px solid #D0D0CE' }}>
          <div className='container-fluid'>
            <ConditionalUI provider={location} data={data} />
            <div className='col-12 px-4 mt-3 report-inaccuracy'>
              <span
                role='none'
                onClick={() => window.open(`${DOCTOR_REPORT_INACCURACIES}/${data?._id}`, '_blank')}
                onKeyDown={() => window.open(`${DOCTOR_REPORT_INACCURACIES}/${data?._id}`, '_blank')}>
                <img
                  className='img-fluid'
                  src={Images.icDetailReport}
                  srcSet={`${Images.icDetailReport2x} 2x, ${Images.icDetailReport3x} 3x`}
                  alt='tick'
                />
                {REPORT_INACCURACIES_LABLE}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherLocations;
