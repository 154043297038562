/* eslint-disable react/require-default-props */
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { DIRECTORY_INFO_LABEL } from '../../../shared/constant/AppConstants';

interface ownProps {
  onPageChange: any;
  currentPage: any;
  data: any;
  skip?: any;
  pageName?: any;
  skipArray?: any[];
}

interface ownState {}

class FooterPaginations extends PureComponent<ownProps, ownState> {
  componentDidMount(): void {
    const { skipArray, skip } = this.props;
    if (skipArray && !skipArray.includes(skip)) {
      skipArray.push(skip);
      skipArray.sort((a, b) => a - b);
    }
  }

  getPreviousSkip = (skip: any): any => {
    const { skipArray } = this.props;
    const index =
      _.findIndex(skipArray, (e) => {
        return e === skip;
      }) - 2;
    return skipArray && (skipArray[index] ? skipArray[index] : 0);
  };

  render(): React.ReactElement {
    const { currentPage, onPageChange, data, skip, pageName } = this.props;
    const totalPages = Math.ceil(data?.totalRecords / 15);

    if (currentPage === 1 && totalPages === 1) {
      return <div />;
    }
    return (
      <div className='row pagination-bar align-items-center px-3 mt-2'>
        <div className='col-auto px-0'>
          <p className='subject-to-change-notice px-0 mb-0'>{DIRECTORY_INFO_LABEL}</p>
        </div>
        {data?.totalRecords > 15 && (
          <div className='col-sm-auto px-0 ms-auto'>
            <nav aria-label='Page navigation example'>
              <ul className='pagination mt-3'>
                <li className='page-item'>
                  <span
                    role='button'
                    tabIndex={0}
                    onClick={() => (currentPage !== 1 && pageName === 'Doctor' ? onPageChange(1) : onPageChange(0))}
                    onKeyPress={() => (currentPage !== 1 && pageName === 'Doctor' ? onPageChange(1) : onPageChange(0))}
                    className='page-link'
                    aria-label='Previous'>
                    <span aria-hidden='true'>{'<<'}</span>
                  </span>
                </li>
                <li className='page-item'>
                  <span
                    role='button'
                    tabIndex={0}
                    onClick={() =>
                      currentPage !== 1 && pageName === 'Doctor'
                        ? onPageChange(this.getPreviousSkip(skip), 'prev')
                        : onPageChange(currentPage - 1)
                    }
                    onKeyPress={() =>
                      currentPage !== 1 && pageName === 'Doctor'
                        ? onPageChange(this.getPreviousSkip(skip), 'prev')
                        : onPageChange(currentPage - 1)
                    }
                    className='page-link'
                    aria-label='Previous'>
                    <span aria-hidden='true'>{'<'}</span>
                  </span>
                </li>
                <li className='page-item '>
                  <span className='page-link pagination-center text-white'>
                    {currentPage} of {Math.ceil(totalPages)}{' '}
                  </span>
                </li>
                <li className='page-item'>
                  <span
                    role='button'
                    tabIndex={0}
                    onClick={() =>
                      currentPage < totalPages && pageName === 'Doctor'
                        ? onPageChange(Math.ceil(skip), 'next')
                        : onPageChange(Math.ceil(currentPage + 1))
                    }
                    onKeyPress={() =>
                      currentPage < totalPages && pageName === 'Doctor'
                        ? onPageChange(Math.ceil(skip), 'next')
                        : onPageChange(Math.ceil(currentPage + 1))
                    }
                    className='page-link'
                    aria-label='Next'>
                    <span aria-hidden='true' className='large'>
                      {'>'}
                    </span>
                  </span>
                </li>
                <li className='page-item'>
                  <span
                    role='button'
                    tabIndex={0}
                    // onClick={() => currentPage < totalPages && onPageChange(totalPages)}
                    // onKeyPress={() => currentPage < totalPages && onPageChange(totalPages)}
                    className='page-link'
                    aria-label='Next'>
                    <span aria-hidden='true'>{'>>'}</span>
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }
}

export default FooterPaginations;
