/* eslint-disable no-unused-vars */
// Label constants
import { i18n } from '@canopy/shared';
import React, { ReactNode } from 'react';
import { dateFormatter } from '../helperMethods/HelperMethod';

const translate = (value: string): any => {
  return i18n.translate(value);
};
export default translate;

export const USERNAME = 'Username';
export const EMAIL = 'Email';
export const FIRST_NAME = 'First Name';
export const LAST_NAME = 'Last Name';
export const BIRTHDATE = 'Birthday';
export const HEALTH_PLAN = 'Health Plan';
// create account

export const BACK_TO_LOGIN = 'Back to Login';
export const BACK = 'Back';
export const STEP_COUNT = (count: number): ReactNode => (
  <div className='enrollment-steps mb-0 pb-0'>Step {count} of 3</div>
);
export const CREATE_ACCOUNT = 'Create Account';

export const CREATE_ACCOUNT_INSTRUCTION_MSG = (): ReactNode => {
  return (
    <div>
      {' '}
      <div className='enrollment-body'>Success, we&apos;ve verified your plan!</div>
      <div className='enrollment-body'>
        In this step, provide a reliable email address and choose a username for your account.
      </div>
      {/* <div className='enrollment-body mt-2 mb-3'>
        Next, enter a unique username & password for ongoing safe access to your MyCanopyHealth
        account
      </div> */}
    </div>
  );
};

export const CREATE_ACCOUNT_PASSWORD_INSTRUCTION_MSG = (): ReactNode => {
  return (
    <div>
      {' '}
      <div className='enrollment-body'>Choose a secure password that you will remember</div>
    </div>
  );
};

export const USERNAME_VALIDATION = (): ReactNode => {
  return (
    <div>
      <strong>Username guidelines:</strong> Minimum of 5 characters. You can choose a combination of letters, numbers
      and the following special characters. ! # $ % & &apos; * + - / = ? ^ _ ` . &#123; | &#125; ~
    </div>
  );
};
export const USERNAME_VALIDATION_CHANGE_USERNAME = (): ReactNode => {
  return (
    <span>
      <strong>Username: </strong>Minimum of 5 characters including: letters, numbers, and certain special characters:
      <br />
      <br />
      ! # $ % & &apos; * + - / = ? ^ _ ` . &#123; | &#125; ~
      <br />
      <br />
      with maximum of one period. Cannot begin or end with a period.
    </span>
  );
};
export const PASSWORD_VALIDATION_CHANGE_USERNAME = (): ReactNode => {
  return (
    <span>
      Minimum of 8 characters including at least one of each: uppercase letter, lowercase letter, number, and certain
      special characters: <br /> ! @ # $ % ^ &amp; *
    </span>
  );
};

export const PASSWORD_VALIDATION = (): ReactNode => {
  return (
    <div>
      <strong>Password guidelines:</strong>
      Minimum of 8 characters including at least one of each uppercase letter, lowercase letter, number and one of the
      following special characters. | @ # $ % ^ & *
    </div>
  );
};

export const CREATE_PASSWORD = 'Create Password';
export const CONFIRM_PASSWORD = 'Confirm Password';
export const CONFIRM_LABEL = 'Confirm';
export const CONTINUE_BUTTON = 'Continue';

// login error code

export const LOGIN_ERROR_CODES = {
  statusCode1502: 1502,
  statusCode1503: 1503,
  statusCode1505: 1505,
  statusCode1509: 1509,
  statusCode1511: 1511,
  statusCode1506: 1506,
};

// Locate your account

export enum StringPrefixes {
  R = 'R',
  r = 'r',
}

export const LOKKUP_YOUR_ACCOUNT = 'Verify Plan Details';
export const LOOKUP_ACCOUNT_INSTRUCTION_MSG = (): ReactNode => {
  return (
    <div>
      {' '}
      <div className='enrollment-body'>
        Have your health plan ID card handy & fill Step 1 info as appears on the card(this is important for us to verify
        you).
      </div>
      <div className='enrollment-hint'>
        Once we’ve successfully verified your plan in Step 1, you’ll create your MyCanopyHealth username & password in
        Step 2.
      </div>
    </div>
  );
};

export const LOOKUP_ACCOUNT_INSTRUCTION_MSG_2 = (): ReactNode => {
  return (
    <div>
      {' '}
      <div className='enrollment-body'>
        Select your health plan from the menu and enter your member ID exactly as it appears on your health plan ID
        card.
      </div>
      <div className='enrollment-hint'>
        <strong>Need assistance:</strong> Click on the (i) to see view as example of your heath plan ID card and where
        to find the information
      </div>
    </div>
  );
};

export const ERROR_TEXT = 'Error';
export const MEMBER_ID_NO = 'Member ID #';
export const WHERE_TO_FIND = 'Where to find?';
export const AGE_VALIDATION_MESSAGE_TITLE = 'Minimum Age Requirement';
export const AGE_VALIDATION_MESSAGE = 'You must be 18 years of age or older to create an account.';

export const INVALID_DATE_TITLE = 'Invalid Date';
export const INVALID_DATE_MESSAGE = 'Invalid date format. Please enter the Date of Birth in the format "MM/DD/YYYY" ';

export const DOB_ERROR_MSG = (dob: string): any =>
  `Date of Birth entered (${dob}) doesn't match our records. For resolution, please contact the Canopy Health Customer Contact Center 888-8-CANOPY.`;

// Login PAGE

export const WELCOME_TXT = 'Welcome to MyCanopyHealth';
export const WELCOME_NEW_MEMBER = 'Welcome';

export const WELCOME_MSG = (): ReactNode => {
  return (
    <p>
      To simplify your digital experience, we&apos;ve said goodbye to the MyCanopyHealth (MCH) app/portal logged-in experience, however, you still have access to Doctors & Services.
      <br />
      <br />
      We encourage you to explore the digital offerings from your health plan and medical group. If you have any
      questions or need assistance, please call the Member Services phone number on the back of your ID card.
    </p>
  );
};

export const HELP_SIGN_IN = 'Need help signing in?';
export const HELP = 'Help?';
export const RECOVER_USERNAME = 'Recover Username?';
export const UNLOCK_ACCOUNT = 'Unlock account?';
export const DOWNLOAD_APP_TEXT = 'Download the MyCanopyHealth app';

export const PLEASE_LOGIN_TXT = 'Please Login';
export const SIGNIN_TXT = 'Sign In';
export const LOADING_LOGING_MSG = 'Logging in, please wait…';
export const UPDATING_INFO_MSG = 'Updating your information...';

export const FORGOT_USERNAME = 'Forgot username?';
export const FORGOT_PASSWORD = 'Forgot password?';

export const LOGIN = 'Login';
export const PASSWORD = 'Password';
export const SUBMIT = 'Save';
export const SUBMIT_LBL = 'Submit';
export const SendEmail = 'Send Email';
export const BackToSignIn = 'Back to Sign In';
export const UNLOCKACCOUNT = 'Unlock account';
export const ACCOUNT_SUCCESSFULLY_UNLOCKED = 'Account Successfully Unlocked';
export const PASSWORD_SUCCESSFULLY_CHANGED = 'Password Successfully Changed';
export const RESETPASSWORD = 'Reset Password';
export const RECOVERUSERNAME = 'Recover Username';
export const CHANGE_PASSWORD = 'Change Password';
export const REPEAT_PASSWORD = 'Repeat Password';
export const EMAIL_ADDRESS_WITH_ACCOUNT =
  'Please enter the email address associated with your account and we will email your username.';
export const SMS_AUTHENTICATION_LBL = 'SMS Authentication';
export const EMAIL_AUTHENTICATION_LBL = 'Email Authentication';

export const PASSWORD_REQUIREMENTS_LBL = 'Password Requiremrnts';
export const PASSWORD_INSTRUCTION_ARRAY = [
  'At least 8 characters',
  'A lowercase letter',
  'An uppercase letter',
  'At symbol',
  'A number',
  'Your password cannot be any or your last 4 password',
];

// mfa

export const VERIFY_YOUR_ACCOUNT = 'Verify Your Account';
export const SECURE_YOUR_ACCOUNT = 'Secure Your Account';
export const VERIFY_YOUR_ACCOUNT_EMAIL = 'Verify Your Account:\nEmail';
export const VERIFY_YOUR_ACCOUNT_SMS = 'Verify Your Account:\nSMS';
export const SETUP_TWO_STEPS_VERIFICATION = 'Set up two-step verification';
export const EMAIL_VERIFICATION = 'Email Verification';
export const SMS_VERIFICATION = 'SMS Verification';
// export const EMAIL_TWO_STEP_MESSAGE = 'Use my email for two-step verification';
export const EMAIL_TWO_STEP_MESSAGE = (email: string): string => {
  return `Use my email address ${email} for two-step verification`;
};
export const SMS_TWO_STEP_MESSAGE = 'Use my phone number for two-step verification';
export const SETUP_LABEL = 'Setup';
export const SETUP_EMAIL_VERIFICATION = 'Set up Email Verification';
export const SETUP_EMAIL_VERIFICATION_MESSAGE = (email: string): any =>
  `A verification code was send to ${email} check the email and enter the code below.`;
export const VERIFICATION_CODE = 'Verification Code';
export const HAVE_NOT_RECEIVED_CODE = 'Haven’t received an email? ';
export const HAVE_NOT_RECEIVED_MOBILE_OTP = 'Haven’t received an SMS? To try again, click';

export const BACK_TO_FACTOR_LIST = 'Back to Factor list';
export const VERIFY_LBL = 'Verify';
export const SETUP_MOBILE_VERIFICATION = 'Set up Mobile Verification';
export const SETUP_SMS_VERIFICATION_MESSAGE =
  'Enter your valid mobile number to enable one-step Verification via OTP for MyCanopyHealth login.';
export const MOBILE_VERIFICATION_MESSAGE = `Click 'Re-Send code', then check your phone & enter the code below and click 'Verify'`;
export const PHON_NUMBER_LABEL = 'Phone Number';
export const SEND_CODE_LABEL = 'Send Code';
export const ENTER_CODE = 'Verification Code';
export const DONT_HAVE_ACCESS_LBL = 'I do not have access to this device.';
export const RESEND_CODE_LABEL = 'Re-Send Code';
export const SIGN_OUT_LBL = 'Sign Out';

// Recover account

const REQUEST_SUCCESS = (): React.ReactNode => {
  return (
    <p>
      <strong>Please check your email:</strong> We have sent you a message with your username.
    </p>
  );
};

export const PHONE_NUMBER_BOLD = (number?: string): ReactNode => {
  return (
    <span
      style={{
        fontFamily: 'SofiaPro',
        fontSize: '30px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#4a4a4a',
        display: 'block',
      }}
      className='text-center'>
      {number}
    </span>
  );
};
// Reset Password

export const EMAIL_USERNAME = 'Email or Username';
export const SEND_EMAIL = 'Reset via Email';
export const PLEASE_CHECK_MAIL = 'Please check your email';
//

export const allowedMymeTypes = ['image/png', 'image/jpeg'];

export const allowedFileExtentions = ['jpeg', 'jpg'];

// Doctors and services

export const HEALTH_PLAN_TITLE = 'HealthPlan';
export const RESULTS_SHOWN_MESSAGE = 'Results will be shown based on selected health plan.';
export const RESULTS_SHOWN_MESSAGE_UNAUTH = 'Results are shown based on the health plan selected.';
export const SELECT_HEALTH_PLAN_MESSAGE = 'Select a Health Plan to begin search';
// Doctors Filter Lables

export const CHANGEPCPC_PAGE_NAME = 'changePCP';
export const ZIP_CODE = 'Zip code';
export const CITY = 'City';
export const CLEAR = 'Clear Filters';
export const ACCEPTING_NEW_PATIENTS = 'Accepting New Patients';
export const LGBTQ = 'LGBTQ+';
export const ACCEPTING_ONLINE_SCHEDULING = 'Accepting Online Scheduling';
export const SERVICES = 'Services';
export const SELECT_HEALTH_PLAN = 'Select a Health Plan';
export const SERVICE_COUNTIES = 'Service Counties';
export const PLEASE_ENTER_ZIP_CODE = 'Please enter a zip code';
export const DISTANCE = 'Distance';
export const OPEN_NOW_ONLY = ' Open Now Only';
export const BEGIN_SEARCH_TO_FIND_DOCTORS = 'Begin search to find doctors in our network';
export const BEGIN_SEARCH_TO_FIND_HOSPITALS = 'Begin search to find hospitals in our network';
export const BEGIN_SEARCH_TO_FIND_SERVICES = 'Begin search to find services in our network';
export const BEGIN_SEARCH_TO_FIND_URGENT_CARE = 'Begin search to find urgent care in our network';
export const HOSPITALS_FOUND = 'Hospitals Found';
export const DOCTORS_FOUND = 'Doctor Locations Found';
export const TYPE_OF_SERVICES = 'TypeOfService';
export const TYPE_OF_SERVICES_LABLE = 'Type Of Service';
export const SERVICE_COUNTIES_SEARCH_TEXT = 'ServiceCounties';
export const DISCLAIMER_TEXT =
  'Disclaimer: You are searching All Health Plans, not all of these providers are in your health plan.';
export const GENDER = 'Gender';
export const DOCTORS_LANGUAGE = 'Doctor Language ';
export const LANGUAGES = 'Languages';
export const PROVIDERS_LANGUAGE = 'ProviderLanguage';
export const PLUS_ADVANCED_FILTERS = 'Show Advanced Filters';
export const MINUS_ADVANCED_FILTERS = 'Hide Advanced Filters';
export const TYPE_OF_LICENSURE = 'TypeOfLicensure';
export const TYPE_OF_LICENSURE_LABEL = 'Type Of Licensure';
export const MEDIA_GROUP_IPA = 'MediaGroupIPA';
export const MEDIA_GROUP_IPA_LABLE = 'Medical Group IPA';
export const AFFILIATED_MEDICAL_GROUP = 'Affiliated Medical Group';
export const HOSPITALS = 'Hospitals';
export const HOSPITALS_OPTION = 'Hospital';
export const COUNTY = 'County';
export const STATE = 'State';
export const PRIMARY_CARE = 'PrimaryCare';
export const SPECIALITIES = 'Speciality';
export const OFFERING_TELEHEALTH = ' Offering Telehealth';
export const HIDE_DETAILS = 'Hide Details';
export const VIEW_DETAILS = 'View Details ';
export const VENDOR_DETAILS = 'Vendor Detail ';
export const OFFICE_HOURS = 'Office Hours ';
export const HOLIDAY_INFO = (): React.ReactNode => {
  return (
    <p>
      <strong>Holiday: </strong> Call for hours of operation.
    </p>
  );
};
export const FAX_LABLE = 'Fax';
export const FAX_NUMBER_LABLE = 'Fax Number';
export const SERVICES_LABLE = 'Services';
export const NPI_LABLE = '  NPI';
export const REPORT_INACCURACIES_LABLE = '   Report Inaccuracies';
export const AFFILIATED_WITH_LABEL = 'Affiliated with:';

export const CANCEL = 'Cancel';
export const TRY_AGAIN_LBL = 'Try Again';
export const SAVE = 'Save';
export const PHONE_NUMBER = 'Phone Number';
export const ADDRESS2 = 'Address 2';
export const ADDRESS1 = 'Address 1';
export const WEBSITE_LABLE = 'Website';
export const VENDOR_NAME = 'Vendor Name';
export const EMAIL_ADDRESS = 'Email address';
export const REPORT_INACCURACIES_THANK_YOU_TEXT =
  '  Thank you for taking the time to submit corrections. Please tell us your email address in case we need to contact you for clarifications.';
export const SUGGEST_INACCURACIES_TEXT =
  'Below you can suggest edits to inaccuracies. We will make corrections as needed.';
export const COUNTIES_SERVED_LABEL = 'Counties Served';
export const FIRST_NAME_LABEL = 'First Name';
export const MIDDLE_NAME_LABEL = 'Middle Name';
export const LAST_NAME_LABEL = 'Last Name';
export const SPECIALITY_LABEL_COUNT = (num: string): string => {
  return `Specialty ${num}`;
};
export const LANGUAGE_LABEL_COUNT = (num: string): string => {
  return `Language ${num}`;
};
export const HOSPITAL_LABEL_COUNT = (num: string): string => {
  return `Hospital ${num}`;
};
export const ACCEPTING_NEW_PATIENTS_LABEL = 'Accepting new patients(Y/N)';
export const OFFERING_TELEHEALTH_LABEL = 'Telehealth (Y/N)';
export const BOARD_CERTIFICATE_LABEL = 'Board Certificate (Y/N)';
export const CLINIC_NAME = 'Clinic Name';
export const REPORT_INACCURACIES_DOCTORS_EDIT_INFO =
  ' Edits for the fields below should only be submitted by providers and medical groups';
export const CA_LICENSE_LABEL = 'CA License';
export const NON_CA_LICENSE_LABEL = 'Non CA License';
export const PROVIDER_TEXT = 'Providers';
export const HOSPITALS_TEXT = 'Hospitals';
export const URGENT_CARE_TEXT = 'Urgentcare';
export const ACCEPTING_NEWPATIENT_VAR = 'acceptingNewPatients';
export const TELEHEALTH_AVAILABLE_VAR = 'telehealth';
export const LGBTQ_VAR = 'lgbtq';
// Constants

export const COST_ESTIMATOR_PAGE_LABEL = 'Cost Estimator';
export const SEARCH_FOR_SERVICE_LABEL = 'Search for a Service';
export const ESTIMATE_COST_MESSAGE_TEXT =
  ' Estimate your out-of-pocket costs for common planned medical services. This tool provides an expected cost range across our many in-network physicians and medical facilities. Learn how your plan benefits apply to specific services and monitor your healthcare spending.';
export const COULD_NOT_FIND_SERVICE_TEXT =
  ' We could not find the service you are looking for. Try again with a different keyword.';
export const COST_ESTIMATOR_INFORMATION_TEXT =
  'Cost Estimator is intended to help you estimate the costs of healthcare services; your actual costs may vary. Information from this tool should not inform your medical decisions. Our goal is to provide our Members with an accurate estimate of services along with reliable out of pocket expenses. The Cost Estimator offers a high-level estimate of costs. We are continually improving our data acquisition processes to allow a closer, more accurate estimate..';
export const DETERMINE_COST_LABEL = 'Determine Costs';
export const SEARCH_TEXT_LABEL = 'SEARCH';
export const TERMS_AND_CONDITION_TEXT = 'Terms and Conditions';
export const CLOSE_TEXT_LABEL = 'Close';
export const OR_SELECT_FROM_CATEGORY = 'or Select from a Category';
export const OFFICE_VISIT_LABEL = 'Office Visits';
export const TESTS_LABEL = 'Tests';
export const IMAGING_RADIOLOGY_LABEL = 'Imaging/Radiology';
export const PROCEDURE_LABEL = 'Procedures';

// Placeholders starts
export const ENTER_SERVICE_PLACEHOLDER = 'Enter Service Name or Keyword';
// Care categories
export const OFFICE_VISIT_CATEGORY = 'Office Visits';
export const IMAGING_OR_RADIOLOGY_CATEGORY = 'Imaging/Radiology';
export const PROCEDURE_CATEGORY = 'Procedures';
export const TESTS_CATEGORY = 'Tests';

// terms of use costEstimator
export const AGREE_WITH_TEXT = 'I agree with';
export const TERM_OF_USE_TEXT = 'Terms of Use.';
export const ESTIMATE_COST_TEXT = 'Estimate Cost';
export const ACCOUNT_SETTING_VAR = 'accountsettings';
export const NOTIFICATION_VAR = 'notification';
export const NETWORK_VAR = 'network';
export const HOME_VAR = 'Home';
export const HOME_LABEL = 'HOME';
export const COSTESTIMATOR_VAR = 'costEstimator';
export const COSTESTIMATOR_CAMELCASE_VAR = 'costEstimator';
export const OUT_OF_POCKET_COST_SMALLCASE_VAR = 'outofpocketcost';
export const MY_HEALTH_PLAN_SMALLCASE_VAR = 'myhealthplan';
export const VERTUAL_VISIT_SMALLCASE_VAR = 'virtualvisit';
export const CUSTOMER_SUPPORT_SMALLCASE_VAR = 'customersupport';
export const GRIEVANCE_APPEAL_SMALLCASE_VAR = 'grievanceappeals';
export const USING_MY_BENIFITS_SMALLCASE_VAR = 'usingbenifits';
export const ACCEPT_BTN_LABEL = 'Accept';
export const DONT_ACCEPT_BTN_LABEL = `Don't accept`;

// Footer

export const PRIVACY_LABEL = 'Privacy';
export const TERMS_LABEL = '  Terms & Conditions';
export const TERMS_VAR = 'termsAndConditions';
export const NONDISCRIMINATION_LABEL = 'Nondiscrimination';

export const EMAIL_SENT_LABEL = 'Email Sent';
export const DIRECTORY_INFO_LABEL = 'The information in this directory is subject to change without notice';
export const ACCEPTING_NEW_PATIENT = 'Accepting New Patients';
export const NOT_ACCEPTING_NEW_PATIENT = 'Not Accepting New Patients';
export const BOOK_APPOINTMENT_LABLE = 'Book Appointment';
export const SELECT_AS_PCP_LABLE = 'Select as PCP';
export const PROVIDER_PRACTICE_DETAILES_LABEL = 'Provider Practice Detail';
// Error Codes
export const DUPLICATE_EMAIL_ERROR_CODE = 1001;
export const DUPLICATE_USERNAME_ERROR_CODE = 1002;
export const DUPLICATE_USERNAME_EMAIL_ERROR_CODE = 1008;
export const ADITIONAL_DETAILS_LABEL = 'Additional Detail';
export const NETWORK_TIER = 'Network tier';
export const PROVIDER_ID_LABEL = 'Provider ID';
export const HOSPITAL_NPI_LABEL = ' Hospital NPI';
export const BOARD_CERTIFIED_LABEL = ' Board Certified';
export const HOSPITALIST_LABEL = ' Hospitalist';
export const TELEHEALTH_LABEL = ' Telehealth';

// Out of pocket cost
export enum FamilyTiers {
  TIER1 = '1',
  TIER2 = '2',
  TIER3 = '3',
  TIER4 = '4',
}
export const OUT_OF_POCKET_COST_LABEL = 'Out of Pocket Cost';
export const INDIVIDUAL_LABEL = 'Individual';
export const MAX_OUT_OF_POCKET_LBL = 'Maximum Out-of-Pocket';
export const DEDUCTIBLE_LABEL = 'Deductible (YTD)';
export const OUT_OF_POCKET_COST_YTB_LABEL = 'Out of Pocket Max (YTD)';
export const SPENT_LABEL = 'Spent';
export const FAMILY_LABEL = 'Family';
export const FACILITY_LABEL = 'Facility Fees';
export const DEFINITION_LABEL = ' Definitions';
export const DEDUCTIBLE_YTD_LABEL = ' Deductible - Year to Date (YTD)';
export const OUT_OF_POCKET_COST_YTD_LABEL = ' Out of Pocket Max - Year to Date (YTD)';
export const OOPC_YTD_DEFINITION =
  'The out-of-pocket maximum/limit is generally the most you have to pay for covered services within a plan year.';
export const DEDUCTIBLE_YTD_DEFINITION =
  ' The "deductible" is the amount you pay for covered health care services before your insurance plan starts to pay for services. For example, if your plan has a $5,000 deductible, you pay the first $5,000 of covered services yourself. After you pay your deductible, you may still be responsible for copayment or coinsurance for covered services.';

export const HEALTH_PLAN_NOT_HAVE_COST_MSG = 'Your health plan does not have any Deductible or Out of Pocket Cost.';
export const SPECIALITY_PRIMARY_CARE = 'Specialty/Primary Care Options';
export const RIMARY_CARE_SPECIALITY_LABEL = ' Primary Care Specialties';
export const ALL_SPECIALITY_LABEL = 'All Specialties';
export const PRIMARY_CARE_SPECIALITY_VAR = 'primaryCareSpeciality';
export const SPECIALITY_VAR = 'specialty';
export const CURRENT_PASSWORD_LABEL = 'Current Password';
export const CHANGE_LABEL = 'Change';
export const NEW_PASSWORED_LABEL = 'New Password';
export const BACK_TO_SIGN_IN_LABEL = 'Back to sign in';
export const LOGOUT_LABEL = 'Logout';
export const MENU_LABEL = 'MENU';
export const DOCTORS_AND_SERVICES_LABEL = 'DOCTORS & SERVICES';
export const ACCOUNT_SETTINGS_LABEL = 'ACCOUNT SETTINGS';
export const NOTIFICATIONS_LABEL = 'NOTIFICATIONS';
export const COST_ESTIMATOR_LABEL = 'COST ESTIMATOR';
export const OUT_OF_POCKET_COST_MENU_LABEL = 'OUT OF POCKET COST';
export const ACCOUNT_INFO_LABEL = 'Account Info';
export const LOADING_LABEL = 'Loading';
export const MANAGE_NOTIFICATION_LABEL = 'Manage Notifications';
export const SEND_ME_MANAGE_NOTIFICATION_LABEL = 'Send me portal notifications for:';
export const MY_HEALTH_PLAN_NOTIFICATION_LABEL = 'MY HEALTH PLAN';
export const VIRTUAL_VISITS_NOTIFICATION_LABEL = 'VIRTUAL VISITS';
export const CUSTOMER_SUPPORT_NOTIFICATION_LABEL = 'CUSTOMER SUPPORT';
export const GRIEVANCE_NOTIFICATION_LABEL = 'GRIEVANCES & APPEALS';
export const GRIEVANCE_NOTIFICATION_LABEL1 = 'GRIEVANCES & APPEALS';
export const USING_MY_BENEFITS_NOTIFICATION_LABEL = 'USING MY BENEFITS';
export const RESET_MFA_LABEL = 'Multi-factor Authentication Settings';
export const RESET_MFA_MODEL_LABEL = 'Reset Multi-Factor Authentication';
export const RESET_LABEL = 'Reset';
export const NOTIFICATIONS_DATA = {
  general: {
    title: 'General',
    info: 'Information about your Canopy Health mobile app and website access',
  },
  healthPlan: {
    title: 'My Health Plan',
    info: 'Information about your Canopy Health plan',
  },
  healthInfo: {
    title: 'Health Info',
    info: 'Information about health with you in mind',
  },
  healthExpenses: {
    title: 'Health Plan Expenses',
    info: 'Information about expenses.',
  },
};

export const VIRTUAL_VISIT_LABEL = 'Virtual Visits';
export const VEDIO_VISIT_LABEL = 'Video Visits';
export const MANAGE_PAYMENT_LABEL = 'Manage Payment';
export const ADD_CARD_WITH_PLUS_LABEL = ' + Add Card';
export const REPLACE_CARD_LABEL = 'Replace Card';
export const NAME_ON_CARD_LABEL = ' Name on Card';
export const PAYMENT_METHOD_LABEL = 'Payment Method';
export const NO_PAYMENT_METHOD_ADDED_LABEL = 'No Payment Method Added';
export const CREDIT_CARD_INFO_LABEL = 'Credit Card Information';
export const ENTER_NAME_ON_CARD_LABEL = 'Enter the name on the card.';
export const EXPIRATION_DATE_LABEL = 'Expiration Date';
export const SECURITY_CODE_LABEL = ' Security Code';
export const BILLING_ADDRESS_LABEL = 'Billing Address';
export const ADDRESS1_LABEL = 'Address 1';
export const ADDRESS2_LABEL = 'Address 2';
export const CARD_NUMBER_LABEL = ' Card Number';

// Cost Estimator

export const COST_BREAKDOWWN_LABEL = 'Cost Breakdown';
export const CONTRACT_RATE_LABEL = 'Contracted Rate';
export const FACILTY_CHARGES_LABEL = 'Facility Charges';
export const FACILTY_FEES_LABEL = 'Facility Fees';
export const CO_PAYS_LBL = 'Co-Pays';
export const YOUR_BENIFITS_LABEL = 'Your Benefits';
export const PROFESSIONAL_CHARGES_LABEL = 'Professional Charges';
export const RESPONSIBILITY_FOR_SERVICE_LABEL = 'Your responsibility for this service';
export const BEFORE_ESTIMATE_LABEL = 'Before Estimate';
export const REMAINING_TOTAL_LABEL = 'Remaining / Total';
export const YOUR_ESTIMATE_LABEL = 'Your Estimated';
export const LOW_LABEL = 'Low';
export const AVERAGE_LABEL = 'Average';
export const HIGH_LABEL = 'High';
export const HIDE_CALCULATIONS = ' Hide Calculation';
export const SHOW_CALCULATIONS = ' Show Calculation';
export const PLACE_OF_SERVICE_LABEL = 'Place of Service';
export const CONTRACTED_RATE_LABEL = ' Contracted Rate';
export const RETRY_LABEL = 'Retry';
export const TIMEOUT_ERROR_LABEL = 'Timeout Error';
export const ESTIMATE_COST_LABEL = 'Estimating Cost';
export const BACK_TO_MAIN_LABEL = ' Back To Main';
export const ESTIMATE_COST_FOR_LABEL = 'Estimate cost for';
export const YES_LABEL = 'Yes';
export const NO_LABEL = 'No';

export const WHAT_DO_YOU_WANT_TO_LOOK_FOR_LABEL = 'What do you want to look for?';
export const DOCTORS_LABLE = 'Doctors';
export const HOSPITALS_LABLE = 'Hospital';
export const URGENT_CARE_LABLE = 'Urgent Care';
export const SORT_BY_LABLE = ' Sort by';
export const ACCOUNT_ERROR_LABLE = ' Account Error';
export const OK_LABEL = 'Ok';
export const LOGIN_OR_CREATE_ACCOUNT_LABEL = 'Login or create an account';

export const WHERE_TO_FIND_MEMBER_ID = ' Where to Find Your Member ID';
export const INTERNAL_SERVER_ERROR_TEXT = 'Internal Server Error';
export const SOME_ERROR_TEXT = 'Some error occurred. Please try again later';
export const SHARE_ERROR_TEXT =
  'Web share is not working at the moment on your browser. Please relaunch the browser and try again';

export enum MyCostEstimatorEnum {
  NUMSTRINGONE = '1',
  SELECTEDCATEGORYLENGTH = 3,
  EPISODES = 'episode',
  SEARCHICONALT = 'Search Icon',
}

// Notification Page

export const NOTIFICATIONS_LABEL_TEXT = 'Notifications';
export const DELETE_LABEL_TEXT = ' Delete All';
export const MANAGE_NOTIFICATIONS_SETTINGS_LABEL_TEXT = 'Manage Notification Settings';
export const SHOW_MORE_TEXT_LABEL = 'Show More';
export const SHOW_LESS_TEXT_LABEL = 'Show Less';
export const GO_TO_PLAN_SUMMARY_TEXT_LABEL = 'Go To Plan Summary';
export const GO_TO_BLOG_POST_TEXT_LABEL = 'Go To Blog Post';
export const NOTIFICATION_GENERAL_VAR = 'generalInfo';
export const NOTIFICATION_MY_HEALTH_PLAN_VAR = 'healthPlan';
export const NOTIFICATION_HEALTH_INFO_VAR = 'healthInfo';
export const DELETE_BTN_TEXT_LABEL = 'Delete';

// My Health Plan

export const VIEW_MY_BENEFIT_DETAILS_LABEL = 'View My Benefits Details';
export const ORDER_ID_CARD = ' Order ID Card';
export const VIEW_ID_CARD = '  View ID Card';
export const DEDUCTIBLE_WITHOUT_YTB_LABEL = 'Deductible';
export const YOU_PAY_LABEL = 'You Pay';
export const OUT_OF_POCKET_MAXIMUM_LABEL = 'Out-of-Pocket Maximum';
export const BENEFIT_HIGHLIGHTS_LABEL = 'Benefits Highlights';
export const PCP_OFFICE_VISIT_LABEL = 'PCP Office Visit';
export const SPECIALIST_VISIT_LABEL = ' Specialist Visit';
export const VIDEO_VISIT_COPAY_AMWELL_LABEL = 'Video Visit CO-pay(Amwell)';
export const EMERGENCU_ROOM_LABEL = 'Emergency Room';
export const PROFESSIONAL_SERVICES_LABEL = 'Professional Services';
export enum MyHealthPlanEnum {
  One = 1,
  Two = 2,
  HN = 'HN',
  UHC = 'UHC',
  HEALTHNET = 'Health Net',
  UNITEDHEALTHCARE = 'UnitedHealthcare',
}
export const HOSPITAL_OUTPATIENT_LABEL = 'Hospital Outpatient';

// DASHABOARD
export const CHANGE_MY_PCP_LBL = 'Change My PCP';
export enum CHANGE_PCP_STEPS_COUNT {
  SURVEY = 1,
  RECENT_SERVICES = 2,
  CONFIRM_ADDRESS = 3,
  SEARCH_PHYSICIAN = 4,
  CONFIRM_CHANGE = 5,
  COMPLETED = 6,
}

export const CHNAGE_PRIMARY_CARE_PROVIDER_LBL = 'Change Primary Care Provider';
export const SELECT_PRIMARY_CARE_PROVIDER_LBL = 'Select Primary Care Provider';
export enum CHANGE_PCP_STEPS {
  SURVEY = 'Survey',
  RECENT_SERVICES = 'Recent Services',
  CONFIRM_ADDRESS = 'Confirm Address',
  SEARCH_PHYSICIAN = 'Search Physician',
  CONFIRM_CHANGE = 'Confirm Changes',
  COMPLETED = 6,
}

export const SURVEY_OPTIONS = [
  {
    expanded: null,
    text: 'I prefer a different doctor or primary physician',
  },
  {
    expanded: null,
    text: 'I prefer a different medical group or PPG',
  },
  {
    expanded: null,
    text: 'Location: I have moved or the current office is inconvenient for me',
  },
  {
    expanded: 'unhappy',
    text: "I'm unhappy with the care or service I received",
    showMoreList: [
      ` The level of care provided: the primary physician didn't provide the level of service I expected`,
      `  The primary physician behavior: I did not like the way the physician
      behaved`,
      `The staff behavior: I did not like how the staff behaved`,
      `I have a filed a legal or court action against my medical group`,
      `I have to wait a long time to see a specialist`,
      ` I could not get authorization or approval for the medical services or
      treatment I requested`,
      `I have to wait a long time to get an appointment`,
      `I did not get the medical treatment or services I expected`,
      `The primary physician did not spend enough time with me`,
    ],
  },
  {
    expanded: null,
    text: 'Health Net assigned me the wrong primary physician or medical group',
  },
  {
    expanded: null,
    text: 'I selected the wrong primary physician or medical group when I enrolled',
  },
  {
    expanded: null,
    text: 'This change is for a newborn',
  },
];

export const PCP_SURVEY_INFO_CONTACT_TEXT = (number: any): any => {
  return (
    <>
      If your contact information has changed, please contact CanopyCare at
      <a className='ps-1' href='tel:{{cpcps.callCenterPhoneNumber}}'>
        {number}
      </a>{' '}
      before changing PCP.
    </>
  );
};

export const REASON_ALERT_INFO_CONTACT_TEXT = (link: any): any => {
  return (
    <>
      The reason you selected is related to Quality of Care, Legal, or Dissatisfaction issue. To file a grievance,
      submit a{' '}
      <a href={link} target='_blank' rel='noreferrer'>
        grievance form
      </a>{' '}
      through secure messenger.
    </>
  );
};

export enum CHANGE_PCP_ENUM {
  SURVEY = 1,
  RECENTSERVICE = 2,
  CONFIRMADDRESS = 3,
  SELECTPHYSICIAN = 4,
  CONFIRMCHANGES = 5,
}

export const TELL_US_WHY_CHANGE_PCP_TEXT = 'Tell us why you want to change PCP';
export const SELECT_OPTION_PCP_TEXT = 'Select an option below';
export const HIDE_EXAMPLES_LABEL = 'Hide Examples';
export const SHOW_EXAMPLES_LABEL = 'Show Examples';
// Surveys
export const CURRENT_PRIMARY_PHYSICIAN_MEDICAL_GRP_INFO = ` Here's the current primary physician and medical group information for`;
export const RECENT_SERVICES_LBL = 'Recent Services';
export const SAW_PHYSICIAN_RECIEVE_SERVICES_LBL =
  'Did you see this physician or receive any services in the current month?';
export const ARE_YOU_CURRENTLY_HOSPITALISED = 'Are you currently hospitalized ?';
export const DID_YOU_VISIT_THE_EMERGENCY_LBL = 'Did you visit the Emergency Room in the current month ?';
export const YOU_SELECTED_PHYSICIAN_AS_NEW_PRIMARY_PROVIDER_LBL = `You've selected this physician as new primary care provider`;
export const I_AM_CURRENTLY_SEEING_THIS_PROVIDER = "I'm currently seeing this provider";
export const I_WANT_TO_APPLY_THIS_CHANGE_FOR_ALL_MEMBERS_LBL = `I want to apply this change for all my family members`;
export const IF_YOU_ARE_ENROLLING_IN_COVERAGE_LBL_MSG = ` If you're enrolling in coverage for a January 1st start date, your selection will
take effect on January 1st.`;
export const VERIFY_THAT_PATIENT_INFORMATION_MAILING_ADDRESS_CORRECT = `Verify that patient information and mailing address is correct`;
export const MAILING_ADDRESS = `Mailing Address`;
export const OUR_RECORDS_SHOW_THAT_THIS_IS_CURRENT_ADDRESS_MSG = `Our records show that this is your current address. This is where we will mail your ID
card.`;
export const BEST_METHOD_TO_CONTACT_YOU = 'Best method to contact you';
export const BEST_TIME_TO_CALL_YOU = 'Best time to call you';
export const IF_A_CUSTOMER_SELECT_REP_NEEDS_TO_CALL_YOU =
  '(If a customer select rep needs to call you, please select up to 3 time periods they can call you back)';
export const ADDRESS_LBL = 'Address';
export const PLEASE_SELECT_APPROPRIATE_RESPONSE_BELOW_LBL = 'Please select the most appropriate response below';
export const ALL_DATA_IS_CORRECT = 'All data above is correct';
export const SOME_OR_ALL_OF_THE_INFORMATION_ABOVE_HAS_CHANGED_LBL = ' Some or all of the information above has changed';
export const MEMBER_ID = 'Member Id';
export const TELEPHONE_NUMBER_LBL = 'Telephone Number';
export const MOBILE_LBL = 'Mobile';
export const TYPE_LBL = 'Type';
export const NEW_YOUR_CITY_LBL = 'New your city';
// DashBoard User Info
export const NAME_LBL = 'Name';
export const MY_PRIMARY_LBL = 'My Primary';
export const CARE_PROVIDER_LBL = 'Care Provider';
export const HIDE_LBL = 'Hide';
export const SHOW_LBL = 'Show';

// contact number
export const CONTACT_PHONE_NUMBERS = 'Contact Phone Numbers';
export const FOR_MEMBERS_LBL = 'For Members';
export const FOR_PROVIDERS_LBL = 'For Providers';

// oopcs

export const FIND_CARE_AND_COST = 'Find Care and Cost';
export const PLAN_BALANCE_LBL = 'Plan Balance';
export const REQUEST_SUBMITTED_MSG_TITLE = 'Your request has been submitted';
export const PHARMACY_LBL = 'Pharmacy';

// Account Setting

export const REMOVE_PHOTO_LBL = 'Remove Photo';
export const PLEASE_CONTACT_THE_LBL = 'Please contact the';
export const CANOPY_HEALTH_CONTACT_CENTER_TXT = 'Canopy Health Contact Center';

export const GENERIC_SECTION_TITLE = 'Generic Section Title';
export const ACCESS_EXTERNAL_SITE_TITLE_TXT = 'Access External Site';

// export const NON_AUTHENTICATED_MENU = [{}]

export enum NavMenuEnum {
  WEIGHT = 'weight',
  DASHBOARD_MODULES = 'dashboard',
  COST_MODULES = 'cost',
  ACCOUNT_MODULES = 'account',
  DOCTOR_MODULES = 'doctor',
  HEALTHPLAN_MODULES = 'healthplan',
  OOP_MODULES = 'oop',
  VISIT_MODULES = 'visit',
  CUSTOM_MODULES = 'custom',
  GRIEVANCE_MODULES = 'grievances',
  NOTIFICATION_MODULES = 'notification',
  USING_BENEFITS_MODULES = 'using-my-benefits',

  HOME = 'HOME',
  COST_ESTIMATOR = 'COST ESTIMATOR',
  ACCOUNT_SETTING = 'ACCOUNT SETTINGS',
  HEALTH_PLAN_TEXT = 'MY HEALTH PLAN',
  OUT_OF_POCKET_COST = 'OUT OF POCKET COST',
  VERTUAL_VISIT = 'VIRTUAL VISITS',
  DOCTORS_AND_SERVICES = 'DOCTORS & SERVICES',
  NOTIFICATION = 'NOTIFICATIONS',
  GRIEVANCES = 'GRIEVANCES & APPEALS',
  USING_MY_BENEFITS_NOTIFICATION = 'USING MY BENEFITS',
}

export enum BrandingLocationEnum {
  LOCATION_ONE = '1',
  LOCATION_TWO = '2',
  LOCATION_THREE = '3',
  LOCATION_FOUR = '4',
}

export const MEMBER_NAME_LBL = 'Member Name';
export const MEMBER_NUMBER_LBL = 'Member Number';
export const MEMBER_ID_LBL = 'Member ID';
export const SUBSCRIBER_NAME_LBL = 'Subscriber Name';
export const SUBSCRIBER_NUMBER_LBL = 'Subscriber Number';
export const HEALTH_PLAN_NUMBER_LBL = 'Health Plan Number';
export const GROUP_NAME_LBL = 'Group Name';
export const GROUP_NUMBER_LBL = 'Group Number';
export const EFFECTIVE_DATE_LBL = 'Effective Date';
export const RERATE_MONTH_LBL = 'Rerate Month';
export const PLAN_TYPE_LBL = 'Plan Type';
export const PAYER_ID_LBL = 'Payer ID';
export const IPA_NAME_LBL = 'IPA Name';
export const MEDICAL_GROUP_NAME = 'Medical Group Name';
export const HEALTH_PLAN_NAME_LBL = 'Health Plan Name';

// My Health Plan
export const PLEASE_VERIFY_PATIENT_INFO_AND_MAILING_ADDRESS_CORRECT =
  'Please verify that patient information and mailing address are correct';
export const PATIENT_INFO_TEXT = 'Patient Information';
export const SUBMIT_REQUEST = 'Submit Request';
export const NO_NOTIFICATION_MSG = `You don't have any notifications yet`;

export enum LOCATE_ACCOUNT_ENUM {
  COUNT_FOUR = '4',
}

export const SELECT_A_PCP = 'Select A PCP';
export const SELECT_PRIMARY_CARE_PROVIDER = 'Select Primary Care Provider';
// export const PCP_SELECTION_IS_IN_PROGRESS = 'Your PCP selection is in progress';
export const PCP_SELECTION_IS_IN_PROGRESS = (date: any): any => {
  return (
    <>
      You have submitted a request to change your primary care provider on {dateFormatter(date)}. You will receive a new
      health plan ID card once your request has been processed. It may take up to 30 days for your new primary care
      provider to display on MyCanopyHealth.
    </>
  );
};
// export const PCP_SELECTION_PROGRESS_INFO =
//   'This action may take a 5-10 days to get applied. Check this same message later.';
export const PCP_SELECTION_PROGRESS_INFO = (number: any): any => {
  return <>If you have any questions please call customer service {number}.</>;
};
export const SELECT_A_PCP_NOT_SELECTED_INFO =
  ' You have not yet selected a primary Care Provider. You can use the "Select A PCP" link below to make your choice';
export const PLAN_NOT_EFFECTIVE = ' Your Plan is not yet effective - some features may not be available yet.';
export const WELCOME_TOHELATH_PCP_MSG =
  '  Welcome to MyCanopyHealth- your one-stop destination to manage your care, coverage, and netwrok';
export const HI_TEXT = 'Hi';

export enum DoctorsHealthPlans {
  DOCTORS_PLAN = 'Canopy Health - UnitedHealthcare Doctors Plan',
  HARMONY_PLAN = 'Canopy Health - UnitedHealthcare SignatureValue Harmony',
  HARMONY_ADVANTAGES = 'Canopy Health - UnitedHealthcare SignatureValue Advantage',
}

export enum SessionStorageKeys {
  UserId = 'userId',
  User = 'user',
  HealthPlan = 'healthPlan',
  UserInfo = 'userInfo',
  AccessToken = 'access_token',
  RefreshToken = 'refresh_token',
  SessionToken = 'session_token',
  LoggedIn = 'logged_in',
  AmwellToken = 'amwellToken',
  WelcomeMessageCloseCount = 'welcome_message_count',
  VVMessageClosed = 'vv_welcome_message',
  OktaUserId = 'oktaUserId',
}

export enum Timeouts {
  logoutIdleTime = 1000 * 60 * 60 * 2,
  logoutIdlePromptTime = 20000,
  GetNotificationsTime = 30000,
}

export enum Limits {
  Page = '1',
  limit = '150',
}

export const TERMS_CONDITION_TITLE = 'Terms and Condition';

export const CVV_INFO = `"Your credit card's security code (CVV code) can be found as a three-digit code on the back of your Visa/MasterCard or a four-digit code on the front of your American Express card.`;

export const CVV_ERROR = `Enter a 4 digit CVV for American Express or a 3 digit CVV
for all other cards.`;

export enum VIRTUAL_VISIT {
  GETTING_STARTED = 1,
  VISIT_INFO = 2,
  ALERGIES_CONDITION = 3,
  MEDICATION_VITALS = 4,
  PAYMENT = 5,
  WAITINGROOM = 6,
  VideoApp = 7,
  SUMMARY = 8,
}

export const DEFAULT_SELECTED_CONSUMER = 'visitForMe';

export const DIGNITYLOGO = 'DIGNITY';
export const MERITAGELOGO = 'MERITAGE';
export const HILLLOGO = 'HILL';
export const PROVIDENCELOGO = 'PROVIDENCE';

export const VERIFY_YOUR_MAILING_ADDRESS = 'Verify your mailing address';
export const VERIFY_YOUR_MAILING_ADDRESS_INFO =
  'You will receive a new health plan ID card in the mail. Please verify that the mailing address is up to date.';
export const VERIFY_YOUR_MAILING_ADDRESS_SUB_INFO =
  'You can update your existing mailing address on file or add a temporary address.';

export const schedule1 = {
  Monday: '8 AM-5 PM',
  Tuesday: '8 AM-5 PM',
  Wednesday: '8 AM-5 PM',
  Thursday: '8 AM-5 PM',
  Friday: '8 AM-5 PM',
};

export const schedule2 = {
  Monday: '1 PM-5 PM',
  Tuesday: 'Closed',
  Wednesday: 'Closed',
  Thursday: 'Closed',
  Friday: 'Closed',
};

export const schedule3 = {
  Monday: '8 AM-5 PM',
  Tuesday: '8 AM-5 PM',
  Wednesday: '8 AM-5 PM',
  Thursday: 'Closed',
  Friday: '8 AM-5 PM',
};
export const schedule4 = {
  Monday: '9 AM-5 PM',
  Tuesday: '9 AM-5 PM',
  Wednesday: '9 AM-5 PM',
  Thursday: '9 AM-5 PM',
  Friday: '9 AM-5 PM',
};
export const schedule5 = {
  Monday: '8 AM-8 PM',
  Tuesday: '8 AM-8 PM',
  Wednesday: '8 AM-8 PM',
  Thursday: '8 AM-8 PM',
  Friday: '8 AM-8 PM',
};
export const schedule6 = {
  Monday: 'Closed',
  Tuesday: 'Closed',
  Wednesday: '8 AM-5 PM',
  Thursday: '8 AM-5 PM',
  Friday: 'Closed',
};
export const schedule7 = {
  Monday: 'Not Available',
  Tuesday: 'Not Available',
  Wednesday: 'Not Available',
  Thursday: 'Not Available',
  Friday: 'Not Available',
};
export const schedule8 = {
  Monday: '8:30 AM-5 PM',
  Tuesday: '8:30 AM-5 PM',
  Wednesday: '8:30 AM-5 PM',
  Thursday: '8:30 AM-5 PM',
  Friday: '8:30 AM-5 PM',
};
