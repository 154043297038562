import React, { PureComponent } from 'react';

import Auth from '../../utils/Auth';
import ComfirmationModalWithTwoButtons from '../ModalWithTwoButtons/ModalWithTwoButtons';
import ConfirmationModal from '../confirmationModal/ComfirmationModal';
import images from '../../utils/Images';
import Analytics, { FA } from '../../utils/Analytics';
import { ACCESS_EXTERNAL_SITE_TITLE_TXT, PHONE_NUMBER_BOLD } from '../../constant/AppConstants';
import { NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG } from '../../constant/AppMessages';

interface ownProps {
  data: any;
}

interface ownState {
  linkUrl: any;
  openModal: boolean;
  showNumber: boolean;
  phoneNumber?: string;
}

class GenericLinkCard extends PureComponent<ownProps, ownState> {
  constructor(props: ownProps) {
    super(props);
    this.state = {
      linkUrl: null,
      openModal: false,
      showNumber: false,
    };
  }

  setUrl = (url: string): void => {
    let uri = url;
    const sessionToken = Auth.getSessionToken();
    if (sessionToken) {
      if (uri.endsWith('/')) {
        uri = uri.replace(/\/\s*$/, '');
        uri = `${uri}?sessionToken=${Auth.getSessionToken()}`;
      } else {
        const path = uri.split('/').pop();
        if (path?.includes('?')) {
          uri = `${uri}&sessionToken=${Auth.getSessionToken()}`;
        } else {
          uri = `${uri}?sessionToken=${Auth.getSessionToken()}`;
        }
      }
    }
    this.setState({ linkUrl: url, openModal: true });
  };

  checkIfPhoneNumber = (text: string): boolean => {
    const num = text.replace(/ /g, '').replace(new RegExp('-', 'g'), '');
    return /^\d+$/.test(num);
  };

  render(): JSX.Element {
    const { data } = this.props;
    const { linkUrl, openModal, showNumber, phoneNumber } = this.state;

    return (
      <>
        <div className='d-flex flex-column justify-content-between h-100' key={`${data.text}link`}>
          <div
            className='link-card col-12 no-pad-lr pb-0'
            style={data?.contentColor ? { borderColor: data?.borderColor } : {}}>
            <div className=''>
              <h3>{data?.title}</h3>
              <p className='p-content' style={data?.contentColor ? { color: data?.contentColor } : {}}>
                {data?.content}
              </p>
            </div>
          </div>
          {data?.url && data?.text && (
            <div className='row mb-4'>
              <div
                role='presentation'
                className='link mt-0 px-0 generic-links d-flex mt-3'
                onClick={(e) => {
                  Analytics.logEvent(FA.APPLICATION_ALERT, {
                    title: ACCESS_EXTERNAL_SITE_TITLE_TXT,
                    description: NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG,
                  });
                  if (this.checkIfPhoneNumber(data?.text)) {
                    this.setState({ phoneNumber: data?.text, showNumber: true });
                  } else {
                    this.setUrl(data?.url);
                  }
                }}>
                <img
                  alt='Deep links'
                  className='clickable arrow img-fluid icon-l mt-1'
                  width={11}
                  height={11}
                  src={images.icDeeplink}
                  srcSet={`${images.icDeeplink}, ${images.icDeeplink2x} 2x, ${images.icDeeplink3x} 3x`}
                />
                <span className='d-inline-block ps-2' style={{ fontSize: '12px' }}>
                  {data?.text}
                </span>
              </div>
            </div>
          )}
          {openModal && (
            <ComfirmationModalWithTwoButtons
              title={ACCESS_EXTERNAL_SITE_TITLE_TXT}
              message={NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG}
              showModal={openModal}
              closeModal={() => this.setState({ openModal: false })}
              linkUrl={linkUrl}
              action={() => this.setState({ openModal: false })}
              btn1Title='Yes'
              btn2Title='No'
              additionalProps='external-link'
            />
          )}
          <ConfirmationModal
            title='Phone Number'
            message={PHONE_NUMBER_BOLD(phoneNumber)}
            showModal={showNumber}
            closeModal={() => this.setState({ showNumber: false })}
          />
        </div>
      </>
    );
  }
}
export default GenericLinkCard;
