import { ReactComponent as CanopyMarkerSVG } from '../../../../../../../assets/images/svg/map-marker.svg';
import './CanopyMarker.scss';

interface CanopyMarkerProps {
  numberOfPoints: number;
}

const CanopyMarker = ({ numberOfPoints }: CanopyMarkerProps): JSX.Element => (
  <div className='map-marker-container'>
    <CanopyMarkerSVG />
    <span className='number-of-points'>{numberOfPoints}</span>
  </div>
);

export default CanopyMarker;
