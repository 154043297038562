// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-view-container {
  position: relative;
  display: flex;
  min-height: 500px;
  --map-fraction: 30;
  --map-left-panel-fraction: 11;
  --map-right-panel-fraction: (var(--map-fraction) - var(--map-left-panel-fraction));
  --map-side-bar-width: calc((100% / var(--map-fraction)) * var(--map-left-panel-fraction));
  --map-container-width-small: calc((100% / var(--map-fraction)) * var(--map-right-panel-fraction));
}
.map-view-container .sidebar-container {
  position: relative;
  display: flex;
  width: 100%;
}
.map-view-container .sidebar-container .sidebar-tab {
  position: absolute;
  top: 220px;
  cursor: pointer;
  z-index: 10;
  background-color: transparent;
}
.map-view-container .sidebar-container .sidebar-tab.open {
  left: var(--map-side-bar-width);
}
@media (max-width: 567px) {
  .map-view-container .sidebar-container .sidebar-tab.open {
    left: 0;
  }
}
.map-view-container .map-container {
  width: 100%;
  height: 500px;
  position: absolute;
  right: 0px;
}
.map-view-container .map-container.small {
  width: var(--map-container-width-small);
}`, "",{"version":3,"sources":["webpack://./src/components/screens/doctorsAndServices/doctors/MapView/MapView.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,iBAAA;EAEA,kBAAA;EACA,6BAAA;EACA,kFAAA;EACA,yFAAA;EACA,iGAAA;AAAF;AAEE;EACE,kBAAA;EACA,aAAA;EACA,WAAA;AAAJ;AAEI;EACE,kBAAA;EACA,UAAA;EACA,eAAA;EACA,WAAA;EAEA,6BAAA;AADN;AAGM;EACE,+BAAA;AADR;AAEQ;EAFF;IAGI,OAAA;EACR;AACF;AAIE;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;AAFJ;AAII;EACE,uCAAA;AAFN","sourcesContent":[".map-view-container {\n  position: relative;\n  display: flex;\n  min-height: 500px;\n\n  --map-fraction: 30;\n  --map-left-panel-fraction: 11;\n  --map-right-panel-fraction: (var(--map-fraction) - var(--map-left-panel-fraction));\n  --map-side-bar-width: calc((100% / var(--map-fraction)) * var(--map-left-panel-fraction));\n  --map-container-width-small: calc((100% / var(--map-fraction)) * var(--map-right-panel-fraction));\n\n  .sidebar-container {\n    position: relative;\n    display: flex;\n    width: 100%;\n\n    .sidebar-tab {\n      position: absolute;\n      top: 220px;\n      cursor: pointer;\n      z-index: 10;\n\n      background-color: transparent;\n\n      &.open {\n        left: var(--map-side-bar-width);\n        @media (max-width: 567px) {\n          left: 0;\n        }\n      }\n    }\n  }\n\n  .map-container {\n    width: 100%;\n    height: 500px;\n    position: absolute;\n    right: 0px;\n\n    &.small {\n      width: var(--map-container-width-small);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
