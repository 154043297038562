/* eslint-disable no-unused-vars */
import { AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { useEffect } from 'react';
import useMapContext from '../hooks/useMapContext';
import { Cluster as ClusterType, Point } from '../types';
import CanopyMarker from './CanopyMarker/CanopyMarker';

interface ClustersProps {
  clusters: ClusterType[];
  onClusterClick: (points: Point[]) => void;
}

const Clusters = ({ clusters, onClusterClick }: ClustersProps): JSX.Element => {
  const map = useMap();
  const { map: mapContext, setMap } = useMapContext();

  useEffect(() => {
    if (!map || mapContext) {
      return;
    }
    setMap(map);
  }, [map, mapContext, setMap]);

  return (
    <>
      {clusters.map(({ points, id, location }) => (
        <AdvancedMarker
          key={id}
          position={location}
          onClick={() => {
            onClusterClick(points);
          }}>
          <CanopyMarker numberOfPoints={points.length} />
        </AdvancedMarker>
      ))}
    </>
  );
};

export default Clusters;
