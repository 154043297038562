import { useEffect } from 'react';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import Login from '../accounts/login/Login';
import NonDiscrimination from '../accounts/nonDiscrimination/NonDiscrimination';
import Privacy from '../accounts/privacy/Privacy';
import TermsAndCondition from '../accounts/termaAndConditions/TermaAndCondition';
import GuestLayout from '../layouts/guestLayout/GuestLayout';
import AuthorizedLayout from '../layouts/protectedLayout/ProtectedLayout';
import CustomGenricLinks from '../screens/customGenericLinks/CustomGenricLinks';
import DoctorsPlan from '../screens/doctorsAndServices/DoctorsPlan';
import Doctors from '../screens/doctorsAndServices/doctors/Doctors';
import ReportInaccuraciesDoctor from '../screens/doctorsAndServices/doctors/ReportInaccuracies';
import GenericLinksDS from '../screens/doctorsAndServices/genericLinks/GenericLinks';
import Hospitals from '../screens/doctorsAndServices/hospitals/Hospitals';
import ReportInaccuraciesService from '../screens/doctorsAndServices/services/ReportInaccuracies';
import Services from '../screens/doctorsAndServices/services/Services';
import ReportInaccuraciesUrgentCare from '../screens/doctorsAndServices/urgentCare/ReportInaccuracies';
import UrgentCare from '../screens/doctorsAndServices/urgentCare/UrgentCare';
import {
  APP_HOME_URL,
  DOCTORS_PLAN,
  DOCTORS_SERVICES_SERVICES,
  DOCTOR_REPORT_INACCURACIES,
  DOCTOR_SERVICES_DOCTOR,
  DOCTOR_SERVICES_HOSPITALS,
  DOCTOR_URGENT_CARE,
  GENERIC_LINKS_DS_URL,
  GENERIC_LINKS_URL,
  NONDISCRIMINATION_URL,
  PRIVACY_URL,
  SERVICES_REPORT_INACCURACIES,
  SSO_ROUTING,
  TERMS_AND_CONDITION_URL,
  URGENT_CARE_REPORT_INACCURACIES,
  VANITY_DOCTORS_URL_1,
  VANITY_DOCTOR_URL_2,
  VANITY_DOCTOR_URL_3,
  VANITY_DOCTOR_URL_4,
  VANITY_DOCTOR_URL_5,
  VANITY_DOCTOR_URL_6,
  VANITY_DOCTOR_URL_7,
  VANITY_SERVICES_URL_1,
  VANITY_SERVICES_URL_2,
  VANITY_SERVICES_URL_3,
  VANITY_SERVICES_URL_4,
  VANITY_SERVICES_URL_5,
  VANITY_SERVICES_URL_6,
} from '../shared/constant/Urls';
import Auth from '../shared/utils/Auth';
import AppRoute from './guestRoute/GuestRoute';
import { ProtectedRoute } from './protectedRoute/ProtectedRoute';

const Routes = (): any => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.hash.includes('#!/sso_routing')) {
      const newLocation = location.hash.replace('#!/', '');
      history.push(newLocation);
    } else if (location.search.includes('fromLogin')) {
      history.push(`${SSO_ROUTING}${location.search}`);
    }
  }, [history, location.hash, location.search]);

  return (
    <Switch>
      {!Auth.isAuthenticated() && (
        <AppRoute layout={GuestLayout} exact path={APP_HOME_URL} component={Login} pageTitle='Canopy Health' />
      )}
      <AppRoute
        component={Doctors}
        exact
        path={DOCTOR_SERVICES_DOCTOR}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />

      <AppRoute
        component={Doctors}
        exact
        path={`${DOCTOR_SERVICES_DOCTOR}?categoryName=:categoryName`}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />

      <Redirect from={VANITY_DOCTORS_URL_1} to={`${DOCTOR_SERVICES_DOCTOR}?categoryName=CanopyCare`} />
      <Redirect from={VANITY_DOCTOR_URL_2} to={`${DOCTOR_SERVICES_DOCTOR}?categoryName=SignatureValue Harmony`} />
      <Redirect from={VANITY_DOCTOR_URL_3} to={`${DOCTOR_SERVICES_DOCTOR}?categoryName=Blue & Gold`} />
      <Redirect from={VANITY_DOCTOR_URL_4} to={`${DOCTOR_SERVICES_DOCTOR}?categoryName=SmartCare`} />
      <Redirect from={VANITY_DOCTOR_URL_5} to={`${DOCTOR_SERVICES_DOCTOR}?categoryName=Medicare Advantage`} />
      <Redirect from={VANITY_DOCTOR_URL_6} to={`${DOCTOR_SERVICES_DOCTOR}?categoryName=SignatureValue Advantage`} />
      <Redirect from={VANITY_DOCTOR_URL_7} to={`${DOCTOR_SERVICES_DOCTOR}?categoryName=Doctors Plan`} />
      <Redirect from={VANITY_SERVICES_URL_1} to={`${DOCTORS_SERVICES_SERVICES}?categoryName=CanopyCare`} />
      <Redirect from={VANITY_SERVICES_URL_2} to={`${DOCTORS_SERVICES_SERVICES}?categoryName=SignatureValue Harmony`} />
      <Redirect from={VANITY_SERVICES_URL_3} to={`${DOCTORS_SERVICES_SERVICES}?categoryName=Blue & Gold`} />
      <Redirect from={VANITY_SERVICES_URL_4} to={`${DOCTORS_SERVICES_SERVICES}?categoryName=SmartCare`} />
      <Redirect from={VANITY_SERVICES_URL_5} to={`${DOCTORS_SERVICES_SERVICES}?categoryName=Medicare Advantage`} />
      <Redirect
        from={VANITY_SERVICES_URL_6}
        to={`${DOCTORS_SERVICES_SERVICES}?categoryName=SignatureValue Advantage`}
      />
      <AppRoute
        component={ReportInaccuraciesDoctor}
        exact
        path={`${DOCTOR_REPORT_INACCURACIES}/:id`}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute
        component={Services}
        exact
        path={DOCTORS_SERVICES_SERVICES}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute
        component={Hospitals}
        exact
        path={DOCTOR_SERVICES_HOSPITALS}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute component={UrgentCare} exact path={DOCTOR_URGENT_CARE} layout={GuestLayout} pageTitle='Canopy Health' />
      <AppRoute
        component={Services}
        exact
        path={DOCTORS_SERVICES_SERVICES}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute
        component={ReportInaccuraciesService}
        exact
        path={`${SERVICES_REPORT_INACCURACIES}/:id`}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute
        component={ReportInaccuraciesUrgentCare}
        exact
        path={`${URGENT_CARE_REPORT_INACCURACIES}/:id`}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute component={UrgentCare} exact path={DOCTOR_URGENT_CARE} layout={GuestLayout} pageTitle='Canopy Health' />
      <ProtectedRoute
        component={CustomGenricLinks}
        exact
        path={GENERIC_LINKS_URL}
        layout={AuthorizedLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute
        component={GenericLinksDS}
        exact
        path={`${GENERIC_LINKS_DS_URL}/:name`}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute
        component={TermsAndCondition}
        exact
        path={TERMS_AND_CONDITION_URL}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute
        component={TermsAndCondition}
        exact
        path={`${TERMS_AND_CONDITION_URL}/:id`}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute
        component={NonDiscrimination}
        exact
        path={NONDISCRIMINATION_URL}
        layout={GuestLayout}
        pageTitle='Canopy Health'
      />
      <AppRoute component={Privacy} exact path={PRIVACY_URL} layout={GuestLayout} pageTitle='Canopy Health' />

      {/* virtual visit */}
      <AppRoute layout={GuestLayout} exact path={DOCTORS_PLAN} component={DoctorsPlan} pageTitle='Canopy Health' />
    </Switch>
  );
};

export default Routes;
