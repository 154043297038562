/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';
import useMapContext from '../hooks/useMapContext';
import { AreaType } from '../types';
import CaliforniaPlacesIDs from './CaliforniaPlacesIDs';

type PlacesService = google.maps.places.PlacesService;
type FeatureType = google.maps.FeatureType;

interface PolygonsProps {
  areas: string[];
  areaType: AreaType;
  onFeatureClick: (placeId: string) => void;
}

const CountyHighlight = {
  strokeColor: '#00A3E1',
  strokeOpacity: 1.0,
  strokeWeight: 1.0,
  fillColor: '#FFFFFF',
  fillOpacity: 0.5,
};

const ZipHighlight = {
  strokeColor: 'transparent',
  strokeOpacity: 1.0,
  strokeWeight: 1.0,
  fillColor: 'transparent',
  fillOpacity: 0.5,
};

const ZipHighlightZipSearch = {
  strokeColor: '#E74235',
  strokeOpacity: 1.0,
  strokeWeight: 1.0,
  fillColor: '#FFFFFF',
  fillOpacity: 0.5,
};

const ShadowLayer = {
  fillColor: '#D0D0CE',
  fillOpacity: 0.4,
};

const Polygons = ({ areas, areaType, onFeatureClick }: PolygonsProps): JSX.Element => {
  const { isZipSearch } = useMapContext();
  const map = useMap();
  const placesLib = useMapsLibrary('places');
  const [placesService, setPlacesService] = useState<PlacesService | null>(null);

  useEffect(() => {
    if (!placesLib || !map) return;

    const temp = new placesLib.PlacesService(map);
    setPlacesService(temp);
  }, [map, placesLib]);

  useEffect(() => {
    if (!placesService || !map || areaType === AreaType.Individual) {
      return;
    }

    const areasPlaceId: string[] = [];

    areas.forEach((area) => {
      const request = {
        query: `${area} ${areaType === AreaType.County ? 'County' : ''}`,
        fields: ['place_id', 'geometry'],
      };

      if (CaliforniaPlacesIDs[area] === undefined) {
        placesService.findPlaceFromQuery(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (results?.length && results[0].place_id) {
              areasPlaceId.push(results[0].place_id);
              const placeId = results[0].place_id;
              const center = results[0].geometry?.location?.toJSON() || new google.maps.LatLng(0, 0).toJSON();
              const viewport = results[0].geometry?.viewport?.toJSON() || new google.maps.LatLngBounds().toJSON();
              CaliforniaPlacesIDs[area] = { placeId, center, viewport };
            }
          }
        });
      } else {
        areasPlaceId.push(CaliforniaPlacesIDs[area].placeId);
      }
    });

    const featureLayerCounty = map.getFeatureLayer('ADMINISTRATIVE_AREA_LEVEL_2' as FeatureType);
    const featureLayerZip = map.getFeatureLayer('POSTAL_CODE' as FeatureType);
    const currentFeatureLayer = areaType === AreaType.County ? featureLayerCounty : featureLayerZip;
    const oldFeatureLayer = areaType === AreaType.County ? featureLayerZip : featureLayerCounty;

    currentFeatureLayer.style = (params) => {
      // @ts-ignore
      if (areasPlaceId.includes(params.feature.placeId)) {
        // @ts-ignore
        // eslint-disable-next-line no-nested-ternary
        return areaType === AreaType.County ? CountyHighlight : isZipSearch ? ZipHighlightZipSearch : ZipHighlight;
      }
      return ShadowLayer;
    };

    oldFeatureLayer.style = null;

    const featureClickListener = currentFeatureLayer.addListener('click', (event: any) => {
      const placeId = event?.features[0].placeId;
      if (placeId && areasPlaceId.includes(placeId)) {
        onFeatureClick(placeId);
      }
    });

    return () => featureClickListener.remove();
  }, [map, placesService, areaType, areas, onFeatureClick]);

  return <div />;
};

export default Polygons;
