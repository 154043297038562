import * as actions from '@canopy/shared/lib';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  ADDRESS1,
  ADDRESS2,
  CANCEL,
  CITY,
  EMAIL_ADDRESS,
  FAX_NUMBER_LABLE,
  HEALTH_PLAN,
  NPI_LABLE,
  PHONE_NUMBER,
  REPORT_INACCURACIES_LABLE,
  REPORT_INACCURACIES_THANK_YOU_TEXT,
  SAVE,
  STATE,
  SUGGEST_INACCURACIES_TEXT,
  VENDOR_NAME,
  WEBSITE_LABLE,
  ZIP_CODE,
} from '../../../shared/constant/AppConstants';
import ConfirmationModal from '../../../shared/sharedComponents/confirmationModal/ComfirmationModal';
import Auth from '../../../shared/utils/Auth';
import { hideLoader, showLoader } from '../../../shared/utils/ReusableComponents';

interface ownProps {
  match: any;
}

interface ownState {
  email: string;
  vendorName: string;
  website: string;
  healthPlan: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  faxNumber: string;
  npi: string;
  saveError: boolean;
  saveSuccess: boolean;
}

interface DispatchProps {
  createGetReportInaccuraciesRequest: typeof actions.createGetReportInaccuraciesRequest;
  createReportInaccuraciesRequest: typeof actions.createReportInaccuraciesRequest;
}

interface stateProps {
  data: any;
}

type props = ownProps & DispatchProps & stateProps & RouteComponentProps;

class ReportInaccuraciesUrgentCare extends PureComponent<props, ownState> {
  constructor(props: props) {
    super(props);
    this.state = {
      email: '',
      vendorName: '',
      website: '',
      healthPlan: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      phone: '',
      faxNumber: '',
      npi: '',
      saveSuccess: false,
      saveError: false,
    };
  }

  componentDidMount(): any {
    const { createGetReportInaccuraciesRequest, match } = this.props;
    const { id } = match.params;
    createGetReportInaccuraciesRequest(
      { serviceName: 'Urgentcare', recordId: id, isAuthenticated: Auth.isAuthenticated() },
      (res: any) => {
        if (res.isSuccess) {
          const { data } = res;
          this.setState({
            npi: data?.npi,
            phone: data?.phone,
            city: data?.address?.city,
            state: data?.address?.state,
            zipcode: data?.address?.zip,
            address1: data?.address?.street1,
            address2: data?.address?.street2,
            faxNumber: data?.fax,
            website: data?.url,
            healthPlan: data?.nameOfNetwork.join(', '),
            vendorName: data?.parentVendorName,
          });
        }
      }
    );
  }

  handleChange = (e: any): void => {
    const change: any = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  handleSave = (): any => {
    const { createReportInaccuraciesRequest } = this.props;
    const { email } = this.state;
    if (actions.Validators.validateEmail(email)) {
      showLoader();
      const data = this.state;
      createReportInaccuraciesRequest({ data, isAuthenticated: Auth.isAuthenticated() }, (res: any) => {
        if (res.isSuccess) {
          hideLoader();
          this.setState({ saveSuccess: true });
        } else {
          hideLoader();
          this.setState({ saveError: true });
        }
      });
    } else {
      toast.error('Please enter a valid email address');
    }
  };

  render(): React.ReactElement {
    const {
      email,
      vendorName,
      website,
      healthPlan,
      address2,
      address1,
      city,
      zipcode,
      state,
      phone,
      faxNumber,
      npi,
      saveSuccess,
      saveError,
    } = this.state;
    const { data } = this.props;
    return (
      <>
        <div id='report-inaccuracies-container'>
          <div className='row'>
            <div className='col-12'>
              <span className='title'>{REPORT_INACCURACIES_LABLE}</span>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 instructions margin-bottom-15'>{REPORT_INACCURACIES_THANK_YOU_TEXT}</div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='email'>{EMAIL_ADDRESS}</label>
                <input
                  type='email'
                  className='form-control'
                  placeholder='Enter Email Address'
                  name='email'
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <span className='title'>
                {data?.DoctorsAndServicesData?.getReportInaccuraciesResponse?.data?.displayName}
              </span>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 instructions margin-bottom-15'>{SUGGEST_INACCURACIES_TEXT}</div>
          </div>

          <div className='row mt-2'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='vendorName'>{VENDOR_NAME}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Vendor Name'
                  name='vendorName'
                  value={vendorName}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='website'>{WEBSITE_LABLE}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Website'
                  name='website'
                  value={website}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='col-lg-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='healthPlan'>{HEALTH_PLAN}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Health Plans'
                  name='healthPlan'
                  value={healthPlan}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='address1'>{ADDRESS1}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Address 1'
                  name='address1'
                  value={address1}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='address2'>{ADDRESS2}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Address 2'
                  name='address2'
                  value={address2}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='city'>{CITY}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter City'
                  name='city'
                  value={city}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='state'>{STATE}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter State'
                  name='state'
                  value={state}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='zipcode'>{ZIP_CODE}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Zip Code'
                  name='zipcode'
                  value={zipcode}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='phone'>{PHONE_NUMBER}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Phone Number'
                  name='phone'
                  value={phone}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <hr />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='faxNumber'>{FAX_NUMBER_LABLE}</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Fax Number'
                  name='faxNumber'
                  value={faxNumber}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <hr />
            </div>
          </div>
          <div className='background-popup margin-bottom-15'>
            <div className='row'>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='npi'>{NPI_LABLE}</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter NPI'
                    name='npi'
                    value={npi}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row text-align-right d-flex justify-content-end'>
            <div className='col-6 col-sm-3 col-lg-2 mb-2 mb-sm-0'>
              <button className='btn btn-report-cancel' type='button' onClick={() => window.close()}>
                {CANCEL}
              </button>
            </div>
            <div className='col-6 col-sm-3 col-lg-2'>
              <button className='btn btn-report-save' type='button' onClick={this.handleSave}>
                {SAVE}
              </button>
            </div>
          </div>
        </div>
        <ConfirmationModal
          title='Thank you!'
          showModal={saveSuccess}
          closeModal={() => this.setState({ saveSuccess: false }, () => window.close())}
          message={`The form has been submitted successfully. Click 'Ok' to close this tab and return back to the Doctors and Services search page.`}
        />
        <ConfirmationModal
          title={`Error Code: ${data?.DoctorsAndServicesData?.errorData?.data?.statusCode} - Error!`}
          showModal={saveError}
          closeModal={() => this.setState({ saveError: false })}
          message={data?.DoctorsAndServicesData?.reportInaccuraciesErrorMessage}
        />
      </>
    );
  }
}

const mapStateToProps: any = (state: actions.manageFilterDataState): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetReportInaccuraciesRequest: actions.createGetReportInaccuraciesRequest,
  createReportInaccuraciesRequest: actions.createReportInaccuraciesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportInaccuraciesUrgentCare);
