/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as actions from '@canopy/shared';
import clsx from 'clsx';
import { Children, cloneElement, isValidElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ACCEPTING_NEW_PATIENTS, NOT_ACCEPTING_NEW_PATIENT } from '../../../../../../shared/constant/AppConstants';
import { convertToLowerCase, getMiles } from '../../../../../../shared/helperMethods/HelperMethod';
import Auth from '../../../../../../shared/utils/Auth';
import Images from '../../../../../../shared/utils/Images';
import useIsDoctorCardLight from '../../hooks/useIsDoctorCardLight';
import { DoctorCardLight, DoctorDetails, DoctorWithDetails, DoctorWithLocations } from '../../types';
import './DoctorCard.scss';

const getDistance = (distance: number): string => {
  if (distance === 0) {
    return '0 miles away';
  }
  if (distance) {
    return getMiles(distance);
  }
  return 'Distance Not Available';
};

type DoctorCardProps = (DoctorCardLight | DoctorWithLocations) & {
  onClick: (doctorDetails: DoctorWithDetails) => void;
  selected: boolean;
  children?: React.ReactNode[];
};

const DoctorCard = ({ onClick, selected, children, ...doctor }: DoctorCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const [doctorDetails, setDoctorDetails] = useState<DoctorDetails>();
  const isLight = useIsDoctorCardLight(doctor);
  const { npi, firstName, middleName, lastName, typeOfLicensure, clinicName, distance, gender, acceptingNewPatients } =
    doctor;

  useEffect(() => {
    if (!isLight) {
      return;
    }

    setDoctorDetails(doctor.details);
  }, [doctor, isLight]);

  useEffect(() => {
    if (!npi || isLight) {
      return;
    }

    dispatch(
      actions.fetchProviderProfile({ query: { npi }, isAuthenticated: Auth.isAuthenticated() }, (response: any) => {
        const { data, isSuccess } = response;
        if (isSuccess && data.length > 0) {
          const { bio, video, lgbtq, pronoun } = data[0];
          setDoctorDetails((prev) => ({ ...prev, bio, video, lgbtq, pronoun }));
        }
      })
    );
    dispatch(
      actions.fetchProviderImage({ query: { npi }, isAuthenticated: Auth.isAuthenticated() }, (response: any) => {
        const { data, isSuccess } = response;
        if (isSuccess && data.bodyInfo) {
          const picture = `data:image/png;base64,${data.bodyInfo}`;
          setDoctorDetails((prev) => ({ ...prev, picture }));
        }
      })
    );
  }, [dispatch, isLight, npi]);

  const picture = useMemo(() => {
    const doctorPicture = doctorDetails?.picture;
    if (doctorPicture) {
      return doctorPicture;
    }

    return gender === 'Male' ? Images.avatarM : Images.avatarF;
  }, [doctorDetails?.picture, gender]);

  const childrenWithProps = useMemo(
    () =>
      Children.map(children, (child) => {
        if (isValidElement<DoctorCardLight>(child)) {
          return cloneElement(child, { details: doctorDetails });
        }
        return child;
      }),
    [children, doctorDetails]
  );

  return (
    <>
      <div
        className={clsx('doctor-card-container', { selected, light: isLight })}
        onClick={() => onClick({ ...doctor, ...doctorDetails })}>
        {!isLight && (
          <div className='doctor-avatar'>
            <img id={`${doctor._id}_profilepic`} className='avatar' src={picture} alt='Avatar' />
            {doctorDetails?.video && (
              <div className='watch-video-container'>
                <img className='play-button' src={Images.watchVideo} alt='' />
                <span className='watch-video-container-watch-video'>Watch Video</span>
              </div>
            )}
          </div>
        )}
        <div className='doctor-info'>
          {!isLight && (
            <div className='info-container'>
              <span className='doctor-card-container-name'>
                {firstName.toLowerCase()} {middleName?.toLowerCase()}{' '}
                <div className='last-name'>
                  {lastName.toLowerCase()}
                  <span>, </span> {typeOfLicensure}
                </div>
                {/* {`${firstName.toLowerCase()} ${middleName?.toLowerCase()} ${lastName.toLowerCase()}, ${typeOfLicensure}`} */}
              </span>
              {doctorDetails?.pronoun && <span className='provider-pronouns'>{doctorDetails.pronoun} </span>}
            </div>
          )}
          <div className='doctor-card-container-clinic'>
            <img className='marker-icon' src={Images.location} alt='' />
            <span className='clinic-name capitalized'>{convertToLowerCase(clinicName)}</span>
          </div>
          <div className='bottom-info'>
            <div className='new-patients-container'>
              {acceptingNewPatients ? (
                <>
                  <img className='marker-icon' src={Images.greenSuccess} alt='accepting new patients' />
                  <span className='new-patients'>{ACCEPTING_NEW_PATIENTS}</span>
                </>
              ) : (
                <>
                  <img className='marker-icon image14' src={Images.icListNocheck2x} alt='not accepting new patients' />
                  <span className='new-patients'>{NOT_ACCEPTING_NEW_PATIENT}</span>
                </>
              )}
            </div>
            {distance && <span className='miles-away'>{getDistance(distance)}</span>}
          </div>
          <div className='arrow-container'>
            <img src={Images.greaterThan} alt='open details' />
          </div>
        </div>
      </div>
      {childrenWithProps}
    </>
  );
};

export default DoctorCard;
