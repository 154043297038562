// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-marker-container {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.map-marker-container .number-of-points {
  position: absolute;
  top: 9px;
  color: #003c71;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/doctorsAndServices/doctors/MapView/Clusters/CanopyMarker/CanopyMarker.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,uBAAA;EAAA,kBAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAF;AAEE;EACE,kBAAA;EACA,QAAA;EACA,cAAA;EACA,eAAA;AAAJ","sourcesContent":[".map-marker-container {\n  position: relative;\n  width: fit-content;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .number-of-points {\n    position: absolute;\n    top: 9px;\n    color: #003c71;\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
