/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import _ from 'lodash';
import { API_KEY, staticMap } from '../../screens/doctorsAndServices/doctors/MapView/constants';
import { Address } from '../../screens/doctorsAndServices/doctors/MapView/types';
import {
  schedule1,
  schedule2,
  schedule3,
  schedule4,
  schedule5,
  schedule6,
  schedule7,
  schedule8,
} from '../constant/AppConstants';
import Images from '../utils/Images';

export const history = createBrowserHistory();

export function errorToastr(message: string): any {
  toast.error(message);
}

export const getShortNameOfWeekDays = (day: string): string => {
  return day.charAt(0) + day.toLowerCase().slice(1, 3);
};

export const getOfficeHours = (hoursOfOperation: any): any => {
  const day: any = [];
  if (hoursOfOperation?.length > 0) {
    hoursOfOperation?.forEach((element: any) => {
      if (element != null) {
        const obj = {
          day: '',
          time: '',
        };
        obj.day = getShortNameOfWeekDays(element.day);
        const string =
          `${element?.startHour > 12 ? `${Number(element?.startHour - 12)}` : element?.startHour}:` +
          `${Number(element?.startMinute) < 10 ? `0${element?.startMinute}` : element?.startMinute}` +
          `${element?.startHour > 12 ? 'PM' : 'AM'}` +
          `-` +
          `${element?.endHour > 12 ? `${Number(element?.endHour - 12)}` : element?.endHour}` +
          `:` +
          `${Number(element?.endMinute) < 10 ? `0${element?.endMinute}` : element?.endMinute}` +
          ` ${element?.endHour > 12 ? 'PM' : 'AM'}`;
        obj.time = string;
        day.push(obj);
      }
    });
  }
};

export const dateFormatter = (date: any): any => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(date));
};

export const weekDayFormatter = (date: any): any => {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(date));
};

export const dayFormatter = (date: any): any => {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
  }).format(new Date(date));
};

export const datetimeFormatter = (date: any): any => {
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(new Date(date));
};
export const timeFormatter = (date: any): any => {
  return new Intl.DateTimeFormat('en-US', {
    timeStyle: 'short',
  }).format(new Date(date));
};

// Sort Array of objects

export const dynamicSort = (property: any): any => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    // eslint-disable-next-line no-param-reassign
    property = property.substr(1);
  }
  return (a: any, b: any): any => {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    // eslint-disable-next-line no-nested-ternary
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const getMiles = (distance: number): string => {
  return `${Math.round(distance * 100) / 100} miles away`;
};

export const zipValidation = (zip: string): any => {
  const reg = /[^0-9]/g;

  return reg.test(zip);
};

export const zipCodeValidation = (): any => {
  const a = document.querySelector('.zip-input');
  if (a) {
    a.addEventListener('keypress', (evt: any) => {
      if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
        evt.preventDefault();
      }
    });
  }
};

export const unMaskPhone = (phone: any): any => {
  return phone.replace(/[^\d]/g, '');
};

export const formatPhone = (phone: string): any => {
  if (!phone) {
    return '';
  }
  if (phone && phone.indexOf('-') === -1) {
    return `(${phone.substring(0, 3)}) ${phone.substring(0, 3)}-${phone.substring(0, 3)}`;
  }
  const arr = phone.split('-');
  return `(${arr[0]}) ${arr[1]}-${arr[2]}`;
};
export const formatFax = (p: string): any => {
  if (p && p.indexOf('(') === -1) {
    return `(${p.substring(0, 3)}) ${p.substring(0, 3)}-${p.substring(0, 3)}`;
  }
  return p;
};
export const parseSchedule = (scheduleString: string): { [key: string]: string } => {
  const daysOfWeek: { [key: string]: string } = {
    M: 'Monday',
    T: 'Tuesday',
    W: 'Wednesday',
    Th: 'Thursday',
    F: 'Friday',
  };

  const scheduleArray = scheduleString?.split(',');

  let scheduleObject: { [key: string]: string } = {};

  scheduleArray?.forEach((item) => {
    const [day, time] = item.split(':');
    const fullDay = daysOfWeek[day];
    scheduleObject[fullDay] = time;
  });
  if (!Object.prototype.hasOwnProperty.call(scheduleObject, 'Monday')) {
    scheduleObject = schedule7;
  }
  return scheduleObject;
};

export const parsedTime = (time: string): any => {
  const t = time.split(' ').join(':');
  return new Date(`January 1, 2022 ${t}`).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });
};
export const formattedScheduleTime = (timeString: unknown): string => {
  const timePeriods = String(timeString).split('-');
  let formattedTime = timePeriods[0];
  if (timePeriods[1]) {
    formattedTime = `${parsedTime(timePeriods[0])} - ${parsedTime(timePeriods[1])}`;
  }
  return formattedTime;
};

export const convertDataURIToFile = (base64String: any, filename: string): any => {
  // Convert the dataURI to a base64 string

  // Convert the base64 string to an ArrayBuffer
  const buffer = atob(base64String);

  // Create a new file
  const file = new File([buffer], filename, { type: 'application/pdf' });

  // Return the file
  return file;
};

export const isJsonString = (str: string): any => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export const convertToLowerCase = (text: string): string => {
  if (text) {
    const words = text.split(' ');
    const convertedWords = words?.map((word) => {
      if (word.toUpperCase() !== 'UCSF' && word.toUpperCase() !== '(UCSF') {
        return word.toLowerCase();
      }
      return word;
    });
    return convertedWords.join(' ');
  }
  return '';
};

export const formattedSchedule = (hoursOfOperation: string): string | {} => {
  if (hoursOfOperation === 'Mon - Fri 8:00am - 5:00pm') {
    return schedule1;
  }
  if (hoursOfOperation === 'M:1 PM-5 PM,T:W:Th:F:') {
    return schedule2;
  }
  if (hoursOfOperation === 'Mon, Tue, Wed, Fri 08:00-05:00;') {
    return schedule3;
  }
  if (hoursOfOperation === 'Mon, Tue, Wed, Thu, Fri 09:00-05:00;') {
    return schedule4;
  }
  if (hoursOfOperation === 'Mon, Tue, Wed, Thu, Fri 08:00-08:00;') {
    return schedule5;
  }
  if (hoursOfOperation === 'WED:800 -1700, THU:800 -1700') {
    return schedule6;
  }
  if (hoursOfOperation === 'Mon - Fri 8:00am - 5:00pm') {
    return schedule1;
  }
  if (hoursOfOperation === 'M:8:30AM-5 PM,T:8:30AM-5 PM,W:8:30AM-5 PM,Th:8:30AM-5 PM,F:8:30AM-5 PM,') {
    return schedule8;
  }
  if (hoursOfOperation === 'MON:800 -1700, TUE:800 -1700, WED:800 -1700, THU:800 -1700, FRI:800 -1700') {
    return schedule1;
  }
  if (hoursOfOperation === '') {
    return {};
  }
  const parsedSchedule = parseSchedule(hoursOfOperation?.replace(/,\s*$/, ''));

  return parsedSchedule;
};

export const createPDFData = (data: any, id: any): any => {
  const allProviders = [...data.result, ...data.additional];
  const primaryLocation = allProviders.find((p: any) => p._id === id);
  const otherLocation = allProviders.filter((p: any) => p._id !== id);
  return {
    name: `${primaryLocation?.firstName?.toLowerCase()} ${primaryLocation?.middleName?.toLowerCase()} ${primaryLocation?.lastName?.toLowerCase()}, ${
      primaryLocation?.typeOfLicensure
    }`,
    pronoun: primaryLocation?.pronoun,
    medicalGroup: primaryLocation?.medicalGroup,
    specialty: primaryLocation?.specialty,
    clinicName: primaryLocation?.clinicName,
    phone: primaryLocation?.phone,
    gender: primaryLocation?.gender,
    acceptingNewPatients: primaryLocation?.acceptingNewPatients,
    npi: primaryLocation?.npi,
    nameOfNetwork: data?.result[0]?.nameOfNetwork,
    hoursOfOperationString: primaryLocation?.hoursOfOperation,
    boardCertifiedOrEligible: primaryLocation?.boardCertifiedOrEligible,
    hospitalPrivilegeStatus: primaryLocation?.hospitalPrivilegeStatus,
    hospitalNPI: primaryLocation?.hospitalNPI,
    caLicense: primaryLocation?.caLicense,
    providerLanguage: primaryLocation?.providerLanguage,
    telehealth: primaryLocation?.telehealth,
    hospital: primaryLocation?.hospital,
    hoursOfOperation: formattedSchedule(primaryLocation?.hoursOfOperation),
    primaryLocation: [
      {
        address: primaryLocation?.address,
        phone: primaryLocation?.phone,
        clinicName: primaryLocation?.clinicName,
      },
    ],
    additionalLocation: otherLocation?.map((l: any) => ({
      address: l?.address,
      phone: l?.phone,
      clinicName: l?.clinicName,
    })),
  };
};

export const createPDFDataForMapView = (data: any, id: any): any => {
  const allProviders = [data, ...data.otherLocations, ...data.outsideSearch];
  const primaryLocation = allProviders.find((p: any) => p._id === id);
  const otherLocation = allProviders.filter((p: any) => p._id !== id);
  return {
    name: `${primaryLocation?.firstName?.toLowerCase()} ${primaryLocation?.middleName?.toLowerCase()} ${primaryLocation?.lastName?.toLowerCase()}, ${
      primaryLocation?.typeOfLicensure
    }`,
    pronoun: primaryLocation?.pronoun,
    medicalGroup: primaryLocation?.medicalGroup,
    specialty: primaryLocation?.specialty,
    clinicName: primaryLocation?.clinicName,
    phone: primaryLocation?.phone,
    gender: primaryLocation?.gender,
    acceptingNewPatients: primaryLocation?.acceptingNewPatients,
    npi: primaryLocation?.npi,
    nameOfNetwork: data?.nameOfNetwork,
    hoursOfOperationString: primaryLocation?.hoursOfOperation,
    boardCertifiedOrEligible: primaryLocation?.boardCertifiedOrEligible,
    hospitalPrivilegeStatus: primaryLocation?.hospitalPrivilegeStatus,
    hospitalNPI: primaryLocation?.hospitalNPI,
    caLicense: primaryLocation?.caLicense,
    providerLanguage: primaryLocation?.providerLanguage,
    telehealth: primaryLocation?.telehealth,
    hospital: primaryLocation?.hospital,
    hoursOfOperation: formattedSchedule(primaryLocation?.hoursOfOperation),
    primaryLocation: [
      {
        address: primaryLocation?.address,
        phone: primaryLocation?.phone,
        clinicName: primaryLocation?.clinicName,
      },
    ],
    additionalLocation: otherLocation?.map((l: any) => ({
      address: l?.address,
      phone: l?.phone,
      clinicName: l?.clinicName,
    })),
  };
};

export const calculateViewport = (coordinates: any): any => {
  let minLat = coordinates[0].lat;
  let maxLat = coordinates[0].lat;
  let minLng = coordinates[0].lng;
  let maxLng = coordinates[0].lng;

  coordinates.forEach((coord: any) => {
    minLat = Math.min(minLat, coord.lat);
    maxLat = Math.max(maxLat, coord.lat);
    minLng = Math.min(minLng, coord.lng);
    maxLng = Math.max(maxLng, coord.lng);
  });

  return {
    south: minLat,
    west: minLng,
    north: maxLat,
    east: maxLng,
  };
};

export const getSearchParams = (address: Address, size: string): URLSearchParams => {
  if (address) {
    const { location } = address;

    const center = location
      ? `${location.lat},${location.lng}`
      : `${address.street1}+${address.city}+${address.state}+${address.zip}`;
    const markers = location
      ? `icon:${staticMap.canopyMarker}%7C${location.lat},${location.lng}`
      : `icon:${staticMap.canopyMarker}%7C${address.street1}+${address.city}+${address.state}+${address.zip}`;

    return new URLSearchParams({
      size,
      center,
      zoom: staticMap.zoom,
      scale: staticMap.scale,
      markers,
      key: API_KEY,
    });
  }
  return new URLSearchParams({});
};

export const getProviderName = (name: string): string => {
  return `${_.startCase(name.split(',')[0])}`;
};

const addFooters = (doc: jsPDF): any => {
  const pageCount = doc.getNumberOfPages();
  const text = document.getElementById('dns-footer-note')?.textContent;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFillColor(255, 255, 255);
    doc.rect(0, 251, 210, 60, 'F');
    doc.addImage(Images.pdfTopStrip, 'PNG', 0, 0, 210, 1.5);
    doc.addImage(Images.pdfLOGO, 'PNG', 7, 5, 45, 10);

    doc.setFontSize(8);
    doc.addFont('SofiaPro', 'Arial', 'normal');
    doc.setDrawColor('#D9D9D9');
    doc.line(0, 257, 210, 257, 'S');
    const splitTitle = doc.splitTextToSize(text!!, 190);
    doc.setTextColor('#3B3D3C');
    doc.text(splitTitle, 7, 262, {
      align: 'left',
    });
    doc.setDrawColor('#D9D9D9');
    doc.line(7, 285, 203, 285, 'S');
    doc.setTextColor('#3B3D3C');
    doc.text('CanopyHealth.com', 7, 290);
    doc.text(dateFormatter(new Date()), 189, 290);
  }
};

export const preparePDFBlob = async (element: any, name: string): Promise<any> => {
  const canvas = await html2canvas(element, { scale: 2, allowTaint: true, useCORS: true });
  const contentImage = canvas.toDataURL('image/png');

  const imgWidth = 210;
  let page = 1;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('p', 'mm');
  const pageHeight = 285;
  doc.setProperties({ title: getProviderName(name) });
  let position = 15;
  doc.addImage(contentImage, 'PNG', 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;
  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    page += 1;
    let offset = 76;
    if (page === 3) {
      offset = 138;
    }
    doc.addImage(contentImage, 'PNG', 0, position + offset, imgWidth, imgHeight);
    doc.setFillColor(255, 255, 255);
    doc.rect(0, 0, 210, 25, 'F');
    heightLeft -= pageHeight;
  }
  addFooters(doc);
  const blob = doc.output('blob');
  return blob;
};
