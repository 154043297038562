/* eslint-disable react/no-redundant-should-component-update */
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import NavMenuLists from './NavMenuLists';
import { BrandingLocationEnum } from '../../shared/constant/AppConstants';
import Auth from '../../shared/utils/Auth';

interface ownState {}

interface ownProps {}

interface dispatchProps {}

interface stateProps {
  data: any;
}

type componentProps = stateProps & dispatchProps & ownProps;
class NavMenu extends Component<componentProps, ownState> {
  constructor(props: componentProps) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(): any {
    return Auth.isAuthenticated();
  }

  getLocationLogo = (location: any): any => {
    const { data } = this.props;
    const brandsData = data?.ConfigData?.getMainConfigdata?.data?.branding?.filter(
      (i: any) => i.location === location
    );
    if(sessionStorage.getItem('branding') === null && brandsData){
      sessionStorage.setItem('branding', JSON.stringify(brandsData));
    }
    return brandsData?.length > 0 ? brandsData[0] : sessionStorage.getItem('branding') ?  JSON.parse(String(sessionStorage.getItem('branding')))[0] : null;
  };

  render(): ReactNode {
    return (
      <>
        <div key={window.location.pathname.split('/')[1]}>
          <div className='logo-container'>
            <a href='https://www.canopyhealth.com/en.html' target='_blank' rel='noreferrer'>
              <img
                src={this.getLocationLogo(BrandingLocationEnum.LOCATION_ONE)?.url?.web}
                className='img-logo-canopy-white'
                alt={this.getLocationLogo(BrandingLocationEnum.LOCATION_ONE)?.name}
              />
            </a>
          </div>
          <ul className='app-menu-items'>
            <NavMenuLists type='desktop' />
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {};
export default connect<stateProps, dispatchProps, ownProps>(
  mapStateToProps,
  mapDispatchToProps
)(NavMenu);
