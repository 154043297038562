import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
/* eslint-disable no-unused-vars */
import { initializeApp } from 'firebase/app';

import { firebaseConfig } from '../../../config'; // eslint-disable-line import/extensions

class Analytics {
  public analytics: any;

  constructor() {
    const app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  onSignIn = (userObject: any): void => {
    setUserId(this.analytics, userObject);
  };
  // static onSignUp = async userObject => {
  //   const { id, email } = userObject;
  //   await Promise.all([
  //     analytics().setUserId(id),
  //     analytics().setUserProperty('email', email),  // <--- DON'T DO THIS !!!
  //     analytics().setUserProperty('created_at', new Date()),
  //     this.logEvent("sign_up")
  //   ]);
  // };

  // static setCurrentScreen = async (screenName:any) => {
  //   await analytics().setCurrentScreen(screenName, screenName);
  // };

  logEvent = (eventName: string, propertyObject = {}): void => {
    logEvent(this.analytics, eventName, propertyObject);
  };

  // static onSignOut = async () => {
  //   await analytics().resetAnalyticsData();
  // };
}

export default new Analytics();

export const enum FA {
  // (//NA) Not available or not getting or need to implement

  CREATE_ACCOUNT_STARTED = 'create_account_started',
  CREATE_ACCOUNT_FAIL_UNKNOWN_ERROR = 'create_account_fail_unknown_error',
  CREATE_ACCOUNT_DUPLICATE_EMAIL_ERROR = 'create_account_fail_duplicate_email', // NA
  CREATE_ACCOUNT_DUPLICATE_USERNAME_ERROR = 'create_account_fail_duplicate_username', // NA
  CREATE_ACCOUNT_FAIL_EMAIL_AND_USERNAME_ERROR = 'create_account_fail_duplicate_email_and_username', // NA
  CREATE_ACCOUNT_FAIL_PASSWORD_ERROR = 'create_account_fail_password_error', // NA
  CREATE_ACCOUNT_USERNAME_SUCCEED = 'create_account_username_succeed', // NA
  CREATE_ACCOUNT_ACCEPT_TERMS_CONDITION = 'create_account_accept_terms',
  CREATE_ACCOUNT_ACCEPT_TERMS_CONDITION_FAILED = 'create_account_accept_terms_failed',
  CREATE_ACCOUNT_BACK_TO_LOCATE = 'create_account_back_to_locate', // NA
  CREATE_ACCOUNT_BACK_TO_USERNAME = 'create_account_back_to_username', // NA
  LOGIN_ATTEMPT_SUCCESS = 'login_attempt_success',
  CREATE_ACCOUNT_LOGIN_ATTEMPT_FAILED = 'create_account_login_attempt_fail',
  CREATE_ACCOUNT_SUCCESS = 'create_account_success',
  CREATE_ACCOUNT_RETURN_TO_LOGIN_FROM_STEP = 'create_account_return_to_login_from_step_*',
  CREATE_ACCOUNT_NEXT_BUTTON = 'create_account_next_button',
  LOGIN_ATTEMPT_FAIL_HEALTH_PLAN_ERROR = 'login_attempt_fail_health_plan_error',
  HOME_DASHBOARD_ADD_PHOTO = 'home_dashboard_add_photo',
  RESET_PASSWORD_SUBMIT = 'reset_password_submit',
  HOME_LOGIN = 'home_login',
  HOME_NETWORK = 'home_network',
  HOME_DASHBOARD_VIEW = 'home_dashboard_view',
  VIM_BOOK_FROM_PROVIDER_SEARCH = 'vim_book_from_provider_search',
  VIM_BOOK_FROM_MY_PCP = 'vim_book_from_my_pcp',
  MENU_HOME = 'menu_home',
  MENU_ACCOUNT_SETTINGS = 'menu_account_settings',
  MENU_COST_ESTIMATOR = 'menu_cost_estimator',
  MENU_CUSTOMER_SUPPORT = 'menu_customer_support',
  MENU_DASHBOARD = 'menu_dashboard',
  MENU_GENERIC_SECTION = 'menu_generic_section',
  MENU_HEALTH_PLAN_SUMMARY = 'menu_health_plan_summary',
  MENU_TELE_HEALTH = 'menu_tele_health',
  MENU_NETWORK = 'menu_network',
  MY_HEALTH_PLAN_VIEW = 'my_health_plan_view',
  MY_HEALTH_PLAN_MY_BENEFITS = 'my_health_plan_my_benefits',
  MY_HEALTH_PLAN_ORDER_ID_CARD_MODAL = 'my_health_plan_order_id_card_modal',
  MY_HEALTH_PLAN_ORDER_ID_CARD_CLOSE = 'my_health_plan_order_id_card_close',
  MY_HEALTH_PLAN_ORDER_ID_CARD_ADDRESS_CORRECT = 'my_health_plan_order_id_card_correct',
  MY_HEALTH_PLAN_ORDER_ID_CARD_SUBMIT = 'my_health_plan_order_id_card_submit',
  MY_HEALTH_PLAN_ORDER_ID_CARD_SUCCESS_MODAL = 'my_health_plan_order_id_card_success_modal',
  MY_HEALTH_PLAN_ORDER_ID_CARD_ERROR = 'my_health_plan_order_id_card_error',
  MY_HEALTH_PLAN_ID_CARD_VIEW = 'my_health_plan_id_card_view',
  NOTIFICATIONS_DETAILS = 'notifications_details',

  HOME_DASHBOARD_ID_CARD_SHARE = 'home_dashboard_id_card_share', // NA

  DOCTOR_AND_SERVICE_PROVIDER_VIEW = 'network_providers_view',
  DOCTOR_AND_SERVICE_HOSPITAL_VIEW = 'network_hospitals_view',
  DOCTOR_AND_SERVICE_SERVICE_VIEW = 'network_services_view',
  DOCTOR_AND_SERVICE_URGENT_CARE_VIEW = 'network_urgent_care_view',
  DOCTOR_AND_SERVICE_PROVIDER_SEARCH = 'network_providers_search',
  DOCTOR_AND_SERVICE_HOSPITAL_SEARCH = 'network_hospitals_search',
  DOCTOR_AND_SERVICE_SERVICE_SEARCH = 'network_services_search',
  DOCTOR_AND_SERVICE_URGENT_CARE_SEARCH = 'network_urgent_care_search',
  DOCTOR_AND_SERVICE_PROVIDER_VIEW_DETAILS = 'network_providers_view_details',
  DOCTOR_AND_SERVICE_SERVICE_VIEW_DETAILS = 'network_services_view_details',
  DOCTOR_AND_SERVICE_URGENT_CARE_VIEW_DETAILS = 'network_urgent_care_view_details',

  OUT_OF_POCKET_COST_VIEW = 'out_of_pocket_cost_view',

  COST_ESTIMATOR_VIEW = 'cost_estimator_view',
  COST_ESTIMATOR_SEARCH_AND_FOUND = 'cost_estimator_search_and_found',
  COST_ESTIMATOR_SEARCH_AND_NOT_FOUND = 'cost_estimator_search_and_not_found',
  COST_ESTIMATOR_FILTER_BY_CATEGORIES = 'cost_estimator_filter_by_categories',
  COST_ESTIMATOR_FILTER_BY_KEYWORD = 'cost_estimator_filter_by_keyword',
  COST_ESTIMATOR_COST_TOTAL = 'cost_estimator_cost_total',
  COST_ESTIMATOR_COST_LOW_SELECT = 'cost_estimator_cost_low_select',
  COST_ESTIMATOR_COST_MEDIUM_SELECT = 'cost_estimator_cost_medium_select',
  COST_ESTIMATOR_COST_HIGH_SELECT = 'cost_estimator_cost_high_select',
  COST_ESTIMATOR_VIEW_PLACE_OF_SERVICE_TEXT = 'cost_estimator_view_place_of_service_text',
  COST_ESTIMATOR_VIEW_PLACE_OF_SERVICE = 'cost_estimator_view_place_of_service',
  COST_ESTIMATOR_VIEW_BEFORE_ESTIMATE = 'cost_estimator_view_before_estimate',
  COST_ESTIMATOR_VIEW_CONTRACTED_RATE = 'cost_estimator_view_contracted_rate',
  COST_ESTIMATOR_VIEW_TERMS = 'cost_estimator_view_terms',
  COST_ESTIMATOR_SHOW_CALCULATION = 'cost_estimator_show_calculation',
  COST_ESTIMATOR_HIDE_CALCULATION = 'cost_estimator_hide_calculation',

  HEALTH_INFO_ARTICLE_DETAIL = 'health_info_article_detail', // NA

  // Need to add this once implement complete feature
  VIRTUAL_VISIT_VIDEO_VISIT_CLICK = 'video_visits_click',
  // VIRTUAL_VISIT_VIDEO_VISIT_JMH_CLICK = 'video_visits_jmh_click',
  VIRTUAL_VISIT_VIDEO_VISIT_PROVIDER_VIEW = 'video_visits_providers_view',
  VIRTUAL_VISIT_VIDEO_VISIT_FIRST_AVAILABLE = 'video_visits_first_available',
  VIRTUAL_VISIT_VIDEO_VISIT_SPECIFIC_PROVIDER = 'video_visits_specific_provider',
  VIRTUAL_VISIT_VIDEO_VISIT_STATE_CHANGE = 'video_visits_state_change',
  VIRTUAL_VISIT_VIDEO_VISIT_DOWNLOAD_PDF_SUMMARY = 'video_visits_download_pdf_summary',
  VIRTUAL_VISIT_VIDEO_VISIT_GET_STARTED_VIEW = 'video_visits_get_started_view',
  VIRTUAL_VISIT_VIDEO_VISIT_GUEST_COUNT = 'video_visits_guests_count',
  VIRTUAL_VISIT_VIDEO_GET_STARTED_CANCEL = 'video_visits_get_started_cancel',

  VIRTUAL_VISIT_VIDEO_VISIT_INFO_VIEW = 'video_visits_visit_info_view',
  VIRTUAL_VISIT_VIDEO_VISIT_REASONS = 'video_visits_visit_reasons',
  VIRTUAL_VISIT_VIDEO_VISIT_PREFERRED_PHARMACY = 'video_visits_preferred_pharmacy',
  VIRTUAL_VISIT_VIDEO_VISIT_LOADED_PHOTO = 'video_visits_loaded_photo',
  VIRTUAL_VISIT_VIDEO_VISIT_INFO_CANCEL = 'video_visits_visit_info_cancel',
  VIRTUAL_VISIT_VIDEO_VISIT_ALLERGIES_AND_CONDITION_VIEW = 'video_visits_allergies_and_conditions_view',
  VIRTUAL_VISIT_VIDEO_VISIT_ALLERGIES = 'video_visits_allergies',
  VIRTUAL_VISIT_VIDEO_VISIT_PROVIDED_CONDITIONS = 'video_visits_provided_conditions',
  VIRTUAL_VISIT_VIDEO_VISIT_ALLERGIES_AND_CONDITION_CANCEL = 'video_visits_allergies_and_conditions_cancel',

  VIRTUAL_VISIT_VIDEO_VISIT_MEDICATIONS_VITAL_VIEW = 'video_visits_medications_vitals_view',
  VIRTUAL_VISIT_VIDEO_VISIT_AGREE_TO_SHARE = 'video_visits_agree_to_share',
  VIRTUAL_VISIT_VIDEO_VISIT_ADD_MEDICATION = 'video_visits_add_medication',
  VIRTUAL_VISIT_VIDEO_VISIT_SUBMITTED_VITALS = 'video_visits_submitted_vitals',

  VIRTUAL_VISIT_VIDEO_VISIT_MEDICATIONS_AND_VITALS_CANCEL = 'video_visits_medications_and_vitals_cancel',

  VIRTUAL_VISIT_VIDEO_VISIT_PAYMENT_INFO_VIEW = 'video_visits_payment_info_view',
  VIRTUAL_VISIT_VIDEO_VISIT_PAYMENT_INFORMATION = 'video_visits_payment_information', // NA

  VIRTUAL_VISIT_VIDEO_VISIT_PAYMENT_INFORMATION_CANCEL = 'video_visits_payment_information_cancel',

  VIRTUAL_VISIT_VIDEO_VISIT_SUMMARY_VIEW = 'video_visits_summary_view',
  VIRTUAL_VISIT_VIDEO_VISIT_WAITING_ROOM = 'video_visits_waiting_room',
  VIRTUAL_VISIT_VIDEO_VISIT_FOUND_VIRTUAL_PROVIDER = 'video_visits_found_virtual_provider',
  VIRTUAL_VISIT_VIDEO_VISIT_PROVIDER_CANCEL = 'video_visits_provider_cancel',
  VIRTUAL_VISIT_VIDEO_VISIT_COMPLETED = 'video_visits_completed',

  VIRTUAL_VISIT_VIDEO_VISIT_USER_CANCEL = 'video_visits_user_cancel',
  VIRTUAL_VISIT_VIDEO_VISIT_WAITING_ROOM_CANCEL = 'video_visits_waiting_room_cancel',
  VIRTUAL_VISIT_VIDEO_VISIT_WRAP_UP_VIEW = 'video_visits_wrap_up_view',
  VIRTUAL_VISIT_VIDEO_VISIT_WRAP_UP_SHARE_WITH_PCP = 'video_visits_wrap_up_share_with_pcp',

  ACCOUNT_SETTINGS_VIEW = 'account_settings_view',
  ACCOUNT_SETTINGS_PROFILE_PHOTO_SUBMIT = 'account_settings_profile_photo_submit',
  ACCOUNT_SETTINGS_CHANGE_EMAIL = 'account_settings_change_email',
  ACCOUNT_SETTINGS_CHANGE_PASSWORD = 'account_settings_change_password',
  ACCOUNT_SETTINGS_CHANGE_USERNAME = 'account_settings_change_username',
  ACCOUNT_SETTINGS_ABOUT_VIEW = 'account_settings_about_view', // *
  ACCOUNT_SETTINGS_NOTIFICATION_VIEW = 'account_settings_notifications_view',
  ACCOUNT_SETTINGS_NOTIFICATION_GENERAL_SWITCH_STATUS = 'account_settings_notifications_general_switch_status',
  ACCOUNT_SETTINGS_NOTIFICATION_MY_HEALTH_PLAN_SWITCH_STATUS = 'account_settings_notifications_my_health_plan_switch_status',
  ACCOUNT_SETTINGS_NOTIFICATION_HEALTH_INFO_SWITCH_STATUS = 'account_settings_notifications_health_info_switch_status',
  ACCOUNT_SETTINGS_PAYMENT_INFORMATION_ADD_CARD = 'account_settings_payment_information_add_card', // NA
  ACCOUNT_SETTINGS_PAYMENT_INFORMATION_ADD_CARD_ERROR = 'account_settings_payment_information_add_card_error', // NA
  ACCOUNT_SETTINGS_PRIVACY_POLICY_VIEW = 'account_settings_privacy_policy_view',
  ACCOUNT_SETTINGS_TERMS_AND_CONDITION_VIEW = 'account_settings_t_c_view',
  ACCOUNT_SETTINGS_NON_DISCRIMINATION_VIEW = 'account_settings_nondiscrimination_view',

  CHANGE_PCP_SURVEY_VIEW = 'change_pcp_survey_view',
  CHANGE_PCP_UNHAPPY_EXAMPLE = 'change_pcp_unhappy_examples',
  CHANGE_PCP_SURVEY_ANSWERED = 'change_pcp_survey_answered',
  CHANGE_PCP_RECENT_SERVICE_VIEW = 'change_pcp_recent_services_view',
  CHANGE_PCP_RECENT_SERVICE_ANSWERED = 'change_pcp_recent_services_answered',
  CHANGE_PCP_SEARCH_PHYSICIAN_VIEW = 'change_pcp_search_physician_view',
  CHANGE_PCP_SEARCH_PHYSICIAN_VIEW_DETAILS = 'change_pcp_search_physicians_view_details',
  CHANGE_PCP_NEW_PCP_CHOSEN = 'change_pcp_new_pcp_chosen',
  CHANGE_PCP_CONFIRM_CHANGE_VIEW = 'change_pcp_confirm_change_view',
  CHANGE_PCP_CONFIRM_CHANGES_OPTION_SET = 'change_pcp_confirm_change_options_set',
  CHANGE_PCP_CONFIRM = 'change_pcp_confirm',
  CHANGE_PCP_BACK_HOME_AFTER_COMPLETE = 'change_pcp_back_home_after_complete',
  CHANGE_PCP_CANCEL = 'change_pcp_cancel', // *

  NOTIFICATION_VIEW = 'notifications_view',
  NOTIFICATION_TITLE = 'notifications_details', // NA

  APPLICATION_ALERT = 'application_alert',

  CLICK_VISIT_MYUHC_COM = 'click_visit_myuhc.com',
  CLICK_CAREMARK_CVS = 'click_caremark_cvs',

  CLICK_PHARMACY_AND_PRESCRIPTIONS = 'click_pharmacy_and_prescriptions',
  CLICK_FIND_CARE_AND_COSTS = 'click_find_care_and_costs',
  CLICK_CLAIMS_AND_ACCOUNT_SUMMARY = 'click_claims_and_account_summary',
  CLICK_CANOPYCARE_PHARMACY_BENEFITS = 'click_canopycare_pharmacy_benefits',
  ACCEPT_FIND_CARE_AND_COSTS = 'accept_find_care_and_costs',
  COST_ESTIMATOR_CATEGORIES_CLOSE = 'cost_estimator_categories_close',
  COST_ESTIMATOR_CATEGORIES_SELECTED_DONE = 'cost_estimator_categories_selected_done',
  COST_ESTIMATOR_SEARCH_DONE = 'cost_estimator_search_done',
  COST_ESTIMATOR_SEARCH_CLOSE = 'cost_estimator_search_close',
  COST_ESTIMATOR_SELECT_CATEGORY = 'cost_estimator_select_category',

  // change, select pcp and order id tags
  CURRENT_ADDRESS_SELECTED = '_current_address_selected',
  CURRENT_ADDRESS_UPDATED = '_current_address_updated',
  TEMP_ADDRESS_SELECTED = '_temp_address_selected',
  BEST_METHOD_EMAIL_SELECTED = '_best_method_email_selected',
  BEST_METHOD_PHONE_SELECTED = '_best_method_phone_selected',
  BEST_TIME_8_10_SELECTED = '_best_time_8 -10_selected',
  BEST_TIME_10_2_SELECTED = '_best_time_10_2_selected',
  BEST_TIME_2_5_SELECTED = '_best_time_2_5_selected',
  BEST_TIME_6_9_SELECTED = '_best_time_6_9_selected',
  ALREADY_SEEING_PROVIDER_SELECTED = '_seeing_provider_selected',
  APPLY_TO_FAMILY_SELECTED = '_apply_to_family_selected',
}
