import React, { PureComponent } from 'react';
import { ACCESS_EXTERNAL_SITE_TITLE_TXT, WHAT_DO_YOU_WANT_TO_LOOK_FOR_LABEL } from '../../shared/constant/AppConstants';
import { NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG } from '../../shared/constant/AppMessages';
import ComfirmationModalWithTwoButtons from '../../shared/sharedComponents/ModalWithTwoButtons/ModalWithTwoButtons';
import Analytics, { FA } from '../../shared/utils/Analytics';
import images from '../../shared/utils/Images';
import { hideLoader } from '../../shared/utils/ReusableComponents';

interface ownState {
  findCareUrl?: any;
}

interface ownProps {}

class DoctorsPlan extends PureComponent<ownProps, ownState> {
  constructor(props: ownProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    setTimeout(() => {
      hideLoader();
    }, 1000);
    Analytics.logEvent(FA.HOME_NETWORK);
  }

  render(): React.ReactElement {
    const setUrl = (): any => {
      this.setState({ findCareUrl: 'https://member.uhc.com/myuhc?deeplink=physiciansandfacilities' });
    };
    const { findCareUrl } = this.state;
    return (
      <>
        <div className='sub-menu clearfix d-flex align-items-center flex-md-row flex-column epoPlan'>
          <h4>{WHAT_DO_YOU_WANT_TO_LOOK_FOR_LABEL}</h4>
          <ul>
            <li className='generic-pills d-inline-block'>
              <div className='horizontal-links pill d-inline-block mt-0'>
                <div className='link-wrapper d-inline'>
                  <button className='link-pill-active mb-0' type='button'>
                    <img
                      alt='Link'
                      className='clickable arrow img-fluid me-2'
                      src={images.icDeeplinkWhite}
                      srcSet={`${images.icDeeplinkWhite}, ${images.icDeeplinkWhite2x} 2x, ${images.icDeeplinkWhite3x} 3x`}
                    />
                    <span>Find Care and Costs</span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className='container-fluid common-filters epo-border'>
          <div className='row'>
            <div className='col-12'>
              Click the link below to find providers or get an estimate of the cost of services
            </div>

            <div className='col-12'>
              <div className='horizontal-links pill'>
                <div className='link-wrapper '>
                  <span role='none' className='link' onClick={() => setUrl()}>
                    <img
                      alt='Find Care and Costs'
                      className='clickable arrow img-fluid'
                      src={images.icDeeplink}
                      srcSet={`${images.icDeeplink}, ${images.icDeeplink2x} 2x, ${images.icDeeplink3x} 3x`}
                    />
                    <span>Find Care and Costs</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {findCareUrl && (
          <ComfirmationModalWithTwoButtons
            title={ACCESS_EXTERNAL_SITE_TITLE_TXT}
            message={NAVIGATING_OUTSIDE_WEBSITE_WARNING_MSG}
            showModal={!!findCareUrl}
            closeModal={() => this.setState({ findCareUrl: undefined })}
            action={() => {
              window.open(findCareUrl, '_blank');
              this.setState({ findCareUrl: undefined });
            }}
            btn1Title='Yes'
            btn2Title='No'
          />
        )}
      </>
    );
  }
}

export default DoctorsPlan;
