import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { PureComponent } from 'react';
import {
  CITY,
  CLEAR,
  DISTANCE,
  HOSPITALS_TEXT,
  // HEALTH_PLAN_TITLE,
  PLEASE_ENTER_ZIP_CODE,
  SELECT_HEALTH_PLAN,
  ZIP_CODE,
} from '../../../shared/constant/AppConstants';
import { history, zipCodeValidation } from '../../../shared/helperMethods/HelperMethod';
import Analytics, { FA } from '../../../shared/utils/Analytics';
import Auth from '../../../shared/utils/Auth';
import Images from '../../../shared/utils/Images';
import { hideLoader, showLoader } from '../../../shared/utils/ReusableComponents';

interface ownState {
  nameOfNetwork: string;
  city: string;
  zip: any;
  distance: string;
  zipCodeBtnActive: boolean;
  disabledSearch: boolean;
  zipcodeError: boolean;
  isSelectPlanDisabled?: boolean;
  isMouseInside?: boolean;
  ddlWidth?: number;
  open?: boolean;
}

interface ownProps {
  filterData: any;
  createGetHospitalsRequest: any;
  healthPlanData: any;
  updateFilterData: any;
  setStringifiedQueryString: any;
  clearResult: any;
}

class SearchFilters extends PureComponent<ownProps, ownState> {
  searchData: any;

  nameOfNetwork = sessionStorage.getItem('healthPlan');

  initialState: Readonly<ownState>;

  selectPlanRef: any;

  constructor(props: ownProps) {
    super(props);
    this.initialState = {
      nameOfNetwork: '',
      city: '',
      zip: '',
      distance: '',
      zipCodeBtnActive: true,
      disabledSearch: false,
      zipcodeError: false,
      isSelectPlanDisabled: true,
      isMouseInside: false,
      ddlWidth: 540,
      open: false,
    };
    this.selectPlanRef = React.createRef();
    this.state = this.initialState;
    this.searchData = {
      nameOfNetwork: Auth.getHealthPlan(),
      serviceName: HOSPITALS_TEXT,
      sort: { hospitalName: 1 },
      page: 1,
      limit: 15,
    };
  }

  // below code will be removed pls ignore 45-50
  componentDidMount(): any {
    // const { createGetHospitalsRequest } = this.props;
    this.setState({ nameOfNetwork: Auth.getHealthPlan() });
    // if (Auth.getHealthPlan()) {
    //   createGetHospitalsRequest({ query: this.searchData }, () => console.log('test success doctor'));
    // }
    zipCodeValidation();
    this.setState({ ddlWidth: this.selectPlanRef?.current?.offsetWidth });
  }

  handleChange = (e: any): void => {
    this.setState({ disabledSearch: false });
    const change: any = {};
    change[e.target.name] = e.target.value;
    this.setState(change, () => this.handleSearchFilterChange(e.target.name, e.target.value));
  };

  handleZipCodeChange = (e: any): any => {
    if (e.target.value.length > 6) {
      e.preventDefault();
      return;
    }
    this.setState({ zip: e.target.value });
    const { distance } = this.state;
    if (e.target.value.length > 0 && e.target.value.length <= 6) {
      if (distance === '') {
        this.setState({ distance: '50 miles' });
        this.handleSearchFilterChange('distance', '50 miles');
      }
    }
    if (e.target.value === '') {
      this.setState({ distance: '' });
      this.handleSearchFilterChange('distance', '');
    }
    this.handleSearchFilterChange(e.target.name, e.target.value);
  };

  handleNetworkChange = (e: any): void => {
    Auth.setHealthPlan(e.target.value);
    if (e.target.value === 'Canopy Health - UnitedHealthcare Doctors Plan') {
      history.push('/network/doctor');
    } else if (!e.target.value) {
      this.handleChange(e);
      this.clear();
    } else {
      this.handleChange(e);
      const { updateFilterData } = this.props;
      const query = {
        nameOfNetwork: e.target.value,
        serviceName: HOSPITALS_TEXT,
      };
      Auth.setHealthPlan(e.target.value);
      this.setState({ isSelectPlanDisabled: true, open: false });
      updateFilterData({ query }, () => console.log('test'));
    }
  };

  handleSearchFilterChange = (name: string, value: string): void => {
    const alreadyExistsData = Object.keys(this.searchData).filter((i: any) => i === name);
    if (alreadyExistsData.length > 0 && value === '') {
      delete this.searchData[name];
    } else {
      Object.assign(this.searchData, { [name]: value });
    }
  };

  clear = (): void => {
    const { filterData, clearResult } = this.props;
    this.setState(this.initialState);
    this.searchData = {
      nameOfNetwork: Auth.getHealthPlan(),
      serviceName: HOSPITALS_TEXT,
      sort: { hospitalName: 1 },
      page: 1,
      limit: 15,
    };
    if (Auth.isAuthenticated()) {
      this.setState({ nameOfNetwork: filterData.nameOfNetwork });
    } else {
      this.setState({ disabledSearch: true, isSelectPlanDisabled: false });
    }
    clearResult();
  };

  onSearch = (): any => {
    this.setState({ isSelectPlanDisabled: true });
    const { createGetHospitalsRequest, setStringifiedQueryString } = this.props;
    const { distance, zip, zipCodeBtnActive } = this.state;
    if (distance !== '' && zip === '') {
      this.setState({ zipcodeError: true });
    } else {
      showLoader();
      Object.assign(this.searchData, { page: 1 });
      if (Object.prototype.hasOwnProperty.call(this.searchData, 'zip')) {
        Object.assign(this.searchData, { sort: { distance: 1 } });
      }
      if (this.searchData.distance === 'Match Zipcode' || this.searchData.distance === undefined) {
        Object.assign(this.searchData, { sort: { hospitalName: 1 } });
      }
      if (zipCodeBtnActive) {
        delete this.searchData.city;
      }
      if (!zipCodeBtnActive) {
        delete this.searchData.zip;
        delete this.searchData.distance;
        Object.assign(this.searchData, { sort: { hospitalName: 1 } });
      }
      if (!this.searchData.zip) {
        delete this.searchData.distance;
      }
      setStringifiedQueryString(this.searchData);
      createGetHospitalsRequest({ query: this.searchData });
      this.setState({ zipcodeError: false });
      Analytics.logEvent(FA.DOCTOR_AND_SERVICE_HOSPITAL_SEARCH, {
        city: this.searchData?.city ? this.searchData?.city : '',
        distance: this.searchData?.distance ? this.searchData?.distance : '',
        health_plan: this.searchData?.nameOfNetwork ? this.searchData?.nameOfNetwork : '',
        zip_code: this.searchData?.zip ? this.searchData?.zip : '',
      });
    }
  };

  callBack = (): any => {
    hideLoader();
  };

  mouseEnter = (): any => {
    this.setState({ isMouseInside: true });
  };

  mouseLeave = (): any => {
    this.setState({ isMouseInside: false });
  };

  onEditClick = (): any => {
    this.setState({ isSelectPlanDisabled: false, open: true });
    this.selectPlanRef?.current?.focus();
  };

  render(): React.ReactElement {
    const {
      zipCodeBtnActive,
      nameOfNetwork,
      zip,
      distance,
      disabledSearch,
      city,
      zipcodeError,
      isSelectPlanDisabled,
      isMouseInside,
      ddlWidth,
      open,
    } = this.state;
    const { filterData, healthPlanData } = this.props;
    return (
      <>
        <div className='common-filters container-fluid'>
          <form
            className='form-inline'
            onSubmit={(e: any) => {
              e.preventDefault();
              this.onSearch();
            }}>
            <div className='row ds-filter'>
              <div className='control-container'>
                {!Auth.isAuthenticated() && (
                  <div className='row'>
                    <div
                      className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-8 position-relative'
                      onMouseEnter={this.mouseEnter}
                      onMouseLeave={this.mouseLeave}>
                      <Select
                        ref={this.selectPlanRef}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}
                        open={open}
                        onClose={() => this.setState({ open: false })}
                        onOpen={() => this.setState({ open: true })}
                        disabled={isSelectPlanDisabled}
                        value={nameOfNetwork}
                        id='select_healthplan'
                        name='nameOfNetwork'
                        className={`custom-dark health-plan blue distance-dropdown ${
                          isSelectPlanDisabled ? 'disabled' : 'Mui-focused'
                        }${nameOfNetwork === '' ? ' cleared-filter' : ''} ${open ? 'Mui-focused' : ''}`}
                        onChange={this.handleNetworkChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        onBlur={() => this.setState({ isSelectPlanDisabled: true })}>
                        <MenuItem style={{ width: `${ddlWidth}px` }} value=''>
                          {SELECT_HEALTH_PLAN}
                        </MenuItem>
                        {healthPlanData &&
                          healthPlanData.sort().map((i: any) => (
                            <MenuItem style={{ width: `${ddlWidth}px` }} value={i} key={i}>
                              {i}
                            </MenuItem>
                          ))}
                      </Select>
                      {isSelectPlanDisabled && (
                        <button
                          type='button'
                          className={`plan-edit-button ${isMouseInside ? 'hovered' : ''}`}
                          onClick={this.onEditClick}>
                          <img
                            className='alert-icon'
                            src={isMouseInside ? Images.icEditActive : Images.icEdit}
                            alt='search'
                          />
                        </button>
                      )}
                    </div>
                  </div>
                )}
                <div className='row'>
                  <div className='form-group checkbox col-sm-12 col-md-6 col-lg-4'>
                    <div className='btn-group btn-group-justified col-12'>
                      <button
                        disabled={nameOfNetwork === ''}
                        type='button'
                        onClick={() => {
                          this.setState({ zipCodeBtnActive: true, city: '' });
                          delete this.searchData.city;
                          delete this.searchData.distance;
                        }}
                        className={`btn btn-default toggleBtn ${zipCodeBtnActive ? 'active' : ''}`}>
                        {ZIP_CODE}
                      </button>
                      <button
                        disabled={nameOfNetwork === ''}
                        type='button'
                        onClick={() => {
                          this.setState({ zipCodeBtnActive: false, zip: '', distance: '' });
                          delete this.searchData.zip;
                          delete this.searchData.distance;
                        }}
                        className={`btn btn-default toggleBtn ${zipCodeBtnActive ? '' : 'active'}`}>
                        {CITY}
                      </button>
                    </div>
                  </div>

                  {zipCodeBtnActive ? (
                    <>
                      <div className='form-group col-12 col-md-6 col-lg-4'>
                        <input
                          disabled={nameOfNetwork === ''}
                          className='zip-input'
                          type='number'
                          pattern='\d'
                          placeholder='Zip code'
                          name='zip'
                          value={zip}
                          maxLength={8}
                          onChange={(e: any) => {
                            this.handleZipCodeChange(e);
                          }}
                          onBlur={() => {
                            return Number(zip) < 0
                              ? this.setState({ zip: '' }, () => this.handleSearchFilterChange('zip', ''))
                              : null;
                          }}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              'wheel',
                              function (event) {
                                event.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                        {zipcodeError && zip === '' && <span className='error-zipcode '>{PLEASE_ENTER_ZIP_CODE}</span>}
                      </div>
                      <div className='form-group col-12 col-md-6 col-lg-4'>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                          displayEmpty
                          disabled={nameOfNetwork === ''}
                          className='select distance-dropdown'
                          onChange={this.handleChange}
                          name='distance'
                          value={distance}>
                          <MenuItem value=''>{DISTANCE}</MenuItem>
                          {filterData &&
                            filterData.distance &&
                            filterData.distance.map((i: any) => (
                              <MenuItem title={i} value={i} key={i}>
                                {i}
                              </MenuItem>
                            ))}
                        </Select>
                        {/* <select
                          disabled={nameOfNetwork === ''}
                          className='distance-dropdown'
                          name='distance'
                          value={distance}
                          onChange={this.handleChange}>
                          <option value=''>{DISTANCE} </option>
                          {filterData &&
                            filterData.distance &&
                            filterData.distance.map((i: any) => (
                              <option value={i} key={i}>
                                {i}
                              </option>
                            ))}
                        </select> */}
                      </div>
                    </>
                  ) : (
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}
                        displayEmpty
                        disabled={nameOfNetwork === ''}
                        className='select distance-dropdown'
                        onChange={this.handleChange}
                        name='city'
                        value={city}>
                        <MenuItem value=''>{CITY}</MenuItem>
                        {filterData &&
                          filterData.city &&
                          filterData.city.sort().map((i: any) => (
                            <MenuItem title={i} value={i} key={i}>
                              {i}
                            </MenuItem>
                          ))}
                      </Select>
                      {/* <select
                        disabled={nameOfNetwork === ''}
                        name='city'
                        className='distance-dropdown'
                        onChange={this.handleChange}
                        value={city}>
                        <option value=''>{CITY} </option>
                        {filterData &&
                          filterData.city &&
                          filterData.city.sort().map((i: any) => (
                            <option value={i} key={i}>
                              {i}
                            </option>
                          ))}
                      </select> */}
                    </div>
                  )}
                </div>
              </div>
              <div className='button-container d-flex align-items-end pe-md-0'>
                <div className='row align-items-sm-center large-screen'>
                  <div className='form-group col-12 col-xl-auto text-center'>
                    <button type='button' className='search-filter' disabled={disabledSearch} onClick={this.onSearch}>
                      <img className='alert-icon' src={Images.icSearch} alt='search' />
                    </button>
                  </div>
                  <div className='form-group zindex col-12 col-xl-auto c-m-17'>
                    <span
                      className='clear-button'
                      role='button'
                      tabIndex={-2}
                      onClick={this.clear}
                      onKeyPress={this.clear}>
                      {CLEAR}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row common-filters small-screen' style={{ borderTop: 'none' }}>
                <div className='col-6 text-left'>
                  <span
                    className='clear-button'
                    role='button'
                    tabIndex={-2}
                    onClick={this.clear}
                    onKeyPress={this.clear}>
                    {CLEAR}
                  </span>
                </div>
                <div className='col-6 text-right'>
                  <button
                    type='button'
                    className='search-filter'
                    disabled={disabledSearch}
                    onClick={this.onSearch}
                    style={{ float: 'right' }}>
                    <img className='alert-icon' src={Images.icSearch} alt='search' />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default SearchFilters;
