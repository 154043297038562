/* eslint-disable no-unused-vars */

import { useEffect, useMemo, useRef, useState } from 'react';
import useMapContext from '../hooks/useMapContext';
import { Doctor, DoctorWithDetails, DoctorWithLocations } from '../types';
import DoctorCard from './DoctorCard';
import DoctorDetails from './DoctorDetails';
import './MapSideBar.scss';

interface MapSideBarProps {
  doctors: Doctor[];
  fetchProviderProfile: (payload: any, callBack: any) => any;
  allData: any;
}

const MapSideBar = ({ doctors, fetchProviderProfile, allData }: MapSideBarProps): JSX.Element => {
  let sliceCount = 15;

  const { isZipSearch } = useMapContext();
  const [isDetailsShown, setIsDetailsShown] = useState(false);
  const [numberOfDoctorCardsInSideBar, setNumberOfDoctorCardsInSideBar] = useState(sliceCount);
  const [doctorDetailsShown, setDoctorDetailsShown] = useState<DoctorWithDetails | null>(null);
  const [doctorWithLocations, setDoctorWithLocations] = useState<DoctorWithLocations | null>(null);
  const [pictureSrc, setPictureSrc] = useState('');
  const loader = useRef(null);
  const nearestDoctors = useMemo(() => {
    if (!isZipSearch) {
      return doctors.sort((a, b) => a.address.city.localeCompare(b.address.city));
    }

    return doctors.sort((a, b) => (a.distance ?? 0) - (b.distance ?? 0));
  }, [doctors, isZipSearch]);

  const slicedDoctorsWithLocations = useMemo(
    () =>
      nearestDoctors
        .reduce((acc: DoctorWithLocations[], curr: Doctor) => {
          const { npi, medicalGroup } = curr;
          const index = acc.findIndex((item: any) => item.npi === npi && item.medicalGroup === medicalGroup);
          if (index !== -1) {
            acc[index].otherLocations.push(curr);
            return acc;
          }
          acc.push({ ...curr, otherLocations: [] });
          return acc;
        }, [])
        .slice(0, numberOfDoctorCardsInSideBar),
    [nearestDoctors, numberOfDoctorCardsInSideBar]
  );

  const handleDoctorCardClick = (toShowDoctorDetails: DoctorWithDetails, allDetails: any): void => {
    if (doctorDetailsShown?._id === toShowDoctorDetails._id) {
      setDoctorDetailsShown(null);
      setIsDetailsShown(false);
      return;
    }
    const pic = document.getElementById(`${allDetails._id}_profilepic`) as HTMLImageElement;
    setPictureSrc(pic?.currentSrc);
    setDoctorDetailsShown({ ...toShowDoctorDetails, nameOfNetwork: allDetails.nameOfNetwork });
    setDoctorWithLocations(allDetails);
    setIsDetailsShown(true);
  };

  const handleOnSideBarClose = (): void => {
    setIsDetailsShown(false);
    setDoctorDetailsShown(null);
  };

  const handleObserver = (entries: any[]): any => {
    const target = entries[0];
    if (target.isIntersecting) {
      sliceCount += 15;
      console.log(sliceCount);
      setNumberOfDoctorCardsInSideBar(sliceCount);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loader]);

  return (
    <div className='map-side-bar-container'>
      <div className='doctor-cards-container'>
        {slicedDoctorsWithLocations.length > 0 ? (
          slicedDoctorsWithLocations.map((doctor) => (
            <DoctorCard
              key={doctor._id}
              onClick={() => handleDoctorCardClick(doctor, doctor)}
              selected={doctor._id === doctorDetailsShown?._id}
              {...doctor}>
              {doctor.otherLocations.map((location) => (
                <DoctorCard
                  key={location._id}
                  light
                  onClick={() => handleDoctorCardClick(location, doctor)}
                  selected={location._id === doctorDetailsShown?._id}
                  {...location}
                />
              ))}
              {doctor.outsideSearch.length > 0 && (
                <>
                  <button type='button' className='advanceToggle additional'>
                    <a
                      data-bs-toggle='collapse'
                      href={`#collapseExample${doctor._id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${doctor._id}`}
                      style={{ textDecoration: 'none' }}>
                      <span className='plusAdvanceFilter'>
                        <span className='capitalized'>{`${doctor?.typeOfLicensure}
                      ${doctor?.lastName?.toLowerCase()}`}</span>
                        {` also works at ${doctor.outsideSearch.length} other locations outside your search criteria`}
                      </span>
                      <span className='minusAdvanceFilter'>
                        <span className='capitalized'>{`${doctor?.typeOfLicensure}
                      ${doctor?.lastName?.toLowerCase()}`}</span>
                        {` also works at ${doctor.outsideSearch.length} other locations outside your search criteria`}
                      </span>
                    </a>
                  </button>
                  <div className='collapse' id={`collapseExample${doctor._id}`}>
                    {doctor.outsideSearch.map((location) => (
                      <DoctorCard
                        key={location._id}
                        light
                        onClick={() => handleDoctorCardClick(location, doctor)}
                        selected={location._id === doctorDetailsShown?._id}
                        {...location}
                      />
                    ))}
                  </div>
                </>
              )}
            </DoctorCard>
          ))
        ) : (
          <div style={{ textAlign: 'center', padding: '16px' }}>No doctors found at this location of map</div>
        )}
        <div ref={loader} style={{ height: '20px' }}>
          {/* Loader component to indicate loading */}
        </div>
      </div>
      {isDetailsShown && doctorDetailsShown && (
        <div className='doctor-details-container'>
          <DoctorDetails
            {...doctorDetailsShown}
            doctorWithAllLocations={doctorWithLocations}
            onClose={handleOnSideBarClose}
            fetchProviderProfile={fetchProviderProfile}
            allData={allData}
            pictureUrl={pictureSrc}
          />
        </div>
      )}
    </div>
  );
};

export default MapSideBar;
