import * as actions from '@canopy/shared/lib';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import {
  ACCEPTING_NEW_PATIENTS_LABEL,
  ADDRESS1,
  ADDRESS2,
  BOARD_CERTIFICATE_LABEL,
  CANCEL,
  CA_LICENSE_LABEL,
  CITY,
  CLINIC_NAME,
  COUNTY,
  EMAIL_ADDRESS,
  FIRST_NAME_LABEL,
  GENDER,
  HEALTH_PLAN,
  HOSPITAL_LABEL_COUNT,
  LANGUAGE_LABEL_COUNT,
  LAST_NAME_LABEL,
  MEDIA_GROUP_IPA,
  MIDDLE_NAME_LABEL,
  NON_CA_LICENSE_LABEL,
  NPI_LABLE,
  OFFERING_TELEHEALTH_LABEL,
  OFFICE_HOURS,
  PHONE_NUMBER,
  REPORT_INACCURACIES_DOCTORS_EDIT_INFO,
  REPORT_INACCURACIES_LABLE,
  REPORT_INACCURACIES_THANK_YOU_TEXT,
  SAVE,
  SPECIALITY_LABEL_COUNT,
  STATE,
  SUGGEST_INACCURACIES_TEXT,
  TYPE_OF_LICENSURE_LABEL,
  ZIP_CODE,
} from '../../../shared/constant/AppConstants';
import ConfirmationModal from '../../../shared/sharedComponents/confirmationModal/ComfirmationModal';
import Auth from '../../../shared/utils/Auth';
import { hideLoader, showLoader } from '../../../shared/utils/ReusableComponents';

interface ownProps {
  match: any;
}

interface ownState {
  email: string;
  healthPlan: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  npi: string;
  fName: string;
  lName: string;
  mName: string;
  spec1: string;
  spec2: string;
  spec3: string;
  acceptingNewPatients: string;
  teleHealth: string;
  gender: string;
  boardCertificate: string;
  language1: string;
  language3: string;
  language2: string;
  clinicName: string;
  mediaGroupIPA: string;
  hospital1: string;
  hospital2: string;
  hospital3: string;
  county: string;
  caLicense: string;
  nonCALicense: string;
  typeOfLicensure: string;
  officeHours: string;
  saveSuccess: boolean;
  saveError: boolean;
}

interface DispatchProps {
  createGetReportInaccuraciesRequest: typeof actions.createGetReportInaccuraciesRequest;
  createReportInaccuraciesRequest: typeof actions.createReportInaccuraciesRequest;
}

interface stateProps {
  data: any;
}

type props = ownProps & DispatchProps & stateProps & RouteComponentProps;

class ReportInaccuraciesDoctor extends PureComponent<props, ownState> {
  constructor(props: props) {
    super(props);
    this.state = {
      email: '',
      healthPlan: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      phone: '',
      npi: '',
      fName: '',
      lName: '',
      mName: '',
      spec1: '',
      spec2: '',
      spec3: '',
      acceptingNewPatients: '',
      teleHealth: '',
      gender: '',
      boardCertificate: '',
      language1: '',
      language3: '',
      language2: '',
      clinicName: '',
      mediaGroupIPA: '',
      hospital1: '',
      hospital2: '',
      hospital3: '',
      county: '',
      caLicense: '',
      nonCALicense: '',
      typeOfLicensure: '',
      officeHours: '',
      saveError: false,
      saveSuccess: false,
    };
  }

  componentDidMount(): any {
    const { createGetReportInaccuraciesRequest, match } = this.props;
    const { id } = match.params;

    createGetReportInaccuraciesRequest(
      { serviceName: 'Providers', recordId: id, isAuthenticated: false },
      (res: any) => {
        if (res.isSuccess) {
          const { data } = res;
          // const officeHours: any =
          //   getOfficeHours(data?.hoursOfOperation).length > 0
          //     ? getOfficeHours(data?.hoursOfOperation).map((i: any) => `${i.day}: ${i.time}`)
          //     : [];

          this.setState({
            npi: data?.npi,
            fName: data?.firstName,
            lName: data?.lastName,
            mName: data?.middleName,
            clinicName: data?.clinicName,
            mediaGroupIPA: data?.medicalGroup,
            acceptingNewPatients: data?.acceptingNewPatients ? 'Y' : 'N',
            spec1: data.specialty[0],
            spec2: data.specialty[1],
            spec3: data.specialty[2],
            language1: data.providerLanguage[0],
            language2: data.providerLanguage[1],
            language3: data.providerLanguage[2],
            gender: data?.gender,
            boardCertificate: data?.boardCertifiedOrEligible ? 'Y' : 'N',
            phone: data?.phone,
            hospital1: data?.hospital[0],
            hospital2: data?.hospital[1],
            hospital3: data?.hospital[2],
            county: data?.address?.county,
            city: data?.address?.city,
            state: data?.address?.state,
            zipcode: data?.address?.zip,
            address1: data?.address?.street1,
            address2: data?.address?.street2,
            healthPlan: data?.nameOfNetwork.join(', '),
            teleHealth: data?.telehealth ? 'Y' : 'N',
            officeHours: data?.hoursOfOperation,
            caLicense: data?.caLicense,
            nonCALicense: data?.nonCALicense,
            typeOfLicensure: data?.typeOfLicensure,
          });
        }
      }
    );
  }

  handleChange = (e: any): void => {
    const change: any = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  handleSave = (): any => {
    const { createReportInaccuraciesRequest } = this.props;
    const { email } = this.state;
    if (actions.Validators.validateEmail(email)) {
      showLoader();
      const data = this.state;
      createReportInaccuraciesRequest({ data, isAuthenticated: Auth.isAuthenticated() }, (res: any) => {
        if (res.isSuccess) {
          hideLoader();
          this.setState({ saveSuccess: true });
        } else {
          hideLoader();
          this.setState({ saveError: true });
        }
      });
    } else {
      toast.error('Please enter a valid email address');
    }
  };

  render(): React.ReactElement {
    const {
      saveError,
      saveSuccess,
      email,
      healthPlan,
      address2,
      address1,
      city,
      zipcode,
      state,
      phone,
      npi,
      fName,
      lName,
      mName,
      spec1,
      spec2,
      spec3,
      teleHealth,
      gender,
      language1,
      language3,
      language2,
      clinicName,
      mediaGroupIPA,
      hospital1,
      hospital2,
      hospital3,
      county,
      caLicense,
      typeOfLicensure,
      nonCALicense,
      acceptingNewPatients,
      boardCertificate,
      officeHours,
    } = this.state;

    const { data } = this.props;
    return (
      <>
        <div id='report-inaccuracies-container'>
          <div className='row'>
            <div className='col-12'>
              <span className='title'>{REPORT_INACCURACIES_LABLE}</span>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 instructions margin-bottom-15'>{REPORT_INACCURACIES_THANK_YOU_TEXT}</div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='email'>{EMAIL_ADDRESS}</label>
                <input
                  name='email'
                  value={email}
                  onChange={this.handleChange}
                  type='email'
                  className='form-control'
                  placeholder='Enter Email Address'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <span className='title'>
                {data?.DoctorsAndServicesData?.getReportInaccuraciesResponse?.data?.firstName}{' '}
                {data?.DoctorsAndServicesData?.getReportInaccuraciesResponse?.data?.lastName},
                {data?.DoctorsAndServicesData?.getReportInaccuraciesResponse?.data?.typeOfLicensure}{' '}
              </span>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 instructions margin-bottom-15'>{SUGGEST_INACCURACIES_TEXT}</div>
          </div>

          <div className='row mt-2'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='fName'>{FIRST_NAME_LABEL}</label>
                <input
                  name='fName'
                  value={fName}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter First Name'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='mName'>{MIDDLE_NAME_LABEL}</label>
                <input
                  name='mName'
                  value={mName}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Middle Name'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='lName'>{LAST_NAME_LABEL}</label>
                <input
                  name='lName'
                  value={lName}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Last Name'
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='spec1'>{SPECIALITY_LABEL_COUNT('1')}</label>
                <input
                  name='spec1'
                  value={spec1}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Specialty 1'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='spec2'>{SPECIALITY_LABEL_COUNT('2')}</label>
                <input
                  name='spec2'
                  value={spec2}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Specialty 2'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='spec3'>{SPECIALITY_LABEL_COUNT('3')}</label>
                <input
                  name='spec3'
                  value={spec3}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Specialty 3'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='acceptingNewPatient'>{ACCEPTING_NEW_PATIENTS_LABEL}</label>
                <input
                  name='acceptingNewPatients'
                  value={acceptingNewPatients}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Accepting new patients(Y/N)'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='telehealth'>{OFFERING_TELEHEALTH_LABEL}</label>
                <input
                  name='teleHealth'
                  value={teleHealth}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Telehealth (Y/N)'
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <hr />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='address1'>{ADDRESS1}</label>
                <input
                  name='address1'
                  value={address1}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Address 1'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='address1'>{ADDRESS2}</label>
                <input
                  name='address2'
                  value={address2}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Address 2'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='city'>{CITY}</label>
                <input
                  name='city'
                  value={city}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter City'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='state'>{STATE}</label>
                <input
                  name='state'
                  value={state}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter State'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='zipcode'>{ZIP_CODE}</label>
                <input
                  name='zipcode'
                  value={zipcode}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Zip Code'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='phone'>{PHONE_NUMBER}</label>
                <input
                  name='phone'
                  value={phone}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Phone Number'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <hr />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='gender'>{GENDER}</label>
                <input
                  name='gender'
                  value={gender}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Gender'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='boardCert.'>{BOARD_CERTIFICATE_LABEL}</label>
                <input
                  name='boardCertificate'
                  value={boardCertificate}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Ente Board Certificater'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='language1'>{LANGUAGE_LABEL_COUNT('1')}</label>
                <input
                  name='language1'
                  value={language1}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Language 1'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='language2'>{LANGUAGE_LABEL_COUNT('2')}</label>
                <input
                  name='language2'
                  value={language2}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Language 2'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='language3'>{LANGUAGE_LABEL_COUNT('3')}</label>
                <input
                  name='language3'
                  value={language3}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Language 3'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='officeHours'>{OFFICE_HOURS}</label>
                <input
                  name='officeHours'
                  value={officeHours}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Office Hours'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <hr />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='clinicName'>{CLINIC_NAME} </label>
                <input
                  name='clinicName'
                  value={clinicName}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Clinic Name'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='mediaGroupIPA'>{MEDIA_GROUP_IPA}</label>
                <input
                  name='mediaGroupIPA'
                  value={mediaGroupIPA}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Medical Group IPA'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='healthPlan'>{HEALTH_PLAN}</label>
                <input
                  name='healthPlan'
                  value={healthPlan}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Health Plans'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='hospital1'>{HOSPITAL_LABEL_COUNT('1')}</label>
                <input
                  name='hospital1'
                  value={hospital1}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Hospital 1'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='hospital2'>{HOSPITAL_LABEL_COUNT('2')}</label>
                <input
                  name='hospital2'
                  value={hospital2}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Hospital 2'
                />
              </div>
            </div>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='hospital3'>{HOSPITAL_LABEL_COUNT('3')}</label>
                <input
                  name='hospital3'
                  value={hospital3}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter Hospital 3'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 margin-bottom-15'>
              <div className='form-group'>
                <label htmlFor='county'>{COUNTY}</label>
                <input
                  name='county'
                  value={county}
                  onChange={this.handleChange}
                  type='text'
                  className='form-control'
                  placeholder='Enter County'
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <hr />
            </div>
          </div>
          <div className='background-popup margin-bottom-15'>
            <div className='row'>
              <div className='col-lg-12 instructions margin-bottom-15'>{REPORT_INACCURACIES_DOCTORS_EDIT_INFO}</div>
            </div>
            <div className='row'>
              <div className='col-sm-4 margin-bottom-15'>
                <div className='form-group'>
                  <label htmlFor='caLicense'>{CA_LICENSE_LABEL}</label>
                  <input
                    name='caLicense'
                    value={caLicense}
                    onChange={this.handleChange}
                    type='text'
                    className='form-control'
                    placeholder='Enter CA License'
                  />
                </div>
              </div>
              <div className='col-sm-4 margin-bottom-15'>
                <div className='form-group'>
                  <label htmlFor='nonCALicense'>{NON_CA_LICENSE_LABEL}</label>
                  <input
                    name='nonCALicense'
                    value={nonCALicense}
                    onChange={this.handleChange}
                    type='text'
                    className='form-control'
                    placeholder='Enter Non CA License'
                  />
                </div>
              </div>
              <div className='col-sm-4 margin-bottom-15'>
                <div className='form-group'>
                  <label htmlFor='typeServices'>{TYPE_OF_LICENSURE_LABEL}</label>
                  <input
                    name='typeOfLicensure'
                    value={typeOfLicensure}
                    onChange={this.handleChange}
                    type='text'
                    className='form-control'
                    placeholder='Enter Type of Licensure'
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='npi'>{NPI_LABLE}</label>
                  <input
                    name='npi'
                    value={npi}
                    onChange={this.handleChange}
                    type='text'
                    className='form-control'
                    placeholder='Enter NPI'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row text-align-right d-flex justify-content-end'>
            <div className='col-6 col-sm-3 col-lg-2 mb-2 mb-sm-0'>
              <button className='btn btn-report-cancel' type='button' onClick={() => window.close()}>
                {CANCEL}
              </button>
            </div>
            <div className='col-6 col-sm-3 col-lg-2'>
              <button className='btn btn-report-save' type='button' onClick={this.handleSave}>
                {SAVE}
              </button>
            </div>
          </div>
        </div>
        <ConfirmationModal
          title='Thank you!'
          showModal={saveSuccess}
          closeModal={() => this.setState({ saveSuccess: false }, () => window.close())}
          message={`The form has been submitted successfully. Click 'Ok' to close this tab and return back to the Doctors and Services search page.`}
        />
        <ConfirmationModal
          title={`Error Code: ${data?.DoctorsAndServicesData?.errorData?.data?.statusCode}`}
          showModal={saveError}
          closeModal={() => this.setState({ saveError: false })}
          message={data?.DoctorsAndServicesData?.reportInaccuraciesErrorMessage}
        />
      </>
    );
  }
}
const mapStateToProps: any = (state: actions.manageFilterDataState): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetReportInaccuraciesRequest: actions.createGetReportInaccuraciesRequest,
  createReportInaccuraciesRequest: actions.createReportInaccuraciesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportInaccuraciesDoctor);
