import React, { PureComponent } from 'react';
import Analytics, { FA } from '../../utils/Analytics';
// import { CANCEL, LOGIN_OR_CREATE_ACCOUNT_LABEL } from '../../constant/AppConstants';
// import { LOGIN_PAGE_URL } from '../../constant/Urls';
// import { history } from '../../helperMethods/HelperMethod';

interface ComfirmationModalProps {
  showModal: boolean;
  closeModal: any;
  title: string;
  message: string;
  btn1Title: string;
  btn2Title: string;
  action: any;
  additionalProps?:any
  linkUrl?:any;
}
interface ComfirmationModalState {}
class ComfirmationModalWithTwoButtons extends PureComponent<
  ComfirmationModalProps,
  ComfirmationModalState
> {
  componentDidMount(): any {
    const { message, title, closeModal } = this.props;
    Analytics.logEvent(FA.APPLICATION_ALERT, { title, description: message });
  }

  render(): JSX.Element {
    const { showModal, closeModal, message, title, btn1Title, btn2Title, action, additionalProps, linkUrl } = this.props;
    const show = showModal ? 'show' : 'd-none';
    return (
      <>
        <div className={`modal-backdrop fade ${show}`} />
        <div className={`modal fade ${show}`}>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content modal-dialog-wider '>
              <div className='modal-header'>
                <h4 className='modal-title'>{title}</h4>
              </div>
              <div className='modal-body'>
                <p>{message}</p>
              </div>
              <div className='modal-footer justify-content-end text-sm-right'>
                <button
                  onClick={() => additionalProps === 'external-link' ? (window.open(linkUrl, '_blank'), closeModal(false)) : action()}
                  type='button'
                  className='btn btn-confirm float-end'
                  data-bs-dismiss='modal'>
                  {btn1Title}
                </button>
                <button
                  onClick={() => closeModal(false)}
                  type='button'
                  className='btn btn-confirm float-end'
                  data-bs-dismiss='modal'>
                  {btn2Title}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ComfirmationModalWithTwoButtons;
