/* eslint-disable no-underscore-dangle */
import * as actions from '@canopy/shared';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  DOCTORS_LABLE,
  DoctorsHealthPlans,
  HOSPITALS_TEXT,
  SERVICES,
  URGENT_CARE_LABLE,
  WHAT_DO_YOU_WANT_TO_LOOK_FOR_LABEL,
} from '../../../shared/constant/AppConstants';
// import { NavigationArray } from '../../../shared/constant/AppConstants';
import { APP_HOME_URL, GENERIC_LINKS_DS_URL } from '../../../shared/constant/Urls';
import { history } from '../../../shared/helperMethods/HelperMethod';
import Analytics, { FA } from '../../../shared/utils/Analytics';
import Auth from '../../../shared/utils/Auth';
import images from '../../../shared/utils/Images';
import { hideLoader } from '../../../shared/utils/ReusableComponents';

interface ownState {
  activeLink: string;
}

interface ownProps {}
interface DispatchProps {
  createGetModuleConfigDataRequest: typeof actions.createGetModuleConfigDataRequest;
}

interface stateProps {
  data: any;
}

type componentProps = ownProps & DispatchProps & stateProps;
class DoctorsAndServicesMenu extends PureComponent<componentProps, ownState> {
  constructor(props: componentProps) {
    super(props);
    this.state = {
      activeLink: 'doctors',
    };
  }

  componentDidMount(): any {
    const pageUrl = window.location.pathname.split('/')[2];
    if (pageUrl !== '') {
      this.setState({
        activeLink: pageUrl,
      });
    }
    hideLoader();
    this.onInit();
  }

  onInit = (): any => {
    const { activeLink } = this.state;
    const { createGetModuleConfigDataRequest, data } = this.props;
    if (data.ConfigData?.getMainConfigdata?.data?._id) {
      createGetModuleConfigDataRequest(
        {
          query: `configId=${data.ConfigData?.getMainConfigdata?.data?._id}&module=${'doctor'}`,
          isAuthenticated: Auth.isAuthenticated(),
        },
        (res: any) => {
          hideLoader();
          if (activeLink === 'doctors' && !res?.data?.sections?.providers?.enabled) {
            history.push(APP_HOME_URL);
          }
          if (activeLink === 'hospitals' && !res?.data?.sections?.hospitals?.enabled) {
            history.push(APP_HOME_URL);
          }
          if (activeLink === 'services' && !res?.data?.sections?.services?.enabled) {
            history.push(APP_HOME_URL);
          }
          if (activeLink === 'urgentCare' && !res?.data?.sections?.urgentCare?.enabled) {
            history.push(APP_HOME_URL);
          }
        }
      );
    }
  };

  getEnabledSection = (section: any): any => {
    const { data } = this.props;
    const enabled = data?.ConfigData?.getModuleConfigdata?.data?.section;
    return enabled?.length > 0 && enabled[section]?.enabled;
  };

  getLocationLink = (location: string): any => {
    const { data } = this.props;
    const filteredData = data?.ConfigData?.getModuleConfigdata?.data?.addressableSpaces?.filter(
      (i: any) => i.location === location
    );
    return filteredData?.length > 0 && filteredData[0];
  };

  render(): React.ReactElement {
    const { data } = this.props;
    const { activeLink } = this.state;
    return (
      <>
        <div className='sub-menu clearfix text-center text-xl-start d-flex flex-column flex-xl-row align-items-center'>
          <h4 className=''>{WHAT_DO_YOU_WANT_TO_LOOK_FOR_LABEL}</h4>
          <ul>
            {Auth.getHealthPlan() !== DoctorsHealthPlans.DOCTORS_PLAN && (
              <>
                {data?.ConfigData?.getModuleConfigdata?.data?.sections?.providers?.enabled && (
                  <li
                    className={`navigation-item ${
                      activeLink === 'doctor' || !activeLink ? 'submenuactive' : 'navigation-item'
                    }`}>
                    <button
                      onClick={() => {
                        history.push('/network/doctor');
                        Analytics.logEvent(FA.DOCTOR_AND_SERVICE_PROVIDER_VIEW);
                      }}
                      onKeyPress={() => {
                        history.push('/network/doctor');
                        Analytics.logEvent(FA.DOCTOR_AND_SERVICE_PROVIDER_VIEW);
                      }}
                      type='button'
                      tabIndex={-2}>
                      {DOCTORS_LABLE}
                    </button>
                  </li>
                )}
                {data?.ConfigData?.getModuleConfigdata?.data?.sections?.hospitals?.enabled && (
                  <li className={`navigation-item ${activeLink === 'hospitals' ? 'submenuactive' : 'navigation-item'}`}>
                    <button
                      onClick={() => {
                        history.push('/network/hospitals');
                        Analytics.logEvent(FA.DOCTOR_AND_SERVICE_HOSPITAL_VIEW);
                      }}
                      onKeyPress={() => {
                        history.push('/network/hospitals');
                        Analytics.logEvent(FA.DOCTOR_AND_SERVICE_HOSPITAL_VIEW);
                      }}
                      tabIndex={-3}
                      type='button'>
                      {HOSPITALS_TEXT}
                    </button>
                  </li>
                )}
                {data?.ConfigData?.getModuleConfigdata?.data?.sections?.services?.enabled && (
                  <li className={`navigation-item ${activeLink === 'services' ? 'submenuactive' : 'navigation-item'}`}>
                    <button
                      onClick={() => {
                        history.push('/network/services');
                        Analytics.logEvent(FA.DOCTOR_AND_SERVICE_SERVICE_VIEW);
                      }}
                      onKeyPress={() => {
                        history.push('/network/services');
                        Analytics.logEvent(FA.DOCTOR_AND_SERVICE_SERVICE_VIEW);
                      }}
                      type='button'
                      // role='button'
                      tabIndex={-1}>
                      {SERVICES}
                    </button>
                  </li>
                )}
                {data?.ConfigData?.getModuleConfigdata?.data?.sections?.urgentCare?.enabled && (
                  <li
                    className={`navigation-item ${activeLink === 'urgentCare' ? 'submenuactive' : 'navigation-item'}`}>
                    <button
                      onClick={() => {
                        history.push('/network/urgentCare');
                      }}
                      onKeyPress={() => {
                        history.push('/network/urgentCare');
                      }}
                      type='button'>
                      {URGENT_CARE_LABLE}
                    </button>
                  </li>
                )}
              </>
            )}
            {/* <div className='generic-pills d-inline' /> */}

            {this.getLocationLink('doctorsAndServices-header')?.items?.map((i: any) => (
              <li className='generic-pills d-inline'>
                <div className='horizontal-links pill d-inline'>
                  <div className='link-wrapper d-inline'>
                    <button
                      className={`${
                        window.location.pathname.split('/')[3] === i?.text?.toLowerCase()?.replace(/\s/g, '')
                          ? 'link-pill-active'
                          : ''
                      }`}
                      type='button'
                      onClick={() =>
                        history.push(`${GENERIC_LINKS_DS_URL}/${i?.text?.toLowerCase()?.replace(/\s/g, '')}`, {
                          from: 'ds',
                          data: { url: i?.url, text: i?.text },
                        } as {
                          from: string;
                          data: any;
                        })
                      }>
                      {window.location.pathname.split('/')[3] === i?.text?.toLowerCase()?.replace(/\s/g, '') ? (
                        <img
                          alt='Link'
                          className='clickable arrow img-fluid me-2'
                          src={images.icDeeplink}
                          srcSet={`${images.icDeeplink}, ${images.icDeeplinkWhite2x} 2x, ${images.icDeeplinkWhite3x} 3x`}
                        />
                      ) : (
                        <img
                          alt='Link'
                          className='clickable arrow img-fluid me-2'
                          src={images.icDeeplinkGray}
                          srcSet={`${images.icDeeplinkGray}, ${images.icDeeplinkGray2x} 2x, ${images.icDeeplinkGray3x} 3x`}
                        />
                      )}

                      <span>{i?.text}</span>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetModuleConfigDataRequest: actions.createGetModuleConfigDataRequest,
};

export default connect<stateProps, DispatchProps, ownProps>(
  mapStateToProps,
  mapDispatchToProps
)(DoctorsAndServicesMenu);
