/* eslint-disable no-underscore-dangle */
import * as actions from '@canopy/shared';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Auth from '../../../../shared/utils/Auth';
import Images from '../../../../shared/utils/Images';

interface ownState {
  profilePicture: any;
  isPicturePresent: boolean;
}

interface ownProps {
  provider: any;
  details: any;
}
interface DispatchProps {
  fetchProviderImage: typeof actions.fetchProviderImage;
}
interface stateProps {}

type componentProps = ownProps & DispatchProps & stateProps;
class ProfilePicture extends PureComponent<componentProps, ownState> {
  constructor(props: componentProps) {
    super(props);
    this.state = { profilePicture: '', isPicturePresent: false };
  }

  componentDidMount(): void {
    const { fetchProviderImage, provider } = this.props;
    fetchProviderImage({ query: { npi: provider.npi }, isAuthenticated: Auth.isAuthenticated() }, (response: any) => {
      if (response.isSuccess) {
        this.setState({
          profilePicture: `data:image/png;base64,${response?.data?.bodyInfo}`,
          isPicturePresent: true,
        });
      } else {
        this.setState({ profilePicture: provider?.gender === 'Male' ? Images.avatarM : Images.avatarF });
      }
    });
    this.setState({ profilePicture: '' });
  }

  render(): React.ReactElement {
    const { profilePicture, isPicturePresent } = this.state;
    const { details, provider } = this.props;
    return (
      <div className={`provider-avatar ${details?.pronoun ? 'hasPronoun' : ''}`}>
        <a
          className='mt-1 d-flex'
          id={`${provider?._id}_span`}
          data-bs-toggle='collapse'
          href={`#collapseDetails${provider?._id}`}
          role='button'
          aria-expanded='false'>
          <img
            id={`${provider?._id}_profilepic`}
            className={`avatar ${isPicturePresent ? 'border' : ''}`}
            src={profilePicture}
            alt='Avatar'
          />
        </a>
        {details?.video && (
          <a
            className='watch-video-wrap d-flex align-items-center '
            id={`${provider?._id}_span`}
            data-bs-toggle='collapse'
            href={`#collapseDetails${provider?._id}`}
            role='button'
            aria-expanded='false'>
            <img className='play-button' src={Images.watchVideo} alt='' width={21} height={21} />
            <span className='watch-video'>Watch Video</span>
          </a>
        )}
        {details?.lgbtq && (
          <div className='img-text col-auto px-0 mt-2 d-none lgbtq-sm'>
            <img className='alert-icon img-fluid' src={Images.LGBTQ} alt='LGBTQ+' />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  fetchProviderImage: actions.fetchProviderImage,
};

export default connect<stateProps, DispatchProps, ownProps>(mapStateToProps, mapDispatchToProps)(ProfilePicture);
