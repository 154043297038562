/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as action from '@canopy/shared';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { LOGIN_PAGE_URL } from '../../shared/constant/Urls';
import { history } from '../../shared/helperMethods/HelperMethod';
import Auth from '../../shared/utils/Auth';
import { hideLoader } from '../../shared/utils/ReusableComponents';

type Guards = { items: boolean[]; fallback?: string };

interface IProps extends RouteProps {
  component?: any;
  layout: React.ComponentClass<any>;
  pageTitle?: string;
  guards?: Guards;
}

export const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  pageTitle,
  guards = { items: [] },
  ...rest
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const { items: guardsItems } = guards;

  const logoutUser = (): any => {
    history.push(LOGIN_PAGE_URL);
    Auth.logout(() => {
      dispatch(action.createGetMainUnAuthConfigDataRequest(() => console.log('user logged out successfully')));
    });
  };

  const isSessionTimeout = (res: any): any => {
    if (JSON.parse(JSON.stringify(res)).status === 401) {
      hideLoader();
      logoutUser();
      return true;
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (Auth.isAuthenticated() && guardsItems.every((guard) => guard)) {
          return (
            <Layout title={pageTitle} key={props.location.pathname + props.location?.state?.contentId}>
              <Helmet>
                <title>{pageTitle}</title>
              </Helmet>
              <Component {...props} isSessionTimeout={isSessionTimeout} />
            </Layout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: guards.fallback || '/',
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};
