import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { PureComponent } from 'react';
import {
  ACCEPTING_NEWPATIENT_VAR,
  ACCEPTING_NEW_PATIENTS,
  ACCEPTING_ONLINE_SCHEDULING,
  CITY,
  CLEAR,
  COUNTY,
  DISTANCE,
  DOCTORS_LANGUAGE,
  GENDER,
  HOSPITALS_OPTION,
  LGBTQ_VAR,
  MEDIA_GROUP_IPA_LABLE,
  MINUS_ADVANCED_FILTERS,
  OFFERING_TELEHEALTH,
  PLEASE_ENTER_ZIP_CODE,
  PLUS_ADVANCED_FILTERS,
  PRIMARY_CARE_SPECIALITY_VAR,
  PROVIDER_TEXT,
  SELECT_HEALTH_PLAN,
  SPECIALITY_VAR,
  TELEHEALTH_AVAILABLE_VAR,
  TYPE_OF_LICENSURE_LABEL,
  ZIP_CODE,
} from '../../../shared/constant/AppConstants';
import { history, zipCodeValidation } from '../../../shared/helperMethods/HelperMethod';
import MultiselectDropDown from '../../../shared/sharedComponents/multiSelectDropDown.tsx/MultiselectDropDown';
import Analytics, { FA } from '../../../shared/utils/Analytics';
import Auth from '../../../shared/utils/Auth';
import Images from '../../../shared/utils/Images';
import { FilterFromMap } from './Doctors';
import { AreaType } from './MapView/types';

const isFilterFromMap = (e: any): e is FilterFromMap => {
  return e && (e.type === AreaType.Zipcode || e.type === AreaType.County);
};

interface basicFilters {
  nameOfNetwork: string;
  name: string;
  gender: string;
  doctorLanguage: string;
  zip: string;
  distance: string;
  acceptingNewPatients: string;
  telehealth: string;
  providerPrimaryCare: [];
  zipCodeBtnActive: boolean;
  expanded: boolean;
  city: string;
  disabledSearch: boolean;
  vimBookable: any;
  zipcodeError: boolean;
  disabled: boolean;
  isSelectPlanDisabled?: boolean;
  isMouseInside?: boolean;
  ddlWidth?: number;
  open?: boolean;
}

interface advancedFilters {
  medicalGroup?: string;
  clinicName: string;
  CALicense: string;
  nonCALicense: string;
  hospital: string;
  county: string;
  state: string;
  typeOfLicensure: string;
  NPI: string;
  payorProviderID: string;
  lgbtq: string;
  disabledAll: boolean;
}

interface ownProps {
  filterData: any;
  createGetDoctorsRequest: any;
  healthPlanData: any;
  updateFilterData: any;
  setStringifiedQueryString: any;
  clearResult: any;
  filterFromMap: FilterFromMap;
  countyProps: string;
}

type ownState = advancedFilters & basicFilters;

class SearchFilters extends PureComponent<ownProps, ownState> {
  searchData: any;

  multiSelectRef: React.RefObject<MultiselectDropDown> = React.createRef();

  initialState: Readonly<ownState>;

  selectPlanRef: any;

  constructor(props: ownProps) {
    super(props);
    this.initialState = {
      disabled: false,
      nameOfNetwork: '',
      name: '',
      gender: '',
      doctorLanguage: '',
      zip: '',
      distance: '',
      acceptingNewPatients: '',
      telehealth: '',
      providerPrimaryCare: [],
      medicalGroup: '',
      city: '',
      clinicName: '',
      CALicense: '',
      nonCALicense: '',
      hospital: '',
      county: '',
      state: '',
      typeOfLicensure: '',
      NPI: '',
      zipCodeBtnActive: true,
      expanded: false,
      disabledSearch: false,
      lgbtq: '',
      vimBookable: '',
      zipcodeError: false,
      payorProviderID: '',
      disabledAll: false,
      isSelectPlanDisabled: true,
      isMouseInside: false,
      ddlWidth: 540,
      open: false,
    };
    this.selectPlanRef = React.createRef();
    this.state = this.initialState;
    this.searchData = {
      nameOfNetwork: Auth.getHealthPlan(),
      serviceName: PROVIDER_TEXT,
      sort: { lastName: 1 },
      page: 1,
      limit: 15,
    };
  }

  componentDidMount(): any {
    if (history.location?.search) {
      this.handleURLParams(history.location?.search);
    } else {
      this.setState({ nameOfNetwork: Auth.getHealthPlan() });
    }
    if (Auth.isAuthenticated()) {
      this.setState({ disabled: false });
    } else if (Auth.getHealthPlan() === '') {
      this.setState({ disabled: true });
    }
    zipCodeValidation();
    this.setState({ ddlWidth: this.selectPlanRef?.current?.offsetWidth });
  }

  componentDidUpdate(prevProps: Readonly<ownProps>): void {
    const { filterFromMap } = this.props;
    if (prevProps.filterFromMap.value !== filterFromMap.value) {
      // this.handleZipCodeChange(
      //   filterFromMap.type === AreaType.Zipcode ? filterFromMap : { value: '', type: AreaType.Zipcode }
      // );
      // this.handleChange(filterFromMap.type === AreaType.County ? filterFromMap : { value: '', type: AreaType.County });

      if (filterFromMap.type === AreaType.County) {
        const change: any = {};
        change[filterFromMap.type] = filterFromMap.value;
        this.setState(change);
        // show #collapseExample with document if it is not already shown
        const collapseExample = document.getElementById('collapseExample');
        if (collapseExample && !collapseExample.classList.contains('show')) {
          collapseExample.classList.add('show');
          const advanceToggler = document.getElementById('advanceToggler');
          advanceToggler?.setAttribute('aria-expanded', 'true');
        }
      }
    }
  }

  handleURLParams = (queryString: string): void => {
    const params = queryString.substring(queryString.indexOf('=') + 1, queryString.length);
    if (params === 'CanopyCare') {
      this.setState({ nameOfNetwork: 'Canopy Health - Health Net CanopyCare' });
    } else if (params === 'SignatureValue Harmony') {
      this.setState({ nameOfNetwork: 'Canopy Health - UnitedHealthcare SignatureValue Harmony' });
    } else {
      this.setState({ nameOfNetwork: Auth.getHealthPlan() });
    }
  };

  handleChange = (e: any): void => {
    const value = isFilterFromMap(e) ? e.value : e.target.value;
    const name = isFilterFromMap(e) ? 'county' : e.target.name;

    const { payorProviderID } = this.state;
    this.setState({ disabledSearch: false, disabled: false });
    const change: any = {};
    change[name] = value;
    this.setState(change, () => this.handleSearchFilterChange(name, value));
    if (name === 'payorProviderID') {
      if (value) {
        this.handleProviderIdChange();
      } else {
        this.setState({ disabledAll: false });
      }
    } else if (!payorProviderID) {
      this.setState({ disabledAll: false });
    }
  };

  handleProviderIdChange = (): void => {
    this.setState({
      disabledAll: true,
      name: '',
      gender: '',
      doctorLanguage: '',
      zip: '',
      distance: '',
      acceptingNewPatients: '',
      telehealth: 'N',
      medicalGroup: '',
      city: '',
      clinicName: '',
      CALicense: '',
      nonCALicense: '',
      hospital: '',
      county: '',
      typeOfLicensure: '',
      NPI: '',
      zipCodeBtnActive: true,
      disabledSearch: false,
      vimBookable: '',
      zipcodeError: false,
    });
  };

  handleZipCodeChange = (e: any): any => {
    const value = isFilterFromMap(e) ? e.value : e.target.value;
    const name = isFilterFromMap(e) ? 'zip' : e.target.name;

    if (value.length > 6 && !isFilterFromMap(e)) {
      e.preventDefault();
      return;
    }
    // console.log('I was called', value, zipValidation(value));
    const { distance } = this.state;
    if (value.length <= 6) this.setState({ zip: value });
    if (value.length > 0 && value.length <= 6) {
      if (distance === '') {
        this.setState({ distance: '50 miles' });
        this.handleSearchFilterChange('distance', '50 miles');
      }
    }
    if (value === '') {
      this.setState({ distance: '' });
      this.handleSearchFilterChange('distance', '');
    }
    if (value.length <= 6) this.handleSearchFilterChange(name, value);
  };

  handleRadioButtonChange = (e: any): any => {
    const change: any = {};
    if (e.target.name === ACCEPTING_NEWPATIENT_VAR && e.target.checked) {
      this.handleSearchFilterChange(e.target.name, e.target.checked ? 'Y' : 'N');
    } else if (e.target.name === TELEHEALTH_AVAILABLE_VAR && e.target.checked) {
      this.handleSearchFilterChange(e.target.name, e.target.checked ? 'Y' : 'N');
    } else if (e.target.name === LGBTQ_VAR && e.target.checked) {
      this.handleSearchFilterChange(e.target.name, e.target.checked);
    } else {
      this.handleSearchFilterChange(e.target.name, '');
    }
    change[e.target.name] = e.target.checked ? 'Y' : 'N';
    this.setState(change);
  };

  handleAcceptingOnlineSchedulingChange = (e: any): any => {
    const change: any = {};
    if (e.target.checked) {
      this.handleSearchFilterChange(e.target.name, e.target.checked);
    } else {
      this.handleSearchFilterChange(e.target.name, '');
    }
    change[e.target.name] = e.target.checked ? 'Y' : 'N';
    this.setState(change);
  };

  handleNetworkChange = (e: any): void => {
    if (!e.target.value) {
      this.handleChange(e);
      this.clear();
    } else {
      this.handleChange(e);
      const { updateFilterData } = this.props;
      const query = {
        nameOfNetwork: e.target.value,
        serviceName: PROVIDER_TEXT,
      };
      Auth.setHealthPlan(e.target.value);
      this.setState({ isSelectPlanDisabled: true, open: false });
      updateFilterData({ query }, () => console.log('test'));
    }
  };

  handleSearchFilterChange = (name: string, value: any): void => {
    const alreadyExistsData = Object.keys(this.searchData).filter((i: any) => i === name);
    if (alreadyExistsData.length > 0 && value === '') {
      delete this.searchData[name];
    } else if (value !== '') {
      Object.assign(this.searchData, { [name]: value });
    }
  };

  clear = (): void => {
    const { filterData, clearResult } = this.props;
    this.setState(this.initialState);
    this.multiSelectRef.current?.onClear();
    this.searchData = {
      nameOfNetwork: Auth.getHealthPlan(),
      serviceName: PROVIDER_TEXT,
      sort: { lastName: 1 },
      page: 1,
      limit: 15,
    };
    if (Auth.isAuthenticated()) {
      if (filterData.nameOfNetwork) {
        this.setState({ nameOfNetwork: filterData.nameOfNetwork });
      } else {
        this.setState({ nameOfNetwork: '' });
      }
    } else {
      this.setState({ disabledSearch: true, disabled: true, isSelectPlanDisabled: false });
    }
    clearResult();
  };

  onSearch = (): any => {
    this.setState({ isSelectPlanDisabled: true });
    const { createGetDoctorsRequest, setStringifiedQueryString } = this.props;
    const { distance, zip, zipCodeBtnActive } = this.state;
    if (distance !== '' && zip === '') {
      this.setState({ zipcodeError: true });
      // setTimeout(() => this.setState({ zipcodeError: false }), 1000);
    } else {
      const primarySpericalityData = this.multiSelectRef.current?.getSelectedData();

      Object.assign(this.searchData, { page: 1 });
      if (Object.prototype.hasOwnProperty.call(this.searchData, 'zip')) {
        if (this.searchData.zip === '') {
          delete this.searchData.city;
        } else {
          Object.assign(this.searchData, { sort: { distance: 1 } });
        }
      }
      if (
        this.searchData.distance === 'Match Zipcode' ||
        this.searchData.distance === undefined ||
        this.searchData.distance === ''
      ) {
        Object.assign(this.searchData, { sort: { lastName: 1 } });
      }
      if (zipCodeBtnActive) {
        delete this.searchData.city;
      }
      if (!zipCodeBtnActive) {
        delete this.searchData.zip;
        delete this.searchData.distance;
        Object.assign(this.searchData, { sort: { lastName: 1 } });
      }
      if (!this.searchData.zip) {
        delete this.searchData.distance;
      }
      this.handleSearchFilterChange(PRIMARY_CARE_SPECIALITY_VAR, primarySpericalityData.primaryCare);

      this.handleSearchFilterChange(SPECIALITY_VAR, primarySpericalityData.providerSpecialitiesString);

      if (!this.searchData.nameOfNetwork) {
        this.searchData.nameOfNetwork = Auth.getHealthPlan();
      }
      if (this.searchData.nameOfNetwork && this.searchData.nameOfNetwork !== '') {
        createGetDoctorsRequest({ query: this.searchData });
      }
      setStringifiedQueryString(this.searchData);
      this.setState({ zipcodeError: false });

      Analytics.logEvent(FA.DOCTOR_AND_SERVICE_PROVIDER_SEARCH, {
        accepting_patients: this.searchData?.acceptingNewPatients ? this.searchData?.acceptingNewPatients : '',
        ca_license: this.searchData?.nonCALicense ? this.searchData?.nonCALicense : '',
        city: this.searchData?.city ? this.searchData?.city : '',
        clinic_name: this.searchData?.clinicName ? this.searchData?.clinicName : '',
        county: this.searchData?.county ? this.searchData?.county : '',
        distance: this.searchData?.distance ? this.searchData?.distance : '',
        gender: this.searchData?.gender ? this.searchData?.gender : '',
        health_plan: this.searchData?.nameOfNetwork ? this.searchData?.nameOfNetwork : '',
        hospitals: this.searchData?.hospital ? this.searchData?.hospital : '',
        media_group_ipa: this.searchData?.medicalGroup ? this.searchData?.medicalGroup : '',
        non_ca_license: this.searchData?.nonCALicense ? this.searchData?.nonCALicense : '',
        primary_care: this.searchData?.primaryCareSpeciality ? this.searchData?.primaryCareSpeciality : '',
        provider_language: this.searchData?.doctorLanguage ? this.searchData?.doctorLanguage : '',
        provider_name: this.searchData?.name ? this.searchData?.name : '',
        speciality: this.searchData?.primaryCareSpeciality ? this.searchData?.primaryCareSpeciality : '',
        telehealth_available: this.searchData?.telehealth ? this.searchData?.telehealth : '',
        type_of_licensure: this.searchData?.typeOfLicensure ? this.searchData?.typeOfLicensure : '',
        zip_code: this.searchData?.zip ? this.searchData?.zip : '',
      });
    }
  };

  mouseEnter = (): any => {
    this.setState({ isMouseInside: true });
  };

  mouseLeave = (): any => {
    this.setState({ isMouseInside: false });
  };

  onEditClick = (): any => {
    this.setState({ isSelectPlanDisabled: false, open: true });
    this.selectPlanRef?.current?.focus();
  };

  render(): React.ReactElement {
    const {
      nameOfNetwork,
      name,
      doctorLanguage,
      gender,
      zip,
      distance,
      medicalGroup,
      city,
      clinicName,
      CALicense,
      nonCALicense,
      hospital,
      county,
      typeOfLicensure,
      NPI,
      zipCodeBtnActive,
      disabledSearch,
      acceptingNewPatients,
      // lgbtq,
      telehealth,
      vimBookable,
      zipcodeError,
      disabledAll,
      disabled,
      isSelectPlanDisabled,
      isMouseInside,
      ddlWidth,
      open,
    } = this.state;
    const { filterData, healthPlanData, countyProps } = this.props;
    return (
      <>
        <div className='common-filters container-fluid'>
          <form className='form-inline'>
            <div className='col-lg-12 col-md-12 col-sm-12 no-padding '>
              <div className='row ds-filter'>
                <div className='control-container pe-xl-0 px-2'>
                  {Auth.isAuthenticated() && (
                    <div className='row'>
                      <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4 order-0'>
                        <input
                          disabled={disabled || disabledAll}
                          type='text'
                          placeholder='Doctor Name'
                          name='name'
                          value={name}
                          onChange={this.handleChange}
                          autoComplete='off'
                          maxLength={100}
                        />
                      </div>
                      <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4 order-1'>
                        <div className='multiselect custom-dark health-plan position-relative'>
                          <MultiselectDropDown
                            ref={this.multiSelectRef}
                            data1={filterData && filterData.primaryCareSpecialty ? filterData.primaryCareSpecialty : []}
                            data2={filterData && filterData.specialty ? filterData.specialty : []}
                            disabled={disabled || disabledAll}
                          />
                        </div>
                      </div>
                      <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4 order-2'>
                        <input
                          disabled={disabled || disabledAll}
                          type='text'
                          placeholder='Clinic Name'
                          name='clinicName'
                          value={clinicName}
                          onChange={this.handleChange}
                          maxLength={100}
                        />
                      </div>

                      <div className='form-group checkbox col-12 col-md-6 col-lg-6 col-xl-4 order-3'>
                        <div className='btn-group btn-group-justified col-12'>
                          <button
                            disabled={disabled || disabledAll}
                            onClick={() => {
                              this.setState({ zipCodeBtnActive: true, city: '' });
                              delete this.searchData.city;
                              delete this.searchData.distance;
                            }}
                            type='button'
                            className={`btn btn-default toggleBtn ${zipCodeBtnActive ? 'active' : ''}`}>
                            {ZIP_CODE}
                          </button>
                          <button
                            disabled={disabled || disabledAll}
                            onClick={() => {
                              this.setState({ zipCodeBtnActive: false, zip: '', distance: '' });
                              delete this.searchData.zip;
                              delete this.searchData.distance;
                            }}
                            type='button'
                            className={`btn btn-default toggleBtn ${zipCodeBtnActive ? '' : 'active'}`}>
                            {CITY}
                          </button>
                        </div>
                      </div>

                      <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4 order-4 order-md-5 order-lg-5 order-xl-4'>
                        <div className='row'>
                          {zipCodeBtnActive ? (
                            <>
                              <div className='form-group-sm col-sm-6 col-lg-6 col-xl-5 position-relative left-0 lg-pr-10'>
                                <input
                                  max={6}
                                  disabled={disabled || disabledAll}
                                  className='zip-input'
                                  type='number'
                                  inputMode='numeric'
                                  placeholder='Zip code'
                                  name='zip'
                                  value={zip}
                                  onChange={(e: any) => {
                                    this.handleZipCodeChange(e);
                                  }}
                                  onBlur={() => {
                                    return Number(zip) < 0
                                      ? this.setState({ zip: '' }, () => this.handleSearchFilterChange('zip', ''))
                                      : null;
                                  }}
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      'wheel',
                                      (event) => {
                                        event.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                                {zipcodeError && zip === '' && (
                                  <span className='error-zipcode'>{PLEASE_ENTER_ZIP_CODE}</span>
                                )}
                              </div>
                              <div className='col-sm-6 col-lg-6 col-xl-7 right-0 lg-pl-10'>
                                <Select
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                  }}
                                  displayEmpty
                                  disabled={disabled || disabledAll}
                                  className='select distance-dropdown'
                                  onChange={this.handleChange}
                                  name='distance'
                                  value={distance}>
                                  <MenuItem value=''>{DISTANCE}</MenuItem>
                                  {filterData &&
                                    filterData.distance &&
                                    filterData.distance.map((i: any) => (
                                      <MenuItem title={i} value={i} key={i}>
                                        {i}
                                      </MenuItem>
                                    ))}
                                </Select>
                                {/* <select
                                  disabled={disabled || disabledAll}
                                  className='distance-dropdown'
                                  name='distance'
                                  value={distance}
                                  onChange={this.handleChange}>
                                  <option value=''>{DISTANCE} </option>
                                  {filterData &&
                                    filterData.distance &&
                                    filterData.distance.map((i: any) => (
                                      <option value={i} key={i}>
                                        {i}
                                      </option>
                                    ))}
                                </select> */}
                              </div>
                            </>
                          ) : (
                            <div className='col-lg-12 col-md-12 col-sm-12 ps-0 pe-0'>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                  },
                                }}
                                displayEmpty
                                disabled={disabled || disabledAll}
                                name='city'
                                className='select distance-dropdown'
                                onChange={this.handleChange}
                                value={city}>
                                <MenuItem value=''>{CITY}</MenuItem>
                                {filterData &&
                                  filterData.city?.sort().map((i: any) => (
                                    <MenuItem title={i} value={i} key={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {/* <select
                                disabled={disabled || disabledAll}
                                name='city'
                                onChange={this.handleChange}
                                value={city}>
                                <option value=''>{CITY} </option>
                                {filterData &&
                                  filterData.city &&
                                  filterData.city.sort().map((i: any) => (
                                    <option value={i} key={i}>
                                      {i}
                                    </option>
                                  ))}
                              </select> */}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4 order-5 order-md-4 order-lg-4 order-xl-5'>
                        <div className='row'>
                          <div className='form-group-sm col-sm-6 col-lg-5 left-0 lg-pr-10'>
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                              }}
                              displayEmpty
                              disabled={disabled || disabledAll}
                              name='gender'
                              className='select distance-dropdown mb-0'
                              onChange={this.handleChange}
                              value={gender}>
                              <MenuItem value=''>{GENDER}</MenuItem>
                              {filterData &&
                                filterData.gender?.sort().map((i: any) => (
                                  <MenuItem title={i} value={i} key={i}>
                                    {i}
                                  </MenuItem>
                                ))}
                            </Select>
                            {/* <select
                              disabled={disabled || disabledAll}
                              id='select_gender'
                              className='mb-0'
                              name='gender'
                              value={gender}
                              style={{ fontSize: 18 }}
                              onChange={this.handleChange}>
                              <option value=''>{GENDER} </option>
                              {filterData &&
                                filterData.gender &&
                                filterData.gender.sort().map((i: any) => (
                                  <option value={i} key={i}>
                                    {i}
                                  </option>
                                ))}
                            </select> */}
                          </div>

                          <div className='col-sm-6 col-lg-7 right-0 lg-pl-10'>
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                              }}
                              displayEmpty
                              disabled={disabled || disabledAll}
                              name='doctorLanguage'
                              value={doctorLanguage}
                              className='select distance-dropdown'
                              onChange={this.handleChange}>
                              <MenuItem value=''>{DOCTORS_LANGUAGE}</MenuItem>
                              {filterData &&
                                filterData.doctorLanguage?.sort().map((i: any) => (
                                  <MenuItem title={i} value={i} key={i}>
                                    {i}
                                  </MenuItem>
                                ))}
                            </Select>
                            {/* <select
                              disabled={disabled || disabledAll}
                              name='doctorLanguage'
                              value={doctorLanguage}
                              onChange={this.handleChange}>
                              <option value=''>{DOCTORS_LANGUAGE}</option>
                              {filterData &&
                                filterData.doctorLanguage &&
                                filterData.doctorLanguage.sort().map((i: any) => (
                                  <option value={i} key={i}>
                                    {i}
                                  </option>
                                ))}
                            </select> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!Auth.isAuthenticated() && (
                    <>
                      <div className='row'>
                        <div
                          className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-8 position-relative'
                          onMouseEnter={this.mouseEnter}
                          onMouseLeave={this.mouseLeave}>
                          <Select
                            ref={this.selectPlanRef}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                            }}
                            open={open}
                            onClose={() => this.setState({ open: false })}
                            onOpen={() => this.setState({ open: true })}
                            disabled={isSelectPlanDisabled}
                            value={nameOfNetwork}
                            id='select_healthplan'
                            name='nameOfNetwork'
                            className={`custom-dark health-plan blue distance-dropdown ${
                              isSelectPlanDisabled ? 'disabled' : 'Mui-focused'
                            }${nameOfNetwork === '' ? ' cleared-filter' : ''} ${open ? 'Mui-focused' : ''}`}
                            onChange={this.handleNetworkChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            onBlur={() => this.setState({ isSelectPlanDisabled: true, open: false })}>
                            <MenuItem style={{ width: `${ddlWidth}px` }} value=''>
                              {SELECT_HEALTH_PLAN}
                            </MenuItem>
                            {healthPlanData &&
                              healthPlanData?.sort().map((i: any) => (
                                <MenuItem title={i} style={{ width: `${ddlWidth}px` }} value={i} key={i}>
                                  {i}
                                </MenuItem>
                              ))}
                          </Select>

                          {/* <select
                            onBlur={() => this.setState({ isSelectPlanDisabled: true })}
                            disabled={isSelectPlanDisabled}
                            className={`custom-dark health-plan blue distance-dropdown ${
                              !isSelectPlanDisabled ? 'halo' : ''
                            }`}
                            id='select_healthplan'
                            name='nameOfNetwork'
                            value={nameOfNetwork}
                            onChange={this.handleNetworkChange}>
                            <option value=''>{SELECT_HEALTH_PLAN} </option>
                            {healthPlanData &&
                              healthPlanData.sort().map((i: any) => (
                                <option value={i} key={i}>
                                  {i}
                                </option>
                              ))}
                          </select> */}
                          {isSelectPlanDisabled && (
                            <button
                              onBlur={() => this.setState({ isSelectPlanDisabled: true })}
                              type='button'
                              className={`plan-edit-button ${isMouseInside ? 'hovered' : ''}`}
                              onClick={this.onEditClick}>
                              <img
                                className='alert-icon'
                                src={isMouseInside ? Images.icEditActive : Images.icEdit}
                                alt='search'
                              />
                            </button>
                          )}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='form-group col-sm-12 col-md-6 col-lg-6 col-xl-4 order-0'>
                          <input
                            disabled={disabled || disabledAll}
                            type='text'
                            placeholder='Doctor Name'
                            name='name'
                            value={name}
                            onChange={this.handleChange}
                            maxLength={100}
                          />
                        </div>
                        <div className='specialty-picker form-group col-sm-12 col-md-6 col-lg-6 col-xl-4 order-1'>
                          <MultiselectDropDown
                            ref={this.multiSelectRef}
                            data1={filterData && filterData.primaryCareSpecialty ? filterData.primaryCareSpecialty : []}
                            data2={filterData && filterData.specialty ? filterData.specialty : []}
                            disabled={disabled || disabledAll}
                          />
                        </div>
                        <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4 order-2'>
                          <input
                            disabled={disabled || disabledAll}
                            type='text'
                            placeholder='Clinic Name'
                            name='clinicName'
                            value={clinicName}
                            onChange={this.handleChange}
                            maxLength={100}
                          />
                        </div>
                        <div className='form-group checkbox col-12 col-md-6 col-lg-6 col-xl-4 order-3'>
                          <div className='btn-group btn-group-justified col-12 zipCity'>
                            <button
                              disabled={disabled || disabledAll}
                              onClick={() => {
                                this.setState({ zipCodeBtnActive: true, city: '' });
                                delete this.searchData.city;
                                delete this.searchData.distance;
                              }}
                              type='button'
                              className={`btn btn-default toggleBtn ${zipCodeBtnActive ? 'active' : ''}`}>
                              {ZIP_CODE}
                            </button>
                            <button
                              disabled={disabled || disabledAll}
                              onClick={() => {
                                this.setState({ zipCodeBtnActive: false, zip: '', distance: '' });
                                delete this.searchData.zip;
                                delete this.searchData.distance;
                              }}
                              type='button'
                              className={`btn btn-default toggleBtn ${zipCodeBtnActive ? '' : 'active'}`}>
                              {CITY}
                            </button>
                          </div>
                        </div>
                        <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4 order-4 order-md-5 order-lg-5 order-xl-4'>
                          <div className='row'>
                            {zipCodeBtnActive ? (
                              <>
                                <div className='form-group-sm col-sm-6 col-lg-6 col-xl-5 position-relative left-0 lg-pr-10'>
                                  <input
                                    max={6}
                                    disabled={disabled || disabledAll}
                                    className='zip-input'
                                    type='number'
                                    inputMode='numeric'
                                    placeholder='Zip code'
                                    name='zip'
                                    value={zip}
                                    onChange={(e: any) => {
                                      this.handleZipCodeChange(e);
                                    }}
                                    onBlur={() => {
                                      return Number(zip) < 0
                                        ? this.setState({ zip: '' }, () => this.handleSearchFilterChange('zip', ''))
                                        : null;
                                    }}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        'wheel',
                                        (event) => {
                                          event.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />
                                  {zip === '' && zipcodeError && (
                                    <span className='error-zipcode'>{PLEASE_ENTER_ZIP_CODE}</span>
                                  )}
                                </div>
                                <div className='col-sm-6 col-lg-6 col-xl-7 right-0 lg-pl-10'>
                                  <Select
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                      transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                      },
                                    }}
                                    displayEmpty
                                    disabled={disabled || disabledAll}
                                    className='select distance-dropdown'
                                    onChange={this.handleChange}
                                    name='distance'
                                    value={distance}>
                                    <MenuItem value=''>{DISTANCE}</MenuItem>
                                    {filterData &&
                                      filterData.distance &&
                                      filterData.distance.map((i: any) => (
                                        <MenuItem title={i} value={i} key={i}>
                                          {i}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  {/* <select
                                    disabled={disabled || disabledAll}
                                    className='distance-dropdown'
                                    name='distance'
                                    value={distance}
                                    onChange={this.handleChange}>
                                    <option value=''>{DISTANCE} </option>
                                    {filterData &&
                                      filterData.distance &&
                                      filterData.distance.map((i: any) => (
                                        <option value={i} key={i}>
                                          {i}
                                        </option>
                                      ))}
                                  </select> */}
                                </div>
                              </>
                            ) : (
                              <div className='col-lg-12 col-md-12 col-sm-12  ps-0 pe-0'>
                                <Select
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                  }}
                                  displayEmpty
                                  disabled={disabled || disabledAll}
                                  name='city'
                                  className='select distance-dropdown'
                                  onChange={this.handleChange}
                                  value={city}>
                                  <MenuItem value=''>{CITY}</MenuItem>
                                  {filterData &&
                                    filterData.city?.sort().map((i: any) => (
                                      <MenuItem title={i} value={i} key={i}>
                                        {i}
                                      </MenuItem>
                                    ))}
                                </Select>
                                {/* <select
                                  disabled={disabled || disabledAll}
                                  name='city'
                                  className='distance-dropdown'
                                  onChange={this.handleChange}
                                  value={city}>
                                  <option value=''>{CITY} </option>
                                  {filterData &&
                                    filterData.city &&
                                    filterData.city.sort().map((i: any) => (
                                      <option value={i} key={i}>
                                        {i}
                                      </option>
                                    ))}
                                </select> */}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4 order-5 order-md-4 order-lg-4 order-xl-5'>
                          <div className='row'>
                            <div className='form-group-sm col-sm-6 col-lg-5 left-0 lg-pr-10'>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                  },
                                }}
                                displayEmpty
                                disabled={disabled || disabledAll}
                                name='gender'
                                className='select distance-dropdown mb-0'
                                onChange={this.handleChange}
                                value={gender}>
                                <MenuItem value=''>{GENDER}</MenuItem>
                                {filterData &&
                                  filterData?.gender?.sort().map((i: any) => (
                                    <MenuItem title={i} value={i} key={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {/* <select
                                disabled={disabled || disabledAll}
                                id='select_gender'
                                className='mb-0'
                                name='gender'
                                value={gender}
                                style={{ fontSize: 18 }}
                                onChange={this.handleChange}>
                                <option value=''>{GENDER} </option>
                                {filterData &&
                                  filterData.gender &&
                                  filterData.gender.sort().map((i: any) => (
                                    <option value={i} key={i}>
                                      {i}
                                    </option>
                                  ))}
                              </select> */}
                            </div>

                            <div className='col-sm-6 col-lg-7 right-0 lg-pl-10'>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                  },
                                }}
                                displayEmpty
                                disabled={disabled || disabledAll}
                                name='doctorLanguage'
                                value={doctorLanguage}
                                className='select distance-dropdown'
                                onChange={this.handleChange}>
                                <MenuItem value=''>{DOCTORS_LANGUAGE}</MenuItem>
                                {filterData &&
                                  filterData.doctorLanguage?.sort().map((i: any) => (
                                    <MenuItem title={i} value={i} key={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {/* <select
                                disabled={disabled || disabledAll}
                                name='doctorLanguage'
                                value={doctorLanguage}
                                onChange={this.handleChange}>
                                <option value=''>{DOCTORS_LANGUAGE}</option>
                                {filterData &&
                                  filterData.doctorLanguage &&
                                  filterData.doctorLanguage.sort().map((i: any) => (
                                    <option value={i} key={i}>
                                      {i}
                                    </option>
                                  ))}
                              </select> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className='row'>
                    <div className='checkbox form-group col-12 col-md-4 col-lg-4'>
                      <div className={`round-checkbox  my-0 ${disabled || disabledAll ? 'disabled' : ''}`}>
                        <input
                          disabled={disabled || disabledAll}
                          type='checkbox'
                          name='acceptingNewPatients'
                          value={acceptingNewPatients}
                          checked={acceptingNewPatients === 'Y'}
                          id='topicid1'
                          onChange={this.handleRadioButtonChange}
                          className='checkbox-label'
                        />
                        <label className='checkbox-label' htmlFor='topicid1'>
                          {' '}
                        </label>
                        <label className='ms-3 mb-0 check-box-label' htmlFor='topicid11'>
                          {' '}
                          {ACCEPTING_NEW_PATIENTS}
                        </label>
                      </div>
                    </div>
                   {/* <div className='checkbox form-group col-12 col-md-4 col-lg-4'>
                      <div className={`round-checkbox  my-0 ${disabled || disabledAll ? 'disabled' : ''}`}>
                        <input
                          disabled={disabled || disabledAll}
                          type='checkbox'
                          name='vimBookable'
                          id='topicid2'
                          value={vimBookable}
                          checked={vimBookable === 'Y'}
                          onChange={this.handleAcceptingOnlineSchedulingChange}
                          className='checkbox-label'
                        />
                        <label className='checkbox-label' htmlFor='topicid2'>
                          {' '}
                        </label>
                        <label className='ms-3 mb-0 check-box-label' htmlFor='topicid12'>
                          {' '}
                          {ACCEPTING_ONLINE_SCHEDULING}
                        </label>
                      </div> 
                    </div> */}
                    <div className='checkbox form-group col-12 col-md-4 col-lg-4'>
                      <div className={`round-checkbox  my-0 ${disabled || disabledAll ? 'disabled' : ''}`}>
                        <input
                          disabled={disabled || disabledAll}
                          type='checkbox'
                          name='telehealth'
                          id='topicid3'
                          value={telehealth}
                          checked={telehealth === 'Y'}
                          onChange={this.handleRadioButtonChange}
                          className='checkbox-label'
                        />
                        <label className='checkbox-label' htmlFor='topicid3'>
                          {' '}
                        </label>
                        <label className='ms-3 mb-0 check-box-label' htmlFor='topicid13'>
                          {' '}
                          {OFFERING_TELEHEALTH}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='collapse' id='collapseExample'>
                    <div className='advance-filters container-fluid px-0'>
                      <div className='form-inline'>
                        <div className='col-lg-12 col-md-12 col-sm-12 no-padding  zindex'>
                          <div className='row'>
                            <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4'>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                  },
                                }}
                                displayEmpty
                                disabled={disabled || disabledAll}
                                id='select_healthplan'
                                name='medicalGroup'
                                value={medicalGroup}
                                className='select distance-dropdown'
                                onChange={this.handleChange}>
                                <MenuItem value=''>{MEDIA_GROUP_IPA_LABLE}</MenuItem>
                                {filterData &&
                                  filterData.medicalGroupIPA &&
                                  filterData.medicalGroupIPA?.sort().map((i: any) => (
                                    <MenuItem title={i} value={i} key={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </div>
                            <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4'>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                  },
                                }}
                                displayEmpty
                                disabled={disabled || disabledAll}
                                name='hospital'
                                value={hospital}
                                className='select distance-dropdown'
                                onChange={this.handleChange}>
                                <MenuItem value=''>{HOSPITALS_OPTION}</MenuItem>
                                {filterData &&
                                  filterData.hospital &&
                                  filterData.hospital?.sort().map((i: any) => (
                                    <MenuItem title={i} value={i} key={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </div>
                            <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4'>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                  },
                                }}
                                displayEmpty
                                disabled={disabled || disabledAll}
                                name='county'
                                value={countyProps || county}
                                className='select distance-dropdown'
                                onChange={this.handleChange}>
                                <MenuItem value=''>{COUNTY}</MenuItem>
                                {filterData &&
                                  filterData.county &&
                                  filterData.county?.sort().map((i: any) => (
                                    <MenuItem title={i} value={i} key={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </div>
                            <div className='checkbox form-group col-12 col-md-6 col-lg-6 col-xl-4'>
                              <input
                                disabled={disabled || disabledAll}
                                type='text'
                                placeholder='National Provider ID'
                                name='NPI'
                                value={NPI}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='form-group col-12 col-md-6 col-lg-6 col-xl-4'>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                  },
                                }}
                                displayEmpty
                                disabled={disabled || disabledAll}
                                value={typeOfLicensure}
                                name='typeOfLicensure'
                                className='select distance-dropdown'
                                onChange={this.handleChange}>
                                <MenuItem value=''>{TYPE_OF_LICENSURE_LABEL}</MenuItem>
                                {filterData &&
                                  filterData.typeOfLicensure &&
                                  filterData.typeOfLicensure?.sort().map((i: any) => (
                                    <MenuItem title={i} value={i} key={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </div>
                            <div className='checkbox col-12 col-md-6 col-lg-6 col-xl-4 form-group'>
                              <div className='row'>
                                <div className='form-group-sm  col-sm-6 col-lg-6 position-relative left-0 lg-pr-10'>
                                  <input
                                    disabled={disabled || disabledAll}
                                    type='text'
                                    placeholder='CA License'
                                    name='CALicense'
                                    value={CALicense}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className='col-sm-6 col-lg-6 right-0 lg-pl-10'>
                                  <input
                                    disabled={disabled || disabledAll}
                                    type='text'
                                    name='nonCALicense'
                                    placeholder='Non CA License'
                                    value={nonCALicense}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='button-container d-flex align-items-end pe-0'>
                  <div className='row align-items-sm-center large-screen'>
                    <div className='form-group col-12 col-xl-auto text-center'>
                      <button type='button' className='search-filter' disabled={disabledSearch} onClick={this.onSearch}>
                        <img className='alert-icon' src={Images.icSearch} alt='search' />
                      </button>
                    </div>
                    <div className='form-group zindex col-12 col-xl-auto c-m-17'>
                      <span
                        className='clear-button'
                        role='button'
                        tabIndex={-2}
                        onClick={this.clear}
                        onKeyPress={this.clear}>
                        {CLEAR}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* Basic search ends here */}
        {/* Advanced Filters */}

        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 text-center' style={{ backgroundColor: '#D9D9D9' }}>
            <button type='button' className='advanceToggle'>
              <a
                id='advanceToggler'
                data-bs-toggle='collapse'
                href='#collapseExample'
                role='button'
                aria-expanded='false'
                aria-controls='collapseExample'
                style={{ textDecoration: 'none' }}>
                <span className='plusAdvanceFilter'>{PLUS_ADVANCED_FILTERS}</span>
                <span className='minusAdvanceFilter'>{MINUS_ADVANCED_FILTERS}</span>
              </a>
            </button>
          </div>
        </div>
        <div className='row common-filters small-screen'>
          <div className='col-6 text-left'>
            <span className='clear-button' role='button' tabIndex={-2} onClick={this.clear} onKeyPress={this.clear}>
              {CLEAR}
            </span>
          </div>
          <div className='col-6 text-right'>
            <button
              type='button'
              className='search-filter'
              disabled={disabledSearch}
              onClick={this.onSearch}
              style={{ float: 'right' }}>
              <img className='alert-icon' src={Images.icSearch} alt='search' />
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default SearchFilters;
