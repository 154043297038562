/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import { dateFormatter } from '../../../shared/helperMethods/HelperMethod';
import Links from '../../../shared/sharedComponents/genericLinks/Links';
import Auth from '../../../shared/utils/Auth';

interface stateProps {
  data: any;
}

interface ownProps {
  location: string;
  page: string;
  // eslint-disable-next-line react/require-default-props
  target?: string;
}

type componentProps = ownProps & stateProps;
class DoctorAndServiceFooterInfo extends PureComponent<componentProps, {}> {
  getLocationLink = (location: any): any => {
    const { data } = this.props;
    const filteredData = data?.ConfigData?.getModuleConfigdata?.data?.addressableSpaces?.filter(
      (i: any) => i.location === location
    );
    return filteredData?.length > 0 && filteredData[0];
  };

  render(): React.ReactElement {
    const { data, location, page, target } = this.props;
    const contact = data?.Register?.planContactsData?.data;
    const lastChangedDate = data?.ConfigData?.getMainConfigdata?.data?.updated[location];
    const userData = data?.userInfoData?.responsedata?.data;
    return (
      <div className='row'>
        <div className={`${target !== 'pdf' ? 'text-descriptive' : ''} px-0 mt-0`}>
          {this.getLocationLink('disclaimer')?.items?.map((i: any) => {
            const text =
              page === 'd_s_home' ||
              Auth.getHealthPlan() === 'Canopy Health - UnitedHealthcare Doctors Plan' ||
              userData?.product === 'EPO'
                ? i.text.replace('This information was last updated on [[last_update_date]].', '')
                : i.text;
            return (
              <div
                id='dns-footer-note'
                key={i._id}
                dangerouslySetInnerHTML={{
                  __html: text
                    .replace(`[[contact_phone]]`, contact?.length > 0 && contact[0]?.phoneNumber)
                    .replace(`[[last_update_date]]`, lastChangedDate && dateFormatter(lastChangedDate)),
                }}
              />
            );
          })}
        </div>
        {target !== 'pdf' && (
          <div className='col-12 mt-2'>
            <div className='horizontal-links'>
              <div className='link-wrapper' style={{ marginLeft: '-32px', marginBottom: '16px' }}>
                {this.getLocationLink('covid19')?.items?.map((i: any) => (
                  <Links key={i._id} type={i?.type} text={i?.text} url={i?.url} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default DoctorAndServiceFooterInfo;
