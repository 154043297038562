import React, { PureComponent } from 'react';
import {
  ALL_SPECIALITY_LABEL,
  RIMARY_CARE_SPECIALITY_LABEL,
  SPECIALITY_PRIMARY_CARE,
} from '../../constant/AppConstants';

interface ownProps {
  data1: any;
  data2: any;
  disabled: boolean;
}
interface ownState {
  onFocus: any;
  providerPrimaryCare: any;
  providerSpecialities: any;
  allChecked: boolean;
  test: boolean;
}

class MultiselectDropDown extends PureComponent<ownProps, ownState> {
  wrapperRef: any;

  constructor(props: ownProps) {
    super(props);
    this.state = {
      onFocus: false,
      providerPrimaryCare: [],
      providerSpecialities: [],
      allChecked: false,
      test: false,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount(): any {
    document.addEventListener('mousedown', this.handleClickOutside);
    const { disabled } = this.props;
    if (disabled) {
      this.setState({ providerPrimaryCare: [], providerSpecialities: [] });
    }
  }

  static getDerivedStateFromProps(props: any, state: any): any {
    if (state.disabled !== props.disabled) {
      return {
        disabled: props.disabled,
        providerPrimaryCare: [],
        providerSpecialities: [],
      };
    }
    return null;
  }

  componentWillUnmount(): any {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: any): void => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      // alert('You clicked outside of me!');
      this.setState({ onFocus: false });
    }
  };

  onChangeProviderSpecialities = (e: any): any => {
    // current array of options
    const { providerSpecialities, test } = this.state;
    const options = providerSpecialities;
    let index;
    if (e.target.checked) {
      options.push(e.target.value);
      this.setState({ providerSpecialities: options });
    } else {
      index = options.indexOf(e.target.value);
      options.splice(index, 1);
      this.setState({ providerSpecialities: options });
    }
    this.setState({ test: !test });
  };

  onChangeproviderPrimaryCare = (e: any): any => {
    const { data1 } = this.props;
    const { providerPrimaryCare, test } = this.state;
    const options = providerPrimaryCare;
    let index;
    if (e.target.checked) {
      providerPrimaryCare.push(e.target.value);
      this.setState({ test: !test });
    } else {
      index = options.indexOf(e.target.value);
      options.splice(index, 1);
      this.setState({ providerPrimaryCare: options });

      this.setState({ test: !test });
    }
    const allCheckeData = data1.length === providerPrimaryCare.length;
    this.setState({ allChecked: allCheckeData });
  };

  selectAll = (): any => {
    const { data1 } = this.props;
    const { allChecked, providerPrimaryCare } = this.state;
    if (allChecked) {
      this.setState({ providerPrimaryCare: [] }, () => {
        const allCheckeData = data1.length === providerPrimaryCare.length;
        this.setState({ allChecked: !allCheckeData });
      });
    } else {
      this.setState({ providerPrimaryCare: data1 }, () => {
        const allCheckeData = data1.length === providerPrimaryCare.length;
        this.setState({ allChecked: !allCheckeData });
      });
    }
  };

  getCheckedPrimaryCare = (name: string): boolean => {
    const { providerPrimaryCare } = this.state;
    const getData = providerPrimaryCare.filter((i: any) => i === name);
    if (getData.length > 0) {
      return true;
    }
    return false;
  };

  getCheckedPrimaryCareSpecialities = (name: string): boolean => {
    const { providerSpecialities } = this.state;
    const getData = providerSpecialities.filter((i: any) => i === name);
    if (getData.length > 0) {
      return true;
    }
    return false;
  };

  getSelectedData = (): any => {
    const { providerPrimaryCare, providerSpecialities } = this.state;
    let primaryCare = '';
    let providerSpecialitiesString = '';
    providerPrimaryCare.forEach((element: any) => {
      primaryCare = primaryCare.concat(`${element},`);
    });
    providerSpecialities.forEach((element: any) => {
      providerSpecialitiesString = providerSpecialitiesString.concat(`${element}|`);
    });
    primaryCare = primaryCare.slice(0, -1);
    providerSpecialitiesString = providerSpecialitiesString.slice(0, -1);
    return { primaryCare, providerSpecialitiesString };
  };

  onClear = (): any => {
    this.setState({ providerPrimaryCare: [], providerSpecialities: [], allChecked: false });
  };

  render(): React.ReactElement {
    const { onFocus, providerPrimaryCare, providerSpecialities, allChecked } = this.state;
    const { data1, data2, disabled } = this.props;
    const allData = [...providerPrimaryCare, ...providerSpecialities];
    return (
      <>
        <div
          className='multiselect custom-dark health-plan position-relative multiselect-dropdowns'
          ref={this.wrapperRef}>
          <div
            className='selectBox'
            onClick={() => !disabled && this.setState({ onFocus: !onFocus })}
            onKeyPress={() => !disabled && this.setState({ onFocus: !onFocus })}
            tabIndex={-1}
            role='button'>
            <select id='select_healthplan' disabled={disabled}>
              {allData && allData.length === 0 ? (
                <option className='placeholder' value=''>
                  {SPECIALITY_PRIMARY_CARE}
                </option>
              ) : (
                <option>
                  {providerPrimaryCare &&
                    providerPrimaryCare.length > 0 &&
                    providerPrimaryCare.map((i: any, index: number) => `${index !== 0 ? ', ' : ''}${i.split('|')[1]} `)}
                  {providerSpecialities &&
                    providerSpecialities.length > 0 &&
                    providerSpecialities.map((i: any, index: number) => `${index !== 0 ? ', ' : ''}${i}`)}
                </option>
              )}
            </select>
            <div className='overSelect' />
          </div>
          <div id='checkboxes' className={`${onFocus ? 'd-block' : 'd-none'}`}>
            <span
              className='px-2 mb-0 bg-gray'
              onClick={() => this.setState({ providerPrimaryCare: [], providerSpecialities: [], onFocus: false })}
              onKeyPress={() => this.setState({ providerPrimaryCare: [], providerSpecialities: [], onFocus: false })}
              role='button'
              tabIndex={-4}>
              {SPECIALITY_PRIMARY_CARE}
            </span>
            <div>
              <p className='px-2 mb-0 fs-6 fw-bolder bg-light line-height-sm primaryCareTextColor'>
                {RIMARY_CARE_SPECIALITY_LABEL}
              </p>
              <button
                type='button'
                className='px-2 mb-0 bg-white border-0 text-start fs-6'
                onClick={() => this.selectAll()}>
                {allChecked ? 'Deselect All Primary Care Specialties' : 'Select All Primary Care Specialties'}
              </button>
              {data1 &&
                data1.sort().map((i: any) => (
                  <label className='mb-0' key={i} style={{ height: 'auto' }}>
                    <input
                      type='checkbox'
                      style={{ height: 14, width: 14 }}
                      checked={this.getCheckedPrimaryCare(i)}
                      id={i}
                      value={i}
                      onChange={this.onChangeproviderPrimaryCare}
                    />
                    <span style={{ display: 'inline-block', lineHeight: 1 }}>{i.split('|')[1]}</span>
                  </label>
                ))}
            </div>
            <div>
              <p className='px-2 mb-0 fw-bolder bg-light fs-6 primaryCareTextColor'>{ALL_SPECIALITY_LABEL}</p>
              {data2 &&
                data2.sort().map((i: any) => (
                  <label className='my-0' key={i} style={{ height: 'auto' }}>
                    <input
                      type='checkbox'
                      style={{ height: 14, width: 14 }}
                      checked={this.getCheckedPrimaryCareSpecialities(i)}
                      id={i}
                      value={i}
                      onChange={this.onChangeProviderSpecialities}
                    />
                    <span style={{ display: 'inline-block', lineHeight: 1 }}>{i}</span>
                  </label>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MultiselectDropDown;
