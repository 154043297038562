export const APP_HOME_URL = '/';
export const SSO_ROUTING = '/sso_routing';
export const LOGIN_PAGE_URL = '/login';
export const TERMS_AND_CONDITION_URL = '/terms';
export const NONDISCRIMINATION_URL = '/nondiscrimination';
export const PRIVACY_URL = '/privacy';
export const DOCTORS_PLAN = '/doctorsplan';

// Vanity URLS
export const VANITY_HARMONEY_REGISTER_PAGE_URL = '/harmonyreg';
export const VANITY_DOCTORS_URL_1 = '/canopycarepcp';
export const VANITY_DOCTOR_URL_2 = '/harmonypcp';
export const VANITY_DOCTOR_URL_3 = '/blueandgoldpcp';
export const VANITY_DOCTOR_URL_4 = '/smartcarepcp';
export const VANITY_DOCTOR_URL_5 = '/medicareadvantagepcp';
export const VANITY_DOCTOR_URL_6 = '/advantagepcp';
export const VANITY_DOCTOR_URL_7 = '/doctorsplanpcp';

export const VANITY_HEALTHNET_REGISTER_PAGE_URL = '/healthnetreg';
export const VANITY_SERVICES_URL_1 = '/canopycareservices';
export const VANITY_SERVICES_URL_2 = '/harmonyservices';
export const VANITY_SERVICES_URL_3 = '/blueandgoldservices';
export const VANITY_SERVICES_URL_4 = '/smartcareservices';
export const VANITY_SERVICES_URL_5 = '/medicareadvantageservices';
export const VANITY_SERVICES_URL_6 = '/advantageservices';
export const VANITY_SERVICES_URL_7 = '/doctorsplanpcp';

// Screens
export const GENERIC_LINKS_URL = '/genriclinks';
export const GENERIC_LINKS_DS_URL = '/network/genrics';
export const DOCTOR_SERVICES = '/doctorservices';
export const DOCTOR_SERVICES_DOCTOR = '/network/doctor';
export const DOCTORS_SERVICES_SERVICES = '/network/services';
export const SERVICES_REPORT_INACCURACIES = '/network/services/report_inaccuracies';
export const DOCTOR_SERVICES_HOSPITALS = '/network/hospitals';
export const DOCTOR_URGENT_CARE = '/network/urgentCare';

export const URGENT_CARE_REPORT_INACCURACIES = '/network/urgentCare/report_inaccuracies';
export const DOCTOR_REPORT_INACCURACIES = '/network/doctor/report_inaccuracies';
export const CUSTOMER_SUPPORT_URL = 'https://www.canopyhealth.com/help/';
export const GRIEVANCE_APPEALS_URL = 'https://www.canopyhealth.com/grievances-and-appeals/';
export const USING_BENIFITS_URL = '/usingbenifits';
// Virtual Visit
export const HELP_LINK = 'https://www.canopyhealth.com/help/';
