/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
// import * as bootstrap from 'bootstrap';
import * as actions from '@canopy/shared';
import _, { toLower } from 'lodash';
import short from 'short-uuid';
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-redundant-should-component-update */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  ACCOUNT_SETTING_VAR,
  COSTESTIMATOR_CAMELCASE_VAR,
  CUSTOMER_SUPPORT_NOTIFICATION_LABEL,
  GRIEVANCE_APPEAL_SMALLCASE_VAR,
  GRIEVANCE_NOTIFICATION_LABEL1,
  MY_HEALTH_PLAN_SMALLCASE_VAR,
  NETWORK_VAR,
  NOTIFICATION_VAR,
  NavMenuEnum,
  OUT_OF_POCKET_COST_SMALLCASE_VAR,
  USING_MY_BENIFITS_SMALLCASE_VAR,
  VERTUAL_VISIT_SMALLCASE_VAR,
} from '../../shared/constant/AppConstants';
import { APP_HOME_URL, DOCTOR_SERVICES_DOCTOR, GRIEVANCE_APPEALS_URL } from '../../shared/constant/Urls';
import { dynamicSort, history } from '../../shared/helperMethods/HelperMethod';
import Analytics, { FA } from '../../shared/utils/Analytics';
import Auth from '../../shared/utils/Auth';
import Image from '../../shared/utils/Images';

interface ownState {
  selectedMenu: string;
  notificationCountValue: any;
  currentActive: any;
}

interface ownProps {
  type: any;
}

interface dispatchProps {
  createGetNotificationRequest: typeof actions.createGetNotificationsRequest;
}

interface stateProps {
  data: any;
}

type componentProps = stateProps & dispatchProps & ownProps;
class NavMenuLists extends Component<componentProps, ownState> {
  _documentRef: any;

  _isMounted = false;

  constructor(props: componentProps) {
    super(props);

    this.state = {
      selectedMenu: window.location.pathname.split('/')[1],
      notificationCountValue: null,
      currentActive: localStorage.getItem('active'),
    };
    document.addEventListener('click', this.handleDocumentClick, true);
  }

  componentDidMount(): any {
    // this._isMounted = true;
    // // this.setState({ selectedMenu });
    // if (!this.state.notificationCountValue) {
    //   this.getNotificationCount();
    // }
  }

  shouldComponentUpdate(nextProps: any, nextState: any): any {
    const { data } = this.props;
    const { notificationCountValue } = this.state;
    if (
      (!_.isEqual(nextProps.data.ConfigData.getMainConfigdata, data.ConfigData.getMainConfigdata) &&
        !Array.isArray(nextProps.data.ConfigData.getMainConfigdata)) ||
      notificationCountValue !== nextState.notificationCountValue
    ) {
      return true;
    }
    return false;
  }

  componentWillUnmount(): any {
    this._isMounted = false;
    document.removeEventListener('click', this.handleDocumentClick, true);
    this.setState({ notificationCountValue: null });
  }

  handleDocumentClick = (): any => {
    setTimeout(
      () =>
        this._isMounted &&
        this.setState({
          selectedMenu: window.location.pathname.split('/')[1],
        }),
      100
    );
  };

  onSelect = (selectedMenu: any, path: any, contentId: any): any => {
    this.setState({ selectedMenu });
    localStorage.setItem('active', contentId);
    if (selectedMenu === 'custom') {
      history.push(path, {
        from: 'custome',
        contentId,
      } as {
        from: string;
        contentId: any;
      });
    } else {
      history.push(path);
    }
  };

  getSortedModules = (): any => {
    const { data } = this.props;
    const sortedData = data?.ConfigData?.getMainConfigdata?.data?.modules
      ?.sort(dynamicSort(NavMenuEnum.WEIGHT))
      .filter((m: any) => !['grievances', 'support'].includes(m.module));
    // console.log('sortedData', sortedData, externalNav);
    return sortedData;
  };

  getActive = (i: any): any => {
    const { selectedMenu, currentActive } = this.state;
    switch (i?.module) {
      case NavMenuEnum.DASHBOARD_MODULES:
        if (
          selectedMenu === '' ||
          selectedMenu === 'login' ||
          selectedMenu === 'verify-account' ||
          selectedMenu === 'terms' ||
          selectedMenu === 'verify-email-setup' ||
          selectedMenu === 'verify-mobile-setup' ||
          selectedMenu === 'accept-mobile-setup' ||
          selectedMenu === 'register' ||
          selectedMenu === 'resetpassword' ||
          selectedMenu === 'recoverusername' ||
          selectedMenu === 'unlockaccount' ||
          selectedMenu === 'changepcp'
        ) {
          return 'active';
        }
        return '';
      case NavMenuEnum.DOCTOR_MODULES:
        if (selectedMenu === NETWORK_VAR) {
          return 'active';
        }
        return '';
      case NavMenuEnum.ACCOUNT_MODULES:
        if (selectedMenu === ACCOUNT_SETTING_VAR) {
          return 'active';
        }
        return '';
      case NavMenuEnum.HEALTHPLAN_MODULES:
        if (selectedMenu === MY_HEALTH_PLAN_SMALLCASE_VAR) {
          return 'active';
        }
        return '';
      case NavMenuEnum.VISIT_MODULES:
        if (selectedMenu === VERTUAL_VISIT_SMALLCASE_VAR) {
          return 'active';
        }
        return '';
      case NavMenuEnum.COST_MODULES:
        if (selectedMenu === COSTESTIMATOR_CAMELCASE_VAR) {
          return 'active';
        }
        return '';
      case NavMenuEnum.OOP_MODULES:
        if (selectedMenu === OUT_OF_POCKET_COST_SMALLCASE_VAR) {
          return 'active';
        }
        return '';
      case NavMenuEnum.NOTIFICATION_MODULES:
        if (selectedMenu === NOTIFICATION_VAR) {
          return 'active';
        }
        return '';
      case NavMenuEnum.GRIEVANCE_MODULES:
        if (selectedMenu === GRIEVANCE_APPEAL_SMALLCASE_VAR) {
          return 'active';
        }
        return '';
      case NavMenuEnum.USING_BENEFITS_MODULES:
        if (selectedMenu === USING_MY_BENIFITS_SMALLCASE_VAR) {
          return 'active';
        }
        return '';
      case NavMenuEnum.CUSTOM_MODULES:
        if (selectedMenu === 'genriclinks' && i?.contentId === currentActive) {
          return 'active';
        }
        return '';
      default:
        return '';
    }
  };

  getModuleInfo = (data: any): any => {
    // console.log('Nav', data);
    switch (data?.module) {
      case NavMenuEnum.DASHBOARD_MODULES:
        // Analytics.logEvent(FA.MENU_HOME);
        Analytics.logEvent(FA.MENU_DASHBOARD);
        return { title: NavMenuEnum.HOME, icon: Image.icMenuHomeActive, url: APP_HOME_URL };
      case NavMenuEnum.DOCTOR_MODULES:
        Analytics.logEvent(FA.MENU_NETWORK);
        return {
          title: NavMenuEnum.DOCTORS_AND_SERVICES,
          icon: Image.icMenuNetworkActive,
          url: DOCTOR_SERVICES_DOCTOR,
        };
      case NavMenuEnum.GRIEVANCE_MODULES:
        return {
          title: NavMenuEnum.GRIEVANCES,
          icon: Image.griverence,
          url: GRIEVANCE_APPEALS_URL,
        };
      default:
        return null;
    }
  };

  getExternalLinkImg = (name: any): any => {
    switch (name) {
      case CUSTOMER_SUPPORT_NOTIFICATION_LABEL:
        return {
          icon: Image.icMenuSupportActive,
        };
      case GRIEVANCE_NOTIFICATION_LABEL1:
        return {
          icon: Image.icMenuGrievancesAppealsActive,
        };
      default:
        return null;
    }
  };

  openInNewTab = (url: string): any => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  getNotificationCount = (): void => {
    const { data, createGetNotificationRequest } = this.props;

    const notificationCount =
      data?.NotificationData?.getNotificationResponse?.unreadRecords &&
      data?.NotificationData?.getNotificationResponse?.unreadRecords > 0
        ? data?.NotificationData?.getNotificationResponse?.unreadRecords
        : null;

    if (!notificationCount && Auth.isAuthenticated()) {
      if (Auth.getUserId())
        createGetNotificationRequest({ page: '1', limit: '-1' }, (res: any) => {
          if (this._isMounted) {
            if (res?.data?.isSuccess) {
              const unreadCount =
                res?.data?.unreadRecords && res?.data?.unreadRecords > 0 ? res?.data?.unreadRecords : null;
              this.setState({ notificationCountValue: unreadCount });
            } else {
              this.setState({ notificationCountValue: notificationCount });
            }
          }
        });
    } else if (this._isMounted) this.setState({ notificationCountValue: notificationCount });
  };

  render(): React.ReactElement {
    const { notificationCountValue } = this.state;
    const { data, type } = this.props;
    const { parameters } = data?.ConfigData?.getMainConfigdata?.data ?? {};
    let externalLinks = data?.ConfigData?.getMainConfigdata?.data?.externalLinks;
    if (Auth.isAuthenticated() && parameters?.effective === false) {
      externalLinks = externalLinks.filter((e: any) => toLower(e?.name) !== 'grievances & appeals');
    }

    return (
      <div ref={this._documentRef}>
        {Auth.isAuthenticated() &&
          // eslint-disable-next-line no-return-assign
          this.getSortedModules()
            ?.filter((x: any) => x.title !== 'Using My Benefits')
            ?.map((i: any) => {
              const info = this.getModuleInfo(i);
              // console.log(info);
              if (info && info?.url === '/genriclinks' && info.title === 'CUSTOMER SUPPORT') {
                return null;
              }
              return (
                <li
                  key={short.uuid()}
                  className={`nav-item menuitem ${this.getActive(i)}${i.enabled ? '' : 'd-none'}`}
                  data-bs-toggle='collapse'
                  data-bs-target={type === 'responsive' ? '#navbarToggleExternalContent' : ''}
                  aria-controls='navbarToggleExternalContent'
                  // aria-expanded='false'
                  aria-label='Toggle navigation'>
                  {i?.module === NavMenuEnum.GRIEVANCE_MODULES ? (
                    <a
                      href={
                        data.ConfigData?.getMainConfigdata?.data?.general?.grievancesAndAppeals?.value
                          ? data.ConfigData?.getMainConfigdata?.data?.general?.grievancesAndAppeals?.value
                          : info?.url
                      }
                      target='_blank'
                      rel='noreferrer'>
                      <img src={info?.icon} className='ic-menu-network-active' alt='Home Active' />
                      <span className='nav-text text-white'>{info?.title}</span>
                    </a>
                  ) : (
                    <span
                      tabIndex={0}
                      role='button'
                      onClick={() => this.onSelect(i?.module, info?.url, i?.contentId)}
                      onKeyPress={() => this.onSelect(i?.module, info?.url, i?.contentId)}>
                      <img src={info?.icon} className='ic-menu-network-active ' alt='Home Active' />
                      <span className='nav-text text-white'>{info?.title}</span>
                      {i.module === NavMenuEnum.NOTIFICATION_MODULES && notificationCountValue && (
                        <span className='notification-badge mobile-badge'>
                          {notificationCountValue ? (notificationCountValue > 99 ? '99+' : notificationCountValue) : 0}
                        </span>
                      )}
                    </span>
                  )}
                </li>
              );
            })}
        {!Auth.isAuthenticated() &&
          this.getSortedModules()?.map((i: any) => (
            <li
              key={short.uuid()}
              className={`${this.getActive(i)} ${i.enabled ? '' : 'd-none'} nav-item menuitem`}
              data-bs-toggle='collapse'
              data-bs-target={type === 'responsive' ? '#navbarToggleExternalContent' : ''}
              aria-controls='navbarToggleExternalContent'
              aria-label='Toggle navigation'>
              {i?.module === NavMenuEnum.GRIEVANCE_MODULES ? (
                // <a href={this.getModuleInfo(i)?.url} target='_blank' rel='noreferrer'>
                //   <img
                //     src={this.getModuleInfo(i)?.icon}
                //     className='ic-menu-network-active'
                //     alt='Home Active'
                //   />
                //   <span className='nav-text text-white'>{this.getModuleInfo(i)?.title}</span>
                //   {i.module === 'notifiaction' && (
                //     <span className='notification-badge mobile-badge'>0</span>
                //   )}
                // </a>

                <span
                  tabIndex={0}
                  role='button'
                  onClick={() => window.open(this.getModuleInfo(i)?.url, '_blank')}
                  onKeyDown={() => console.log('Key Down', i?.url)}>
                  <img src={this.getModuleInfo(i)?.icon} className='ic-menu-network-active' alt='Home Active' />
                  <span className='nav-text text-white text-white'>{this.getModuleInfo(i)?.title}</span>
                  {i.module === 'notifiaction' && <span className='notification-badge mobile-badge'>0</span>}
                </span>
              ) : (
                <span
                  tabIndex={0}
                  role='button'
                  onClick={() => this.onSelect('', this.getModuleInfo(i)?.url, i?.contentId)}
                  onKeyPress={() => this.onSelect('', this.getModuleInfo(i)?.url, i?.contentId)}>
                  <img src={this.getModuleInfo(i)?.icon} className='ic-menu-network-active' alt='Home Active' />
                  <span className='nav-text text-white'>{this.getModuleInfo(i)?.title}</span>
                  {i.module === 'notifiaction' && <span className='notification-badge mobile-badge'>0</span>}
                </span>
              )}
            </li>
          ))}
        {externalLinks?.map((i: any) => (
          <li className='nav-item menuitem' key={short.uuid()}>
            <span tabIndex={0} role='button' onClick={() => window.open(i?.url, '_blank')}>
              <img
                src={
                  toLower(i?.name) === 'customer support'
                    ? Image.icMenuSupportActive
                    : toLower(i?.name) === 'grievances & appeals'
                    ? Image.icMenuGrievancesAppealsActive
                    : Image.icDeeplinkWhite
                }
                className={`ic-menu-network-active ${
                  // eslint-disable-next-line no-constant-condition
                  toLower(i?.name) === 'customer support' || 'grievances & appeals' ? '' : 'deeplink'
                }`}
                alt='Grievance'
              />
              <span className='nav-text text-white text-white'>{i?.text?.toUpperCase()}</span>
            </span>
          </li>
        ))}
        {Auth.isAuthenticated() &&
          // eslint-disable-next-line no-return-assign
          this.getSortedModules()
            ?.filter((x: any) => x.title === 'Using My Benefits')
            ?.map((i: any) => {
              const info = this.getModuleInfo(i);
              // console.log(info);
              if (info && info?.url === '/genriclinks' && info.title === 'CUSTOMER SUPPORT') {
                return null;
              }
              return (
                <li
                  key={short.uuid()}
                  className={`nav-item menuitem ${this.getActive(i)}${i.enabled ? '' : 'd-none'}`}
                  data-bs-toggle='collapse'
                  data-bs-target={type === 'responsive' ? '#navbarToggleExternalContent' : ''}
                  aria-controls='navbarToggleExternalContent'
                  // aria-expanded='false'
                  aria-label='Toggle navigation'>
                  {i?.module === NavMenuEnum.GRIEVANCE_MODULES ? (
                    <a
                      href={
                        data.ConfigData?.getMainConfigdata?.data?.general?.grievancesAndAppeals?.value
                          ? data.ConfigData?.getMainConfigdata?.data?.general?.grievancesAndAppeals?.value
                          : info?.url
                      }
                      target='_blank'
                      rel='noreferrer'>
                      <img src={info?.icon} className='ic-menu-network-active' alt='Home Active' />
                      <span className='nav-text text-white'>{info?.title}</span>
                    </a>
                  ) : (
                    <span
                      tabIndex={0}
                      role='button'
                      onClick={() => this.onSelect(i?.module, info?.url, i?.contentId)}
                      onKeyPress={() => this.onSelect(i?.module, info?.url, i?.contentId)}>
                      <img src={info?.icon} className='ic-menu-network-active ' alt='Home Active' />
                      <span className='nav-text text-white'>{info?.title}</span>
                      {i.module === NavMenuEnum.NOTIFICATION_MODULES && notificationCountValue && (
                        <span className='notification-badge mobile-badge'>
                          {notificationCountValue ? (notificationCountValue > 99 ? '99+' : notificationCountValue) : 0}
                        </span>
                      )}
                    </span>
                  )}
                </li>
              );
            })}
      </div>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetUserData: actions.createGetUserInfoRequest,
  createGetProfilePicture: actions.getPhotoRequestAction,
  createGetPlanContactsData: actions.createGetPlanContactsRequest,
  createGetMainConfigDataRequest: actions.createGetMainConfigDataRequest,
  createGetModuleConfigDataRequest: actions.createGetModuleConfigDataRequest,
  createGetNotificationRequest: actions.createGetNotificationsRequest,
};

export default connect<stateProps, dispatchProps, ownProps>(mapStateToProps, mapDispatchToProps)(NavMenuLists);
