import { marked } from 'marked';
import React, { PureComponent } from 'react';
import { ACCEPTING_NEW_PATIENT, NOT_ACCEPTING_NEW_PATIENT } from '../../../../shared/constant/AppConstants';
import {
  convertToLowerCase,
  formatPhone,
  formattedScheduleTime,
  getSearchParams,
} from '../../../../shared/helperMethods/HelperMethod';
import Images from '../../../../shared/utils/Images';
import { getFormattedBio } from '../../../../shared/utils/ReusableComponents';
import { staticMap } from '../MapView/constants';
import './style.scss';

interface ownProps {
  providerInfo: any;
  reference: any;
  providerDetails: any;
  imageSrc: string;
}

class PrintComponent extends PureComponent<ownProps, {}> {
  constructor(props: ownProps) {
    super(props);
    this.state = {};
  }

  getImageByText = (text: string): any => {
    switch (text) {
      case 'Hill Physicians Medical Group':
        return Images.HILL;
      case 'SCCIPA':
        return Images.SCCIPA;
      case 'sccipa':
        return Images.SCCIPA;
      case 'Santa Clara County IPA':
        return Images.SCCIPA;
      case 'Meritage Medical Network':
        return Images.MERITAGE;
      case 'John Muir Health':
        return Images.JOHNMUIR;
      case 'John Muir Physician Network':
        return Images.JOHNMUIR;
      case 'Dignity Health Medical Network - Santa Cruz':
        return Images.DIGNITY;
      case 'Providence Medical Network':
        return Images.PROVIDENCE;
      default:
        return null;
    }
  };

  render(): React.ReactElement {
    const { providerInfo, reference, providerDetails, imageSrc } = this.props;
    const searchParams = getSearchParams(providerInfo?.primaryLocation[0]?.address, '446x220');
    const decodedParams = decodeURIComponent(searchParams.toString());
    const mapSrc = staticMap.url + decodedParams;
    const bio = providerDetails?.bio ? marked.parse(providerDetails?.bio) : null;
    return (
      <div style={{ position: 'absolute', zIndex: -999999, height: 1, overflow: 'scroll' }}>
        <div
          ref={reference}
          id='print-component'
          className='col-container'
          style={{
            width: 595,
            padding: '20px',
            // backgroundImage: `url(${Images.pdfTopStrip})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',

            fontFamily: 'SofiaPro',
          }}>
          {/* <div style={{ width: '100%', margin: '0px 0 16px 0', paddingTop: 8 }}>
          <img alt='' width={130} height={35} src={Images.pdfLOGO} />
        </div> */}
          <div style={{ display: 'flex' }}>
            <div style={{ width: '67%', margin: '0 0 10px 0' }}>
              <div className='col' style={{ display: 'flex' }}>
                <img
                  alt=''
                  className='avatar border'
                  style={{ borderRadius: '50%', height: '81px', width: '81px' }}
                  src={imageSrc}
                />
                <div style={{ marginLeft: 17 }}>
                  <h4
                    style={{
                      color: '#003C71',
                      fontSize: 16,
                      marginBottom: 0,
                      marginTop: 5,
                      textTransform: 'capitalize',
                      fontWeight: 700,
                    }}>
                    {providerInfo?.name}
                  </h4>
                  {providerDetails?.pronoun && (
                    <span
                      style={{
                        marginLeft: 0,
                        marginTop: '2px',
                        fontSize: 12,
                        color: '#696969',
                        display: 'block',
                        fontFamily: 'SofiaProLight',
                      }}>
                      {providerDetails?.pronoun}
                    </span>
                  )}

                  <div style={{ display: 'flex', marginBottom: 10, marginTop: 5 }}>
                    <img
                      alt=''
                      height=''
                      src={this.getImageByText(providerInfo?.medicalGroup)}
                      style={{ marginTop: 2 }}
                    />
                    <span style={{ marginLeft: 5, fontSize: 12, color: '#231F20' }}>{providerInfo?.medicalGroup}</span>
                  </div>
                  <span style={{ fontSize: 12, color: '#000' }}>
                    <strong>{providerInfo?.specialty && providerInfo?.specialty?.join(', ')}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div style={{ width: '33%', margin: '0 0 6px 0', padding: '0 8px' }}>
              <div style={{ display: 'flex', margin: '0', textTransform: 'capitalize' }}>
                <div style={{ width: 22, minWidth: 22 }}>
                  <img alt='' width='11.6' height={16} src={Images.pdfMapBlue} style={{ marginTop: '3px' }} />
                </div>{' '}
                <span className='blue-color font-12 fw-medium'>{convertToLowerCase(providerInfo?.clinicName)}</span>
              </div>
              <div style={{ margin: '0 0 4px 22px', lineHeight: 1.2 }}>
                <div style={{ marginRight: 10, width: 22 }} />{' '}
                <span
                  className='font-12 fw-light'
                  style={{ textTransform: 'capitalize', width: '100%', display: 'block', color: '#231F20' }}>
                  {convertToLowerCase(providerInfo?.primaryLocation[0]?.address?.street1)}{' '}
                  {convertToLowerCase(providerInfo?.primaryLocation[0]?.address?.street2)}
                </span>
                <span className='font-12 fw-light' style={{ color: '#231F20' }}>
                  {providerInfo?.primaryLocation[0]?.address?.city.replace(
                    /(\w)(\w*)/g,
                    (g0: any, g1: any, g2: any) => {
                      return g1.toUpperCase() + g2.toLowerCase();
                    }
                  )}
                  , {providerInfo?.primaryLocation[0]?.address?.state} {providerInfo?.primaryLocation[0]?.address?.zip}
                </span>
              </div>
              <div style={{ display: 'flex', margin: '0 0 4px 0' }}>
                <div style={{ width: 22 }}>
                  <img alt='' width={13} height={14} src={Images.pdfCallBlue} style={{ marginTop: '3px' }} />
                </div>{' '}
                <span style={{ fontFamily: 'SofiaProMedium', fontSize: '14px' }} className='blue-color fw-medium'>
                  {formatPhone(providerInfo?.phone)}
                </span>
              </div>
              <div style={{ display: 'flex', margin: '0 0 6px 0' }}>
                <div style={{ width: 22 }}>
                  <img
                    style={{ marginTop: '3px' }}
                    alt=''
                    width={15}
                    height={15}
                    src={`${providerInfo?.acceptingNewPatients ? Images.pdfCheck : Images.pdfCross}`}
                  />
                </div>{' '}
                <span className='font-12 fw-light'>
                  {`${providerInfo?.acceptingNewPatients ? ACCEPTING_NEW_PATIENT : NOT_ACCEPTING_NEW_PATIENT}`}
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '67%', margin: '0 0 10px 0', paddingRight: 15 }}>
              {bio && <div className='provider-bio mt-3'>{getFormattedBio(bio)}</div>}
            </div>
            {providerInfo?.primaryLocation[0]?.address && (
              <div style={{ width: '33%', margin: '0 0 10px 0' }}>
                <img alt='' style={{ border: '2px solid #00A3E1' }} width={185} height={102} src={mapSrc} />
              </div>
            )}
          </div>
          <div className='line' style={{ borderBottom: '1px solid #D9D9D9', margin: '0 -20px' }} />
          <div style={{ display: 'flex' }}>
            <div style={{ width: '67%', margin: '0 0 0px 0', paddingRight: 15 }}>
              {providerInfo?.hoursOfOperationString && (
                <>
                  <h5 style={{ margin: '12px 0 6px 0', fontWeight: 700 }} className='blue font-14 '>
                    Office Hours
                  </h5>
                  <ul>
                    {Object.entries(providerInfo?.hoursOfOperation).map(([day, time]) => {
                      return (
                        <li style={{ marginBottom: 0 }}>
                          <span style={{ display: 'inline-block', minWidth: 94 }}>{day}</span>
                          {formattedScheduleTime(time)}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}

              <h5 style={{ margin: '14px 0 6px 0', fontWeight: 700 }} className='blue font-14 '>
                Additional Information
              </h5>
              <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', fontSize: '12px' }}>
                <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                  <span style={{ display: 'inline-block', minWidth: 22 }}>
                    <img style={{ marginTop: 6 }} width={18} height={10} src={Images.pdfMedicalGrp} alt='' />
                  </span>
                  <div>
                    <span className='fw-semibold blue'> Medical Group IPA</span>
                    <div style={{ paddingLeft: 0, color: '#696969!important' }}>{providerInfo?.medicalGroup}</div>
                  </div>
                </div>
                <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                  <span style={{ display: 'inline-block', minWidth: 22 }}>
                    <img style={{ marginTop: 6 }} width={15} height={13} src={Images.pdfHealthPlan} alt='' />
                  </span>
                  <div>
                    <span className='fw-semibold blue'>Health Plan</span>
                    <div style={{ paddingLeft: 0, color: '#696969!important' }}>{providerInfo?.nameOfNetwork}</div>
                  </div>
                </div>

                <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                  <span style={{ display: 'inline-block', minWidth: 22 }}>
                    <img style={{ marginTop: 4 }} width={17} height={15} src={Images.pdfClinic} alt='' />
                  </span>
                  <div>
                    <span className='fw-semibold blue'> Clinic Name</span>
                    <div style={{ paddingLeft: 0, color: '#696969!important', textTransform: 'capitalize' }}>
                      {convertToLowerCase(providerInfo?.clinicName)}
                    </div>
                  </div>
                </div>
                <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                  <span style={{ display: 'inline-block', minWidth: 22 }}>
                    <img style={{ marginTop: 4 }} width={12} height={15} src={Images.pdfBoardCert} alt='' />
                  </span>
                  <div>
                    <span className='fw-semibold blue'>Board Certified</span>
                    <div style={{ paddingLeft: 0, color: '#696969!important' }}>
                      {providerInfo?.boardCertifiedOrEligible === 'Y' ? 'Yes' : 'No'}
                    </div>
                  </div>
                </div>

                {providerInfo?.hospitalNPI && (
                  <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                    <span style={{ display: 'inline-block', minWidth: 22 }}>
                      <img style={{ marginTop: 5 }} width={17} height={15} src={Images.pdfHospitalNPI} alt='' />
                    </span>
                    <div>
                      <span className='fw-semibold blue'>Hospital NPI</span>
                      <div style={{ paddingLeft: 0, color: '#696969!important' }}>{providerInfo?.hospitalNPI}</div>
                    </div>
                  </div>
                )}

                <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                  <span style={{ display: 'inline-block', minWidth: 22 }}>
                    <img style={{ marginTop: 3 }} width={17} height={15} src={Images.pdfHospitalNPI} alt='' />
                  </span>
                  <div>
                    <span className='fw-semibold blue'>NPI</span>
                    <div style={{ paddingLeft: 0, color: '#696969!important' }}>{providerInfo?.npi}</div>
                  </div>
                </div>

                {providerInfo?.primaryLocation[0]?.address?.county && (
                  <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                    <span style={{ display: 'inline-block', minWidth: 22 }}>
                      <img style={{ marginTop: 3 }} width={12} height={16} src={Images.pdfMap} alt='' />
                    </span>
                    <div>
                      <span className='fw-semibold blue'>County</span>
                      <div style={{ paddingLeft: 0, color: '#696969!important', textTransform: 'capitalize' }}>
                        {providerInfo?.primaryLocation[0]?.address?.county.toLowerCase()}
                      </div>
                    </div>
                  </div>
                )}

                <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                  <span style={{ display: 'inline-block', minWidth: 22 }}>
                    <img style={{ marginTop: 3 }} width={12} height={18} src={Images.pdfCALic} alt='' />
                  </span>
                  <div>
                    <span className='fw-semibold blue'>CA License</span>
                    <div style={{ paddingLeft: 0, color: '#696969!important' }}>{providerInfo?.caLicense}</div>
                  </div>
                </div>

                {providerInfo?.providerLanguage && providerInfo?.providerLanguage?.length > 0 && (
                  <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                    <span style={{ display: 'inline-block', minWidth: 22 }}>
                      <img style={{ marginTop: 3 }} width={11} height={14} src={Images.pdfFlag} alt='' />
                    </span>
                    <div>
                      <span className='fw-semibold blue'>Languages </span>
                      <div style={{ paddingLeft: 0, color: '#696969!important' }}>
                        {providerInfo?.providerLanguage?.join(', ').toLowerCase()}
                      </div>
                    </div>
                  </div>
                )}

                <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                  <span style={{ display: 'inline-block', minWidth: 22 }}>
                    <img style={{ marginTop: 6 }} width={17} height={14} src={Images.pdfTelehealth} alt='' />
                  </span>
                  <div>
                    <span className='fw-semibold blue'>Telehealth</span>
                    <div style={{ paddingLeft: 0, color: '#696969!important' }}>
                      {providerInfo?.telehealth ? 'Yes' : 'No'}
                    </div>
                  </div>
                </div>

                <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                  <span style={{ display: 'inline-block', minWidth: 22 }}>
                    <img style={{ marginTop: 3 }} width={16} height={16} src={Images.pdfHospitalist} alt='' />
                  </span>
                  <div>
                    <span className='fw-semibold blue'>Hospitalist</span>
                    <div style={{ paddingLeft: 0, color: '#696969!important' }}>No</div>
                  </div>
                </div>
                {providerInfo?.hospital && providerInfo?.hospital?.length > 0 && (
                  <div style={{ width: '50%', display: 'flex', float: 'left', paddingRight: 3, marginBottom: 3 }}>
                    <span style={{ display: 'inline-block', minWidth: 22 }}>
                      <img style={{ marginTop: 6 }} width={15} height={12} src={Images.pdfHospital} alt='' />
                    </span>
                    <div>
                      <span className='fw-semibold blue'>Hospitals</span>
                      {providerInfo?.hospital.map((hospital: any) => (
                        <div style={{ paddingLeft: 0, color: '#696969!important' }}>
                          {hospital}{' '}
                          {providerInfo?.hospitalPrivilegeStatus?.length > 0 && '(Has Privileges, Referral Required)'}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                width: '33%',
                margin: '0 0 10px 0',
                backgroundColor: '#F5F5F5',
                padding: '0 8px',
              }}>
              {providerInfo.additionalLocation.length > 0 && (
                <h5 style={{ margin: '12px 0 6px 0', fontWeight: 700 }} className='blue font-14 '>
                  Additional Locations
                </h5>
              )}

              {providerInfo.additionalLocation.map((l: any) => (
                <div style={{ width: '100%', marginBottom: 20 }}>
                  <div style={{ display: 'flex', margin: '0 0 6px 0' }}>
                    <div style={{ width: 22, minWidth: 22 }}>
                      <img alt='' style={{ marginTop: 3 }} width='11.6' height={16} src={Images.pdfMap} />
                    </div>
                    <span
                      style={{
                        color: '#3B3D3C',
                        textTransform: 'capitalize',
                        fontFamily: 'SofiaProSemiBold',
                        fontSize: '12px',
                      }}
                      className='font-12'>
                      {convertToLowerCase(l.clinicName)}
                    </span>
                  </div>
                  <div style={{ display: 'flex', margin: '0px' }}>
                    <div style={{ marginRight: 3, width: 22 }} />{' '}
                    <span
                      className='font-12 fw-light'
                      style={{ textTransform: 'capitalize', width: '100%', display: 'block', color: '#231F20' }}>
                      {convertToLowerCase(l?.address?.street1)}, {convertToLowerCase(l?.address?.street2)}
                    </span>
                  </div>
                  <div style={{ display: 'flex', margin: '0 0 6px 0' }}>
                    <div style={{ marginRight: 0, width: 22 }} />{' '}
                    <span className='font-12 fw-light' style={{ color: '#231F20' }}>
                      {l?.address?.city.replace(/(\w)(\w*)/g, (g0: any, g1: any, g2: any) => {
                        return g1.toUpperCase() + g2.toLowerCase();
                      })}
                      , {l?.address?.state} {l?.address?.zip}
                    </span>
                  </div>
                  <div style={{ display: 'flex', margin: '0 0 6px 0' }}>
                    <div style={{ width: 22 }}>
                      <img alt='' style={{ marginTop: 3 }} width={13} height={14} src={Images.pdfCall} />
                    </div>
                    <span
                      style={{ color: '#3B3D3C', fontFamily: 'SofiaProSemiBold', fontSize: '12px' }}
                      className='font-12'>
                      {l.phone}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div style={{ padding: '6px 0', borderTop: '1px solid #D9D9D9', marginTop: 10 }}>
          <span style={{ fontSize: 8, display: 'inline-block', color: '#3B3D3C' }}>
            <DoctorAndServiceFooterInfo data={data} location='providers' page='d_s_home' target='pdf' />
          </span>
        </div>
        <div style={{ padding: '6px 0', borderTop: '1px solid #D9D9D9' }}>
          <span
            style={{
              fontSize: 10,
              display: 'inline-block',
              textAlign: 'left',
              color: '#3B3D3C',
            }}>
            CanopyHealth.com
          </span>
          <div
            style={{
              fontSize: 10,
              display: 'inline-block',
              textAlign: 'right',
              float: 'right',
              color: '#3B3D3C',
            }}>
            Date: {dateFormatter(new Date())}
          </div>
        </div> */}
        </div>
      </div>
    );
  }
}

export default PrintComponent;
