import { PureComponent } from 'react';
import { history } from '../../../shared/helperMethods/HelperMethod';
import Links from '../../../shared/sharedComponents/genericLinks/Links';
import DoctorAndServiceFooterInfo from '../sharedDoctorsComponents/DoctorAndServiceFooterInfo';
import DoctorAndServiceMenu from '../sharedDoctorsComponents/Menu';

export default class GenericLinksDS extends PureComponent<{}, { url: any; text: any }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      url: '',
      text: 'Generic Link 1',
    };
  }

  componentDidMount(): any {
    const state: any = history.location.state as { url: string; text: any };
    this.setState({ url: state?.data?.url, text: state?.data?.text });
  }

  render(): JSX.Element {
    console.log(history.location.state, 'dddddd', this.state);
    const { url, text } = this.state;
    return (
      <>
        {' '}
        <div className='network services '>
          <DoctorAndServiceMenu />
          <div className='container-fluid common-filters'>
            <div className='row'>
              <div className='col-12'>{text}</div>

              <div className='col-12'>
                <div className='horizontal-links pill'>
                  <div className='link-wrapper'>
                    <Links type='link' url={url} text='Generic link 1' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DoctorAndServiceFooterInfo location='' page='' data={null} />
      </>
    );
  }
}
