/* eslint-disable react/require-default-props */
import { getSearchParams } from '../../../../shared/helperMethods/HelperMethod';
import { staticMap } from '../MapView/constants';
import { Address } from '../MapView/types';
import './AddressMap.scss';

interface AddressMapProps {
  address: Address;
  size?: string;
}
const AddressMap = ({ size = staticMap.size, address }: AddressMapProps): JSX.Element => {
  const { street1, city, state, zip } = address;
  const searchParams = getSearchParams(address, size);
  const decodedParams = decodeURIComponent(searchParams.toString());

  return (
    <a href={`https://www.google.com/maps/place/${street1}+${city}+${state}+${zip}`} target='_blank' rel='noreferrer'>
      <img className='map-img' src={staticMap.url + decodedParams} alt='Static Provider Map' />
    </a>
  );
};

export default AddressMap;
