/* eslint-disable no-unused-vars */
import { createContext, useState } from 'react';
import { Doctor } from './types';

type GoogleMap = google.maps.Map;
export type MapContextType = {
  map: GoogleMap | null;
  setMap: (map: GoogleMap) => void;
  isZipSearch: boolean;
  isUserPreEffective: boolean;
  onBookAppointment: (doctor: Doctor) => void;
};

export const MapContext = createContext<MapContextType>({
  map: null,
  setMap: () => {},
  isZipSearch: false,
  isUserPreEffective: false,
  onBookAppointment: () => {},
});

interface MapProviderProps {
  children: React.ReactNode;
  isZipSearch: boolean;
  isUserPreEffective: boolean;
  onBookAppointment: (data: Doctor) => void;
}
export const MapProvider = ({
  children,
  isZipSearch,
  isUserPreEffective,
  onBookAppointment,
}: MapProviderProps): JSX.Element => {
  const [map, setMap] = useState<GoogleMap | null>(null);

  return (
    <MapContext.Provider value={{ map, setMap, isZipSearch, isUserPreEffective, onBookAppointment }}>
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
