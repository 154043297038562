/* eslint-disable no-restricted-syntax */
import * as actions from '@canopy/shared';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { TERMS_VAR } from '../../shared/constant/AppConstants';
import Analytics, { FA } from '../../shared/utils/Analytics';
import { hideLoader, showLoader } from '../../shared/utils/ReusableComponents';

interface ownState {}

interface ownProps {}

interface DispatchProps {
  createGetTermsRequest: typeof actions.createGetTermsRequest;
}

interface StateProps {
  data: any;
}

type Props = StateProps & ownProps & DispatchProps & RouteComponentProps;

class Privacy extends PureComponent<Props, ownState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount(): any {
    const { createGetTermsRequest } = this.props;
    showLoader();
    createGetTermsRequest(TERMS_VAR, () => hideLoader());
    Analytics.logEvent(FA.ACCOUNT_SETTINGS_PRIVACY_POLICY_VIEW);
  }

  getFormattedHTML = (html: string): string => {
    if (html) {
      try {
        const snippet = document.createElement('div');
        snippet.innerHTML = html;
        const links: any = snippet.getElementsByTagName('a');
        console.log(links);
        for (const element of links) {
          // eslint-disable-line no-restricted-syntax
          const lastURL = element.href;
          if (lastURL.includes('#')) {
            const splitterURL = lastURL.split('#');
            const pathName = window.location.pathname.substring(1, window.location.pathname.length);
            const finalPath = `${splitterURL[0] + pathName}#${splitterURL[1]}`;
            element.setAttribute('href', finalPath);
          }
        }
        return snippet.innerHTML;
      } catch (error) {
        return html;
      }
    }
    return html;
  };

  render(): React.ReactElement {
    const { data } = this.props;
    const dataHtml = this.getFormattedHTML(data?.ConfigData?.getMainConfigdata?.data?.general?.privacyPolicy?.value);

    return (
      <div className='enrollment'>
        <div className='recovery-container col-12 px-2'>
          <div className='recover-divider large-divider col-12' />
          <div className='row'>
            <div className='remote-content  mt-4 mt-sm-2'>
              <div dangerouslySetInnerHTML={{ __html: dataHtml }} />
              {/* <iframe
                height='1000px'
                srcDoc={data?.ConfigData?.getMainConfigdata?.data?.general?.privacyPolicy?.value}
                title='W3Schools Free Online Web Tutorials'
              /> */}
            </div>
            <div className='col-12 error-container'>
              <div className='error pull-right' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: actions.termsState): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetTermsRequest: actions.createGetTermsRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
