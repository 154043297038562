import { AreaType, LatLngBoundsLiteral, LatLngLiteral } from '../types';

type GoogleMapsPlacesIDs = {
  [key: string]: { placeId: string; center: LatLngLiteral; viewport?: LatLngBoundsLiteral; type?: AreaType };
};

/**
 * Represents a mapping of California places to their corresponding Google Maps Places IDs.
 * Refresh this list every 6 months - 12 months
 */
const CaliforniaPlacesIDs: GoogleMapsPlacesIDs = {
  '94002': {
    placeId: 'ChIJD9K8CeCdj4ARfVUx0VEeodM',
    center: { lat: 37.5178022, lng: -122.2900288 },
    viewport: { south: 37.482132, west: -122.405202, north: 37.6619879, east: -122.2442159 },
  },
  '94005': {
    placeId: 'ChIJPdomh9h4j4ARtX9WvM3V3Zw',
    center: { lat: 37.6948419, lng: -122.4070834 },
    viewport: { south: 37.665877, west: -122.441962, north: 37.7088241, east: -122.381007 },
  },
  '94010': {
    placeId: 'ChIJtX4JXg92j4ARe8rvGNS9VCI',
    center: { lat: 37.5685247, lng: -122.367428 },
    viewport: { south: 37.528838, west: -122.431274, north: 37.604147, east: -122.328772 },
  },
  '94014': {
    placeId: 'ChIJjSZHBVN5j4ARL9vD45vA7a0',
    center: { lat: 37.6904558, lng: -122.4523855 },
    viewport: { south: 37.665551, west: -122.4871369, north: 37.7085839, east: -122.40529 },
  },
  '94015': {
    placeId: 'ChIJL1JjBIp7j4AREUS-HHEumzk',
    center: { lat: 37.68000019999999, lng: -122.4863492 },
    viewport: { south: 37.643417, west: -122.5028319, north: 37.708332, east: -122.4494269 },
  },
  '94019': {
    placeId: 'ChIJC8sZCqULj4ARf92dh61PgQk',
    center: { lat: 37.4903082, lng: -122.4353995 },
    viewport: { south: 37.3116529, west: -122.518456, north: 37.5785769, east: -122.310655 },
  },
  '94022': {
    placeId: 'ChIJcWn9-lGwj4ARHtGJMQcrD-I',
    center: { lat: 37.3694009, lng: -122.1405413 },
    viewport: { south: 37.3120199, west: -122.184777, north: 37.406258, east: -122.09598 },
  },
  '94024': {
    placeId: 'ChIJr_c4Bjexj4ARbKhPgrhogj8',
    center: { lat: 37.3478257, lng: -122.1007867 },
    viewport: { south: 37.3135379, west: -122.1505261, north: 37.38444200000001, east: -122.06063 },
  },
  '94025': {
    placeId: 'ChIJNREEB6ykj4ARQSDDhLXB1nw',
    center: { lat: 37.4484914, lng: -122.1802812 },
    viewport: { south: 37.414466, west: -122.2291331, north: 37.5079439, east: -122.1213449 },
  },
  '94027': {
    placeId: 'ChIJk8yo4Zikj4ARSu8z_d17c9I',
    center: { lat: 37.45117949999999, lng: -122.2029832 },
    viewport: { south: 37.4235229, west: -122.243382, north: 37.4767579, east: -122.167865 },
  },
  '94030': {
    placeId: 'ChIJEcVQEZh3j4ARC4trOriNaA8',
    center: { lat: 37.5958949, lng: -122.4184108 },
    viewport: { south: 37.577704, west: -122.4500679, north: 37.6213909, east: -122.367066 },
  },
  '94040': {
    placeId: 'ChIJhZZfP9Kwj4AR2LUWdqV3MwI',
    center: { lat: 37.3785351, lng: -122.086585 },
    viewport: { south: 37.35676, west: -122.1181001, north: 37.412765, east: -122.05831 },
  },
  '94041': {
    placeId: 'ChIJM5uGGy63j4ARfeOMwhgqtVs',
    center: { lat: 37.386812, lng: -122.0751549 },
    viewport: { south: 37.3753199, west: -122.0963229, north: 37.400773, east: -122.052311 },
  },
  '94044': {
    placeId: 'ChIJnSRzsS93j4ARsiZDmkTXLyk',
    center: { lat: 37.6101767, lng: -122.4806894 },
    viewport: { south: 37.55773689999999, west: -122.5208519, north: 37.668352, east: -122.4209929 },
  },
  '94061': {
    placeId: 'ChIJf6xATZGjj4ARnTzFNW4kpuQ',
    center: { lat: 37.4634447, lng: -122.2256803 },
    viewport: { south: 37.4425729, west: -122.269844, north: 37.482088, east: -122.20861 },
  },
  '94062': {
    placeId: 'ChIJF24KZ6Omj4ARQ-2iSTIfTDs',
    center: { lat: 37.41089270000001, lng: -122.2880726 },
    viewport: { south: 37.3070936, west: -122.4110063, north: 37.55722040000001, east: -122.1791199 },
  },
  '94063': {
    placeId: 'ChIJGbGfdziij4AR1KNJ04ublg0',
    center: { lat: 37.5021585, lng: -122.2086579 },
    viewport: { south: 37.4676058, west: -122.245104, north: 37.5359611, east: -122.166279 },
  },
  '94065': {
    placeId: 'ChIJbY2Yv3-Yj4AR6jmFiGbiXzA',
    center: { lat: 37.5331558, lng: -122.2483726 },
    viewport: { south: 37.5156319, west: -122.269979, north: 37.553596, east: -122.194557 },
  },
  '94066': {
    placeId: 'ChIJm8dmkuV5j4ARQ4MGVfa1QD0',
    center: { lat: 37.6210696, lng: -122.4297369 },
    viewport: { south: 37.5995281, west: -122.472817, north: 37.6423159, east: -122.3963499 },
  },
  '94070': {
    placeId: 'ChIJF7-Fm-mhj4ARp3FkIrcRSyY',
    center: { lat: 37.4975165, lng: -122.2710602 },
    viewport: { south: 37.4747739, west: -122.3159701, north: 37.523182, east: -122.233676 },
  },
  '94080': {
    placeId: 'ChIJVxDJj595j4ARFDzG0LyHEiw',
    center: { lat: 37.6537343, lng: -122.4083383 },
    viewport: { south: 37.63397, west: -122.471883, north: 37.6790449, east: -122.374315 },
  },
  '94085': {
    placeId: 'ChIJd4dqQ0i2j4AR0yXmyEvvYa8',
    center: { lat: 37.3895297, lng: -122.0183914 },
    viewport: { south: 37.3378699, west: -122.04989, north: 37.402107, east: -121.986811 },
  },
  '94086': {
    placeId: 'ChIJw0w0kkG2j4AR2SBnXfwd4Sw',
    center: { lat: 37.371859, lng: -122.0212337 },
    viewport: { south: 37.3520899, west: -122.0600761, north: 37.3878189, east: -121.9824199 },
  },
  '94087': {
    placeId: 'ChIJb0EdOtW1j4ARX73uHICOO3E',
    center: { lat: 37.3492097, lng: -122.0326019 },
    viewport: { south: 37.329901, west: -122.065265, north: 37.37543489999999, east: -121.9957801 },
  },
  '94102': {
    placeId: 'ChIJs88qnZmAhYARk8u-7t1Sc2g',
    center: { lat: 37.7786871, lng: -122.4212424 },
    viewport: { south: 37.7694409, west: -122.429849, north: 37.789226, east: -122.4034491 },
  },
  '94103': {
    placeId: 'ChIJ09mpM52AhYARm2WOMfyfxhs',
    center: { lat: 37.7726402, lng: -122.4099154 },
    viewport: { south: 37.7635609, west: -122.4286571, north: 37.78805, east: -122.3978164 },
  },
  '94104': {
    placeId: 'ChIJD6M14YmAhYAR5WVbcn7uWPk',
    center: { lat: 37.7911148, lng: -122.4021273 },
    viewport: { south: 37.7879766, west: -122.4046131, north: 37.7939871, east: -122.399149 },
  },
  '94105': {
    placeId: 'ChIJDXK6UmKAhYARfzuOY6DDgeM',
    center: { lat: 37.7890183, lng: -122.3915063 },
    viewport: { south: 37.7824491, west: -122.4035291, north: 37.80018200000001, east: -122.381681 },
  },
  '94107': {
    placeId: 'ChIJg0__2jN-j4AR479OXNRG7O8',
    center: { lat: 37.7618242, lng: -122.3985871 },
    viewport: { south: 37.749358, west: -122.406282, north: 37.786636, east: -122.377981 },
  },
  '94108': {
    placeId: 'ChIJx5rJUYyAhYARxagLGBVGeFs',
    center: { lat: 37.7909427, lng: -122.4084994 },
    viewport: { south: 37.785744, west: -122.4148261, north: 37.7962109, east: -122.4034656 },
  },
  '94109': {
    placeId: 'ChIJy0ilcOmAhYARCLOo6oZQNxk',
    center: { lat: 37.7929789, lng: -122.4212424 },
    viewport: { south: 37.7811169, west: -122.4326081, north: 37.8128889, east: -122.411699 },
  },
  '94110': {
    placeId: 'ChIJjxQcAEF-j4ARegNFVBwq4vg',
    center: { lat: 37.7485824, lng: -122.4184108 },
    viewport: { south: 37.731608, west: -122.427269, north: 37.7657831, east: -122.4028549 },
  },
  '94111': {
    placeId: 'ChIJ49w0El-AhYAR9WVSnuOiWM8',
    center: { lat: 37.79593620000001, lng: -122.4000032 },
    viewport: { south: 37.737043, west: -122.4059541, north: 37.8072279, east: -122.3673941 },
  },
  '94112': {
    placeId: 'ChIJ_wUjuoN-j4ARLYl5YXTu7iQ',
    center: { lat: 37.72254909999999, lng: -122.4410618 },
    viewport: { south: 37.70734789999999, west: -122.4691299, north: 37.735749, east: -122.415459 },
  },
  '94114': {
    placeId: 'ChIJ_dA2rgV-j4ARt3snzTZ80Ds',
    center: { lat: 37.7561438, lng: -122.4325682 },
    viewport: { south: 37.7466831, west: -122.453769, north: 37.770452, east: -122.424923 },
  },
  '94115': {
    placeId: 'ChIJzyP3rbeAhYARTmAfNPJZzeY',
    center: { lat: 37.7877522, lng: -122.4382307 },
    viewport: { south: 37.776435, west: -122.449367, north: 37.7958539, east: -122.426829 },
  },
  '94116': {
    placeId: 'ChIJddwdf4N9j4ARNjehk8mVCNA',
    center: { lat: 37.7432421, lng: -122.497668 },
    viewport: { south: 37.733694, west: -122.509831, north: 37.75469200000001, east: -122.4586351 },
  },
  '94117': {
    placeId: 'ChIJ773z7quAhYARnGZ-5ZUIkcg',
    center: { lat: 37.7717185, lng: -122.4438929 },
    viewport: { south: 37.75875, west: -122.469553, north: 37.8002219, east: -122.428426 },
  },
  '94118': {
    placeId: 'ChIJE8NGljiHhYARnSY8nSgLJkk',
    center: { lat: 37.7822891, lng: -122.463708 },
    viewport: { south: 37.766062, west: -122.479504, north: 37.791637, east: -122.4436991 },
  },
  '94121': {
    placeId: 'ChIJ-6VsK6aHhYARhH00Wvw4WGs',
    center: { lat: 37.7813454, lng: -122.497668 },
    viewport: { south: 37.7635859, west: -122.517914, north: 37.7908389, east: -122.4716729 },
  },
  '94122': {
    placeId: 'ChIJaZi-WHqHhYARP44d5B8lUhI',
    center: { lat: 37.7597481, lng: -122.4750292 },
    viewport: { south: 37.7482199, west: -122.5124721, north: 37.77095, east: -122.4575359 },
  },
  '94123': {
    placeId: 'ChIJrYrvSdeAhYARtJlHJMWNQzY',
    center: { lat: 37.8020405, lng: -122.4382307 },
    viewport: { south: 37.7917029, west: -122.454562, north: 37.8097279, east: -122.423521 },
  },
  '94124': {
    placeId: 'ChIJe7_iURF_j4ARlf3H78H0YTk',
    center: { lat: 37.7304167, lng: -122.384425 },
    viewport: { south: 37.708231, west: -122.408137, north: 37.7536288, east: -122.356879 },
  },
  '94127': {
    placeId: 'ChIJK9xbjZR9j4ARBsVPOdGWHs8',
    center: { lat: 37.734646, lng: -122.463708 },
    viewport: { south: 37.721569, west: -122.472671, north: 37.7513029, east: -122.442329 },
  },
  '94131': {
    placeId: 'ChIJ22y0JQt-j4ARCs3ThPYOWpc',
    center: { lat: 37.7401042, lng: -122.4382307 },
    viewport: { south: 37.72826200000001, west: -122.463842, north: 37.7638849, east: -122.4241631 },
  },
  '94132': {
    placeId: 'ChIJSbCANq99j4ARVMl6KzrGk9c',
    center: { lat: 37.7181398, lng: -122.4863492 },
    viewport: { south: 37.705705, west: -122.5085381, north: 37.7379569, east: -122.462254 },
  },
  '94133': {
    placeId: 'ChIJ61hhQeGAhYARo_x_aAlCar8',
    center: { lat: 37.8059887, lng: -122.4099154 },
    viewport: { south: 37.793769, west: -122.4256409, north: 37.863203, east: -122.392499 },
  },
  '94143': {
    placeId: 'ChIJx2Ut5lmHhYARRUAfGnAt19I',
    center: { lat: 37.7642093, lng: -122.4571623 },
    viewport: {
      south: 37.76289917010727,
      west: -122.4585438298927,
      north: 37.76559882989272,
      east: -122.4558441701073,
    },
  },
  '94158': {
    placeId: 'ChIJTayi3tN_j4ARIwuQJy7-etE',
    center: { lat: 37.7748363, lng: -122.3872576 },
    viewport: { south: 37.7640839, west: -122.401657, north: 37.7835291, east: -122.3796809 },
  },
  '94301': {
    placeId: 'ChIJbfq2kBe7j4ARBzf9LVIpZok',
    center: { lat: 37.4457966, lng: -122.1575745 },
    viewport: { south: 37.42523, west: -122.171706, north: 37.4581999, east: -122.1304251 },
  },
  '94306': {
    placeId: 'ChIJhxxeoom6j4ARKMXcNAF30Rc',
    center: { lat: 37.4177563, lng: -122.1235054 },
    viewport: { south: 37.38876, west: -122.16025, north: 37.4383301, east: -122.10203 },
  },
  '94401': {
    placeId: 'ChIJ5TjWyNedj4AR1EQrzuRNaMU',
    center: { lat: 37.5793536, lng: -122.3164207 },
    viewport: { south: 37.5560318, west: -122.347063, north: 37.5963281, east: -122.2884139 },
  },
  '94402': {
    placeId: 'ChIJHSMvQf2dj4ARfzKRE5zdJKI',
    center: { lat: 37.5245965, lng: -122.3390936 },
    viewport: { south: 37.506029, west: -122.361134, north: 37.5731629, east: -122.2961209 },
  },
  '94403': {
    placeId: 'ChIJUS8gl_2ej4AR4KB0vyRXFH0',
    center: { lat: 37.5349925, lng: -122.3050823 },
    viewport: { south: 37.514998, west: -122.33256, north: 37.5676641, east: -122.2749741 },
  },
  '94404': {
    placeId: 'ChIJgaOrhjyZj4ARJLkyFXSV0wU',
    center: { lat: 37.5549479, lng: -122.2710602 },
    viewport: { south: 37.533401, west: -122.294573, north: 37.5758218, east: -122.2451559 },
  },
  '94501': {
    placeId: 'ChIJs3wB2CeBj4ARMPiRBmuhQLs',
    center: { lat: 37.7712165, lng: -122.2824021 },
    viewport: { south: 37.747903, west: -122.3402809, north: 37.80062789999999, east: -122.2237791 },
  },
  '94506': {
    placeId: 'ChIJ1--SUvXxj4AR2Ha04lkoXOA',
    center: { lat: 37.8213111, lng: -121.9131761 },
    viewport: { south: 37.777254, west: -121.9730191, north: 37.8677039, east: -121.8399601 },
  },
  '94507': {
    placeId: 'ChIJE7K-hSaLj4ARoFF-RzGvMdw',
    center: { lat: 37.8541701, lng: -122.0098642 },
    viewport: { south: 37.827221, west: -122.059817, north: 37.8887571, east: -121.952001 },
  },
  '94509': {
    placeId: 'ChIJAXAiboZXhYARHhQiT3_d_oo',
    center: { lat: 38.0083802, lng: -121.7993168 },
    viewport: { south: 37.945066, west: -121.870809, north: 38.063087, east: -121.750528 },
  },
  '94510': {
    placeId: 'ChIJgVtaYmBrhYARdh2nY_7HEKQ',
    center: { lat: 38.1153501, lng: -122.1064668 },
    viewport: { south: 38.030901, west: -122.1985039, north: 38.1875748, east: -122.053002 },
  },
  '94513': {
    placeId: 'ChIJ2Q1lu2r8j4AREnNWiwjbeHc',
    center: { lat: 37.9341399, lng: -121.6967438 },
    viewport: { south: 37.818442, west: -121.8651731, north: 38.042985, east: -121.556866 },
  },
  '94515': {
    placeId: 'ChIJb5PTXcRphIARCfjSff-DsdI',
    center: { lat: 38.6045556, lng: -122.6051351 },
    viewport: { south: 38.5296588, west: -122.747236, north: 38.73622599999999, east: -122.4879541 },
  },
  '94517': {
    placeId: 'ChIJ8cEE0O72j4ARcHB4EcunApw',
    center: { lat: 37.8793779, lng: -121.8790306 },
    viewport: { south: 37.7890419, west: -121.961973, north: 37.9557409, east: -121.732627 },
  },
  '94518': {
    placeId: 'ChIJi9M1w8NghYARH6uHM0-jQDY',
    center: { lat: 37.9461207, lng: -122.0212337 },
    viewport: { south: 37.931385, west: -122.0529061, north: 37.975324, east: -121.990871 },
  },
  '94519': {
    placeId: 'ChIJ9cQfrUFnhYARd9GSbF4da-I',
    center: { lat: 37.9876914, lng: -122.0098642 },
    viewport: { south: 37.968772, west: -122.0337239, north: 38.011468, east: -121.970002 },
  },
  '94520': {
    placeId: 'ChIJEUxoJU1mhYARWZOORWptwGw',
    center: { lat: 38.0349026, lng: -122.026918 },
    viewport: { south: 37.9470898, west: -122.0706919, north: 38.0613359, east: -121.976661 },
  },
  '94521': {
    placeId: 'ChIJPRRIoxNehYARnmyB8qrheSc',
    center: { lat: 37.9538109, lng: -121.9643745 },
    viewport: { south: 37.91605089999999, west: -122.009633, north: 37.996998, east: -121.901042 },
  },
  '94523': {
    placeId: 'ChIJjSKNpKxmhYARmpX20uyLq9E',
    center: { lat: 37.9448915, lng: -122.0723816 },
    viewport: { south: 37.92526, west: -122.104371, north: 37.982394, east: -122.05062 },
  },
  '94526': {
    placeId: 'ChIJp77p60bzj4ARzE-tDuj0Do0',
    center: { lat: 37.8006948, lng: -121.9814354 },
    viewport: { south: 37.7799139, west: -122.053979, north: 37.8463, east: -121.948562 },
  },
  '94530': {
    placeId: 'ChIJ9w_4HAd5hYAREpGJE24eARk',
    center: { lat: 37.9202057, lng: -122.2937428 },
    viewport: { south: 37.8977429, west: -122.324952, north: 37.9445289, east: -122.2641779 },
  },
  '94531': {
    placeId: 'ChIJ33A8dnD4j4AR4954crHpxE4',
    center: { lat: 37.9644542, lng: -121.7708337 },
    viewport: { south: 37.9322498, west: -121.841926, north: 37.996029, east: -121.7397279 },
  },
  '94536': {
    placeId: 'ChIJXezxrl7Aj4ARbqGvmu4yDZs',
    center: { lat: 37.5641425, lng: -122.004179 },
    viewport: { south: 37.5297739, west: -122.038506, north: 37.606946, east: -121.926968 },
  },
  '94538': {
    placeId: 'ChIJWcvu2qO4j4ARpT5KSTkRc_0',
    center: { lat: 37.5042267, lng: -121.9643745 },
    viewport: { south: 37.453235, west: -122.002236, north: 37.565455, east: -121.917097 },
  },
  '94539': {
    placeId: 'ChIJ24wgUSjEj4ARs2MIuB5u5y4',
    center: { lat: 37.5148444, lng: -121.9131761 },
    viewport: { south: 37.458473, west: -121.961554, north: 37.576397, east: -121.826864 },
  },
  '94541': {
    placeId: 'ChIJbfJM2WKRj4ARKMCZW3XWQYM',
    center: { lat: 37.6737239, lng: -122.1007867 },
    viewport: { south: 37.653022, west: -122.1281019, north: 37.693962, east: -122.0402909 },
  },
  '94544': {
    placeId: 'ChIJ6ynTwRSUj4ARr4VRerh-M-g',
    center: { lat: 37.6270256, lng: -122.0496521 },
    viewport: { south: 37.603907, west: -122.10161, north: 37.6698509, east: -121.996915 },
  },
  '94545': {
    placeId: 'ChIJjwdFgtOQj4ARg6bA3DRalv4',
    center: { lat: 37.6063621, lng: -122.1178261 },
    viewport: { south: 37.558852, west: -122.166947, north: 37.6672971, east: -122.07145 },
  },
  '94546': {
    placeId: 'ChIJk4EoJ_6Rj4ARomkWF9tn3Kk',
    center: { lat: 37.7188649, lng: -122.0780632 },
    viewport: { south: 37.6789459, west: -122.1226659, north: 37.762723, east: -122.0537199 },
  },
  '94547': {
    placeId: 'ChIJIav3r35whYAR_l4k2ogZKWQ',
    center: { lat: 38.00674, lng: -122.259717 },
    viewport: { south: 37.970327, west: -122.3021779, north: 38.0422699, east: -122.214646 },
  },
  '94549': {
    placeId: 'ChIJKUnNc6xjhYARvRe9SXpU90E',
    center: { lat: 37.8929461, lng: -122.1178261 },
    viewport: { south: 37.848223, west: -122.203817, north: 37.959237, east: -122.0779761 },
  },
  '94550': {
    placeId: 'ChIJK7_xvNnXj4ARzVg8nDI4l-M',
    center: { lat: 37.6536947, lng: -121.6739371 },
    viewport: { south: 37.2737949, west: -121.827072, north: 37.73149, east: -121.336033 },
  },
  '94551': {
    placeId: 'ChIJzVJs7mnkj4ARBC-SOJPojZc',
    center: { lat: 37.7537708, lng: -121.7879244 },
    viewport: { south: 37.6751149, west: -121.8717931, north: 37.845572, east: -121.630016 },
  },
  '94553': {
    placeId: 'ChIJ1bvv5z1lhYARZ_OLoSzKAM0',
    center: { lat: 37.9873593, lng: -122.151897 },
    viewport: { south: 37.91787, west: -122.2522169, north: 38.0621369, east: -122.0485079 },
  },
  '94555': {
    placeId: 'ChIJX4ijKgq-j4ARqn9yFg5woio',
    center: { lat: 37.5677679, lng: -122.0522177 },
    viewport: { south: 37.50667, west: -122.135769, north: 37.59579910000001, east: -122.02536 },
  },
  '94558': {
    placeId: 'ChIJU24Y1cH2hIARtgAfsitUglg',
    center: { lat: 38.3170536, lng: -122.3019206 },
    viewport: { south: 38.201742, west: -122.497592, north: 38.8642448, east: -122.0613789 },
  },
  '94559': {
    placeId: 'ChIJ20627FoIhYAR5r2q7PcMMSE',
    center: { lat: 38.22965, lng: -122.3220894 },
    viewport: { south: 38.1547489, west: -122.407512, north: 38.314478, east: -122.1932811 },
  },
  '94560': {
    placeId: 'ChIJAwuLNyu5j4ARXrjtGmNuHhE',
    center: { lat: 37.5189256, lng: -122.026918 },
    viewport: { south: 37.4489491, west: -122.110894, north: 37.5634078, east: -121.9733811 },
  },
  '94561': {
    placeId: 'ChIJc6rscpZVhYARQuQIBboiMIk',
    center: { lat: 38.0044162, lng: -121.685341 },
    viewport: { south: 37.9624181, west: -121.756753, north: 38.0578189, east: -121.6200951 },
  },
  '94563': {
    placeId: 'ChIJ-ZSjytx8hYAR-J07IPCFEEs',
    center: { lat: 37.8912529, lng: -122.1859572 },
    viewport: { south: 37.837499, west: -122.249468, north: 37.93593690000001, east: -122.139376 },
  },
  '94564': {
    placeId: 'ChIJlzziqx53hYARNfbLfVCgMA8',
    center: { lat: 37.9963136, lng: -122.2937428 },
    viewport: { south: 37.9612051, west: -122.3263819, north: 38.0179861, east: -122.227616 },
  },
  '94565': {
    placeId: 'ChIJr_Wy20lahYAR0E-ZlNSPERg',
    center: { lat: 38.0182853, lng: -121.9245556 },
    viewport: { south: 37.9374859, west: -122.015571, north: 38.0870589, east: -121.815907 },
  },
  '94566': {
    placeId: 'ChIJC4eqyTLoj4ARhPmDpD2sYGI',
    center: { lat: 37.6436199, lng: -121.8676464 },
    viewport: { south: 37.6196739, west: -121.912314, north: 37.6883761, east: -121.798144 },
  },
  '94568': {
    placeId: 'ChIJlZJIzSHsj4ARry4hXTrbB38',
    center: { lat: 37.7202463, lng: -121.8676464 },
    viewport: { south: 37.6973739, west: -122.0012771, north: 37.74500810000001, east: -121.832043 },
  },
  '94574': {
    placeId: 'ChIJmzqxCI9XhIARW17wfSbyQkc',
    center: { lat: 38.5241378, lng: -122.3787597 },
    viewport: { south: 38.43649389999999, west: -122.571762, north: 38.65856300000001, east: -122.2397769 },
  },
  '94577': {
    placeId: 'ChIJ__Ci6eyPj4ARuXvco9op0ow',
    center: { lat: 37.7199247, lng: -122.1689284 },
    viewport: { south: 37.6826851, west: -122.209269, north: 37.7450099, east: -122.119075 },
  },
  '94578': {
    placeId: 'ChIJraFGf9SRj4ARqLO4kWExgSs',
    center: { lat: 37.7050703, lng: -122.1235054 },
    viewport: { south: 37.6877769, west: -122.160971, north: 37.72166, east: -122.096226 },
  },
  '94582': {
    placeId: 'ChIJeQzsWY_tj4AR0VhmX5wBtvE',
    center: { lat: 37.7639471, lng: -121.9131761 },
    viewport: { south: 37.728955, west: -121.9644579, north: 37.795123, east: -121.8559979 },
  },
  '94583': {
    placeId: 'ChIJlXCVgbfyj4ARwYU7AZ_nS5w',
    center: { lat: 37.7624642, lng: -121.9814354 },
    viewport: { south: 37.7186291, west: -122.011771, north: 37.7867269, east: -121.9173719 },
  },
  '94587': {
    placeId: 'ChIJAUo3KWKVj4ARRtdpH5dVod0',
    center: { lat: 37.5952304, lng: -122.043969 },
    viewport: { south: 37.561402, west: -122.096567, north: 37.6316209, east: -121.9926449 },
  },
  '94588': {
    placeId: 'ChIJhz_eheXrj4ARCvNe3h_0dig',
    center: { lat: 37.6873908, lng: -121.9131761 },
    viewport: { south: 37.632432, west: -121.979341, north: 37.702088, east: -121.8213661 },
  },
  '94591': {
    placeId: 'ChIJo6joN4lyhYARNezY5QucCYw',
    center: { lat: 38.1256242, lng: -122.1973081 },
    viewport: { south: 38.05677110000001, west: -122.2336811, north: 38.191064, east: -122.1475709 },
  },
  '94595': {
    placeId: 'ChIJoQWDzwiKj4AR8Bi8fizAeN8',
    center: { lat: 37.8655663, lng: -122.0666997 },
    viewport: { south: 37.8350349, west: -122.0917549, north: 37.8976049, east: -122.0433061 },
  },
  '94596': {
    placeId: 'ChIJtwq3_8ZhhYARdz9xUgdS4Nk',
    center: { lat: 37.8824054, lng: -122.026918 },
    viewport: { south: 37.865707, west: -122.0712849, north: 37.9179648, east: -122.006096 },
  },
  '94597': {
    placeId: 'ChIJkYFMH3phhYARTD9hwuY4POA',
    center: { lat: 37.9193365, lng: -122.0780632 },
    viewport: { south: 37.895976, west: -122.100011, north: 37.940474, east: -122.04727 },
  },
  '94598': {
    placeId: 'ChIJfaU7yWxghYARg8csdNl0xLY',
    center: { lat: 37.89618249999999, lng: -121.9814354 },
    viewport: { south: 37.849998, west: -122.055467, north: 37.9412669, east: -121.9094841 },
  },
  '94601': {
    placeId: 'ChIJb04_cF-Gj4AREgBPyVjgA3c',
    center: { lat: 37.7729273, lng: -122.2143323 },
    viewport: { south: 37.754428, west: -122.238723, north: 37.79614189999999, east: -122.1945941 },
  },
  '94602': {
    placeId: 'ChIJQwEAbK6Hj4ARJ2pir7stgqQ',
    center: { lat: 37.8015517, lng: -122.2143323 },
    viewport: { south: 37.78901, west: -122.234679, north: 37.821647, east: -122.1773601 },
  },
  '94605': {
    placeId: 'ChIJH8FFyxuPj4ARoRZNksBvPTQ',
    center: { lat: 37.7554905, lng: -122.1462193 },
    viewport: { south: 37.727038, west: -122.198593, north: 37.786332, east: -122.1116459 },
  },
  '94606': {
    placeId: 'ChIJAWrgqyGHj4ARFfu2yT7TWIw',
    center: { lat: 37.7944092, lng: -122.2455364 },
    viewport: { south: 37.7716959, west: -122.26838, north: 37.8085669, east: -122.2235309 },
  },
  '94607': {
    placeId: 'ChIJUZYFroKAj4ARrTs45mWdoH4',
    center: { lat: 37.8134679, lng: -122.307917 },
    viewport: { south: 37.7880281, west: -122.346681, north: 37.832947, east: -122.2595771 },
  },
  '94608': {
    placeId: 'ChIJj4VwQUJ-hYARW-kxrTBdc-g',
    center: { lat: 37.837959, lng: -122.2824021 },
    viewport: { south: 37.81711689999999, west: -122.3202479, north: 37.850726, east: -122.267169 },
  },
  '94609': {
    placeId: 'ChIJEysWhOF9hYARF2r-1IUGoi4',
    center: { lat: 37.8321173, lng: -122.2625529 },
    viewport: { south: 37.816237, west: -122.2754799, north: 37.85308089999999, east: -122.2517109 },
  },
  '94610': {
    placeId: 'ChIJbdp1-kGHj4ARnuXAxr5HiZM',
    center: { lat: 37.8104485, lng: -122.2398636 },
    viewport: { south: 37.798995, west: -122.2642541, north: 37.823831, east: -122.2165211 },
  },
  '94611': {
    placeId: 'ChIJg5MBfH99hYARIZKqn6zWexo',
    center: { lat: 37.8336281, lng: -122.2029832 },
    viewport: { south: 37.804085, west: -122.2637559, north: 37.867866, east: -122.1573919 },
  },
  '94612': {
    placeId: 'ChIJj31Vkq2Aj4ARF9GHuQbYTDE',
    center: { lat: 37.8113159, lng: -122.2682245 },
    viewport: { south: 37.79855209999999, west: -122.28141, north: 37.8185081, east: -122.2585229 },
  },
  '94618': {
    placeId: 'ChIJu8x5V5R9hYARvvUFDZ13hbw',
    center: { lat: 37.8438192, lng: -122.2398636 },
    viewport: { south: 37.829719, west: -122.262305, north: 37.860282, east: -122.2144389 },
  },
  '94619': {
    placeId: 'ChIJyaCx-KSIj4ARsX5AMEqgGU4',
    center: { lat: 37.7936811, lng: -122.1462193 },
    viewport: { south: 37.753138, west: -122.2104611, north: 37.81842200000001, east: -122.0945259 },
  },
  '94621': {
    placeId: 'ChIJp-Y9Jr6Fj4AREYoBmrhC_PU',
    center: { lat: 37.7347438, lng: -122.2143323 },
    viewport: { south: 37.6953939, west: -122.256542, north: 37.7710919, east: -122.165823 },
  },
  '94702': {
    placeId: 'ChIJif71QpN-hYARsJ3d5fgWGTQ',
    center: { lat: 37.8680576, lng: -122.2852374 },
    viewport: { south: 37.84697200000001, west: -122.296997, north: 37.881492, east: -122.277627 },
  },
  '94704': {
    placeId: 'ChIJk5trQy58hYARnl9MIbSf6aU',
    center: { lat: 37.86373760000001, lng: -122.2682245 },
    viewport: { south: 37.859769, west: -122.273855, north: 37.87412, east: -122.234669 },
  },
  '94705': {
    placeId: 'ChIJo7K2p0l8hYARWCUmvf6THKg',
    center: { lat: 37.8646149, lng: -122.2341905 },
    viewport: { south: 37.8495549, west: -122.267954, north: 37.8843448, east: -122.2131959 },
  },
  '94706': {
    placeId: 'ChIJa-vkkzN5hYARI0dPB36iU9g',
    center: { lat: 37.8883952, lng: -122.2965778 },
    viewport: { south: 37.8801348, west: -122.3097921, north: 37.901013, east: -122.28006 },
  },
  '94709': {
    placeId: 'ChIJuSDrJR58hYARynM2Nh0zZwk',
    center: { lat: 37.8796524, lng: -122.2668066 },
    viewport: { south: 37.8721319, west: -122.2756731, north: 37.8880009, east: -122.2547229 },
  },
  '94806': {
    placeId: 'ChIJO4Dz37d3hYARDAsM_1WnMh0',
    center: { lat: 37.9759027, lng: -122.3447611 },
    viewport: { south: 37.9486869, west: -122.3878711, north: 38.017366, east: -122.307483 },
  },
  '94901': {
    placeId: 'ChIJJT_NhoiZhYARbYoe7g43Nhg',
    center: { lat: 37.9650627, lng: -122.503327 },
    viewport: { south: 37.9415369, west: -122.560974, north: 38.0171599, east: -122.442814 },
  },
  '94903': {
    placeId: 'ChIJlXUTUZOXhYARJySPxpxTHvI',
    center: { lat: 38.0270044, lng: -122.5485873 },
    viewport: { south: 37.986823, west: -122.641769, north: 38.062933, east: -122.485361 },
  },
  '94904': {
    placeId: 'ChIJIYx9VqaQhYAR5SsiM14pWRY',
    center: { lat: 37.941063, lng: -122.5655549 },
    viewport: { south: 37.924923, west: -122.6072791, north: 37.9692481, east: -122.502214 },
  },
  '94924': {
    placeId: 'ChIJr7-PeyuVhYARB6nTeEH9paw',
    center: { lat: 37.9588807, lng: -122.7068429 },
    viewport: { south: 37.8898629, west: -122.8175521, north: 38.029727, east: -122.6249401 },
  },
  '94925': {
    placeId: 'ChIJB39hdu2ahYARH4zaqhlG77Q',
    center: { lat: 37.920558, lng: -122.5089856 },
    viewport: { south: 37.9078249, west: -122.5388479, north: 37.9422199, east: -122.481384 },
  },
  '94928': {
    placeId: 'ChIJscz1ZWlKhIARiKnIeLJbfw4',
    center: { lat: 38.3437823, lng: -122.7011951 },
    viewport: { south: 38.31430900000001, west: -122.7319671, north: 38.3724149, east: -122.666825 },
  },
  '94939': {
    placeId: 'ChIJsw3LTJ2QhYARVgEFLcenfCU',
    center: { lat: 37.938818, lng: -122.5344456 },
    viewport: { south: 37.92190009999999, west: -122.557524, north: 37.952005, east: -122.501383 },
  },
  '94941': {
    placeId: 'ChIJFXVq1haQhYARBXhFotBhSVo',
    center: { lat: 37.9060624, lng: -122.5712101 },
    viewport: { south: 37.8149469, west: -122.6212141, north: 37.9340411, east: -122.4782819 },
  },
  '94945': {
    placeId: 'ChIJLXQEKhG7hYAROdC3kAV1sUQ',
    center: { lat: 38.1218145, lng: -122.5485873 },
    viewport: { south: 38.0800799, west: -122.6327149, north: 38.176815, east: -122.482661 },
  },
  '94947': {
    placeId: 'ChIJnbR5qmG5hYARX4srsQQYcSo',
    center: { lat: 38.12457310000001, lng: -122.6616518 },
    viewport: { south: 38.0600499, west: -122.774276, north: 38.185583, east: -122.536725 },
  },
  '94949': {
    placeId: 'ChIJaxVaQcmihYAR2ma6lWXt9cA',
    center: { lat: 38.0599857, lng: -122.503327 },
    viewport: { south: 38.036507, west: -122.6043221, north: 38.099271, east: -122.47966 },
  },
  '94952': {
    placeId: 'ChIJ57mp-dAshIARd5Npwzkc83Q',
    center: { lat: 38.235501, lng: -122.6420207 },
    viewport: { south: 38.12051, west: -122.936843, north: 38.3604449, east: -122.565758 },
  },
  '94954': {
    placeId: 'ChIJX1LguqixhYARIxHmC07vucU',
    center: { lat: 38.2473117, lng: -122.5712101 },
    viewport: { south: 38.111702, west: -122.6771039, north: 38.3441428, east: -122.4572289 },
  },
  '94956': {
    placeId: 'ChIJ40RJVW3FhYARnBtVDTNc4AQ',
    center: { lat: 38.0373567, lng: -122.8197329 },
    viewport: { south: 37.9212879, west: -122.998237, north: 38.1412059, east: -122.7417539 },
  },
  '94960': {
    placeId: 'ChIJ2cX8c6yXhYARxOLJklYtWDo',
    center: { lat: 37.9885355, lng: -122.5655549 },
    viewport: { south: 37.967223, west: -122.6168679, north: 38.031254, east: -122.5505989 },
  },
  '94965': {
    placeId: 'ChIJPWNmZpyFhYARv9KtuCjLnx8',
    center: { lat: 37.8444843, lng: -122.5089856 },
    viewport: { south: 37.8230358, west: -122.5936469, north: 37.8965588, east: -122.4675489 },
  },
  '95003': {
    placeId: 'ChIJMVg0ehMWjoARHkejgBtL4uA',
    center: { lat: 36.9771465, lng: -121.8994154 },
    viewport: { south: 36.9338891, west: -121.939473, north: 37.077109, east: -121.817698 },
  },
  '95006': {
    placeId: 'ChIJxW8_J9pNjoARJDyjCQmcoR8',
    center: { lat: 37.1833232, lng: -122.151897 },
    viewport: { south: 37.1017359, west: -122.2529241, north: 37.260213, east: -122.0574389 },
  },
  '95008': {
    placeId: 'ChIJnYxD9xc1joARCqJFcBmEAWM',
    center: { lat: 37.2770029, lng: -121.9529992 },
    viewport: { south: 37.257055, west: -121.990884, north: 37.303146, east: -121.922453 },
  },
  '95010': {
    placeId: 'ChIJ_aE19nMVjoAR2uGLbAzaav4',
    center: { lat: 36.9764598, lng: -121.9501551 },
    viewport: { south: 36.9674089, west: -121.971182, north: 36.984083, east: -121.931849 },
  },
  '95014': {
    placeId: 'ChIJyTSQVXm0j4ARmdUQoA1BpwQ',
    center: { lat: 37.31317, lng: -122.0723816 },
    viewport: { south: 37.247995, west: -122.143823, north: 37.341595, east: -121.9955771 },
  },
  '95018': {
    placeId: 'ChIJ9f6_4mBGjoARlDjOb3XE-gg',
    center: { lat: 37.0435289, lng: -122.0723816 },
    viewport: { south: 37.009749, west: -122.1145419, north: 37.1423119, east: -121.996761 },
  },
  '95019': {
    placeId: 'ChIJLeMBzkIajoAR_zPIgFDr-Fo',
    center: { lat: 36.9355718, lng: -121.7793794 },
    viewport: { south: 36.9243889, west: -121.80003, north: 36.9449569, east: -121.7652671 },
  },
  '95030': {
    placeId: 'ChIJu_0MDPQ1joAR7-PJCgfeZAk',
    center: { lat: 37.22823169999999, lng: -121.9871217 },
    viewport: { south: 37.194693, west: -122.0305689, north: 37.2576089, east: -121.9132061 },
  },
  '95032': {
    placeId: 'ChIJ0-Fd6tI1joAR4wMVjsNLNLA',
    center: { lat: 37.2260616, lng: -121.9302449 },
    viewport: { south: 37.163101, west: -121.9954511, north: 37.2691059, east: -121.8633319 },
  },
  '95035': {
    placeId: 'ChIJjx97cYDFj4ARHhAdSJLFPB4',
    center: { lat: 37.4323716, lng: -121.8993526 },
    viewport: { south: 37.3961101, west: -121.9319049, north: 37.504886, east: -121.7765171 },
  },
  '95050': {
    placeId: 'ChIJkyN3003Kj4ARP-4TcpEoJuA',
    center: { lat: 37.3539663, lng: -121.9529992 },
    viewport: { south: 37.32314, west: -121.9702899, north: 37.37448000000001, east: -121.9308761 },
  },
  '95051': {
    placeId: 'ChIJaY0iIQ3Kj4ARyU7ORmUkWqA',
    center: { lat: 37.3598283, lng: -121.9814354 },
    viewport: { south: 37.321056, west: -122.0062301, north: 37.3804799, east: -121.96422 },
  },
  '95054': {
    placeId: 'ChIJEytLHr7Jj4ARtyMBjxZVW40',
    center: { lat: 37.3986039, lng: -121.9643745 },
    viewport: { south: 37.3732199, west: -121.9875759, north: 37.42322, east: -121.935639 },
  },
  '95060': {
    placeId: 'ChIJARH6oe5DjoARMGaroZqxmew',
    center: { lat: 37.0105307, lng: -122.1178261 },
    viewport: { south: 36.944122, west: -122.2266641, north: 37.15623190000001, east: -122.002328 },
  },
  '95062': {
    placeId: 'ChIJcaQSglIVjoARzUaFAQFJVDE',
    center: { lat: 36.9677899, lng: -121.9871217 },
    viewport: { south: 36.949892, west: -122.0215529, north: 36.98854600000001, east: -121.95046 },
  },
  '95065': {
    placeId: 'ChIJISOgUmQ_joARimSQN1vZcCQ',
    center: { lat: 37.0353977, lng: -121.9871217 },
    viewport: { south: 36.983241, west: -122.0132749, north: 37.0857899, east: -121.946402 },
  },
  '95066': {
    placeId: 'ChIJW76Xi0FHjoARXAHlAm26DRA',
    center: { lat: 37.0702838, lng: -122.0155491 },
    viewport: { south: 37.028877, west: -122.0508451, north: 37.1164689, east: -121.953385 },
  },
  '95070': {
    placeId: 'ChIJ26Tj4TpLjoARRNn2cfYUP0A',
    center: { lat: 37.2638319, lng: -122.023004 },
    viewport: { south: 37.2079899, west: -122.12229, north: 37.299909, east: -121.980608 },
  },
  '95073': {
    placeId: 'ChIJJXB4pVI-joARXZ02tPSOPC4',
    center: { lat: 37.0459268, lng: -121.9359339 },
    viewport: { south: 36.982662, west: -121.9785359, north: 37.0938289, east: -121.8583001 },
  },
  '95076': {
    placeId: 'ChIJGyYtzg0bjoARPM__G3S1b6E',
    center: { lat: 36.9199564, lng: -121.7423434 },
    viewport: { south: 36.8143829, west: -121.8699409, north: 37.0707448, east: -121.581154 },
  },
  '95110': {
    placeId: 'ChIJ31SYUmLLj4AR4o5iJ_upyvk',
    center: { lat: 37.354611, lng: -121.918866 },
    viewport: { south: 37.307479, west: -121.9368571, north: 37.3749269, east: -121.870558 },
  },
  '95111': {
    placeId: 'ChIJoUEVh2syjoARCxcoKKPt_QM',
    center: { lat: 37.28566319999999, lng: -121.8277925 },
    viewport: { south: 37.25548810000001, west: -121.8663881, north: 37.313241, east: -121.789541 },
  },
  '95112': {
    placeId: 'ChIJeTX-s5XMj4ARpd08-2y8v5w',
    center: { lat: 37.3456227, lng: -121.8847222 },
    viewport: { south: 37.3034849, west: -121.9193939, north: 37.3834109, east: -121.8450339 },
  },
  '95113': {
    placeId: 'ChIJ1exwkKTMj4ARj5Ab73tinkE',
    center: { lat: 37.3326639, lng: -121.8918364 },
    viewport: { south: 37.326564, west: -121.9014579, north: 37.339833, east: -121.883881 },
  },
  '95116': {
    placeId: 'ChIJYTY2Nh_Nj4ARMZCOkQZEniA',
    center: { lat: 37.3558627, lng: -121.8505679 },
    viewport: { south: 37.3296599, west: -121.8742601, north: 37.3683939, east: -121.8276441 },
  },
  '95117': {
    placeId: 'ChIJ4YcqPMHKj4AR_oe_L8U_ojs',
    center: { lat: 37.3120731, lng: -121.9643745 },
    viewport: { south: 37.2943199, west: -121.9817339, north: 37.3299101, east: -121.9499179 },
  },
  '95118': {
    placeId: 'ChIJBYcmMu4zjoAR23pm-OuPmhk',
    center: { lat: 37.2589827, lng: -121.8847222 },
    viewport: { south: 37.2261808, west: -121.9109769, north: 37.2784819, east: -121.868919 },
  },
  '95120': {
    placeId: 'ChIJ7dRTL0AwjoARro34O1rqo6g',
    center: { lat: 37.1696999, lng: -121.8448745 },
    viewport: { south: 37.1076361, west: -121.9139209, north: 37.2437751, east: -121.76169 },
  },
  '95121': {
    placeId: 'ChIJXXfFDoAtjoAR41J6sTZE5no',
    center: { lat: 37.302108, lng: -121.8050125 },
    viewport: { south: 37.2852439, west: -121.8439831, north: 37.320797, east: -121.7716251 },
  },
  '95122': {
    placeId: 'ChIJTUEkLsgyjoARxHqSosTCbHw',
    center: { lat: 37.3304014, lng: -121.8391808 },
    viewport: { south: 37.310167, west: -121.8682601, north: 37.350835, east: -121.807718 },
  },
  '95123': {
    placeId: 'ChIJRUdx1SQujoAR1D1l6hEvUYE',
    center: { lat: 37.2374847, lng: -121.8277925 },
    viewport: { south: 37.214426, west: -121.875841, north: 37.25927009999999, east: -121.779679 },
  },
  '95124': {
    placeId: 'ChIJLYZ0HUI0joARxZgtM_Ya-JY',
    center: { lat: 37.2583724, lng: -121.918866 },
    viewport: { south: 37.2266869, west: -121.951968, north: 37.28715710000001, east: -121.8962499 },
  },
  '95125': {
    placeId: 'ChIJtykoQGQzjoARurml4V3nAyA',
    center: { lat: 37.2909813, lng: -121.8904136 },
    viewport: { south: 37.271282, west: -121.9317799, north: 37.3242569, east: -121.85611 },
  },
  '95126': {
    placeId: 'ChIJWyd8j0PLj4AR0jHDddMCreQ',
    center: { lat: 37.3290122, lng: -121.9160211 },
    viewport: { south: 37.2972349, west: -121.9364, north: 37.3510739, east: -121.892193 },
  },
  '95127': {
    placeId: 'ChIJacdbrpHSj4ARM6AAE-bcDPQ',
    center: { lat: 37.373498, lng: -121.7594384 },
    viewport: { south: 37.3287799, west: -121.8470931, north: 37.414144, east: -121.682389 },
  },
  '95128': {
    placeId: 'ChIJ3ZbVUSXLj4ARuieDkEUgBOI',
    center: { lat: 37.3189149, lng: -121.9416226 },
    viewport: { south: 37.29436, west: -121.9524019, north: 37.3381411, east: -121.913288 },
  },
  '95129': {
    placeId: 'ChIJtYfohF21j4ARJPJuL5HvXUA',
    center: { lat: 37.3052272, lng: -121.9871217 },
    viewport: { south: 37.2871528, west: -122.035237, north: 37.3232299, east: -121.96836 },
  },
  '95133': {
    placeId: 'ChIJFS71F1HMj4ARh01coyA6A-U',
    center: { lat: 37.3716914, lng: -121.8619539 },
    viewport: { south: 37.354195, west: -121.89171, north: 37.389068, east: -121.8365129 },
  },
  '95138': {
    placeId: 'ChIJ0YUuM-EujoAREUuAYIkFtD8',
    center: { lat: 37.2422863, lng: -121.7309452 },
    viewport: { south: 37.166066, west: -121.809145, north: 37.299344, east: -121.6368501 },
  },
  '95139': {
    placeId: 'ChIJsyWpKHAvjoARADUnGXwEqno',
    center: { lat: 37.2257107, lng: -121.7622874 },
    viewport: { south: 37.2143411, west: -121.779174, north: 37.2354379, east: -121.7465481 },
  },
  '95148': {
    placeId: 'ChIJ6ZtKgx4tjoARABCcOiZT_sQ',
    center: { lat: 37.3315827, lng: -121.7708337 },
    viewport: { south: 37.301291, west: -121.8259181, north: 37.3574339, east: -121.7016459 },
  },
  '95401': {
    placeId: 'ChIJ8z_bE3Y5hIARNOkF353KXdc',
    center: { lat: 38.4409697, lng: -122.7971649 },
    viewport: { south: 38.405408, west: -122.855341, north: 38.4882591, east: -122.7111899 },
  },
  '95403': {
    placeId: 'ChIJ08AbaNE4hIARwkLeq7_LhEY',
    center: { lat: 38.4739555, lng: -122.7520139 },
    viewport: { south: 38.448438, west: -122.826004, north: 38.558935, east: -122.7028579 },
  },
  '95404': {
    placeId: 'ChIJ76AV30VGhIARdixoxN2lD3E',
    center: { lat: 38.5386881, lng: -122.695547 },
    viewport: { south: 38.337067, west: -122.742226, north: 38.584579, east: -122.5482001 },
  },
  '95405': {
    placeId: 'ChIJ96XtQYlIhIARPR-mNEHX4FU',
    center: { lat: 38.43922999999999, lng: -122.6673018 },
    viewport: { south: 38.416245, west: -122.703557, north: 38.45827, east: -122.6327939 },
  },
  '95407': {
    placeId: 'ChIJ-2opGEo2hIARe6UOwd_ZdiM',
    center: { lat: 38.3986068, lng: -122.7520139 },
    viewport: { south: 38.351458, west: -122.811418, north: 38.433484, east: -122.6869369 },
  },
  '95409': {
    placeId: 'ChIJGV1wEttHhIARlvG7oxqEASI',
    center: { lat: 38.4668525, lng: -122.593828 },
    viewport: { south: 38.409856, west: -122.6941989, north: 38.5027561, east: -122.517831 },
  },
  '95425': {
    placeId: 'ChIJn7ZSAqQHhIARzPEpRKX4bKI',
    center: { lat: 38.8143447, lng: -123.0113568 },
    viewport: { south: 38.712316, west: -123.272657, north: 38.9105329, east: -122.7467221 },
  },
  '95448': {
    placeId: 'ChIJG4lsZaYQhIARDoeVxjljoqs',
    center: { lat: 38.6323169, lng: -122.8761309 },
    viewport: { south: 38.491577, west: -123.127096, north: 38.790905, east: -122.6237851 },
  },
  '95472': {
    placeId: 'ChIJ4ZWbiH06hIAR3yKKk0D6DsQ',
    center: { lat: 38.4067427, lng: -122.8761309 },
    viewport: { south: 38.326029, west: -122.9816291, north: 38.4820129, east: -122.748494 },
  },
  '95476': {
    placeId: 'ChIJEWh46T2nhYARa1V1vP0_Qs0',
    center: { lat: 38.2315738, lng: -122.4693688 },
    viewport: { south: 38.1006191, west: -122.571292, north: 38.3704958, east: -122.349564 },
  },
  '95492': {
    placeId: 'ChIJFy3V_NY9hIARbW6JtorAPQ0',
    center: { lat: 38.54676570000001, lng: -122.8197329 },
    viewport: { south: 38.472766, west: -122.858644, north: 38.5911891, east: -122.770631 },
  },
  'MARIN': {
    type: AreaType.County,
    placeId: 'ChIJ82aOMnyhhYARHLRVUdyH0As',
    center: { lat: 38.083403, lng: -122.7633036 },
    viewport: { south: 37.8151021, west: -123.1345229, north: 38.321227, east: -122.3474541 },
  },
  'SAN MATEO': {
    type: AreaType.County,
    placeId: 'ChIJiUYt5gF4j4AR3OQX5ii4s1c',
    center: { lat: 37.4337342, lng: -122.4014193 },
    viewport: { south: 37.0538579, west: -122.588177, north: 37.7086169, east: -122.081473 },
  },
  'SANTA CLARA': {
    type: AreaType.County,
    placeId: 'ChIJd_Y0eVIvkIARuQyDN0F1LBA',
    center: { lat: 37.2938907, lng: -121.7195459 },
    viewport: { south: 36.8929759, west: -122.202653, north: 37.484637, east: -121.208178 },
  },
  'SAN FRANCISCO': {
    type: AreaType.County,
    placeId: 'ChIJIQBpAG2ahYARUksNqd0_1h8',
    center: { lat: 37.7749295, lng: -122.4194155 },
    viewport: {
      south: 37.68123800868264,
      west: -122.55615363813435,
      north: 37.816970448301205,
      east: -122.34226356303508,
    },
  },
  'SANTA CRUZ': {
    type: AreaType.County,
    placeId: 'ChIJfzsx1n8TjoARGbNHDCbAPhU',
    center: { lat: 37.04539949999999, lng: -121.9579573 },
    viewport: { south: 36.8196, west: -122.3176821, north: 37.286055, east: -121.581154 },
  },
  'CONTRA COSTA': {
    type: AreaType.County,
    placeId: 'ChIJ3QQ6ifNuhYAR4fM4Ln-yyVk',
    center: { lat: 37.8534093, lng: -121.9017954 },
    viewport: { south: 37.718531, west: -122.4415839, north: 38.0998779, east: -121.5341021 },
  },
  'SOLANO': {
    type: AreaType.County,
    placeId: 'ChIJB_R9n88XhYAR-L8fjKYikj0',
    center: { lat: 38.3104969, lng: -121.9017954 },
    viewport: { south: 38.0317209, west: -122.4069699, north: 38.5390501, east: -121.5932731 },
  },
  'NAPA': {
    type: AreaType.County,
    placeId: 'ChIJMU8qI_lPhIARfMUQoxvSFP4',
    center: { lat: 38.5024689, lng: -122.2653887 },
    viewport: { south: 38.1549329, west: -122.646616, north: 38.8643, east: -122.061397 },
  },
  'SONOMA': {
    type: AreaType.County,
    placeId: 'ChIJj7atfV0ihIAR_qYnBSATSUk',
    center: { lat: 38.5779555, lng: -122.9888319 },
    viewport: { south: 38.07326, west: -123.632497, north: 38.8529161, east: -122.3474541 },
  },
  'ALAMEDA': {
    type: AreaType.County,
    placeId: 'ChIJWRd5NDfyj4ARc30TGxHHxmg',
    center: { lat: 37.6016892, lng: -121.7195459 },
    viewport: { south: 37.454186, west: -122.3737821, north: 37.905824, east: -121.4692139 },
  },
};

export default CaliforniaPlacesIDs;
