import {ApplicationInsights, ITelemetryItem, SeverityLevel} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { applicatioInsightUrl } from '../../../config';
const MaskData = require('maskdata')

const jsonMaskConfig2 = {
  cardMaskOptions: { maskWith: "X", unmaskedStartDigits: 2,unmaskedEndDigits: 4 },
  cardFields: [],

  // Email
  emailMaskOptions: { maskWith: "*", unmaskedStartCharactersBeforeAt: 2, unmaskedEndCharactersAfterAt: 2, maskAtTheRate: false },
  emailFields: [],

  // UUID
  uuidMaskOptions: { maskWith: "*", unmaskedStartCharacters: 4, unmaskedEndCharacters: 2 },
  uuidFields: [],

  // Password
  passwordMaskOptions: { maskWith: "*", maxMaskedCharacters: 10, unmaskedStartCharacters: 0, unmaskedEndCharacters: 0 },
  passwordFields: ['password'],

  // Phone
  phoneMaskOptions: { maskWith: "*", unmaskedStartDigits: 2, unmaskedEndDigits: 1 },
  phoneFields: ['phoneNumber'],

  // String
  stringMaskOptions: { maskWith: "*", maskOnlyFirstOccurance: false, values: [], maskAll: true, maskSpace: false },
  stringFields: ['dateOfBirth','passCode'],

};



const trackException =(message:any, properties?:any) =>{
  if(properties?.response?.config?.data){
    properties.response.config.data = MaskData.maskJSON2(JSON.parse(properties?.response?.config?.data), jsonMaskConfig2);
  }
  
  appInsights.trackException({ error: new Error(message), severityLevel: SeverityLevel.Error,properties:properties });
}
const trackInfo =(message:any, properties?:any) =>{
  appInsights.trackTrace({ message:message, severityLevel: SeverityLevel.Information,properties:properties });
}
const trackWarning =(message:any) =>{
  appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Warning });
}

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: applicatioInsightUrl,
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludePatterns: [new RegExp('/.*/')],
  }
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env:ITelemetryItem) => {
    env.tags = env.tags || [];
    env.tags["ai.cloud.role"] = "mchrp";
});

export { reactPlugin, appInsights, trackException, trackInfo, trackWarning };
