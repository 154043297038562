/* eslint no-underscore-dangle: 0 */
import * as actions from '@canopy/shared/lib';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { SERVICES } from '../../../shared/constant/AppConstants';
import { history, isJsonString } from '../../../shared/helperMethods/HelperMethod';
import ConfirmationModal from '../../../shared/sharedComponents/confirmationModal/ComfirmationModal';
import Links from '../../../shared/sharedComponents/genericLinks/Links';
import Auth from '../../../shared/utils/Auth';
import { hideLoader, showLoader } from '../../../shared/utils/ReusableComponents';
import DoctorAndServiceFooterInfo from '../sharedDoctorsComponents/DoctorAndServiceFooterInfo';
import FooterPaginations from '../sharedDoctorsComponents/FooterPaginations';
import DoctorAndServiceMenu from '../sharedDoctorsComponents/Menu';
import PSA from '../sharedDoctorsComponents/PSA';
import Paginations from '../sharedDoctorsComponents/Paginations';
import SearchResultServices from './ServicesResults';
import ServicesSearchFilters from './ServicesSearchFilters';

interface ownProps {}
interface ownState {
  currentPage: any;
  sort: any;
  showErrorModal: boolean;
  getDataSuccess: boolean;
  errorTitle: string;
  errorMessage?: string;
  stringifiedQuery: string;
  closedPsaVersion: boolean;
}
interface DispatchProps {
  createGetHealthPlanDataRequest: typeof actions.createGetHealthPlanDataRequest;
  createGetFilterDataRequest: typeof actions.createGetFilterDataRequest;
  createGetFilterDataAuthRequest: typeof actions.createGetFilterDataAuthRequest;
  createGetServicesRequest: typeof actions.createGetDoctorsServiceRequest;
  createGetDoctorsServiceCountRequest: typeof actions.createGetDoctorsServiceCountRequest;
  createGetUserData: typeof actions.createGetUserInfoRequest;
}

interface stateProps {
  data: any;
}

type props = ownProps & DispatchProps & stateProps & RouteComponentProps;

class Services extends PureComponent<props, ownState> {
  searchData: any;

  constructor(props: props) {
    super(props);
    this.state = {
      currentPage: 1,
      sort: { displayName: 1 },
      showErrorModal: false,
      getDataSuccess: false,
      errorTitle: 'Error Code: 500 - Internal Server Error',
      errorMessage: 'Some error occurred. Please try again later.',
      stringifiedQuery: '',
      closedPsaVersion: false,
    };
    this.searchData = {};
  }

  componentDidMount(): any {
    const { createGetHealthPlanDataRequest, data } = this.props;
    const resultData = data && data.DoctorsAndServicesData;
    resultData.DoctorsServicesDataResponse.data = null;
    if (!Auth.isAuthenticated()) {
      createGetHealthPlanDataRequest({}, () => this.setState({ getDataSuccess: true }));
      if (
        sessionStorage.getItem('closedPsaVersion') !== null &&
        sessionStorage.getItem('closedPsaVersion') === 'true'
      ) {
        this.setState({ closedPsaVersion: true });
      }
    }
    if (Auth.isAuthenticated()) {
      const userData = data?.userInfoData?.responsedata?.data;
      if (userData?.closedPsaVersion === '1') {
        this.setState({ closedPsaVersion: true });
      }
    }

    let params = history.location?.search;
    if (params) {
      params = params.substring(params.indexOf('=') + 1, params.length);
      if (params === 'CanopyCare') {
        Auth.setHealthPlan('Canopy Health - Health Net CanopyCare');
        this.filterCall();
      } else if (params === 'SignatureValue Harmony') {
        Auth.setHealthPlan('Canopy Health - UnitedHealthcare SignatureValue Harmony');
        this.filterCall();
      } else if (params === 'Blue & Gold') {
        Auth.setHealthPlan('Canopy Health - Health Net Blue & Gold');
        this.filterCall();
      } else if (params === 'SmartCare') {
        Auth.setHealthPlan('Canopy Health - Health Net SmartCare');
        this.filterCall();
      } else if (params === 'Medicare Advantage') {
        Auth.setHealthPlan('Canopy Health - UnitedHealthcare Medicare Advantage');
        this.filterCall();
      } else if (params === 'SignatureValue Advantage') {
        Auth.setHealthPlan('Canopy Health - UnitedHealthcare SignatureValue Advantage');
        this.filterCall();
      }
    }
    this.filterCall();
  }

  setStringifiedQueryString = (query: any): any => {
    this.setState({ stringifiedQuery: JSON.stringify(query) });
  };

  filterCall = (): void => {
    const { createGetFilterDataAuthRequest, createGetFilterDataRequest } = this.props;
    showLoader();
    if (Auth.isAuthenticated()) {
      const query = {
        serviceName: SERVICES,
      };
      createGetFilterDataAuthRequest({ query }, (res: any) => this.callBack(res));
    } else {
      const query = {
        nameOfNetwork: Auth.getHealthPlan(),
        serviceName: SERVICES,
      };
      createGetFilterDataRequest({ query }, (res: any) => this.callBack(res));
    }
  };

  searchServices = (searchData: any): any => {
    const { createGetServicesRequest, createGetDoctorsServiceCountRequest } = this.props;
    createGetDoctorsServiceCountRequest({ query: searchData, isAuthenticated: Auth.isAuthenticated() }, (res: any) =>
      this.searchCallBack(res)
    );
    if (Auth.isAuthenticated()) {
      createGetServicesRequest({ query: searchData, isAuthenticated: true }, (res: any) => this.searchCallBack(res));
    } else {
      createGetServicesRequest({ query: searchData, isAuthenticated: false }, (res: any) => this.searchCallBack(res));
    }
  };

  onSearchServices = (searchData: any): any => {
    const { data } = this.props;
    data.DoctorsAndServicesData = null;
    // showLoader();
    this.searchData = searchData.query;
    this.setState({ sort: { firstName: 1 }, currentPage: 1 });
    this.setState({ stringifiedQuery: JSON.stringify(searchData.query) });
    this.searchServices(searchData.query);
  };

  onPageChange = (pageValue: any): any => {
    const { stringifiedQuery } = this.state;
    if (isJsonString(stringifiedQuery)) {
      showLoader();
      this.setState({ currentPage: pageValue });
      const final = Object.assign(JSON.parse(stringifiedQuery), { page: pageValue });
      this.searchServices(final);
      const section = document.querySelector('#header-pagination');
      if (section) section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  onSortChange = (e: any): any => {
    showLoader();
    const sort = {
      displayName: Number(e.target.value),
    };
    this.setState({ sort });
    Object.assign(this.searchData, { sort });
    this.searchServices(this.searchData);
  };

  searchCallBack = (res: any): any => {
    hideLoader();
    if (!res.isSuccess) {
      this.setState({ errorTitle: `Status Code: ${res.statusCode}`, errorMessage: res.message, showErrorModal: true });
    }
  };

  callBack = (res: any): any => {
    hideLoader();
    if (res.isSuccess) {
      const { data } = this.props;
      const filterData = data && data.Flterdata;
      if (filterData.getFilterDataResponse && filterData.getFilterDataResponse.data) {
        Auth.setHealthPlan(filterData.getFilterDataResponse.data.nameOfNetwork);
        this.setState({ getDataSuccess: true });
      }
    } else {
      hideLoader();
      this.setState({ getDataSuccess: true });
    }
  };

  getLocationLink = (location: string): any => {
    const { data } = this.props;
    const filteredData = data?.ConfigData?.getModuleConfigdata?.data?.addressableSpaces?.filter(
      (i: any) => i.location === location
    );
    return filteredData?.length > 0 && filteredData[0];
  };

  clearResult = (): any => {
    const { data } = this.props;
    const resultData = data && data.DoctorsAndServicesData;
    resultData.DoctorsServicesDataResponse.data = null;
    this.forceUpdate();
  };

  render(): React.ReactElement {
    const { data, createGetFilterDataRequest, createGetUserData } = this.props;
    const { currentPage, sort, showErrorModal, getDataSuccess, errorTitle, errorMessage, closedPsaVersion } =
      this.state;
    const healthPlanData = data && data.HealthPlanData;
    const filterData = data && data.Flterdata;
    const resultData = data && data.DoctorsAndServicesData;
    return (
      <>
        {this.getLocationLink('psa') && !closedPsaVersion && (
          <PSA
            closedPsaVersion={() => this.setState({ closedPsaVersion: true })}
            createGetUserData={createGetUserData}
          />
        )}
        <div className='network services '>
          <div style={{ border: 'solid 1px #d0d0ce', borderRadius: '4px 4px 0 0' }}>
            <DoctorAndServiceMenu />{' '}
            {getDataSuccess && (
              <ServicesSearchFilters
                createGetServicesRequest={this.onSearchServices}
                filterData={filterData && filterData.getFilterDataResponse && filterData.getFilterDataResponse.data}
                healthPlanData={
                  healthPlanData &&
                  healthPlanData.getHealthPlanDataResponse &&
                  healthPlanData.getHealthPlanDataResponse.data
                }
                updateFilterData={createGetFilterDataRequest}
                setStringifiedQueryString={this.setStringifiedQueryString}
                clearResult={this.clearResult}
              />
            )}
          </div>
          {getDataSuccess && (
            <>
              <div className='row'>
                <div className='grid-section container-fluid col-12 px-0'>
                  {resultData &&
                  resultData.DoctorsServicesDataSuccess &&
                  resultData.DoctorsServicesDataResponse &&
                  resultData.DoctorsServicesDataResponse?.data &&
                  resultData.DoctorsServicesDataResponse?.data.length === 0 ? (
                    <>
                      {' '}
                      <div className='no-record hint-label'>
                        <p>0 Services Found</p>
                      </div>
                    </>
                  ) : (
                    <>
                      {resultData &&
                        resultData.DoctorsServicesDataResponse &&
                        resultData.DoctorsServicesDataResponse?.data && (
                          <>
                            <div className='row'>
                              <>
                                <Paginations
                                  data={resultData.DoctorsServicesCountResponse}
                                  currentPage={currentPage}
                                  onPageChange={this.onPageChange}
                                  sortOrder={sort}
                                  onSortChange={this.onSortChange}
                                  pageName='Services'
                                  isListView
                                />
                                {Auth.isAuthenticated() &&
                                  this.getLocationLink('doctorsAndServices-searchResults')?.items?.map((i: any) => (
                                    <div className='horizontal-links pill d-inline'>
                                      <div className='link-wrapper d-inline'>
                                        {' '}
                                        <Links type={i?.type} text={i?.text} url={i?.url} />
                                      </div>
                                    </div>
                                  ))}
                              </>
                            </div>
                            {resultData.DoctorsServicesDataResponse?.data?.map((i: any) => (
                              <div className='row mt-3'>
                                <SearchResultServices data={i} />
                              </div>
                            ))}
                          </>
                        )}
                    </>
                  )}
                </div>
              </div>
              {resultData &&
                resultData.DoctorsServicesDataResponse &&
                resultData.DoctorsServicesDataResponse?.data &&
                resultData.DoctorsServicesDataResponse?.data?.length > 0 && (
                  <FooterPaginations
                    currentPage={currentPage}
                    onPageChange={this.onPageChange}
                    data={resultData?.DoctorsServicesCountResponse?.data}
                  />
                )}
            </>
          )}
        </div>
        {!resultData.DoctorsServicesDataResponse?.data && (
          <div className='row mb-4'>
            <div className='col-12 highlight-message text-start'>
              Select one or more filter options then click the search button.
            </div>
          </div>
        )}
        <DoctorAndServiceFooterInfo location='services' data={data} page='' />
        {showErrorModal && (
          <ConfirmationModal
            title={errorTitle}
            message={errorMessage}
            showModal={showErrorModal}
            closeModal={() => this.setState({ showErrorModal: false })}
          />
        )}
      </>
    );
  }
}
const mapStateToProps: any = (state: actions.manageFilterDataState): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createGetHealthPlanDataRequest: actions.createGetHealthPlanDataRequest,
  createGetFilterDataRequest: actions.createGetFilterDataRequest,
  createGetFilterDataAuthRequest: actions.createGetFilterDataAuthRequest,
  createGetServicesRequest: actions.createGetDoctorsServiceRequest,
  createGetDoctorsServiceCountRequest: actions.createGetDoctorsServiceCountRequest,
  createGetUserData: actions.createGetUserInfoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
