/* eslint-disable */
import * as action from '@canopy/shared';
import { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { baseVersion } from '../../../config';
import { NONDISCRIMINATION_LABEL, PRIVACY_LABEL, TERMS_LABEL } from '../../shared/constant/AppConstants';
import { NONDISCRIMINATION_URL, PRIVACY_URL, TERMS_AND_CONDITION_URL } from '../../shared/constant/Urls';
import { history } from '../../shared/helperMethods/HelperMethod';
import Auth from '../../shared/utils/Auth';
import Images from '../../shared/utils/Images';

interface footerState {}
interface footerProps {}

interface dispatchProps {
  createGetPlanContactsData: typeof action.createGetPlanContactsRequest;
}
interface stateProps {
  data: any;
}

type componentProps = footerProps & dispatchProps & stateProps;

class Footer extends PureComponent<componentProps, footerState> {
  constructor(props: componentProps) {
    super(props);
    this.state = {
      getDataSuccess: false,
      showWelcomeMessage: true,
    };
  }

  componentDidMount(): any {
    const { createGetPlanContactsData } = this.props;
    if (Auth.isAuthenticated()) {
      createGetPlanContactsData({
        type: ['Provider', 'Member', 'CallCenter'],
        userId: Auth.getUserId(),
      });
    } else {
      createGetPlanContactsData({ type: ['CallCenter'], payor: '*' });
    }
  }
  render(): ReactNode {
    const { data } = this.props;
    const contact = data?.Register?.planContactsData?.data?.find((i: any) => i?.type === 'CallCenter')?.phoneNumber;
    return (
      <div className='footer-container col-12 px-2 px-sm-4'>
        <div className='flex-wrapper'>
          <div className='footer-item'>
            <ul className='navigation'>
              <li>
                <span role='button' onClick={() => history.push(PRIVACY_URL)}>
                  {PRIVACY_LABEL}
                </span>
              </li>
              <li>
                <span
                  role='button'
                  tabIndex={0}
                  onClick={() => history.push(TERMS_AND_CONDITION_URL)}
                  onKeyPress={() => history.push(TERMS_AND_CONDITION_URL)}>
                  {TERMS_LABEL}
                </span>
              </li>
              <li>
                <span role='button' onClick={() => history.push(NONDISCRIMINATION_URL)}>
                  {NONDISCRIMINATION_LABEL}
                </span>
              </li>
            </ul>
          </div>
          <div className='footer-item'>
            <ul>
              <li>
                <img
                  className='phone'
                  src={Images.icPhoneHeader}
                  srcSet={`${Images.icPhoneHeader2x} 2x, ${Images.icPhoneHeader3x} 3x`}
                  alt='canopy'
                />
                <a href={`tel:${contact}`}>{contact}</a>
              </li>
              <li>
                <span>&copy; {new Date().getFullYear()}</span>
              </li>
              <li>
                <span>v{baseVersion}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any): any => ({
  data: state,
});

const mapDispatchToProps = {
  createGetPlanContactsData: action.createGetPlanContactsRequest,
};

export default connect<stateProps, dispatchProps, footerProps>(mapStateToProps, mapDispatchToProps)(Footer);
