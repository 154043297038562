const baseVersion = process.env.REACT_APP_VERSION;
const sdkApiKey = process.env.REACT_APP_SDK_API_KEY;
const sdkBaseUrl = process.env.REACT_APP_SDK_BASE_URL;
const applicatioInsightUrl = process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;
const vimApiKey = process.env.REACT_APP_VIM_API_KEY;
const vimEnvironment = process.env.REACT_APP_VIM_ENVIRONMENT;
const carouselUrl = 'https://www.canopyhealth.com/wp-json/wp/v2/posts/';
const oktaUrl = process.env.REACT_APP_OKTA_URL;
const sessionRedirectUrl = process.env.REACT_APP_SESSION_REDIRECT_URL;
const ssoRedirectUrl = process.env.REACT_APP_SSO_REDIRECT_URL;
const oktaClientId = process.env.REACT_APP_OKTA_CLIENT_ID;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export {
  baseVersion,
  carouselUrl,
  firebaseConfig,
  sdkApiKey,
  sdkBaseUrl,
  applicatioInsightUrl,
  vimApiKey,
  vimEnvironment,
  oktaUrl,
  sessionRedirectUrl,
  ssoRedirectUrl,
  oktaClientId,
};
