/* eslint-disable no-underscore-dangle */
import * as action from '@canopy/shared';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  // RECOVER_USERNAME,
  // SIGNIN_TXT,
  // UNLOCK_ACCOUNT,
  // USERNAME,
  WELCOME_MSG,
  WELCOME_TXT,
} from '../../shared/constant/AppConstants';
// import {
//   ERRORS_MSG,
//   PASSWORD_VALIDATION_EMTY_MSG,
//   USERNAME_VALIDATION_EMTY_MSG,
// } from '../../shared/constant/AppMessages';
// import {
//   APP_HOME_URL,
//   // DASHBOARD_PAGE_URL,
//   HELP_LINK,
//   TERMS_AND_CONDITION_URL,
//   // VERIFY_ACCOUNT_SETUP,
//   // VERIFY_LOGIN_OTP
// } from '../../shared/constant/Urls';
// import { history } from '../../shared/helperMethods/HelperMethod';
import ConfirmationModal from '../../shared/sharedComponents/confirmationModal/ComfirmationModal';
// import CreateAccountButton from '../../shared/sharedComponents/createAccountButton/CreateAccountButton';
// import Analytics, { FA } from '../../shared/utils/Analytics';
// import Auth from '../../shared/utils/Auth';
// import Images from '../../shared/utils/Images';
import './_Login.scss';

interface LoginState {
  userName?: string;
  password?: string;
  showError?: boolean;
  open?: any;
  showConfirmPassword?: boolean;
  showNewPassword?: boolean;
  ifIncorrectUserNamePass: boolean;
  ifLoginFailed: boolean;
}

interface OwnProps {}

interface DispatchProps {
  // createLoginRequest: typeof action.createLoginRequest;
  openCloseConfirmationModalRequest: typeof action.openCloseConfirmationModalRequest;
  createGetMainUnAuthConfigDataRequest: typeof action.createGetMainUnAuthConfigDataRequest;
}

interface StateProps {
  data: any;
}

type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

class Login extends React.Component<Props, LoginState> {
  initialState: any;

  constructor(prop: Props) {
    super(prop);
    this.initialState = {
      userName: '',
      password: '',
      ifIncorrectUserNamePass: false,
      ifLoginFailed: false,
    };
    this.state = this.initialState;
  }

  componentDidMount(): void {
    const { createGetMainUnAuthConfigDataRequest } = this.props;

    createGetMainUnAuthConfigDataRequest(() => {
      console.log('get unauth config success');
    });
  }

  componentWillUnmount(): any {
    this.setState(this.initialState);
  }

  handleChange = (e?: any): void => {
    const change: any = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  // handleLogin = (e: any): void => {
  //   e.preventDefault();
  //   const { userName, password } = this.state;
  //   const { createLoginRequest } = this.props;
  //   if (userName && userName !== '' && password && password !== '') {
  //     createLoginRequest({ username: userName, password }, (data: any, header: any) => {
  //       this.callBack(data, header);
  //     });
  //   } else {
  //     this.setState({ showError: true });
  //   }
  // };

  // callBack = (data: any, header: any): void => {
  //   const { userName, password } = this.state;
  //   if (data.isSuccess) {
  //     const {
  //       data: { mfa, profile, userId },
  //     } = data;
  //     if (mfa?.enabled) {
  //       history.push(`${VERIFY_LOGIN_OTP}/${mfa.factorType}`, {
  //         from: 'login',
  //         userData: { mfa, profile, userId, username: userName, password },
  //       } as {
  //         from: string;
  //         userData: any;
  //       });
  //     } else {
  //       Auth.setAccessToken(header?.access_token);
  //       Auth.setRefreshToken(header?.refresh_token);
  //       Auth.setSessionToken(header?.session_token);
  //       Auth.setUserId(data?.data?._id);
  //       window.location.href = APP_HOME_URL;
  //     }
  //   } else if (
  //     data?.data?.statusCode === LOGIN_ERROR_CODES.statusCode1511 &&
  //     data?.data?.data?.status === 'MFA_ENROLL'
  //   ) {
  //     history.push(VERIFY_ACCOUNT_SETUP, {
  //       from: 'login ',
  //       userData: {
  //         username: userName,
  //         password,
  //         email: data?.data?.data?.email,
  //         id: data?.data?.data?.userId,
  //         termsAndCondition: false,
  //       },
  //     } as {
  //       from: string;
  //       userData: any;
  //     });
  //   } else if (
  //     data?.data?.statusCode === LOGIN_ERROR_CODES.statusCode1502 ||
  //     data?.data?.statusCode === LOGIN_ERROR_CODES.statusCode1503
  //   ) {
  //     if (data?.data?.data?.status === 'MFA_REQUIRED') {
  //       const factor = data?.data?.data?._embedded?.factors[0];
  //       const user = data?.data?.data?._embedded?.user;
  //       history.push(`${VERIFY_LOGIN_OTP}/${factor.factorType}`, {
  //         from: 'login',
  //         userData: { user, factor },
  //       } as {
  //         from: string;
  //         userData: any;
  //       });
  //     } else if (data?.data?.statusCode === LOGIN_ERROR_CODES.statusCode1506) {
  //       this.setState({ ifIncorrectUserNamePass: true, ifLoginFailed: true });
  //     } else {
  //       this.setState({ ifIncorrectUserNamePass: true, ifLoginFailed: true });
  //     }
  //   } else if (data?.data?.statusCode === LOGIN_ERROR_CODES.statusCode1509) {
  //     this.setState({ ifLoginFailed: true });
  //     Analytics.logEvent(FA.LOGIN_ATTEMPT_FAIL_HEALTH_PLAN_ERROR);
  //     history.push(`${TERMS_AND_CONDITION_URL}/${data?.data?.data?.userId}`, {
  //       from: 'login',
  //       userData: { username: userName, password },
  //     } as {
  //       from: string;
  //       userData: any;
  //     });
  //   } else {
  //     this.setState({ ifLoginFailed: true });
  //   }
  // };

  render(): React.ReactNode {
    // const {
    //   userName,
    //   password,
    //   showError,
    //   showNewPassword,
    //   ifIncorrectUserNamePass,
    //   ifLoginFailed,
    // } = this.state;
    const { data, openCloseConfirmationModalRequest, location } = this.props;
    // const errorMessage = data?.Login?.errorData?.response?.data?.message;
    return (
      <>
        <div className='row' key={location?.key}>
          <div className='login-area px-0'>
            <div className='welcome-card'>
              <div className='welcome-html-container'>
                <div className='text parbase'>
                  <h1 style={{ marginTop: 40 }}>{WELCOME_TXT}</h1>
                  {WELCOME_MSG()}
                  {/* <p>
                    <a
                      href='https://itunes.apple.com/us/app/my-canopy-app/id1279407608'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <img src={Images.appStore} alt='Download on the Apple Store' width='120' />
                    </a>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.canopyhealth.mycanopy'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <img src={Images.googlePlay} alt='Download on Google Play' width='120' />
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='div-line col-12' />
        </div>
        {/* <CreateAccountButton /> */}
        <ConfirmationModal
          title='Account Creation Successful'
          message='Login to MyCanopyHealth now with your newly created username & Password'
          showModal={data.Login.show}
          closeModal={() => openCloseConfirmationModalRequest(false)}
        />

        {/* <ConfirmationModal
          title={data?.Login?.errorMsg?.split(';')[0]}
          message={data?.Login?.errorMsg?.split(';')[1]}
          showModal={ifIncorrectUserNamePass}
          closeModal={() => this.setState({ ifIncorrectUserNamePass: false })}
        /> */}
      </>
    );
  }
}
const mapStateToProps = (state: action.loginState): any => ({
  data: state,
});

const mapDispatchToProps: any = {
  createLoginRequest: action.createLoginRequest,
  openCloseConfirmationModalRequest: action.openCloseConfirmationModalRequest,
  createGetMainUnAuthConfigDataRequest: action.createGetMainUnAuthConfigDataRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
