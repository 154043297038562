import { useState, useEffect } from 'react';

const useWindowFocus = (): boolean => {
  const [focused, setFocus] = useState(true);
  const handleFocus = (): void => setFocus(true);
  const handleBlur = (): void => setFocus(false);

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  });

  return focused;
};

export default useWindowFocus;
